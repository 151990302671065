import { Link } from "react-router-dom";

export default function MoijeyFineJewelryDiamonds() {
    return (
        <section className="page-portfolio pt-5">
            <div className="container-xl portfolio-details mt-5">
                <div className="row pt-1 pt-sm-5">
                    <div className="col-lg-10 offset-lg-1 text-center">
                        <h2 className="title mb-5">Moijey Fine Jewelry & Diamonds</h2>
                    </div>
                </div>
            </div>
            <div className="container-xl portfolio-details mb-5">
                <img src="../../assets/images/portfolio/moijey-fine-jewelry/hero.webp" alt="" className="img-fluid w-100" />
            </div>
            <div className="container-xl portfolio-details mb-5">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="about-project">
                            <div className="row">
                                <div className="col-lg-6 mb-5 mb-lg-0">
                                    <h3>About this project </h3>
                                    <p>For Moijey Fine Jewelry & Diamonds, we developed a comprehensive e-commerce store and mobile app, enhancing user experience and streamlining online shopping for their exquisite jewelry collections. The platform features an intuitive interface, secure payment options, and seamless navigation, showcasing their luxurious diamonds and fine jewelry. The mobile app, compatible with both iOS and Android, provides personalized notifications, easy browsing, and a smooth checkout process. Our solution ensures a robust, scalable, and engaging shopping experience, driving customer satisfaction and sales growth for Moijey.</p>
                                </div>
                                <div className="col-lg-6">
                                    <div className="about-project-availability">
                                        <div className="row">
                                            <div className="col-sm-6 mb-4">
                                                <h6>Services</h6>
                                                <ul>
                                                    <li>UI/UX Designing</li>
                                                    <li>Project Management</li>
                                                    <li>Application development</li>
                                                    <li>Mobile app development</li>
                                                    <li>Quality Assurance</li>
                                                </ul>
                                            </div>
                                            <div className="col-sm-6 mb-4">
                                                <h6>Technology </h6>
                                                <ul>
                                                    <li>Figma</li>
                                                    <li>CodeIgniter PHP</li>
                                                    <li>Android</li>
                                                    <li>iOS</li>
                                                    <li>MySQL</li>
                                                </ul>
                                            </div>
                                        </div>

                                        <div className="availability">
                                            <a href="https://moijeydiamonds.com/" target="_blank"><img src="../../assets/images/link-web.webp" alt="Website" /></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-xl portfolio-details mb-5">
                <div className="row">
                    <div className="col-lg-10 offset-lg-1">
                        <h3>Meet Moijey Fine Jewelry & Diamonds</h3>
                        <p>Moijey Fine Jewelry & Diamonds has created an innovative e-commerce platform to bring its exquisite collections directly to consumers. Founded by jewelry experts with a passion for luxury and quality, Moijey offers a curated selection of fine jewelry that combines timeless elegance with modern design.</p>
                        <p>The online store is designed to provide a seamless shopping experience, featuring intuitive navigation, secure payment options, and personalized recommendations. The Moijey mobile app enhances this experience, offering easy browsing, exclusive notifications, and a streamlined checkout process. Committed to quality and customer satisfaction, Moijey continues to set the standard in online jewelry retail.</p>
                    </div>
                </div>
            </div>
            <div className="container-xl portfolio-details mb-5">
                <img src="../../assets/images/portfolio/moijey-fine-jewelry/image1.webp" alt="" className="img-fluid w-100" />
            </div>
            <div className="container-xl portfolio-details mb-5">
                <div className="row">
                    <div className="col-lg-10 offset-lg-1">
                        <h3>Moijey’s Growth Story</h3>
                        <p>Moijey Fine Jewelry & Diamonds started as a small brand with a vision for combining luxury jewelry with modern design. Through dedication to quality and a keen understanding of their audience, they quickly gained popularity. Launching their innovative e-commerce store and mobile app expanded their reach, offering seamless shopping experiences and personalized service. Strategic marketing and customer engagement initiatives further boosted brand loyalty and sales. Today, Moijey stands as a leading online jewelry retailer, continuously growing and setting new standards in the industry, with a passionate customer base and a strong presence in the luxury market.</p>
                    </div>
                </div>
            </div>
            <div className="container-xl portfolio-details mb-5">
                <img src="../../assets/images/portfolio/moijey-fine-jewelry/image2.webp" alt="" className="img-fluid w-100" />
            </div>
            <div className="container-xl portfolio-details mb-5">
                <div className="row">
                    <div className="col-lg-10 offset-lg-1">
                        <h3>What is Unique about Moijey Fine Jewelry & Diamonds</h3>
                        <p>Moijey Fine Jewelry & Diamonds stands out for its unique blend of timeless elegance and modern design, offering a distinctive luxury experience. Each piece is crafted with meticulous attention to detail, ensuring high-quality jewelry that exudes sophistication. What truly sets Moijey apart is their commitment to innovation in the jewelry retail space. Their e-commerce store and mobile app provide a seamless, user-friendly shopping experience, featuring secure payment options, personalized recommendations, and exclusive notifications. Additionally, Moijey maintains a strong connection with its audience through strategic marketing and customer engagement initiatives, fostering a loyal customer base. By continuously evolving their product offerings and incorporating customer feedback, Moijey ensures they stay ahead of jewelry trends while meeting the diverse needs of their clientele. This dedication to quality, innovation, and customer satisfaction makes Moijey a standout in the luxury jewelry industry.</p>
                    </div>
                </div>
            </div>
            <div className="container-xl portfolio-details mb-5">
                <img src="../../assets/images/portfolio/moijey-fine-jewelry/image3.webp" alt="" className="img-fluid w-100" />
            </div>
            <div className="container-xl portfolio-details mb-5">
                <div className="row">
                    <div className="col-lg-10 offset-lg-1">
                        <h3>Faced Challenges</h3>
                        <p>The existing backend system frequently experienced speed issues, causing headaches for the client.</p>
                        <p>Addressing these challenges required implementing robust data management systems, improving customer relationship management strategies, optimizing promotional campaigns, and enhancing order processing workflows. Successfully navigating these tasks demanded a combination of technology integration, staff training, and strategic planning. The ability to efficiently handle data, interact with customers, run promotions seamlessly, and manage orders played a pivotal role in achieving business success.</p>
                    </div>
                </div>
            </div>
            <div className="container-xl portfolio-details mb-5">
                <img src="../../assets/images/portfolio/moijey-fine-jewelry/image4.webp" alt="" className="img-fluid w-100" />
            </div>
            <div className="container-xl portfolio-details mb-5">
                <div className="row">
                    <div className="col-lg-10 offset-lg-1">
                        <h3>Project Approach</h3>
                        <p>The project began with discovery, analyzing client needs. Planning set milestones, timelines, and communication. Development optimized performance. Testing ensured quality. Successful implementation replaced old versions.</p>
                        <p>The overall approach to the project involved a systematic and comprehensive process. Starting with the discovery phase, the team thoroughly analyzed the client’s requirements, identified pain points, and determined the scope of the project. During the planning stage, they defined the project milestones, created a detailed timeline, and established communication channels for seamless collaboration. In the development phase, the team focused on front-end and back-end development, ensuring optimal performance and user experience. Extensive testing and quality assurance measures were implemented to identify and rectify any issues. The project was then implemented, with the new website and mobile app seamlessly replacing the previous versions.</p>
                        <p className="mb-2">Key Benefits</p>
                        <ul>
                            <li>Streamlined data management, customer interactions, promotions, and order processes.</li>
                            <li>Improved user experience through a visually appealing design and intuitive navigation.</li>
                            <li>Enhanced website and mobile app performance.</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="container-xl portfolio-details mb-5">
                <img src="../../assets/images/portfolio/moijey-fine-jewelry/image5.webp" alt="" className="img-fluid w-100" />
            </div>

            <div className="container-xl portfolio-details mb-5">
                <div className="row">
                    <div className="col-lg-10 offset-lg-1">
                        <h3>Results</h3>
                        <p>Following the implementation of the project, Moijey Fine Jewelry & Diamonds experienced numerous positive outcomes. The new backend system resolved the speed issues that previously plagued the client, resulting in a seamless and reliable platform for their business operations. The enhanced features allowed for better management of data, customers, promotions, and orders, leading to improved efficiency and customer satisfaction. The flawless website and cross-platform mobile app catered to both iOS and Android users, expanding the client’s reach and increasing their customer base. Overall, the project brought significant improvements to the client’s operations, ensuring a smooth and engaging experience for their users.</p>
                    </div>
                </div>
            </div>
            <div className="container-xl portfolio-details mb-5">
                <div className="row">
                    <div className="col-lg-8 offset-lg-2">
                        <div className="portfolio-quote">
                            <div className="content">
                                Webmaa's adaptability to our evolving needs and the steady stream of insightful feedback and ideas prove they were not just a partner – they were an indispensable asset in helping us reach our vision to democratize the fintech industry.
                            </div>
                            <div className="author">
                                <div className="auth-image">
                                    <img src="../../assets/images/portfolio/moijey-fine-jewelry/author.png" alt="author" />
                                </div>
                                <div className="auth-desig">
                                    <h6>User Name</h6>
                                    <p>Designation, Company Name</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-xl portfolio-nav mb-5">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="row">
                            <div className="col-6">
                                <Link to="/portfolio/maap" className="d-block pprev">
                                    <span className="fs-14 fw-500 text-black">Previous Project</span> <br />
                                    <span className="fs-13 text-gray d-none d-sm-block">Fast and Simple Website Builder<br /></span>
                                    <span className="fs-15 fw-600 text-secondary">MAAP</span>
                                </Link>
                            </div>
                            <div className="col-6">
                                <Link to="/portfolio/dukes-seafood" className="d-block pnext text-end">
                                    <span className="fs-14 fw-500 text-black">Next Project</span> <br />
                                    <span className="fs-13 text-gray d-none d-sm-block">Fast and Simple Website Builder<br /></span>
                                    <span className="fs-15 fw-600 text-secondary">Duke's Seafood</span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}