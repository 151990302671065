import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { Fragment } from "react/jsx-runtime";
import BlogGrid from "../../components/blog-grid";

const ServiceScreen = () => {
    useEffect(() => {
        ($('.owl_success_stories') as any).owlCarousel();
    }, []);
    return (
        <Fragment>
            <Helmet>
                <title>Our Services</title>
                <meta charSet="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
            </Helmet>
            <section className="inner-banner services overlay" style={{ backgroundImage: "url('../assets/images/banner-service.webp')" }}>
                <div className="container-xl">
                    <div className="row gx-3">
                        <div className="col-lg-10 offset-lg-1 text-center">
                            <h1 className="mb-4 text-white mb-sm-5">We craft apps and digital experiences for enterprises, mid-size companies, and startups.</h1>
                            <p className="lead fw-600">
                                <span className="text-red">Design</span><span className="dot"></span>
                                <span className="text-purple">Development</span><span className="dot"></span>
                                <span className="text-green">Consultancy</span><span className="dot"></span>
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="py-5">
                <div className="container-xl">
                    <div className="row gx-3">
                        <div className="col-lg-10 offset-lg-1 text-center">
                            <div className="services-subanner-content">
                                <h3 className="title mb-4">Exceptional products with <span className="text-red">end-to-end support</span> and expert guidance</h3>
                                <p className="lead">At Webmaa, we offer a complete range of services that build up
                                    business value, from the initial idea and formulation of product strategy, through building a
                                    prototype and testing it with users, right to the creation of the product itself.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="py-5">
                <div className="container-xl">
                    <div className="row gx-3 mb-4 mb-sm-5">
                        <div className="col-lg-8 col-md-12">
                            <div className="service-heading">
                                <h4>01</h4>
                                <h5>Discover</h5>
                                <h6>Ideas & Evaluation</h6>
                            </div>
                            <p className="lead fw-300">Give your product idea a shape. Plan and evaluate the essential features of your product to accomplish your business goals and eliminate possible mistakes.</p>
                        </div>
                    </div>

                    <div className="row-servicebox">
                        <div className="col-servicebox">
                            <div className="item-servicebox">
                                <div className="no">01</div>
                                <h6>Brainstorming</h6>
                                <p>A five-day workshop to answer crucial business questions, plan the essential features of your product and reduce the risk of bringing a product to the market.</p>
                            </div>
                        </div>

                        <div className="col-servicebox">
                            <div className="item-servicebox">
                                <div className="no">02</div>
                                <h6>Hypothesis</h6>
                                <p>A workshop aimed at shaping your business idea, answering questions regarding project planning, time estimation and budgeting.</p>
                            </div>
                        </div>

                        <div className="col-servicebox">
                            <div className="item-servicebox">
                                <div className="no">03</div>
                                <h6>Conceptualization</h6>
                                <p>An evaluation that will significantly enhance your product by resolving all UX issues, resulting in a truly appealing and streamlined user experience.</p>
                            </div>
                        </div>

                        <div className="col-servicebox">
                            <div className="item-servicebox">
                                <div className="no">04</div>
                                <h6>Assumptions</h6>
                                <p>Considering the task's scope and complexity helps identify improvement areas and understand how changes will impact the project's outcome.</p>
                            </div>
                        </div>

                        <div className="col-servicebox">
                            <div className="item-servicebox">
                                <div className="no">05</div>
                                <h6>Constraints</h6>
                                <p>To meet constraints like budget, timeline, resources, and skills, project managers must understand the requirements and plan to ensure timely delivery.</p>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            <section className="py-5 bg-success-stories">
                <div className="container-xl mb-4">
                    <div className="row gx-3">
                        <div className="col-lg-12">
                            <h5 className="mb-4 fw-600 text-black">Success stories</h5>
                        </div>
                    </div>
                    <div className="row gx-3">
                        <div className="col-lg-12">
                            <BlogGrid />
                        </div>
                    </div>
                </div>
            </section>

            <section className="py-5">
                <div className="container-xl">
                    <div className="row gx-3 mb-4 mb-sm-5">
                        <div className="col-lg-8 col-md-12">
                            <div className="service-heading">
                                <h4>02</h4>
                                <h5>Design</h5>
                                <h6> beautiful and usable products</h6>
                            </div>
                            <p className="lead fw-300">Product design strives to deliver beautiful and usable products that solve user problems and help you achieve your business goals.</p>
                        </div>
                    </div>

                    <div className="row-servicebox">
                        <div className="col-servicebox">
                            <div className="item-servicebox">
                                <div className="no">01</div>
                                <h6>Branding</h6>
                                <p>Build a strong brand, shape how consumers perceive your company, drive new business, and build trust with consumers.</p>
                            </div>
                        </div>

                        <div className="col-servicebox">
                            <div className="item-servicebox">
                                <div className="no">02</div>
                                <h6>User interface design</h6>
                                <p>Turn potential visitors to customers by delivering responsive and accessible user interface designs for your product.</p>
                            </div>
                        </div>

                        <div className="col-servicebox">
                            <div className="item-servicebox">
                                <div className="no">03</div>
                                <h6>User experience design</h6>
                                <p>Humanize your software user experience and provide additional value to your customers and your business.</p>
                            </div>
                        </div>

                        <div className="col-servicebox">
                            <div className="item-servicebox">
                                <div className="no">04</div>
                                <h6>Illustration</h6>
                                <p>Establish a unique and unmistakable visual language of your brand and product through custom-built illustrations.</p>
                            </div>
                        </div>

                        <div className="col-servicebox">
                            <div className="item-servicebox">
                                <div className="no">05</div>
                                <h6>UX writing</h6>
                                <p>Establish a competitive advantage with frictionless copy that nudges users to take</p>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            <section className="py-5 bg-success-stories">
                <div className="container-xl mb-4">
                    <div className="row gx-3">
                        <div className="col-lg-12">
                            <h5 className="mb-4 fw-600 text-black">Success stories</h5>
                        </div>
                    </div>
                    <div className="row gx-3">
                        <div className="col-lg-12">
                            <BlogGrid />
                        </div>
                    </div>
                </div>
            </section>

            <section className="py-5">
                <div className="container-xl">
                    <div className="row gx-3 mb-4 mb-sm-5">
                        <div className="col-lg-8 col-md-12">
                            <div className="service-heading">
                                <h4>03</h4>
                                <h5>Build</h5>
                                <h6>fast & secure web application</h6>
                            </div>
                            <p className="lead fw-300">Developing a successful digital product is a complex process that requires choosing the right partner, applying innovative solutions, and following reliable processes.</p>
                        </div>
                    </div>

                    <div className="row-servicebox">
                        <div className="col-servicebox">
                            <div className="item-servicebox">
                                <div className="no">01</div>
                                <h6>Wordpress</h6>
                                <p>Most popular website platforms in the world, used by millions of individuals, businesses, and organizations to create and manage websites.</p>
                            </div>
                        </div>

                        <div className="col-servicebox">
                            <div className="item-servicebox">
                                <div className="no">02</div>
                                <h6>Laravel</h6>
                                <p>Laravel is designed to  build robust, modern web applications quickly and efficiently. It also has a large ecosystem of packages and tools that can be easily integrated.</p>
                            </div>
                        </div>

                        <div className="col-servicebox">
                            <div className="item-servicebox">
                                <div className="no">03</div>
                                <h6>CodeIgniter</h6>
                                <p>A lightweight, open-source PHP web application, It is known for its small footprint, simple setup, and easy-to-use interface. </p>
                            </div>
                        </div>

                        <div className="col-servicebox">
                            <div className="item-servicebox">
                                <div className="no">04</div>
                                <h6>NodeJs</h6>
                                <p>Build performant web applications based on Javascript – one language for full-stack development.</p>
                            </div>
                        </div>

                        <div className="col-servicebox">
                            <div className="item-servicebox">
                                <div className="no">05</div>
                                <h6>Python</h6>
                                <p>High-level programming language for enterprise web development, scientific computing, data analysis, artificial intelligence, and many other fields.</p>
                            </div>
                        </div>
                        <div className="col-servicebox">
                            <div className="item-servicebox">
                                <div className="no">06</div>
                                <h6>React</h6>
                                <p>Build a strong brand, shape how consumers perceive your company, drive new business, and build trust with consumers.</p>
                            </div>
                        </div>
                        <div className="col-servicebox">
                            <div className="item-servicebox">
                                <div className="no">07</div>
                                <h6>VueJS</h6>
                                <p>Turn potential visitors to customers by delivering responsive and accessible user interface designs for your product.</p>
                            </div>
                        </div>
                        <div className="col-servicebox">
                            <div className="item-servicebox">
                                <div className="no">08</div>
                                <h6>Flutter</h6>
                                <p>Humanize your software user experience and provide additional value to your customers and your business.</p>
                            </div>
                        </div>
                        <div className="col-servicebox">
                            <div className="item-servicebox">
                                <div className="no">09</div>
                                <h6>MySQL</h6>
                                <p>Establish a unique and unmistakable visual language of your brand and product through custom-built illustrations.</p>
                            </div>
                        </div>
                        <div className="col-servicebox">
                            <div className="item-servicebox">
                                <div className="no">10</div>
                                <h6>GraphQL</h6>
                                <p>Establish a competitive advantage with frictionless copy that nudges users to take</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="py-5 bg-success-stories">
                <div className="container-xl mb-4">
                    <div className="row gx-3">
                        <div className="col-lg-12">
                            <h5 className="mb-4 fw-600 text-black">Success stories</h5>
                        </div>
                    </div>
                    <div className="row gx-3">
                        <div className="col-lg-12">
                            <BlogGrid />
                        </div>
                    </div>
                </div>
            </section>

            <section className="py-5">
                <div className="container-xl">
                    <div className="row gx-3 mb-4 mb-sm-5">
                        <div className="col-lg-8 col-md-12">
                            <div className="service-heading">
                                <h4>04</h4>
                                <h5>Build</h5>
                                <h6>impactful mobile application</h6>
                            </div>
                            <p className="lead fw-300">Providing battle-tested methodologies guarantees the best possible experience for the users.</p>
                        </div>
                    </div>

                    <div className="row-servicebox">
                        <div className="col-servicebox">
                            <div className="item-servicebox">
                                <div className="no">01</div>
                                <h6>iOS</h6>
                                <p>Develop top-quality iOS apps with cutting edge technology using objective C and Swift programming languages.</p>
                            </div>
                        </div>

                        <div className="col-servicebox">
                            <div className="item-servicebox">
                                <div className="no">02</div>
                                <h6>Android</h6>
                                <p>Building beautiful and scalable mobile applications with top performance using the Java and Kotlin programming languages.</p>
                            </div>
                        </div>

                        <div className="col-servicebox">
                            <div className="item-servicebox">
                                <div className="no">03</div>
                                <h6>React Native</h6>
                                <p>Take your idea to the next stage and accelerate your product's go-to-market with react native development.</p>
                            </div>
                        </div>

                        <div className="col-servicebox">
                            <div className="item-servicebox">
                                <div className="no">04</div>
                                <h6>Flutter</h6>
                                <p>Take your idea to the next stage and accelerate your product's go-to-market with Flutter development.</p>
                            </div>
                        </div>

                        <div className="col-servicebox">
                            <div className="item-servicebox">
                                <div className="no">05</div>
                                <h6>PWA</h6>
                                <p>Web-based applications offer a native-like experience, can be installed on mobile devices, accessed offline, and send push notifications.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="py-5 bg-success-stories">
                <div className="container-xl mb-4">
                    <div className="row gx-3">
                        <div className="col-lg-12">
                            <h5 className="mb-4 fw-600 text-black">Success stories</h5>
                        </div>
                    </div>
                    <div className="row gx-3">
                        <div className="col-lg-12">
                            <BlogGrid />
                        </div>
                    </div>
                </div>
            </section>

            <section className="py-5">
                <div className="container-xl">
                    <div className="row gx-3 mb-4 mb-sm-5">
                        <div className="col-lg-8 col-md-12">
                            <div className="service-heading">
                                <h4>05</h4>
                                <h5>Resources as a service</h5>
                                <h6>for you</h6>
                            </div>
                            <p className="lead fw-300">Spreading yourself too thin? Let our dedicated team handle all your development needs so that you can put your energy to good use with fewer, more critical stuff.</p>
                        </div>
                    </div>

                    <div className="row gx-3">
                        <div className="col-md-4 mb-4 mb-md-0">
                            <div className="hiring-box bg1">
                                <img src="../assets/images/hiring1.webp" alt="Time Material" className="img-fluid mb-4 mt-3 mt-lg-4" />
                                <h6 className="mb-4">Time &amp; <span className="text-red">Material</span></h6>
                                <p className="mb-0">We welcome the obscure vision through Time and Material Model that supports the Agile Development Process. This is a model where the client only pays for the time and resources spent on the project.</p>
                            </div>
                        </div>
                        <div className="col-md-4 mb-4 mb-md-0">
                            <div className="hiring-box bg2">
                                <img src="../assets/images/hiring2.webp" alt="Time Material" className="img-fluid mb-4 mt-3 mt-lg-4" />
                                <h6 className="mb-4">Fix Scope <span className="text-purple">Model</span></h6>
                                <p className="mb-0">We welcome the crystal clear vision of our clients and provide the best web and mobile app solutions through Fix Scope Model. Fix Scope Model is the best model to get the complete solution under a fixed budget.</p>
                            </div>
                        </div>
                        <div className="col-md-4 mb-4 mb-md-0">
                            <div className="hiring-box bg3">
                                <img src="../assets/images/hiring3.webp" alt="Time Material" className="img-fluid mb-4 mt-3 mt-lg-4" />
                                <h6 className="mb-4">Hire <span className="text-green">Team</span></h6>
                                <p className="mb-0">The Extended Team is a model where the client can control the entire development team remotely. It is cost-effective as the entire team is hired remotely and does not require the investment in the infrastructure still they have full control.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="py-5 bg-success-stories">
                <div className="container-xl mb-4">
                    <div className="row gx-3">
                        <div className="col-lg-12">
                            <h5 className="mb-4 fw-600 text-black">Success stories</h5>
                        </div>
                    </div>
                    <div className="row gx-3">
                        <div className="col-lg-12">
                            <BlogGrid />
                        </div>
                    </div>
                </div>
            </section>

            <section className="py-5">
                <div className="container-xl">
                    <div className="row gx-3 mb-4 mb-sm-5">
                        <div className="col-lg-8 col-md-12">
                            <div className="service-heading">
                                <h4>06</h4>
                                <h5>Support and management </h5>
                                <h6>services</h6>
                            </div>
                            <p className="lead fw-300">To develop a successful project is to to meet the ever changing market needs. We are here to support your growth.</p>
                        </div>
                    </div>

                    <div className="row-servicebox">
                        <div className="col-servicebox">
                            <div className="item-servicebox">
                                <div className="no">01</div>
                                <h6>Project Management</h6>
                                <p>Our clear communication and processes ensure the Project Manager delivers the project on time and within budget.</p>
                            </div>
                        </div>

                        <div className="col-servicebox">
                            <div className="item-servicebox">
                                <div className="no">02</div>
                                <h6>System Management</h6>
                                <p>We have rock-solid DevOps/SLA practices to help you leverage cloud technology speeding up development and increasing app security and scalability.</p>
                            </div>
                        </div>

                        <div className="col-servicebox">
                            <div className="item-servicebox">
                                <div className="no">03</div>
                                <h6>Quality Management</h6>
                                <p>"Quality assurance specialists ensure your project is released bug-free and provides a flawless user experience."</p>
                            </div>
                        </div>

                        <div className="col-servicebox">
                            <div className="item-servicebox">
                                <div className="no">04</div>
                                <h6>Performance monitor</h6>
                                <p>"Focus on building the right things. Our Product Managers validate ideas with the market to enhance product launch success."</p>
                            </div>
                        </div>

                        <div className="col-servicebox">
                            <div className="item-servicebox">
                                <div className="no">05</div>
                                <h6>Cybersecurity</h6>
                                <p>We offer a full range of cybersecurity services, including security audits, pentests, DevSecOps, consulting, and trainings.</p>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            <section className="py-5 bg-success-stories">
                <div className="container-xl mb-4">
                    <div className="row gx-3">
                        <div className="col-lg-12">
                            <h5 className="mb-4 fw-600 text-black">Success stories</h5>
                        </div>
                    </div>
                    <div className="row gx-3">
                        <div className="col-lg-12">
                            <BlogGrid />
                        </div>
                    </div>
                </div>
            </section>


            <section className="py-5">
                <div className="container-xl">
                    <div className="row gx-3 mb-4 mb-sm-5">
                        <div className="col-lg-8 col-md-12">
                            <div className="service-heading">
                                <h4>07</h4>
                                <h5>Digital Marketing </h5>
                                <h6>services</h6>
                            </div>
                            <p className="lead fw-300">Propel your business to new heights with our award-winning digital marketing services.</p>
                        </div>
                    </div>

                    <div className="row-servicebox">
                        <div className="col-servicebox">
                            <div className="item-servicebox">
                                <div className="no">01</div>
                                <h6>Market Research</h6>
                                <p>Our comprehensive market research services, delivering actionable insights to help businesses understand their market, identify opportunities, and drive strategic growth.</p>
                            </div>
                        </div>

                        <div className="col-servicebox">
                            <div className="item-servicebox">
                                <div className="no">02</div>
                                <h6>Brand Positioning</h6>
                                <p>Our brand positioning research services offer deep insights to define your brand's unique value, enhance market presence, and resonate with your target audience.</p>
                            </div>
                        </div>

                        <div className="col-servicebox">
                            <div className="item-servicebox">
                                <div className="no">03</div>
                                <h6>Content Marketing </h6>
                                <p>Webmaa's content marketing services create engaging, targeted content to boost your brand visibility, attract your audience, and drive meaningful customer interactions.</p>
                            </div>
                        </div>

                        <div className="col-servicebox">
                            <div className="item-servicebox">
                                <div className="no">04</div>
                                <h6>Search Engine optimization</h6>
                                <p>Our comprehensive SEO services enhance your online visibility, improve search rankings, and drive organic traffic to your website, ensuring sustained business growth.</p>
                            </div>
                        </div>

                        <div className="col-servicebox">
                            <div className="item-servicebox">
                                <div className="no">05</div>
                                <h6>PPC Campaign</h6>
                                <p>Our PPC campaign services deliver targeted advertising, maximizing your ROI with strategic, data-driven ad placements to boost traffic and conversions.</p>
                            </div>
                        </div>

                        <div className="col-servicebox">
                            <div className="item-servicebox">
                                <div className="no">06</div>
                                <h6>Video Marketing</h6>
                                <p>Webmaa's video marketing services create compelling, high-quality videos to engage your audience, enhance brand awareness, and drive higher conversion rates.</p>
                            </div>
                        </div>

                        <div className="col-servicebox">
                            <div className="item-servicebox">
                                <div className="no">07</div>
                                <h6>Email Marketing</h6>
                                <p>Craft personalized, impactful campaigns to nurture leads, retain customers, and drive conversions for your business.</p>
                            </div>
                        </div>

                        <div className="col-servicebox">
                            <div className="item-servicebox">
                                <div className="no">08</div>
                                <h6>SMS Marketing</h6>
                                <p>Deliver timely, targeted messages to engage customers, boost brand loyalty, and drive instant responses and conversions.</p>
                            </div>
                        </div>

                        <div className="col-servicebox">
                            <div className="item-servicebox">
                                <div className="no">09</div>
                                <h6>WhastApp Marketing</h6>
                                <p>Webmaa's WhatsApp marketing services use direct messaging to engage customers, deliver personalized content, and drive real-time interactions and conversions.</p>
                            </div>
                        </div>

                        <div className="col-servicebox">
                            <div className="item-servicebox">
                                <div className="no">10</div>
                                <h6>Marketing Automation</h6>
                                <p>Webmaa's marketing automation services streamline and optimize your campaigns, automating tasks and workflows to increase efficiency, engagement, and overall marketing effectiveness.</p>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            <section className="py-5 bg-success-stories">
                <div className="container-xl mb-4">
                    <div className="row gx-3">
                        <div className="col-lg-12">
                            <h5 className="mb-4 fw-600 text-black">Success stories</h5>
                        </div>
                    </div>
                    <div className="row gx-3">
                        <div className="col-lg-12">
                            <BlogGrid />
                        </div>
                    </div>
                </div>
            </section>

            <section className="py-5">
                <div className="container-xl">
                    <div className="row gx-3">
                        <div className="col-lg-6 mb-4 mb-lg-0">
                            <h6 className="title mb-2">words from our clients</h6>
                            <h3 className="title mb-2">We believe in building lasting relationships.</h3>
                            <p className="fs-14 m-0">We have connected with amazing clients to enable them with all that the digital revolution has to offer. Seeing our clients passionate about their ideas makes us want to deliver exceedingly successful mobile applications and websites. And that’s what we strive to do everyday.</p>
                        </div>
                        <div className="col-lg-6">
                            <div className="row gx-3">
                                <div className="col-6">
                                    <div className="youtube_video_thumb">
                                        <img src="../assets/images/video-thumb1.webp" alt="Video 1" className="img-fluid w-100" />
                                        <a href="javascript:;" data-bs-toggle="modal" data-bs-target="#modalYtVideo1"><img src="../assets/images/YouTube-play.webp" /></a>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="youtube_video_thumb">
                                        <img src="../assets/images/video-thumb2.webp" alt="Video 2" className="img-fluid w-100" />
                                        <a href="javascript:;" data-bs-toggle="modal" data-bs-target="#modalYtVideo2"><img src="../assets/images/YouTube-play.webp" /></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="pb-5">
                <div className="container-xl">
                    <div className="start_project p-4 p-sm-5">
                        <div className="row gx-3">
                            <div className="col-lg-8 text-center text-lg-start mb-4 mb-lg-0 align-items-center">
                                <h2 className="mb-3 mb-lg-1 text-primary fw-500">Ready to start a project ?</h2>
                                <p className="lead fw-300 m-0">To start, use form to tell us about you and the project</p>
                            </div>
                            <div className="col-lg-4 text-center align-self-center text-lg-end">
                                <Link to="/contact" className="btn btn-primary">Let’s connect</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    );
}

export default ServiceScreen;