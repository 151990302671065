const HomeHiringModel = () => {
    return (
        <section className="pb-5">
            <div className="container-xl my-3 my-lg-4">
                <div className="row gx-3">
                    <div className="col-lg-10 offset-lg-1 text-center">
                        <h6 className="title">hiring models</h6>
                        <h3 className="title fw-300 mb-5">Whatever technology you need, our team of expert offshore developers has the skills. They have successfully delivered solutions across all major platforms.</h3>
                    </div>
                </div>
                <div className="row gx-3">
                    <div className="col-md-4 mb-4 mb-md-0">
                        <div className="hiring-box bg1">
                            <img src="assets/images/hiring1.webp" alt="Time Material" className="img-fluid  mb-4 mt-3 mt-lg-4" />
                            <h6 className="mb-4">Time &amp; <span className="text-red">Material</span></h6>
                            <p className="mb-0">We welcome the obscure vision through Time and Material Model that supports the Agile Development Process. This is a model where the client only pays for the time and resources spent on the project.</p>
                        </div>
                    </div>
                    <div className="col-md-4 mb-4 mb-md-0">
                        <div className="hiring-box bg2">
                            <img src="assets/images/hiring3.webp" alt="Time Material" className="img-fluid mb-4 mt-3 mt-lg-4" />
                            <h6 className="mb-4">Fix Scope <span className="text-purple">Model</span></h6>
                            <p className="mb-0">We welcome the crystal clear vision of our clients and provide the best web and mobile app solutions through Fix Scope Model. Fix Scope Model is the best model to get the complete solution under a fixed budget.</p>
                        </div>
                    </div>
                    <div className="col-md-4 mb-4 mb-md-0">
                        <div className="hiring-box bg3">
                            <img src="assets/images/hiring2.webp" alt="Time Material" className="img-fluid  mb-4 mt-3 mt-lg-4" />
                            <h6 className="mb-4">Hire <span className="text-green">Team</span></h6>
                            <p className="mb-0">The Extended Team is a model where the client can control the entire development team remotely. It is cost-effective as the entire team is hired remotely and does not require the investment in the infrastructure still they have full control.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default HomeHiringModel;