import { useEffect } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Fragment } from "react/jsx-runtime";

const Footer = () => {
    useEffect(() => {
        let userName: any = '';
        let contactNumber = '';
        let emailId = '';

        $('#chat-icon').click(function () {
            $('#chat-box').toggle();
            if ($('#chat-box').is(':visible')) {
                $(this).removeClass('la-comment').addClass('la-times');
                $('#user-input').val('');
                $('#user-input').show();
                $('#system-message').html('<div class="msg_div text_start"><img alt=""  src="assets/images/logo-icon.webp"><p>Hey, what\'s your name?</p></div>');
                $('#error-message').html('');
                userName = '';
                contactNumber = '';
                emailId = '';
            } else {
                $(this).removeClass('la-times').addClass('la-comment');
            }
        });

        $('#user-input').keypress(function (e) {
            if (e.which === 13) {
                const userInput: any = $(this).val();
                $(this).val('');
                let systemMessage = $('#system-message').html();

                if (!userName) {
                    if (userInput) {
                        userName = userInput;
                        systemMessage += `<div class="msg_div text_end"><p>${userName}</p><img alt=""  src="assets/images/chat-icon.webp"></div>`;
                        systemMessage += `<div class="msg_div text_start"><img alt=""  src="assets/images/logo-icon.webp"><p>May I know your mobile number?</p></div>`;
                        $('#error-message').html('');
                    } else {
                        $('#error-message').html(`Oops! Please enter your name.`);
                    }
                } else if (!contactNumber) {
                    if (userInput.length === 10 && !isNaN(userInput)) {
                        contactNumber = userInput;
                        systemMessage += `<div class="msg_div text_end"><p>${contactNumber}</p><img alt=""  src="assets/images/chat-icon.webp"></div>`;
                        systemMessage += `<div class="msg_div text_start"><img alt=""  src="assets/images/logo-icon.webp"><p>Please give your email id and you're done</p></div>`;
                        $('#error-message').html('');
                    } else {
                        $('#error-message').html(`I think there was a mistake, please enter 10 digits mobile number`);
                    }
                } else if (!emailId) {
                    if (validateEmail(userInput)) {
                        emailId = userInput;
                        systemMessage += `<div class="msg_div text_end"><p>${emailId}</p><img alt=""  src="assets/images/chat-icon.webp"></div>`;
                        $('#user-input').hide();
                        window.open(`https://wa.me/9836987431`, '_blank');
                        let formData = new FormData();
                        formData.append("first_name", userName?.split(" ")[0]);
                        formData.append("last_name", userName?.split(" ")[1]);
                        formData.append("email", emailId);
                        formData.append("phone", contactNumber);
                        fetch(`${process.env.REACT_APP_API_URL}contact`, {
                            method: 'POST',
                            body: formData
                        })
                            .then(res => res.json())
                            .then(response => {
                                console.log(response);
                                systemMessage += `<div class="msg_div text_start"><img alt="" src="../../assets/images/logo-icon.webp"><p> Thank you! We'll get in touch with you very soon.</p></div>`;
                                $('#system-message').html(systemMessage);
                            })
                            .catch(error => {
                                $('#error-message').html('Something went wrong !!!');
                            });
                    } else {
                        $('#error-message').html(`You entered something wrong in email id. Please try again.`);
                    }
                }
                $('#system-message').html(systemMessage);
            }
        });
    }, []);
    return (
        <Fragment>
            <footer className="py-5">
                <div className="container-xl">
                    <div className="row gx-3">
                        <div className="col-12 col-md-6 ft-lt">
                            <img alt="" className="mb-3" src="assets/images/logo.webp" />
                            <ul>
                                <li>
                                    <p className="mb-0 fw-500">Get Free Estimation</p>
                                    <p><a href="mailto:hello@webmaa.com" className="text-red">hello@webmaa.com</a></p>
                                    <p className="mb-0 w-500">Skype</p>
                                    <p><a href="https://web.skype.com/" target="_blank" className="text-red">hello.webmaa</a></p>
                                </li>
                                <li>
                                    <p className="mb-0 fw-500">Call</p>
                                    <p><a href="tel:+919830990076">+91 9830990076</a> <a href="tel:+919836987431">+91 9836987431</a></p>
                                </li>
                                <li>
                                    <p className="mb-0 fw-700"><strong>India</strong></p>
                                    <p className="mb-0 fw-400"><span className="fw-500">Godrej Genesis,</span><br />
                                        11 Floor, Sector 5,<br />
                                        Salt Lake City,<br />
                                        Kolkata 700091, WB</p>
                                </li>
                            </ul>
                        </div>
                        <div className="col-12 col-md-3">
                            <p className="mb-2 mb-md-4 mt-4 mt-md-0 fw-700 pl-2">Company</p>
                            <ul className="list-footer">
                                <li><Link to="/about">About us</Link></li>
                                <li><Link to="/career">Career</Link></li>
                                <li><Link to="/blog">Blog</Link></li>
                                <li><Link to="/hire-team">How to engage</Link></li>
                                <li><Link to="/faq">FAQ&rsquo;s</Link></li>
                                <li><Link to="/policies">Policies</Link></li>
                                <li><Link to="/sitemap">Sitemap</Link></li>
                                <li><Link to="/contact">Contact</Link></li>
                            </ul>
                        </div>
                        <div className="col-12 col-md-3">
                            <div className="pl-2">
                                <p className="mb-2 mb-md-4 mt-4 mt-md-0 fw-700">Digital Acceleration Editorial</p>
                                <p className="mb-2 fw-500 lh-12">Boost innovation with insights for change</p>
                                <form>
                                    <div className="footer-form mb-2">
                                        <input type="text" className="form-control" placeholder="Enter your email id" />
                                        <button type="submit">Submit</button>
                                    </div>
                                    <div className="form-check">
                                        <label className="form-check-label fs-13 d-flex gap-2 lh-12"> <input className="form-check-input me-2" type="checkbox" id="check1" name="option1" value="something" /> I agree to receive marketing communication from Webmaa</label>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            <div className="py-4 copyright">
                <div className="container-xl">
                    <div className="row gx-3">
                        <div className="col-lg-7 mb-3 mb-lg-0 text-center text-lg-start">
                            <p>&copy; 2024 Webmaa. All rights reserved. &nbsp;|&nbsp; <a href="works/">Portfolio</a> &nbsp;|&nbsp; <a href="blog/">Blog</a> &nbsp;|&nbsp; <a href="policies/">Privacy Policy</a> &nbsp;|&nbsp; <a href="sitemap/">Sitemap</a></p>
                        </div>
                        <div className="col-lg-5 text-center text-lg-end">
                            <p className="footer-social">Follow us on &nbsp; &nbsp;
                                <a href="https://www.facebook.com/Webmaa24" target="_blank">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="20" height="20"><path className="social_icon" d="M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64h98.2V334.2H109.4V256h52.8V222.3c0-87.1 39.4-127.5 125-127.5c16.2 0 44.2 3.2 55.7 6.4V172c-6-.6-16.5-1-29.6-1c-42 0-58.2 15.9-58.2 57.2V256h83.6l-14.4 78.2H255V480H384c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64z" /></svg>
                                </a>
                                <a href="https://www.instagram.com/webmaa24/" target="_blank">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="20" height="20"><path className="social_icon" d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z" /></svg>
                                </a>
                                <a href="https://www.linkedin.com/company/webmaa/" target="_blank">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="20" height="20"><path className="social_icon" d="M194.4 211.7a53.3 53.3 0 1 0 59.3 88.7 53.3 53.3 0 1 0 -59.3-88.7zm142.3-68.4c-5.2-5.2-11.5-9.3-18.4-12c-18.1-7.1-57.6-6.8-83.1-6.5c-4.1 0-7.9 .1-11.2 .1c-3.3 0-7.2 0-11.4-.1c-25.5-.3-64.8-.7-82.9 6.5c-6.9 2.7-13.1 6.8-18.4 12s-9.3 11.5-12 18.4c-7.1 18.1-6.7 57.7-6.5 83.2c0 4.1 .1 7.9 .1 11.1s0 7-.1 11.1c-.2 25.5-.6 65.1 6.5 83.2c2.7 6.9 6.8 13.1 12 18.4s11.5 9.3 18.4 12c18.1 7.1 57.6 6.8 83.1 6.5c4.1 0 7.9-.1 11.2-.1c3.3 0 7.2 0 11.4 .1c25.5 .3 64.8 .7 82.9-6.5c6.9-2.7 13.1-6.8 18.4-12s9.3-11.5 12-18.4c7.2-18 6.8-57.4 6.5-83c0-4.2-.1-8.1-.1-11.4s0-7.1 .1-11.4c.3-25.5 .7-64.9-6.5-83l0 0c-2.7-6.9-6.8-13.1-12-18.4zm-67.1 44.5A82 82 0 1 1 178.4 324.2a82 82 0 1 1 91.1-136.4zm29.2-1.3c-3.1-2.1-5.6-5.1-7.1-8.6s-1.8-7.3-1.1-11.1s2.6-7.1 5.2-9.8s6.1-4.5 9.8-5.2s7.6-.4 11.1 1.1s6.5 3.9 8.6 7s3.2 6.8 3.2 10.6c0 2.5-.5 5-1.4 7.3s-2.4 4.4-4.1 6.2s-3.9 3.2-6.2 4.2s-4.8 1.5-7.3 1.5l0 0c-3.8 0-7.5-1.1-10.6-3.2zM448 96c0-35.3-28.7-64-64-64H64C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V96zM357 389c-18.7 18.7-41.4 24.6-67 25.9c-26.4 1.5-105.6 1.5-132 0c-25.6-1.3-48.3-7.2-67-25.9s-24.6-41.4-25.8-67c-1.5-26.4-1.5-105.6 0-132c1.3-25.6 7.1-48.3 25.8-67s41.5-24.6 67-25.8c26.4-1.5 105.6-1.5 132 0c25.6 1.3 48.3 7.1 67 25.8s24.6 41.4 25.8 67c1.5 26.3 1.5 105.4 0 131.9c-1.3 25.6-7.1 48.3-25.8 67z" /></svg>
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="floating-chat">
                <div className="text-end chat_icon mb-2"><i className="la la-comment ripple-effect text-secndary" id="chat-icon"></i></div>
                <div className="chat-box" id="chat-box">
                    <div className="chat-content" id="chat-content">
                        <div id="system-message"><h5 className="text-primary my-4">Hey, what's your name?</h5></div>
                        <input type="text" id="user-input" placeholder="Type your message..." className="form-control fs-15 mb-2" />
                        <p className="m-0"><small id="error-message" className="text-danger"></small></p>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default Footer;