// define a child component for the home screen

const HomeBanner = () => {
    return (
        <section className="banner hero-banner">
            <div className="hero-content-wrap">
                <div className="container-xl">
                    <div className="row gx-3">
                        <div className="col-lg-12">
                            <h1 className="m-0">We build <span className="text-purple">software</span><br />
                                that are <span className="text-red">scalable</span> and <span style={{ color: "var(--green)" }}>reliable</span><br />
                                in all work environment</h1>
                            <h3><span><span>.</span>Discover</span> <span><span className="text-red">.</span>Design</span> <span><span className="text-purple">.</span>Build</span> <span><span style={{ color: "var(--green)" }}>.</span>Deliver</span> <span><span className="text-yellow">.</span>Scale up</span></h3>
                            <div className="ourbrand">
                                <div className="item"><img alt="" className="img-fluid" src="assets/images/image12.webp" title="" /></div>
                                <div className="item"><img alt="" className="img-fluid" src="assets/images/image13.webp" title="" /></div>
                                <div className="item"><img alt="" className="img-fluid" src="assets/images/image14.webp" title="" /></div>
                                <div className="item"><img alt="" className="img-fluid" src="assets/images/image15.webp" title="" /></div>
                                <div className="item"><img alt="" className="img-fluid" src="assets/images/image16.webp" title="" /></div>
                                <div className="item"><img alt="" className="img-fluid" src="assets/images/image17.webp" title="" /></div>
                                <div className="item"><img alt="" className="img-fluid" src="assets/images/image31.webp" title="" /></div>
                                <div className="item"><img alt="" className="img-fluid" src="assets/images/image32.webp" title="" /></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default HomeBanner;