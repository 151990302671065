import { Link } from "react-router-dom";

export default function MohendraDutt() {
    return (
        <section className="page-portfolio pt-5">
            <div className="container-xl portfolio-details mt-5">
                <div className="row pt-1 pt-sm-5">
                    <div className="col-lg-10 offset-lg-1 text-center">
                        <h2 className="title mb-5">Mohendra Dutt</h2>
                    </div>
                </div>
            </div>
            <div className="container-xl portfolio-details mb-5">
                <img src="../../assets/images/portfolio/mohendra-dutt/hero.webp" alt="" className="img-fluid w-100" />
            </div>
            <div className="container-xl portfolio-details mb-5">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="about-project">
                            <div className="row">
                                <div className="col-lg-6 mb-5 mb-lg-0">
                                    <h3>About this project </h3>
                                    <p>For Mohendra Dutt, we overhauled their existing WooCommerce-based webstore to address numerous issues and enhance overall performance. The revamped platform features a modern interface, optimized navigation, and secure payment options, delivering a seamless shopping experience. Our solution ensures a robust, scalable, and engaging online presence, driving customer satisfaction and boosting sales for Mohendra Dutt.</p>
                                </div>
                                <div className="col-lg-6">
                                    <div className="about-project-availability">
                                        <div className="row">
                                            <div className="col-sm-6 mb-4">
                                                <h6>Services</h6>
                                                <ul>
                                                    <li>UI/UX Designing</li>
                                                    <li>Project Management</li>
                                                    <li>Application development</li>
                                                    <li>Mobile app development</li>
                                                    <li>Quality Assurance</li>
                                                </ul>
                                            </div>
                                            <div className="col-sm-6 mb-4">
                                                <h6>Technology </h6>
                                                <ul>
                                                    <li>Figma</li>
                                                    <li>CodeIgniter PHP</li>
                                                    <li>Android</li>
                                                    <li>iOS</li>
                                                    <li>MySQL</li>
                                                </ul>
                                            </div>
                                        </div>

                                        <div className="availability">
                                            <a href="https://www.mohendradutt.com/" target="_blank"><img src="../../assets/images/link-web.webp" alt="Website" /></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-xl portfolio-details mb-5">
                <div className="row">
                    <div className="col-lg-10 offset-lg-1">
                        <h3>Meet Mohendra Dutt</h3>
                        <p>Mohendra Dutt has revitalized their online presence with a newly optimized WooCommerce-based webstore designed to elevate their retail operations. Established with a commitment to offering high-quality products and exceptional customer service, Mohendra Dutt provides a diverse range of items through an enhanced ecommerce platform.</p>
                        <p>The updated webstore features an intuitive design, easy-to-navigate layout, and secure payment options, ensuring a streamlined shopping experience for customers. Optimized for both desktop and mobile devices, the platform allows users to browse, select, and purchase products effortlessly. Dedicated to customer satisfaction, Mohendra Dutt continues to set high standards in the online retail space.</p>
                    </div>
                </div>
            </div>
            <div className="container-xl portfolio-details mb-5">
                <img src="../../assets/images/portfolio/mohendra-dutt/image1.webp" alt="" className="img-fluid w-100" />
            </div>
            <div className="container-xl portfolio-details mb-5">
                <div className="row">
                    <div className="col-lg-10 offset-lg-1">
                        <h3>Mohendra Dutt’s  Growth Story</h3>
                        <p>Mohendra Dutt began as a local retailer with a vision to offer a wide range of quality products through a reliable online platform. Facing challenges with their previous WooCommerce store, they sought a comprehensive overhaul to enhance functionality and user experience. The launch of the revamped webstore marked a significant step forward, addressing performance issues and improving site usability. Strategic marketing and customer engagement efforts have further boosted their brand visibility and sales. Today, Mohendra Dutt stands as a leading online retailer, with a strong presence and growing customer base, continuously evolving to meet market demands.</p>
                    </div>
                </div>
            </div>
            <div className="container-xl portfolio-details mb-5">
                <img src="../../assets/images/portfolio/mohendra-dutt/image2.webp" alt="" className="img-fluid w-100" />
            </div>
            <div className="container-xl portfolio-details mb-5">
                <div className="row">
                    <div className="col-lg-10 offset-lg-1">
                        <h3>What is unique about Mohendra Dutt</h3>
                        <p>Mohendra Dutt stands out for its commitment to providing a superior online shopping experience through a revitalized WooCommerce-based webstore. Each aspect of the platform is designed to offer high performance and reliability, addressing previous issues with site speed and functionality. The updated store features an intuitive design, secure payment options, and enhanced navigation that caters to diverse customer needs. What sets Mohendra Dutt apart is their dedication to resolving previous challenges and continuously improving their e-commerce operations. By leveraging advanced WooCommerce features and incorporating user feedback, Mohendra Dutt ensures a smooth, engaging, and efficient shopping experience. This focus on quality and customer satisfaction makes Mohendra Dutt a standout in the online retail industry.</p>
                    </div>
                </div>
            </div>
            <div className="container-xl portfolio-details mb-5">
                <img src="../../assets/images/portfolio/mohendra-dutt/image3.webp" alt="" className="img-fluid w-100" />
            </div>
            <div className="container-xl portfolio-details mb-5">
                <div className="row">
                    <div className="col-lg-10 offset-lg-1">
                        <h3>Faced Challenges</h3>
                        <p>The existing WooCommerce store faced significant issues with performance, speed, and user experience, affecting the client’s ability to manage inventory and process orders efficiently.</p>
                        <p>Addressing these challenges required a comprehensive overhaul of the platform, including optimizing site performance, enhancing user interface design, and improving backend functionality. Successfully navigating these tasks involved advanced technology integration, performance tuning, and strategic planning. The ability to provide a smooth shopping experience, manage inventory effectively, and ensure fast loading times was crucial for achieving business success.</p>
                    </div>
                </div>
            </div>
            <div className="container-xl portfolio-details mb-5">
                <img src="../../assets/images/portfolio/mohendra-dutt/image4.webp" alt="" className="img-fluid w-100" />
            </div>
            <div className="container-xl portfolio-details mb-5">
                <div className="row">
                    <div className="col-lg-10 offset-lg-1">
                        <h3>Project Approach</h3>
                        <p>The project began with discovery, analyzing client needs. Planning set milestones, timelines, and communication. Development optimized performance. Testing ensured quality. Successful implementation replaced old versions.</p>
                        <p>The overall approach to the project involved a systematic and thorough process. Starting with the discovery phase, the team analyzed the client’s requirements, identified performance issues, and defined the scope of improvements. During the planning stage, milestones and timelines were established, and communication channels were set up for effective collaboration. In the development phase, the team focused on optimizing the WooCommerce platform, addressing performance issues, and enhancing the user interface. Extensive testing and quality assurance measures were implemented to ensure a flawless user experience. The project was then implemented, with the new webstore seamlessly replacing the previous version.</p>
                        <p className="mb-2">Key Benefits</p>
                        <ul>
                            <li>Resolved performance issues with an optimized WooCommerce platform.</li>
                            <li>Improved user experience through enhanced design and navigation.</li>
                            <li>Reliable and scalable platform optimized for efficient inventory management.</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="container-xl portfolio-details mb-5">
                <img src="../../assets/images/portfolio/mohendra-dutt/image5.webp" alt="" className="img-fluid w-100" />
            </div>
            <div className="container-xl portfolio-details mb-5">
                <div className="row">
                    <div className="col-lg-10 offset-lg-1">
                        <h3>Results</h3>
                        <p>Following the implementation of the project, Mohendra Dutt experienced numerous positive outcomes. The revamped WooCommerce-based webstore resolved previous performance issues, resulting in a more reliable and efficient platform for managing inventory and processing orders. The enhanced features provided a smoother and more engaging shopping experience, boosting customer satisfaction and sales. The optimized website catered to a growing customer base, improving Mohendra Dutt’s online presence and market reach. Overall, the project brought significant improvements to the client’s e-commerce operations, ensuring a seamless and effective experience for their users.</p>
                    </div>
                </div>
            </div>
            <div className="container-xl portfolio-details mb-5">
                <div className="row">
                    <div className="col-lg-8 offset-lg-2">
                        <div className="portfolio-quote">
                            <div className="content">
                                Webmaa's adaptability to our evolving needs and the steady stream of insightful feedback and ideas prove they were not just a partner – they were an indispensable asset in helping us reach our vision to democratize the fintech industry.
                            </div>
                            <div className="author">
                                <div className="auth-image">
                                    <img src="../../assets/images/portfolio/mohendra-dutt/author.png" alt="author" />
                                </div>
                                <div className="auth-desig">
                                    <h6>User Name</h6>
                                    <p>Designation, Company Name</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-xl portfolio-nav mb-5">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="row">
                            <div className="col-6">
                                <Link to="/portfolio/fectiv" className="d-block pprev">
                                    <span className="fs-14 fw-500 text-black">Previous Project</span> <br />
                                    <span className="fs-13 text-gray d-none d-sm-block">Fast and Simple Website Builder<br /></span>
                                    <span className="fs-15 fw-600 text-secondary">Fectiv</span>
                                </Link>
                            </div>
                            <div className="col-6">
                                <Link to="/portfolio/around-me" className="d-block pnext text-end">
                                    <span className="fs-14 fw-500 text-black">Next Project</span> <br />
                                    <span className="fs-13 text-gray d-none d-sm-block">Fast and Simple Website Builder<br /></span>
                                    <span className="fs-15 fw-600 text-secondary">AroundMe</span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </section>
    )
}