const HomeRecentWork = () => {
    return (
        <section className="pb-5">
            <div className="container-xl mt-3 mt-lg-4 px-3">
                <div className="row gx-3">
                    <div className="col-lg-8">
                        <h6 className="title">Recent Works</h6>
                        <h3 className="title fw-500 mb-5">Get a glimpse of 5% of our work. Discover our complete capabilities.</h3>
                    </div>
                </div>
            </div>
            <div className="container-xl mb-3 mb-lg-4 px-0 px-sm-3">
                <div className="owl-theme owl-carousel owl_recent_work nav_left_right">
                    <div className="item">
                        <div className="recent-box bg-black">
                            <div className="row gx-3">
                                <div className="col-md-6 col-lg-7 align-availability mb-3 mb-md-0">
                                    <h6 className="title text-white mt-0 mt-sm-3 mt-lg-5 mb-2 mb-md-3 text-white">Reason Clothing</h6>
                                    <h3 className="title mb-2 mb-sm-3 mb-lg-4 text-white">E-Commerce for Premium Streetwear in affordable priceing</h3>
                                    <p className="lead text-white mb-3 mb-sm-4 text-white">Technologies Used : Shopify, React Native</p>
                                    <div className="availability">
                                        <a href="https://apps.apple.com/us/app/reason-clothing/id1539090815" target="_blank"><img src="assets/images/link-appstore.webp" alt="App Store" /></a>
                                        <a href="https://play.google.com/store/apps/details?id=co.tapcart.app.id_7U6pfs4HPZ" target="_blank"><img src="assets/images/link-playstore.webp" alt="Google Play" /></a>
                                        <a href="https://reasonclothing.com/" target="_blank"><img src="assets/images/link-web.webp" alt="Website" /></a>
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-5  align-items-end">
                                    <a href="https://reasonclothing.com/" target="_blank"><img className="img-fluid" src="assets/images/work-reason.webp" title="" /></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="item">
                        <div className="recent-box bg-around">
                            <div className="row gx-3">
                                <div className="col-md-6 col-lg-7 align-availability mb-3 mb-md-0">
                                    <h6 className="title text-white mt-0 mt-sm-3 mt-lg-5 mb-2 mb-md-3 text-white">Around Me</h6>
                                    <h3 className="title mb-2 mb-sm-3 mb-lg-4 text-white">Quickly find out information about your surroundings</h3>
                                    <p className="lead text-white mb-3 mb-sm-4 text-white">Technologies Used : Swift</p>
                                    <div className="availability">
                                        <a href="https://apps.apple.com/in/app/aroundme/id290051590?platform=iphone" target="_blank"><img src="assets/images/link-appstore.webp" alt="App Store" /></a>
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-5  align-items-end">
                                    <a href="https://apps.apple.com/in/app/aroundme/id290051590?platform=iphone" target="_blank"><img className="img-fluid" src="assets/images/work-around-me.webp" title="" /></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="item">
                        <div className="recent-box bg-duke">
                            <div className="row gx-3">
                                <div className="col-md-6 col-lg-7 align-availability mb-3 mb-md-0">
                                    <h6 className="title text-white mt-0 mt-sm-3 mt-lg-5 mb-2 mb-md-3">Duke Seafood chain</h6>
                                    <h3 className="title mb-2 mb-sm-3 mb-lg-4">E-Commerce for Dine in restaurant chain</h3>
                                    <p className="lead text-white mb-3 mb-sm-4">Technologies Used : WordPress, React</p>
                                    <div className="availability">
                                        {/* <a href="javascript:;" target="_blank"><img src="assets/images/link-appstore.webp" alt="App Store"></a>
                                        <a href="javascript:;" target="_blank"><img src="assets/images/link-playstore.webp" alt="Google Play"></a> */}
                                        <a href="https://www.dukesseafood.com/" target="_blank"><img src="assets/images/link-web.webp" alt="Website" /></a>
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-5  align-items-end">
                                    <a href="https://www.dukesseafood.com/" target="_blank"><img className="img-fluid" src="assets/images/work-duke.webp" title="" /></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="item">
                        <div className="recent-box bg-moly">
                            <div className="row gx-3">
                                <div className="col-md-6 col-lg-7 align-availability mb-3 mb-md-0">
                                    <h6 className="title text-white mt-0 mt-sm-3 mt-lg-5 mb-2 mb-md-3">MOLEY</h6>
                                    <h3 className="title mb-2 mb-sm-3 mb-lg-4">Marketplace for Fine Jewelry and Diamonds </h3>
                                    <p className="lead text-white mb-3 mb-sm-4">Technologies Used : Shopify, ReactJs</p>
                                    <div className="availability">
                                        {/* <a href="javascript:;" target="_blank"><img src="assets/images/link-appstore.webp" alt="App Store" /></a>
                                        <a href="javascript:;" target="_blank"><img src="assets/images/link-playstore.webp" alt="Google Play" /></a> */}
                                        <a href="https://moijeydiamonds.com/" target="_blank"><img src="assets/images/link-web.webp" alt="Website" /></a>
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-5  align-items-end">
                                    <a href="https://moijeydiamonds.com/" target="_blank"><img className="img-fluid" src="assets/images/work-moly.webp" title="" /></a>
                                </div>
                            </div>
                        </div>
                    </div>



                    <div className="item">
                        <div className="recent-box bg-2">
                            <div className="row gx-3">
                                <div className="col-md-6 col-lg-7 align-availability mb-3 mb-md-0">
                                    <h6 className="title text-white mt-0 mt-sm-3 mt-lg-5 mb-2 mb-md-3">Craftoleather</h6>
                                    <h3 className="title mb-2 mb-sm-3 mb-lg-4">Tailored Laravel based eCommerce for leather bag products with multilingual features </h3>
                                    <p className="lead text-white mb-3 mb-sm-4">Technologies Used : LAMP, Laravel</p>
                                    <div className="availability">
                                        <a href="https://craftoleather.com/" target="_blank"><img src="assets/images/link-web.webp" alt="Website" /></a>
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-5  align-items-end">
                                    <a href="https://craftoleather.com/" target="_blank"><img className="img-fluid" src="assets/images/work2.webp" title="" /></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="item">
                        <div className="recent-box bg-3">
                            <div className="row gx-3">
                                <div className="col-md-6 col-lg-7 align-availability mb-3 mb-md-0">
                                    <h6 className="title text-white mt-0 mt-sm-3 mt-lg-5 mb-2 mb-md-3">NISE</h6>
                                    <h3 className="title mb-2 mb-sm-3 mb-lg-4">Research and development of solar by Govt. of India </h3>
                                    <p className="lead text-white mb-3 mb-sm-4">Technologies Used : LAMP, WordPress</p>
                                    <div className="availability">
                                        <a href="https://nise.res.in/" target="_blank"><img src="assets/images/link-web.webp" alt="Website" /></a>
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-5  align-items-end">
                                    <a href="https://nise.res.in/" target="_blank"><img className="img-fluid" src="assets/images/work3.webp" title="" /></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="item">
                        <div className="recent-box bg-bb">
                            <div className="row gx-3">
                                <div className="col-md-6 col-lg-7 align-availability mb-3 mb-md-0">
                                    <h6 className="title text-white mt-0 mt-sm-3 mt-lg-5 mb-2 mb-md-3">Bengal Beverages</h6>
                                    <h3 className="title mb-2 mb-sm-3 mb-lg-4">Laravel CMS based website for Coca-Cola franchisee bottler operating in 10 districs of West Bengal</h3>
                                    <p className="lead text-white mb-3 mb-sm-4">Technologies Used :  LAMP, React JS, Laravel </p>
                                    <div className="availability">
                                        <a href="https://bengalbeverages.net/" target="_blank"><img src="assets/images/link-web.webp" alt="Website" /></a>
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-5  align-items-end">
                                    <a href="https://bengalbeverages.net/" target="_blank"><img className="img-fluid" src="assets/images/work4.webp" title="" /></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="item">
                        <div className="recent-box bg-5">
                            <div className="row gx-3">
                                <div className="col-md-6 col-lg-7 align-availability mb-3 mb-md-0">
                                    <h6 className="title text-white mt-0 mt-sm-3 mt-lg-5 mb-2 mb-md-3">Fectiv</h6>
                                    <h3 className="title mb-2 mb-sm-3 mb-lg-4">Tailored WordPress CMS based website Builder for small businesses SaaS application. </h3>
                                    <p className="lead text-white mb-3 mb-sm-4">Technologies Used : LAMP, WordPress, Custom PHP</p>
                                    <div className="availability">
                                        <a href="https://fectiv.com/" target="_blank"><img src="assets/images/link-web.webp" alt="Website" /></a>
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-5  align-items-end">
                                    <a href="https://fectiv.com/" target="_blank"><img className="img-fluid" src="assets/images/work5.webp" title="" /></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="item">
                        <div className="recent-box bg-6">
                            <div className="row gx-3">
                                <div className="col-md-6 col-lg-7 align-availability mb-3 mb-md-0">
                                    <h6 className="title text-white mt-0 mt-sm-3 mt-lg-5 mb-2 mb-md-3">Mohendradutt</h6>
                                    <h3 className="title mb-2 mb-sm-3 mb-lg-4">Tailored WordPress based eCommerce for one of the oldest umbrella maker in India established in 1882. </h3>
                                    <p className="lead text-white mb-3 mb-sm-4">Technologies Used : LAMP, WordPress</p>
                                    <div className="availability">
                                        <a href="https://www.mohendradutt.com/" target="_blank"><img src="assets/images/link-web.webp" alt="Website" /></a>
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-5  align-items-end">
                                    <a href="https://www.mohendradutt.com/" target="_blank"><img className="img-fluid" src="assets/images/work6.webp" title="" /></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="item">
                        <div className="recent-box bg-7">
                            <div className="row gx-3">
                                <div className="col-md-6 col-lg-7 align-availability mb-3 mb-md-0">
                                    <h6 className="title text-white mt-0 mt-sm-3 mt-lg-5 mb-2 mb-md-3">Pailan World School</h6>
                                    <h3 className="title mb-2 mb-sm-3 mb-lg-4">Tailored WordPress CMS Build for School Website for Pailan Group with advanced CMS feature</h3>
                                    <p className="lead text-white mb-3 mb-sm-4">Technologies Used : LAMP, WordPress</p>
                                    <div className="availability">
                                        <a href="https://pailanworldschool.com/" target="_blank"><img src="assets/images/link-web.webp" alt="Website" /></a>
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-5  align-items-end">
                                    <a href="https://pailanworldschool.com/" target="_blank"><img className="img-fluid" src="assets/images/work7.webp" title="" /></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="item">
                        <div className="recent-box bg-8">
                            <div className="row gx-3">
                                <div className="col-md-6 col-lg-7 align-availability mb-3 mb-md-0">
                                    <h6 className="title text-white mt-0 mt-sm-3 mt-lg-5 mb-2 mb-md-3">Saino Pens</h6>
                                    <h3 className="title mb-2 mb-sm-3 mb-lg-4">Tailored WordPress CMS build for one of renowned brand of pen manufacturer in India, supply worldwide </h3>
                                    <p className="lead text-white mb-3 mb-sm-4">Technologies Used : LAMP, WordPress</p>
                                    <div className="availability">
                                        <a href="https://www.sainopens.com/" target="_blank"><img src="assets/images/link-web.webp" alt="Website" /></a>
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-5  align-items-end">
                                    <a href="https://www.sainopens.com/" target="_blank"><img className="img-fluid" src="assets/images/work8.webp" title="" /></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="item">
                        <div className="recent-box bg-9">
                            <div className="row gx-3">
                                <div className="col-md-6 col-lg-7 align-availability mb-3 mb-md-0">
                                    <h6 className="title text-white mt-0 mt-sm-3 mt-lg-5 mb-2 mb-md-3">Wiseasy</h6>
                                    <h3 className="title mb-2 mb-sm-3 mb-lg-4">Tailored WordPress CMS Build for Chinese payment solution provider and point of sales manufacturer.</h3>
                                    <p className="lead text-white mb-3 mb-sm-4">Technologies Used : LAMP, WordPress</p>
                                    <div className="availability">
                                        <a href="https://www.wiseasy.com/" target="_blank"><img src="assets/images/link-web.webp" alt="Website" /></a>
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-5  align-items-end">
                                    <a href="https://www.wiseasy.com/" target="_blank"><img className="img-fluid" src="assets/images/work9.webp" title="" /></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="item">
                        <div className="recent-box bg-1">
                            <div className="row gx-3">
                                <div className="col-md-6 col-lg-7 align-availability mb-3 mb-md-0">
                                    <h6 className="title text-white mt-0 mt-sm-3 mt-lg-5 mb-2 mb-md-3">RIDEWISE</h6>
                                    <h3 className="title mb-2 mb-sm-3 mb-lg-4">Delivery management system for small restaurants and business owners</h3>
                                    <p className="lead text-white mb-3 mb-sm-4">Technologies Used : LAMP, CI, Android, iOS, NodeJs</p>
                                    <div className="availability">
                                        <a href="https://www.figma.com/proto/eR9My8FiOC27pA1UNaT2Pp/Ridewise?page-id=215%3A411&node-id=215-412&viewport=71%2C299%2C0.14&t=qT6cXdxRJEAtyWA6-1&scaling=scale-down&content-scaling=fixed" target="_blank"><img src="assets/images/link-appstore.webp" alt="App Store" /></a>
                                        <a href="https://www.figma.com/proto/eR9My8FiOC27pA1UNaT2Pp/Ridewise?page-id=0%3A1&node-id=1-2&viewport=193%2C310%2C0.07&t=NPEJQAZ1qU0c93Nb-1&scaling=scale-down&content-scaling=fixed&starting-point-node-id=1%3A2" target="_blank"><img src="assets/images/link-playstore.webp" alt="Google Play" /></a>
                                        <a href="https://www.figma.com/design/eR9My8FiOC27pA1UNaT2Pp/Ridewise?node-id=0-1&t=IcDJqmZF4xAYbn46-0" target="_blank"><img src="assets/images/link-web.webp" alt="Website" /></a>
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-5  align-items-end">
                                    <a href="https://freeflow.zone/ridewise-electric-two-wheelers-for-the-future-businesses/" target="_blank"><img className="img-fluid" src="assets/images/work1.webp" title="" /></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </section>
    );
}

export default HomeRecentWork;