import { Fragment } from "react/jsx-runtime";
import ContactForm from "./contact-form";
import { Helmet } from "react-helmet";

const Contact = () => {
    return (
        <Fragment>
            <Helmet>
                <title>Contact Us</title>
                <meta charSet="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
            </Helmet>
            <section className="inner-banner contact">
                <div className="container-xl">
                    <div className="row gx-3">
                        <div className="col-lg-10 offset-lg-1 text-center">
                            <p className="lead-lg"></p>
                            <h1 className="mb-4 mb-sm-5">Get in <span className="text-green">touch</span> with us</h1>
                        </div>
                    </div>
                </div>
            </section>
            <ContactForm />
        </Fragment>
    )
}

export default Contact;