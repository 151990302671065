import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import { Fragment } from "react/jsx-runtime";

const BlogDetails = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [blogContentDetails, setBlogContentDetails] = useState<{
        id: number,
        title: string,
        content: string | TrustedHTML,
        slug: string,
        thumbnail_image: string,
        banner_image: string,
        publish_date: string,
        is_featured: boolean
    }>({
        id: 0,
        title: "",
        content: "",
        slug: "",
        thumbnail_image: "",
        banner_image: "",
        publish_date: "",
        is_featured: false
    });

    const getBlogDetails = () => {
        fetch(`${process.env.REACT_APP_API_URL}blogs/${id}`, {
            method: 'GET'
        })
            .then(res => res.json())
            .then(response => {
                // console.log(response);
                setBlogContentDetails(response.data);
            })
            .catch(error => {
                toast.error('Something went wrong !', {
                    position: "bottom-center",
                    theme: "dark"
                });
            });
    }

    useEffect(() => {
        getBlogDetails();
    }, []);
    return (
        <Fragment>
            <section className="inner-banner banner-slick blog-dtls">
                <div className="container-xl">
                    <div className="row">
                        <div className="col-lg-10 offset-lg-1 text-center">
                            <h6 className="title"><a href="#" onClick={() => navigate(-1)}><i className="la la-angle-left"></i> Our Blog</a></h6>
                        </div>
                    </div>
                </div>
            </section>
            <section className="py-5 blogpage">
                <div className="container-xl">
                    <div className="row mb-5">
                        <div className="col-lg-8 blog-details">
                            <h2>{blogContentDetails?.title}</h2>
                            <img src={blogContentDetails?.banner_image} alt="" className="img-fluid w-100" />
                            <p dangerouslySetInnerHTML={{ __html: blogContentDetails.content }} />
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    )
}

export default BlogDetails;