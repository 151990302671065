import { Fragment } from "react/jsx-runtime";

export default function FAQ() {
    return (
        <Fragment>
            <section className="inner-banner banner-slick faq">
                <div className="container-xl">
                    <div className="row gx-3">
                        <div className="col-lg-8 offset-lg-2 text-center">
                            <h6 className="title">FAQ'<sub>s</sub></h6>
                            <h4 className="mb-4 mb-sm-5"><span className="text-green">Frequently</span> <span className="text-orange">Asked</span> <span className="text-red">Questions</span></h4>
                        </div>
                    </div>
                </div>
            </section>
            <section className="mb-2">
                <div className="container-xl my-0 my-lg-4">
                    <div className="row">
                        <div className="col-lg-12">
                            <h4>General FAQ's</h4>
                        </div>
                    </div>
                    <div className="row mb-5">
                        <div className="col-lg-12">
                            <div className="accordion" id="generalAcco">
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#acco1" aria-expanded="true" aria-controls="acco1">
                                            Where is Webmaa based? What is the workforce?
                                        </button>
                                    </h2>
                                    <div id="acco1" className="accordion-collapse collapse show" data-bs-parent="#generalAcco">
                                        <div className="accordion-body">
                                            <p>Established in 2008, we are a premier offshore software development IT company based in Kolkata, India. Kolkata, located in the state of West Bengal, is one of the oldest cities in the country and is affectionately known as the 'City of Joy.' Our highly skilled team consists of over 50 talented professionals, with the majority working from our headquarters in Kolkata.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#acco2" aria-expanded="false" aria-controls="acco2">
                                            What is your core expertise and industry experience?
                                        </button>
                                    </h2>
                                    <div id="acco2" className="accordion-collapse collapse" data-bs-parent="#generalAcco">
                                        <div className="accordion-body">
                                            <p>Our team of exceptionally talented web and mobile app developers excels at creating outstanding mobile apps, websites, and games. When we launched in 2008, our focus was on graphic design and web design services. With the advent of smartphones, we expanded our expertise significantly. Over the past two decades, we have distinguished ourselves in web development, mobile app development, and game app development services.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#acco3" aria-expanded="false" aria-controls="acco3">
                                            Does your company provide a paid support contract?
                                        </button>
                                    </h2>
                                    <div id="acco3" className="accordion-collapse collapse" data-bs-parent="#generalAcco">
                                        <div className="accordion-body">
                                            <p>Yes, our company offers paid support contracts. You can request an extension of our support services after the project is completed. The charges for support vary depending on the project's size and scope. Please inquire about this once these details are defined.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#acco4" aria-expanded="false" aria-controls="acco4">
                                            What about the confidentiality of my idea?
                                        </button>
                                    </h2>
                                    <div id="acco4" className="accordion-collapse collapse" data-bs-parent="#generalAcco">
                                        <div className="accordion-body">
                                            <p>We understand the value of your idea and take its confidentiality very seriously. From the outset, we ensure your idea remains protected by signing a Non-Disclosure Agreement (NDA) before any discussions begin. Throughout the development process, we implement strict security and confidentiality measures to prevent unauthorized information sharing. Additionally, all our employees, including the software development teams, are bound by legal agreements to maintain security and trust..</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#acco5" aria-expanded="false" aria-controls="acco5">
                                            Do you reuse code?
                                        </button>
                                    </h2>
                                    <div id="acco5" className="accordion-collapse collapse" data-bs-parent="#generalAcco">
                                        <div className="accordion-body">
                                            <p>This is a nuanced question. We never reuse your proprietary algorithms, license keys, trade secrets, trademarks, or patented processes. However, to streamline development and enhance quality, we utilize open-source libraries and our own libraries. This approach helps save time and ensures you don't pay for something that can be efficiently produced using existing resources.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#acco6" aria-expanded="false" aria-controls="acco6">
                                            What is your project development methodology?
                                        </button>
                                    </h2>
                                    <div id="acco6" className="accordion-collapse collapse" data-bs-parent="#generalAcco">
                                        <div className="accordion-body">
                                            <p>Each web or mobile app development project is unique and demands a tailored approach. However, most projects follow these common development steps: requirement gathering, analysis, solution consulting, wireframing/prototyping, UI design, development, and testing. Depending on the project's scope and nature, the sequence and selection of these steps may vary.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#acco7" aria-expanded="false" aria-controls="acco7">
                                            Do you work according to the client's time zone?
                                        </button>
                                    </h2>
                                    <div id="acco7" className="accordion-collapse collapse" data-bs-parent="#generalAcco">
                                        <div className="accordion-body">
                                            <p>Our development team is based at our headquarters in Kolkata, India. However, we are flexible and can accommodate your time zone for special meetings and specific situations. We prefer scheduling these in advance to ensure we can plan our other commitments accordingly.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#acco8" aria-expanded="false" aria-controls="acco8">
                                            Can you send over the resources onsite for initial startup?
                                        </button>
                                    </h2>
                                    <div id="acco8" className="accordion-collapse collapse" data-bs-parent="#generalAcco">
                                        <div className="accordion-body">
                                            <p>Yes, if the project requires and where our representatives are available, we can send a resource onsite for the initial startup. Webmaa offers cost-effective solutions for global clients to hire dedicated developers at their locations. If we do not have an office in your city or country and the project is substantial, we will travel to your location from our corporate office.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#acco9" aria-expanded="false" aria-controls="acco9">
                                            What is the Quality Control Methodology which you have in place?
                                        </button>
                                    </h2>
                                    <div id="acco9" className="accordion-collapse collapse" data-bs-parent="#generalAcco">
                                        <div className="accordion-body">
                                            <p>We follow robust processes to ensure the highest quality. Our dedicated QA and QC division rigorously tests every project using the black-box method. They perform comprehensive testing to cover all aspects, providing the final approval before handover and deployment.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#acco10" aria-expanded="false" aria-controls="acco10">
                                            Do you have a Service Level Agreement in place?
                                        </button>
                                    </h2>
                                    <div id="acco10" className="accordion-collapse collapse" data-bs-parent="#generalAcco">
                                        <div className="accordion-body">
                                            <p>Yes, we do have. We have been a trusted offshore web and mobile app development company since 2008, adhering to protocols of the IT industry. We have a service level agreement in place as a symbol of trust and quality IT services delivery.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#acco11" aria-expanded="false" aria-controls="acco11">
                                            What’s your pricing model?
                                        </button>
                                    </h2>
                                    <div id="acco11" className="accordion-collapse collapse" data-bs-parent="#generalAcco">
                                        <div className="accordion-body">
                                            <p>We offer two primary pricing models to cater to different project needs. First is the 'Fixed Scope' model, where we provide a set timeframe and fixed price for the project. The second option is the 'Progressive Scope' model, ideal for projects whose size and details may evolve over time. For this, we assemble a team of diverse technology experts who work collaboratively with you as the project progresses. Given the flexibility required, charges are based on a monthly fee per team member. In both models, we outline payment milestones that correspond closely with project delivery stages.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#acco12" aria-expanded="false" aria-controls="acco12">
                                            How do we communicate with the project or software development team?
                                        </button>
                                    </h2>
                                    <div id="acco12" className="accordion-collapse collapse" data-bs-parent="#generalAcco">
                                        <div className="accordion-body">
                                            <p>We utilize Asana for project communications. Once you're signed up, we integrate you into our Asana platform alongside the Webmaa team assigned to your project. In addition to Asana, we also leverage Email and Skype for communication. Typically, you'll interact with a Project Manager and Tech Lead, but you're welcome to communicate directly with any team member involved in your project.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <h4>Hiring Models FAQ’s</h4>
                        </div>
                    </div>
                    <div className="row mb-5">
                        <div className="col-lg-12">
                            <div className="accordion" id="hiringAcco">
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#hiringAcco1" aria-expanded="false" aria-controls="HiringAcco1">
                                            Can I select my preferred developer? If yes, how do I do so?
                                        </button>
                                    </h2>
                                    <div id="hiringAcco1" className="accordion-collapse collapse" data-bs-parent="#hiringAcco">
                                        <div className="accordion-body">
                                            <p>You may choose your own software development team if you’re going for an Evolving project scope model. You have to share with us the profile of the ideal candidate and we’ll schedule interviews with you and our team members.</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#hiringAcco2" aria-expanded="false" aria-controls="HiringAcco2">
                                            Is there a minimum limit for hiring developers under the Progressive Scope model?
                                        </button>
                                    </h2>
                                    <div id="hiringAcco2" className="accordion-collapse collapse" data-bs-parent="#hiringAcco">
                                        <div className="accordion-body">
                                            <p>Well, there’s no limit as such. You can hire as little or as much as you think your project will require. However, we will certainly help you in composing the best team that helps you take your idea to the market with unmatched quality and customer service.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#hiringAcco3" aria-expanded="false" aria-controls="HiringAcco3">
                                            Is there any minimum period of hiring programmers for my project?
                                        </button>
                                    </h2>
                                    <div id="hiringAcco3" className="accordion-collapse collapse" data-bs-parent="#hiringAcco">
                                        <div className="accordion-body">
                                            <p>This is applicable to the Evolving Scope model. We require that you hire a team for at least 3 months minimum – so that they get to know your objectives, align closely with your team (if you have) and ensure all critical aspects of the projects are taken care of. During this period – they’ll not work on any of the projects except for yours. If you see you need shorter engagement, we’d recommend you go for a fixed price model.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#hiringAcco4" aria-expanded="false" aria-controls="HiringAcco4">
                                            Can I alter the scope during development?
                                        </button>
                                    </h2>
                                    <div id="hiringAcco4" className="accordion-collapse collapse" data-bs-parent="#hiringAcco">
                                        <div className="accordion-body">
                                            <p>Well, we always prefer that project scope is outlined and concluded upfront in case of Fixed Scope model (as the name suggests). Obviously, it is purely due to the planning and expectations set in terms of timeline and cost. While in the case of Progressive Scope model, you’re free to make alterations because you’ll be directly driving the team here – giving you enough room to set new goals, scope, timelines and everything else. Although – even in the later case, we recommend you plan small, execute it and then set up new goals.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#hiringAcco5" aria-expanded="false" aria-controls="HiringAcco5">
                                            I am not a techie. Will I still be able to work?
                                        </button>
                                    </h2>
                                    <div id="hiringAcco5" className="accordion-collapse collapse" data-bs-parent="#hiringAcco">
                                        <div className="accordion-body">
                                            <p>Certainly. While we absolutely love our clients to be technical, we also carry years of experience with us to fill in the gap for technology on your behalf.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#hiringAcco6" aria-expanded="false" aria-controls="HiringAcco6">
                                            My documentation and project scope are ready, and my requirements are clearly defined. What next?
                                        </button>
                                    </h2>
                                    <div id="hiringAcco6" className="accordion-collapse collapse" data-bs-parent="#hiringAcco">
                                        <div className="accordion-body">
                                            <p>It couldn’t have been better. If you know what you’d expect from the development team and know what to execute, it will require much less time to get started. We just need to understand everything you’ve got for us on the table and we can figure out the right engagement model for the project. We can provide you the estimates and the Fixed Scope model would just be right for you.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#hiringAcco7" aria-expanded="false" aria-controls="HiringAcco7">
                                            May I interview the developers I hire for my project?
                                        </button>
                                    </h2>
                                    <div id="hiringAcco7" className="accordion-collapse collapse" data-bs-parent="#hiringAcco">
                                        <div className="accordion-body">
                                            <p>Yes, you can! In the model where you build your own web or mobile app development team, it is possible to interview and evaluate the skills of programmers at Webmaa. Nevertheless, you are provided with each developer’s resume that contains information about their expertise and experience in the domain.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#hiringAcco8" aria-expanded="false" aria-controls="HiringAcco8">
                                            What technologies does Webmaa work with?
                                        </button>
                                    </h2>
                                    <div id="hiringAcco8" className="accordion-collapse collapse" data-bs-parent="#hiringAcco">
                                        <div className="accordion-body">
                                            <p>Oh we are absolutely in love with modern technologies. We carry tremendous enthusiasm to learn, adapt and implement new technologies. There is an array of technologies we have excelled at when it comes to Web including PHP and .NET – of course with a bunch of open source and SaaS products. For Mobile – we work with Native SDKs for iOS and Android. We also work at times with cross-platform tools such as Titanium, PhoneGap and Configure.IT. For creating stunning UI and Graphics, we use a combination of software such as Adobe Photoshop, Sketch, Affinity, and Corel Suites.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#hiringAcco9" aria-expanded="false" aria-controls="HiringAcco9">
                                            I have a technical team at my end. Can you work in conjunction with them?
                                        </button>
                                    </h2>
                                    <div id="hiringAcco9" className="accordion-collapse collapse" data-bs-parent="#hiringAcco">
                                        <div className="accordion-body">
                                            <p>Of course. We’d love to collaborate with fellow team members at your end who can make a great team along with our team. We recommend hiring a team of people from us – who can fill in the gap of your team to establish a perfect balance for your project.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <h4>Dedicated Team FAQ's</h4>
                        </div>
                    </div>
                    <div className="row mb-5">
                        <div className="col-lg-12">
                            <div className="accordion" id="dedicatedAcco">
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#dedicatedAcco1" aria-expanded="false" aria-controls="dedicatedAcco1">
                                            Why should I hire dedicated developers from Webmaa?
                                        </button>
                                    </h2>
                                    <div id="dedicatedAcco1" className="accordion-collapse collapse" data-bs-parent="#dedicatedAcco">
                                        <div className="accordion-body">
                                            <p>We’ve been in outsourcing service for more than two decades now. We were there when nobody was around, and we’re still going strong since 1998. We have had situations with clients and have continuously refined our internal operations, service quality and communication with the help of learnings of all these years. We have a very strong team bond, where we share a lot of things – good and bad. That’s what keeps us on our toes to ensure to excel at what we do – while we continuously learn from our mistakes.</p>
                                            <p>We are a perfect blend of young minds and experienced veterans in the industry. With 50+ people working with Webmaa for more than 8 years, our average age of the team is still below 28. This is absolutely a great sign for driving great ideas backed by even greater minds.  For more about us – please visit www.Webmaa.com/about.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#dedicatedAcco2" aria-expanded="false" aria-controls="dedicatedAcco2">
                                            During the execution, does your team completely rely on the client to provide all the information?
                                        </button>
                                    </h2>
                                    <div id="dedicatedAcco2" className="accordion-collapse collapse" data-bs-parent="#dedicatedAcco">
                                        <div className="accordion-body">
                                            <p>No. They don’t rely 100% on you for generic cases. Most of the time and more often than not – they’ll find out the solution by seeking advice, consulting and technical help from their peers, supervisors and experienced members within the team. However, the more the team knows about long term goals and you treat them as your own team, the better they can perform. All and every expectation shared during the early stage of engagement ensures meeting them over a period easier. Nobody likes surprises and it’s the communication at an appropriate time that can cover it up.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#dedicatedAcco3" aria-expanded="false" aria-controls="dedicatedAcco3">
                                            Just in case, our hosting services or any other related services are down, do you own the problem or you prefer to say “that’s not what we’re supposed to do”?
                                        </button>
                                    </h2>
                                    <div id="dedicatedAcco3" className="accordion-collapse collapse" data-bs-parent="#dedicatedAcco">
                                        <div className="accordion-body">
                                            <p>We believe it is our responsibility. However, something that’s within our reach or access – we can certainly chime in and help you to fix the issue even before it arises. However, in certain cases – our clients prefer to leave a few parts of the project to their own teams. In such cases, we can’t do much – except to guide you or show you the solution. To be able to “FIX” the problem itself – we need to have your permission along with the access.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#dedicatedAcco4" aria-expanded="false" aria-controls="dedicatedAcco4">
                                            Can you give a quote over a quick phone call?
                                        </button>
                                    </h2>
                                    <div id="dedicatedAcco4" className="accordion-collapse collapse" data-bs-parent="#dedicatedAcco">
                                        <div className="accordion-body">
                                            <p>Certainly. The pricing in terms of numbers is not difficult. However – as you’d expect, it is not JUST about the price and quote only. The core benchmark of pricing depends on a lot of factors such as composition of team, experience level required and type of engagement as well as the length of minimum commitment for the contract.</p>
                                            <p>Most critically – the actual solution that needs to be developed plays a vital role here. It depends a lot on whether the solution has to be built from ground up or work is required on a system that’s already built and is live. In both cases, there are many discussions that need to happen in order to craft a perfect and transparent cost model.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#dedicatedAcco5" aria-expanded="false" aria-controls="dedicatedAcco5">
                                            Do you have a public holiday calendar for days you’ll not be working?
                                        </button>
                                    </h2>
                                    <div id="dedicatedAcco5" className="accordion-collapse collapse" data-bs-parent="#dedicatedAcco">
                                        <div className="accordion-body">
                                            <p>Certainly. We will share our holiday calendar upon initiation process – and will also keep you informed a week in advance for any public holiday.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#dedicatedAcco6" aria-expanded="false" aria-controls="dedicatedAcco6">
                                            Do you allow developers to come to our offices and work with our local team?
                                        </button>
                                    </h2>
                                    <div id="dedicatedAcco6" className="accordion-collapse collapse" data-bs-parent="#dedicatedAcco">
                                        <div className="accordion-body">
                                            <p>Absolutely. This is totally possible. We can arrange for the developer(s) to visit your country and stay there to work with your team for a period. In such cases, you have to bear the costs of Visa, travel, accommodation and per diem expenses on top of their monthly fee. This can be discussed and may vary on a case-to-case basis.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#dedicatedAcco7" aria-expanded="false" aria-controls="dedicatedAcco7">
                                            How much experience do your developers carry?
                                        </button>
                                    </h2>
                                    <div id="dedicatedAcco7" className="accordion-collapse collapse" data-bs-parent="#dedicatedAcco">
                                        <div className="accordion-body">
                                            <p>We have developers from 1 year to 8 years of core programming, project management and QA experience. Many of them also have industry specific experience in terms of driving solutions catering to a domain. Having said that, our core expertise is programming. The more domain knowledge and expectations in terms of technical requirement and solution brief you provide, the better the development quality that can be exhibited.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#dedicatedAcco8" aria-expanded="false" aria-controls="dedicatedAcco8">
                                            Do you have certifications for your developers?
                                        </button>
                                    </h2>
                                    <div id="dedicatedAcco8" className="accordion-collapse collapse" data-bs-parent="#dedicatedAcco">
                                        <div className="accordion-body">
                                            <p>Yes. We recruit experienced developers who have done bachelors or masters in computer science, information technology and computer engineering. On top of that, we time and time again obtain certifications from applicable technology vendors to pass through their examination and eligibility process. We already have developers who have Magento Certification, Appcelerator Titanium Certification, Unity 3D certifications and many others at organization level.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#dedicatedAcco9" aria-expanded="false" aria-controls="dedicatedAcco9">
                                            Do your developers keep them updated with the latest in technology?
                                        </button>
                                    </h2>
                                    <div id="dedicatedAcco9" className="accordion-collapse collapse" data-bs-parent="#dedicatedAcco">
                                        <div className="accordion-body">
                                            <p>Staying updated in the modern technology world is not a choice. We totally understand the importance of staying up to date and not only just that, ensuring to update the knowledge which is trivial in terms of communication, technology trends, version updates etc. For the same, we conduct regular internal and external training programs as well as participate in online courses, to gain knowledge.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#dedicatedAcco10" aria-expanded="false" aria-controls="dedicatedAcco10">
                                            What if the developer decides to leave your company?
                                        </button>
                                    </h2>
                                    <div id="dedicatedAcco10" className="accordion-collapse collapse" data-bs-parent="#dedicatedAcco">
                                        <div className="accordion-body">
                                            <p>We require that anybody in our company who wishes to leave us – they notify their immediate supervisor, delivery head and HR team at least 2 months in advance. When they resign from their job and we accept their resignation – we immediately plan to switch the resource and initiate a handover process along with knowledge transfer (KT) to the new developer. During the transition the new developer takes up the front seat while the person leaving the job has to support and ensure the new developer is in full control of what is supposed to be worked upon. Before leaving the company, the resigned developer signs EXIT NDA with Webmaa, where they undersign the confidentiality and non-compete, non-sharing terms. They also handover all credentials and access and communication rights back to the relevant authorities at Webmaa.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#dedicatedAcco11" aria-expanded="false" aria-controls="dedicatedAcco11">
                                            Can the developer work in the same timezone as ours?
                                        </button>
                                    </h2>
                                    <div id="dedicatedAcco11" className="accordion-collapse collapse" data-bs-parent="#dedicatedAcco">
                                        <div className="accordion-body">
                                            <p>Our developers work out of our India Development Center. They work for 8 hours a day and can be available to work easily in any slot between 9:00 AM to 9:00 PM India Time (+5:30 GMT). It is totally fine if you need a meeting in a week for discussion during your timezone. However, if you require them to work dedicatedly during your timezone only – they can certainly be made available. In such cases – you have to pay a nominal additional fee to ensure they’re available during your timezone.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#dedicatedAcco12" aria-expanded="false" aria-controls="dedicatedAcco12">
                                            How do I begin once I have clearly identified what type of developer / designer I need?
                                        </button>
                                    </h2>
                                    <div id="dedicatedAcco12" className="accordion-collapse collapse" data-bs-parent="#dedicatedAcco">
                                        <div className="accordion-body">
                                            <p>Once you’ve identified the type of developer to be hired, you will be presented with a few ideal candidates’ profiles in the form of their resume. You can decide to interview them personally and see for yourself, which candidate(s) fit in the best for your team. Once you select a candidate, the actual project on boarding process is initiated at our end. We will raise the invoice for the 1st month’s engagement. Once you make the payment, the developer will be introduced to you and on the same day – you can start working with the member of the team assigned to you. Please refer to the on boarding graphic in this presentation or contact us for more detailed info.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#dedicatedAcco13" aria-expanded="false" aria-controls="dedicatedAcco13">
                                            Do you keep a check on my developers?
                                        </button>
                                    </h2>
                                    <div id="dedicatedAcco13" className="accordion-collapse collapse" data-bs-parent="#dedicatedAcco">
                                        <div className="accordion-body">
                                            <p>We have different teams called “Clusters” in place, where developers of similar skills or technology stack are supervised by a Cluster Head. The Cluster Head keeps a daily check on engagement of all team members working with him, to ensure they are fully engaged and facilitate the work. You can also ask them about an update and take their inputs during the engagement period.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#dedicatedAcco14" aria-expanded="false" aria-controls="dedicatedAcco14">
                                            Do you consistently (and proactively) offer new ways to improve and accelerate the implementation and performance of my web and mobile app development projects?
                                        </button>
                                    </h2>
                                    <div id="dedicatedAcco14" className="accordion-collapse collapse" data-bs-parent="#dedicatedAcco">
                                        <div className="accordion-body">
                                            <p>To help maximize profitability, efficiency and competitive advantage, we hold monthly review meetings with our clients. We are constantly looking for new ways to help improve their operations, lower costs, increase efficiencies and resolve any problems that arise. We have a very open and forward thinking communication philosophy designed deliberately to help clients stay one-step ahead.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#dedicatedAcco15" aria-expanded="false" aria-controls="dedicatedAcco15">
                                            Do you keep a backup resource available in case my regular developer is unavailable?
                                        </button>
                                    </h2>
                                    <div id="dedicatedAcco15" className="accordion-collapse collapse" data-bs-parent="#dedicatedAcco">
                                        <div className="accordion-body">
                                            <p>Honestly, there cannot be a 100% replacement on an immediate basis. In case of temporary unavailability of the developer due to sickness, emergencies or planned leaves – we compensate the working hours within the same week or same month. In case we can’t compensate for the loss of hours due to unavailability of the resource, those hours will carry over for the next month and will be compensated in the subsequent weeks.</p>
                                            <p>In case of a longer absence of the developer, we will certainly replace the resource with the new one – again following the same on boarding procedure that was used to place the original developer.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#dedicatedAcco16" aria-expanded="false" aria-controls="dedicatedAcco16">
                                            Do all of your developers speak English?
                                        </button>
                                    </h2>
                                    <div id="dedicatedAcco16" className="accordion-collapse collapse" data-bs-parent="#dedicatedAcco">
                                        <div className="accordion-body">
                                            <p>Yes. However, we certainly do not consider the ability to speak English as the ONLY benchmark of the development skill and intelligence. However, all of our developers are good at either writing or speaking english and can efficiently communicate. In case we have a very good developer who just cannot communicate to the extent you’d like – we will position a Project Manager level person to facilitate the communication and ensure there’s no communication loss and strategic focus.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#dedicatedAcco17" aria-expanded="false" aria-controls="dedicatedAcco17">
                                            Can I contact my developer directly over the phone?
                                        </button>
                                    </h2>
                                    <div id="dedicatedAcco17" className="accordion-collapse collapse" data-bs-parent="#dedicatedAcco">
                                        <div className="accordion-body">
                                            <p>Absolutely. We do not hide our team – and in the modern day of communication, we believe communication is the only way in which a lot of problems can be solved. We will share with you all contact info of our developers – their email address and Skype. In special cases, we don’t mind sharing directly phone numbers of the developers to be able to communicate with them instantly with tools like WhatsApp and Messages.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#dedicatedAcco18" aria-expanded="false" aria-controls="dedicatedAcco18">
                                            Do you guarantee response time?
                                        </button>
                                    </h2>
                                    <div id="dedicatedAcco18" className="accordion-collapse collapse" data-bs-parent="#dedicatedAcco">
                                        <div className="accordion-body">
                                            <p>We do. We commit that all of your requests are responded to within a 12 hour window. Although we ensure you’re well aware of the state of your project, tasks and any sensitive situations – we understand there may be cases where you need instant response. Please note that our response does not necessarily mean a “SOLUTION” at all times. We do handle emergencies and have a very good attentiveness during weekends as well. However, we would love to avoid emergencies during the weekend but at the same time, we totally stay on top of the situation in general cases.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#dedicatedAcco19" aria-expanded="false" aria-controls="dedicatedAcco19">
                                            Whom do I speak to in case I need some inputs, ideas or consultation?
                                        </button>
                                    </h2>
                                    <div id="dedicatedAcco19" className="accordion-collapse collapse" data-bs-parent="#dedicatedAcco">
                                        <div className="accordion-body">
                                            <p>We’re all ears in case you have something to say. We love talking about industry, insights, sharing our learnings, experiences with other clients, and similar situations that give us new insights. Just let the developer, the project manager or the BDM be in touch with you and we are just a call away.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#dedicatedAcco20" aria-expanded="false" aria-controls="dedicatedAcco20">
                                            What about Security?
                                        </button>
                                    </h2>
                                    <div id="dedicatedAcco20" className="accordion-collapse collapse" data-bs-parent="#dedicatedAcco">
                                        <div className="accordion-body">
                                            <p>We are equally alert about security as you are. All of our servers are behind a very secure firewall that prevents outside intrusion. Moreover, any sort of personal storage devices and laptops and cloud storage access is prevented in all computers in our network. So your data and code are 100% secure when they reside on our servers. Additionally, we have a very well defined IT policy, that imposes stringent rules for preventing unauthorized software installation with workgroup set up, personal device provisioning and remote access prevention.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#dedicatedAcco21" aria-expanded="false" aria-controls="dedicatedAcco21">
                                            Where do you keep the source code / assets of the work done? Are they always available?
                                        </button>
                                    </h2>
                                    <div id="dedicatedAcco21" className="accordion-collapse collapse" data-bs-parent="#dedicatedAcco">
                                        <div className="accordion-body">
                                            <p>It depends on the situation. Most of the time, we work on development servers set up on our end. Once you review and approve the work, the changes are uploaded on the live server. In case you already have a DevOps set up with development and live servers, we are absolutely happy to use the same and continue working directly on your own servers.</p>
                                            <p>The advantage of working with our own servers is the speed of development, quick debugging and freedom to try at server level without hindering the actual live servers.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#dedicatedAcco22" aria-expanded="false" aria-controls="dedicatedAcco22">
                                            What are the payment terms?
                                        </button>
                                    </h2>
                                    <div id="dedicatedAcco22" className="accordion-collapse collapse" data-bs-parent="#dedicatedAcco">
                                        <div className="accordion-body">
                                            <p>Our payment process and schedule is extremely simple. It follows the simple principle of a Subscription model you may already be aware of.</p>
                                            <p>We require the receipt of the fees of engaged resources in advance before the work begins.</p>
                                            <p>When you are ready to start, you’ll pay for the 1 month advance fee for the engaged resources. For the subsequent month – we will raise the invoice on 22nd the month, so that you can ideally process the payment within 7 days (before the month ends).</p>
                                            <p>This prevents you from overpayment, as well as timely reimbursement for us to keep the team engaged with your project. For the fastest payment method, we prefer direct WIRE Transfer to our US or India bank. Alternatively, you can pay via PayPal or Credit Card as well. All the information of payment will be present in the invoice you’ll receive.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#dedicatedAcco23" aria-expanded="false" aria-controls="dedicatedAcco23">
                                            Why do I need to pay in advance?
                                        </button>
                                    </h2>
                                    <div id="dedicatedAcco23" className="accordion-collapse collapse" data-bs-parent="#dedicatedAcco">
                                        <div className="accordion-body">
                                            <p>Very good question. The advance payment ensures we 100% commit the resource to you and not have him/her engage in any other runtime work despite any classification of the work. Having cleared the payment will ensure we can provide you continuous delivery without hindrance including the source code all the time. You’ll be in full custody of the work done so far and our written commitment to engage the resource for your work.</p>
                                            <p>Additionally, it is a good practice to maintain a payment-to-work association to build confidence and trust in each other for providing quality and uninterrupted services.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#dedicatedAcco24" aria-expanded="false" aria-controls="dedicatedAcco24">
                                            Do you offer Refund?
                                        </button>
                                    </h2>
                                    <div id="dedicatedAcco24" className="accordion-collapse collapse" data-bs-parent="#dedicatedAcco">
                                        <div className="accordion-body">
                                            <p>We will offer you a refund if the developer is completely unable to provide you the work needed. If the work is satisfactory, but is not according to your standards – you’ll be charged for the efforts the developer has put in, on a PRO-RATA basis. The outstanding amount will be refunded back.</p>
                                            <p>For the refund to be processed, we will insist you to try an alternative developer with a proper replacement if you wish. The replacement doesn’t cost you anything. We have seen a replacement working out very well almost all the time.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#dedicatedAcco25" aria-expanded="false" aria-controls="dedicatedAcco25">
                                            What do you mean by 100% Guarantee?
                                        </button>
                                    </h2>
                                    <div id="dedicatedAcco25" className="accordion-collapse collapse" data-bs-parent="#dedicatedAcco">
                                        <div className="accordion-body">
                                            <p>In case of refund, the amount in question will be only for the current billing cycle. As we will provide streamed updates to you at all times, any discrepancy in the expectations versus reality has to be escalated to the relevant person immediately. We won’t be able to refund for the work that’s been presented and been properly reviewed and acknowledged by you.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#dedicatedAcco26" aria-expanded="false" aria-controls="dedicatedAcco26">
                                            When can I request for refund?
                                        </button>
                                    </h2>
                                    <div id="dedicatedAcco26" className="accordion-collapse collapse" data-bs-parent="#dedicatedAcco">
                                        <div className="accordion-body">
                                            <p>Before we can process any request for a refund – it is fair on our part to correct the situation and be given a chance. We know you don’t pay to get a refund some day. You pay to get quality work. Therefore, we require you to escalate the dissatisfaction of the work to the immediate supervisor or the relevant person as per escalation channel. If we are not able to resolve the case after 3 such iterations in a billing cycle, you’ve got a case for the refund.</p>
                                            <p>In case of any dissatisfaction from the developer, we will require prior notifications for allow us to look into the matter immediately and take necessary counter measures to improve quality and lead the project in the right direction. In absence of any communication of such degree of dissatisfaction within a reasonable period, the notice period for termination as per mentioned in the contract shall apply.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#dedicatedAcco27" aria-expanded="false" aria-controls="dedicatedAcco27">
                                            Can I hire on a Part-Time basis?
                                        </button>
                                    </h2>
                                    <div id="dedicatedAcco27" className="accordion-collapse collapse" data-bs-parent="#dedicatedAcco">
                                        <div className="accordion-body">
                                            <p>Yes. We do have that option for special skills like Graphic designer, UI / UX Designer, Project Manager. Part-Time engagement enables the team member to be available for consecutive 4 hours per day on all working days in a week.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#dedicatedAcco28" aria-expanded="false" aria-controls="dedicatedAcco28">
                                            Do you offer discounts?
                                        </button>
                                    </h2>
                                    <div id="dedicatedAcco28" className="accordion-collapse collapse" data-bs-parent="#dedicatedAcco">
                                        <div className="accordion-body">
                                            <p>Everybody loves discounts, isn’t it? No problem. We can certainly consider some, in case you’re looking at hiring multiple developers.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#dedicatedAcco29" aria-expanded="false" aria-controls="dedicatedAcco29">
                                            Do you have any hidden fees?
                                        </button>
                                    </h2>
                                    <div id="dedicatedAcco29" className="accordion-collapse collapse" data-bs-parent="#dedicatedAcco">
                                        <div className="accordion-body">
                                            <p>No. Everything from cost perspective will be kept 100% transparent to you and we guarantee – there’ll be NO SURPRISES when it comes to Dedicated Teams.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#dedicatedAcco30" aria-expanded="false" aria-controls="dedicatedAcco30">
                                            Can I terminate the service immediately?
                                        </button>
                                    </h2>
                                    <div id="dedicatedAcco30" className="accordion-collapse collapse" data-bs-parent="#dedicatedAcco">
                                        <div className="accordion-body">
                                            <p>No. You’ll be required to notify us as per the contractual agreement before the said period as mentioned in the contract. You cannot terminate the engagement within the first month.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#dedicatedAcco31" aria-expanded="false" aria-controls="dedicatedAcco31">
                                            Why can't I cancel during the first month?
                                        </button>
                                    </h2>
                                    <div id="dedicatedAcco31" className="accordion-collapse collapse" data-bs-parent="#dedicatedAcco">
                                        <div className="accordion-body">
                                            <p>It is too early to measure the effectiveness of the work, and doesn’t help measure the effectiveness of the developer. In case of shorter work cycles, we’d prefer you work with us on a Fixed cost based model.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <h4>Application Development</h4>
                        </div>
                    </div>
                    <div className="row mb-5">
                        <div className="col-lg-12">
                            <div className="accordion" id="appDevAcco">
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#appDevAcco1" aria-expanded="false" aria-controls="appDevAcco1">
                                            What kind of enterprise applications can be developed?
                                        </button>
                                    </h2>
                                    <div id="appDevAcco1" className="accordion-collapse collapse" data-bs-parent="#appDevAcco">
                                        <div className="accordion-body">
                                            <p>Enterprise applications can be developed for a wide range of purposes, including customer relationship management (CRM), human resources management, supply chain management, enterprise resource planning (ERP), business intelligence (BI), project management, collaboration tools, and more. These applications streamline processes, enhance productivity, and improve overall efficiency within organizations.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#appDevAcco2" aria-expanded="false" aria-controls="appDevAcco2">
                                            What technologies and platforms do you specialize in for enterprise application development?
                                        </button>
                                    </h2>
                                    <div id="appDevAcco2" className="accordion-collapse collapse" data-bs-parent="#appDevAcco">
                                        <div className="accordion-body">
                                            <p>We specialize in a variety of technologies and platforms for enterprise application development, including but not limited to Java, .NET, Python, JavaScript, cloud computing (such as AWS, Azure, and Google Cloud), mobile app development (iOS and Android), web development frameworks (such as Angular, React, and Node.js), and database management systems (such as SQL and NoSQL). Our expertise ensures customized solutions tailored to meet your specific business needs</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#appDevAcco3" aria-expanded="false" aria-controls="appDevAcco3">
                                            How long does it take to develop an enterprise application?
                                        </button>
                                    </h2>
                                    <div id="appDevAcco3" className="accordion-collapse collapse" data-bs-parent="#appDevAcco">
                                        <div className="accordion-body">
                                            <p>The development timeline for an enterprise application can vary depending on various factors, including the complexity of the project, desired features, team size, and technology stack. It's essential to conduct a detailed analysis and project planning to provide a more accurate estimate.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#appDevAcco4" aria-expanded="false" aria-controls="appDevAcco4">
                                            How much does enterprise application development cost?

                                        </button>
                                    </h2>
                                    <div id="appDevAcco4" className="accordion-collapse collapse" data-bs-parent="#appDevAcco">
                                        <div className="accordion-body">
                                            <p>The cost of enterprise application development can vary significantly based on several factors, including project complexity, desired features, technology stack, development time, and resources involved. It is recommended to consult with a development team to assess your specific requirements and receive a tailored cost estimate for your enterprise application project.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#appDevAcco5" aria-expanded="false" aria-controls="appDevAcco5">
                                            Can you integrate the enterprise application with our existing systems?

                                        </button>
                                    </h2>
                                    <div id="appDevAcco5" className="accordion-collapse collapse" data-bs-parent="#appDevAcco">
                                        <div className="accordion-body">
                                            <p>Yes, we can integrate your enterprise application with your existing systems. Our development team has expertise in integrating applications with various systems such as CRM, ERP, HRM, databases, legacy systems, and third-party APIs. Seamless integration ensures smooth data flow and enhances overall efficiency within your organization.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#appDevAcco6" aria-expanded="false" aria-controls="appDevAcco6">
                                            What level of support and maintenance do you provide after the application is developed?
                                        </button>
                                    </h2>
                                    <div id="appDevAcco6" className="accordion-collapse collapse" data-bs-parent="#appDevAcco">
                                        <div className="accordion-body">
                                            <p>We provide comprehensive support and maintenance services after the application is developed. This includes regular updates, bug fixes, performance monitoring, security patches, and technical assistance. We offer different levels of support agreements tailored to your specific needs to ensure your application remains secure, up-to-date, and fully functional.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <h4>Services FAQ's</h4>
                        </div>
                    </div>
                    <div className="row mb-5">
                        <div className="col-lg-12">
                            <div className="accordion" id="servicesAcco">
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#servicesAcco1" aria-expanded="false" aria-controls="servicesAcco1">
                                            What services does Webmaa offer in the field of digital commerce?
                                        </button>
                                    </h2>
                                    <div id="servicesAcco1" className="accordion-collapse collapse" data-bs-parent="#servicesAcco">
                                        <div className="accordion-body">
                                            <p>Webmaa offers a wide range of digital commerce services, including e-commerce website development, mobile app development for online stores, API middleware development, performance optimization, Re-engineering, and UX consulting.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#servicesAcco2" aria-expanded="false" aria-controls="servicesAcco2">
                                            How can businesses leverage digital commerce for their operations?

                                        </button>
                                    </h2>
                                    <div id="servicesAcco2" className="accordion-collapse collapse" data-bs-parent="#servicesAcco">
                                        <div className="accordion-body">
                                            <p>Businesses can leverage digital commerce to expand their reach, streamline operations, and increase sales. By creating e-commerce websites or mobile apps, they can reach a global audience, provide 24/7 accessibility, automate transactions, use data for personalised marketing, and optimize the customer experience, eventually increasing growth and profitability.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#servicesAcco3" aria-expanded="false" aria-controls="servicesAcco3">
                                            How does platform development and customization contribute to digital commerce?

                                        </button>
                                    </h2>
                                    <div id="servicesAcco3" className="accordion-collapse collapse" data-bs-parent="#servicesAcco">
                                        <div className="accordion-body">
                                            <p>Platform development and customisation are critical in digital commerce because they provide organisations with personalised solutions. It enables the creation of user-friendly e-commerce websites and apps, as well as the smooth integration of payment gateways, inventory management, and personalised customer experiences. This increases efficiency, sales, and long-term client loyalty.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#servicesAcco4" aria-expanded="false" aria-controls="servicesAcco4">
                                            How can Webmaa help businesses optimize their e-commerce platforms?

                                        </button>
                                    </h2>
                                    <div id="servicesAcco4" className="accordion-collapse collapse" data-bs-parent="#servicesAcco">
                                        <div className="accordion-body">
                                            <p>Webmaa helps businesses optimize their e-commerce platforms by offering expert development and customization services. They improve user experience, implement efficient payment gateways, optimize site performance, provide data-driven insights for marketing strategies, and provide continuing support to help businesses achieve maximum success and growth in the digital commerce ecosystem.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#servicesAcco5" aria-expanded="false" aria-controls="servicesAcco5">
                                            What technologies does Webmaa utilize for digital commerce transformation?

                                        </button>
                                    </h2>
                                    <div id="servicesAcco5" className="accordion-collapse collapse" data-bs-parent="#servicesAcco">
                                        <div className="accordion-body">
                                            <p>For digital commerce transformation, Webmaa employs modern technologies such as AI-driven data analytics, cloud-based solutions, blockchain for secure transactions, progressive web apps (PWAs) for enhanced user experiences, and responsive layouts for easy mobile access. These technologies enable businesses to stay competitive in the digital economy, drive innovation, and grow.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#servicesAcco6" aria-expanded="false" aria-controls="servicesAcco6">
                                            How can businesses get started with Webmaa's digital commerce transformation services?

                                        </button>
                                    </h2>
                                    <div id="servicesAcco6" className="accordion-collapse collapse" data-bs-parent="#servicesAcco">
                                        <div className="accordion-body">
                                            <p>You can easily contact Webmaa to discuss your digital commerce transformation requirements. You can contact us by our website contact form, email, or phone. Our staff will respond quickly to your inquiry and schedule a session to better understand your needs and deliver customised solutions for your digital commerce transformation.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <h4>Business Automation FAQ's</h4>
                        </div>
                    </div>
                    <div className="row mb-5">
                        <div className="col-lg-12">
                            <div className="accordion" id="businessAutomationAcco">
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#businessAutomationAcco1" aria-expanded="false" aria-controls="businessAutomationAcco1">
                                            How can business automation services benefit my company?

                                        </button>
                                    </h2>
                                    <div id="businessAutomationAcco1" className="accordion-collapse collapse" data-bs-parent="#businessAutomationAcco">
                                        <div className="accordion-body">
                                            <p>
                                                Webmaa’s Business automation services can help your business by streamlining operations, eliminating human labour, boosting efficiency, decreasing errors, and saving time and resources. They allow you to focus on important tasks while increasing efficiency and scalability, resulting in increased overall performance and growth.

                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#businessAutomationAcco2" aria-expanded="false" aria-controls="businessAutomationAcco2">
                                            What processes can be automated using business automation services?

                                        </button>
                                    </h2>
                                    <div id="businessAutomationAcco2" className="accordion-collapse collapse" data-bs-parent="#businessAutomationAcco">
                                        <div className="accordion-body">
                                            <p>
                                                Webmaa’s Business automation services can automate a wide range of operations, including data entry, invoicing, email marketing, customer care, inventory management, and workflow approvals. Furthermore, complicated procedures such as sales and lead management, financial reporting, and HR onboarding can be automated, increasing overall efficiency and productivity.

                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#businessAutomationAcco3" aria-expanded="false" aria-controls="businessAutomationAcco3">
                                            How much does it cost to implement business automation services?

                                        </button>
                                    </h2>
                                    <div id="businessAutomationAcco3" className="accordion-collapse collapse" data-bs-parent="#businessAutomationAcco">
                                        <div className="accordion-body">
                                            <p>
                                                The cost of implementing business automation services is determined by the scale and complexity of your automation requirements. The total price is influenced by factors such as the number of operations to automate, the software and tools used, and the level of customization required. Contact us for a personalized quote according to your business requirements.

                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#businessAutomationAcco4" aria-expanded="false" aria-controls="businessAutomationAcco4">
                                            Are business automation services customizable to my company's specific needs?

                                        </button>
                                    </h2>
                                    <div id="businessAutomationAcco4" className="accordion-collapse collapse" data-bs-parent="#businessAutomationAcco">
                                        <div className="accordion-body">
                                            <p>
                                                Yes, business automation services can be customised to your company's specific requirements. Whether it's optimising existing processes or developing new automated workflows, our services can be personalised to your specific needs, increasing efficiency and productivity in your organisation.

                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#businessAutomationAcco5" aria-expanded="false" aria-controls="businessAutomationAcco5">
                                            What kind of support and maintenance is provided for business automation services?

                                        </button>
                                    </h2>
                                    <div id="businessAutomationAcco5" className="accordion-collapse collapse" data-bs-parent="#businessAutomationAcco">
                                        <div className="accordion-body">
                                            <p>
                                                For our business automation services, Webmaa offer wide support and maintenance. Our team assures seamless operation, timely updates, and technical assistance as needed. We provide continual monitoring, troubleshooting, and upgrades to ensure smooth automation and maximise the benefits for your business.

                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#businessAutomationAcco6" aria-expanded="false" aria-controls="businessAutomationAcco6">
                                            What industries or sectors can benefit from business automation services?

                                        </button>
                                    </h2>
                                    <div id="businessAutomationAcco6" className="accordion-collapse collapse" data-bs-parent="#businessAutomationAcco">
                                        <div className="accordion-body">
                                            <p>
                                                Business automation services benefit various industries, including but not limited to manufacturing, finance, healthcare, e-commerce, logistics, and customer service.

                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#businessAutomationAcco7" aria-expanded="false" aria-controls="businessAutomationAcco7">
                                            How do I get started with business automation services for my company?

                                        </button>
                                    </h2>
                                    <div id="businessAutomationAcco7" className="accordion-collapse collapse" data-bs-parent="#businessAutomationAcco">
                                        <div className="accordion-body">
                                            <p>
                                                To discuss your business automation service needs, you can easily get in touch with Webmaa. You can reach out to us through our website contact form, email, or phone. Our team will promptly respond to your inquiry and schedule a consultation to understand your requirements and provide tailored solutions for your business automation services.

                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <h4>Artificial Intelligence FAQ's</h4>
                        </div>
                    </div>
                    <div className="row mb-5">
                        <div className="col-lg-12">
                            <div className="accordion" id="AIAcco">
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#AIAcco1" aria-expanded="false" aria-controls="AIAcco1">
                                            How can artificial intelligence services help my business achieve its goals?

                                        </button>
                                    </h2>
                                    <div id="AIAcco1" className="accordion-collapse collapse" data-bs-parent="#AIAcco">
                                        <div className="accordion-body">
                                            <p>
                                                Artificial intelligence services can assist your business in achieving its goals by automating repetitive tasks, enhancing decision-making processes with data-driven insights, improving customer experiences through personalized recommendations, optimizing operations and resource allocation, and identifying new growth opportunities. AI can provide valuable efficiencies and competitive advantages in various industries.

                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#AIAcco2" aria-expanded="false" aria-controls="AIAcco2">
                                            What specific AI solutions does your company offer?

                                        </button>
                                    </h2>
                                    <div id="AIAcco2" className="accordion-collapse collapse" data-bs-parent="#AIAcco">
                                        <div className="accordion-body">
                                            <p>
                                                Our company offers a range of AI solutions, including AI strategy and consulting, data analytics and insights, natural language processing (NLP), computer vision and image recognition, robotic process automation (RPA), predictive analytics and modeling, machine learning and deep learning, sentiment analysis solutions, and voice-enabled AI solutions. These solutions can enhance healthcare collaboration, streamline processes, improve patient care, and increase operational efficiency.

                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#AIAcco3" aria-expanded="false" aria-controls="AIAcco3">
                                            Can artificial intelligence services be customized to meet my unique business requirements?

                                        </button>
                                    </h2>
                                    <div id="AIAcco3" className="accordion-collapse collapse" data-bs-parent="#AIAcco">
                                        <div className="accordion-body">
                                            <p>
                                                Yes, artificial intelligence services can be customized to meet your unique business requirements. AI solutions can be tailored and adapted to align with your specific goals, industry, processes, and data. Customization allows for the development of AI models and systems that address your specific challenges and deliver optimal results for your business.

                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#AIAcco4" aria-expanded="false" aria-controls="AIAcco4">
                                            How long does it typically take to implement artificial intelligence services?

                                        </button>
                                    </h2>
                                    <div id="AIAcco4" className="accordion-collapse collapse" data-bs-parent="#AIAcco">
                                        <div className="accordion-body">
                                            <p>
                                                The implementation timeline for artificial intelligence services can vary depending on the complexity of the project, the availability of data, and the specific requirements of your business. While some AI solutions can be implemented relatively quickly, others may take several weeks or months to develop, train models, and integrate them into existing systems.

                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#AIAcco5" aria-expanded="false" aria-controls="AIAcco5">
                                            How can I get started with implementing artificial intelligence services for my business?

                                        </button>
                                    </h2>
                                    <div id="AIAcco5" className="accordion-collapse collapse" data-bs-parent="#AIAcco">
                                        <div className="accordion-body">
                                            <p>
                                                To get started with implementing artificial intelligence services for your business, you can follow these steps: 1) Identify specific areas where AI can bring value, 2) Define your goals and requirements, 3) Gather and prepare relevant data, 4) Seek out AI service providers or build an in-house team, 5) Develop and test AI models, and 6) Integrate the AI solutions into your existing systems and processes.

                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <h4>Technology Integration FAQ's</h4>
                        </div>
                    </div>
                    <div className="row mb-5">
                        <div className="col-lg-12">
                            <div className="accordion" id="TechnologyIntegrationAcco">
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#TechnologyIntegrationAcco1" aria-expanded="false" aria-controls="TechnologyIntegrationAcco1">
                                            How does technology integration improve efficiency in businesses?

                                        </button>
                                    </h2>
                                    <div id="TechnologyIntegrationAcco1" className="accordion-collapse collapse" data-bs-parent="#TechnologyIntegrationAcco">
                                        <div className="accordion-body">
                                            <p>
                                                Technology integration boosts business productivity by streamlining processes, automating operations, and improving cooperation. Integrated systems offer more smooth data sharing, real-time updates, and better decision-making. Businesses can operate more smoothly, save time, and reach higher levels of productivity and success by minimising manual effort and optimising operations.

                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#TechnologyIntegrationAcco2" aria-expanded="false" aria-controls="TechnologyIntegrationAcco2">
                                            Can technology integration lead to cost savings for businesses?

                                        </button>
                                    </h2>
                                    <div id="TechnologyIntegrationAcco2" className="accordion-collapse collapse" data-bs-parent="#TechnologyIntegrationAcco">
                                        <div className="accordion-body">
                                            <p>
                                                Yes, businesses can save cost by integrating technology. Businesses can reduce labour costs and errors by automating operations, removing manual work, and improving operational efficiency. Furthermore, integrated systems assist with optimising resource allocation, purchasing, and inventory management, resulting in lower total costs and higher profitability.

                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#TechnologyIntegrationAcco3" aria-expanded="false" aria-controls="TechnologyIntegrationAcco3">
                                            What are some use cases of technology integration?

                                        </button>
                                    </h2>
                                    <div id="TechnologyIntegrationAcco3" className="accordion-collapse collapse" data-bs-parent="#TechnologyIntegrationAcco">
                                        <div className="accordion-body">
                                            <p>
                                                Some use cases of technology integration include streamlining customer relationship management (CRM), integrating e-commerce platforms with inventory systems, automating accounting and invoicing processes, connecting marketing automation tools with customer databases, and linking HR management software with payroll systems.

                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#TechnologyIntegrationAcco4" aria-expanded="false" aria-controls="TechnologyIntegrationAcco4">
                                            How can Webmaa help with IT system integration?

                                        </button>
                                    </h2>
                                    <div id="TechnologyIntegrationAcco4" className="accordion-collapse collapse" data-bs-parent="#TechnologyIntegrationAcco">
                                        <div className="accordion-body">
                                            <p>
                                                Webmaa can assist with IT system integration by offering expert assistance to combine your different software systems and applications. We enable effective data flow, improved communication, and optimised processes using our expertise and technical ability, resulting in an integrated and streamlined IT ecosystem for your organisation.

                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#TechnologyIntegrationAcco5" aria-expanded="false" aria-controls="TechnologyIntegrationAcco5">
                                            How can I get started with Webmaa's IT system integration services?

                                        </button>
                                    </h2>
                                    <div id="TechnologyIntegrationAcco5" className="accordion-collapse collapse" data-bs-parent="#TechnologyIntegrationAcco">
                                        <div className="accordion-body">
                                            <p>
                                                You can easily contact Webmaa to discuss your IT system integration requirements. You can contact us by our website contact form, email, or phone. Our staff will respond quickly to your inquiry and schedule a session to better understand your needs and deliver customised solutions for your IT system integration.

                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <h4>Data Management FAQ's</h4>
                        </div>
                    </div>
                    <div className="row mb-5">
                        <div className="col-lg-12">
                            <div className="accordion" id="DataManagementAcco">
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#DataManagementAcco1" aria-expanded="false" aria-controls="DataManagementAcco1">
                                            What data sources does Webmaa utilize for its analytics services?

                                        </button>
                                    </h2>
                                    <div id="DataManagementAcco1" className="accordion-collapse collapse" data-bs-parent="#DataManagementAcco">
                                        <div className="accordion-body">
                                            <p>
                                                Webmaa utilizes a wide range of data sources for its analytics services. This includes structured data from internal systems, external APIs, social media platforms, website analytics, customer feedback, and more. We collect and analyze data from diverse sources to provide comprehensive insights and enable data-driven decision-making for our clients.

                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#DataManagementAcco2" aria-expanded="false" aria-controls="DataManagementAcco2">
                                            Does Webmaa offer real-time data analytics solutions?

                                        </button>
                                    </h2>
                                    <div id="DataManagementAcco2" className="accordion-collapse collapse" data-bs-parent="#DataManagementAcco">
                                        <div className="accordion-body">
                                            <p>
                                                Yes, Webmaa offers real-time data analytics solutions. We leverage advanced technologies and frameworks to process and analyze data in real-time, allowing businesses to gain valuable insights and make informed decisions on the fly. Our real-time analytics solutions enable timely actions, improve operational efficiency, and provide a competitive edge in today's fast-paced business environment.

                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#DataManagementAcco3" aria-expanded="false" aria-controls="DataManagementAcco3">
                                            Does Webmaa provide data visualization services to simplify complex information?

                                        </button>
                                    </h2>
                                    <div id="DataManagementAcco3" className="accordion-collapse collapse" data-bs-parent="#DataManagementAcco">
                                        <div className="accordion-body">
                                            <p>
                                                Yes, Webmaa provides data visualization services to simplify complex information. We leverage modern visualization tools and techniques to transform raw data into visually appealing and interactive charts, graphs, and dashboards.

                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#DataManagementAcco4" aria-expanded="false" aria-controls="DataManagementAcco4">
                                            How can Webmaa help my business with its BI and data analytics needs?

                                        </button>
                                    </h2>
                                    <div id="DataManagementAcco4" className="accordion-collapse collapse" data-bs-parent="#DataManagementAcco">
                                        <div className="accordion-body">
                                            <p>
                                                Webmaa can help your business with its Business Intelligence (BI) and data analytics needs in several ways. We offer custom BI solutions, data analysis and reporting, and performance management tailored to your specific requirements. Our expertise in data integration, analysis, and visualization enables us to provide valuable insights, improve decision-making processes, optimize operations, and drive business growth.

                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#DataManagementAcco5" aria-expanded="false" aria-controls="DataManagementAcco5">
                                            What industries does Webmaa specialize in for BI and data analytics solutions?

                                        </button>
                                    </h2>
                                    <div id="DataManagementAcco5" className="accordion-collapse collapse" data-bs-parent="#DataManagementAcco">
                                        <div className="accordion-body">
                                            <p>
                                                Webmaa specializes in providing BI and data analytics solutions across various industries. We have experience serving industries such as healthcare, finance, retail, e-commerce, manufacturing, logistics, and more. Our team of experts understands the unique challenges and requirements of each industry, enabling us to deliver tailored BI and data analytics solutions that drive meaningful insights and business value.

                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#DataManagementAcco6" aria-expanded="false" aria-controls="DataManagementAcco6">
                                            Does Webmaa offer ongoing support and maintenance for the implemented solutions?

                                        </button>
                                    </h2>
                                    <div id="DataManagementAcco6" className="accordion-collapse collapse" data-bs-parent="#DataManagementAcco">
                                        <div className="accordion-body">
                                            <p>
                                                Yes, Webmaa offers ongoing support and maintenance services for the implemented solutions. We provide timely technical support, bug fixes, updates, and enhancements to ensure the smooth operation and optimal performance of the solutions. Our dedicated support team is committed to providing excellent post-implementation support to our clients.

                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <h4>Enterprise Web App Development FAQ's</h4>
                        </div>
                    </div>
                    <div className="row mb-5">
                        <div className="col-lg-12">
                            <div className="accordion" id="EntWebAppAcco">
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#EntWebAppAcco1" aria-expanded="false" aria-controls="EntWebAppAcco1">
                                            What is enterprise web app development?

                                        </button>
                                    </h2>
                                    <div id="EntWebAppAcco1" className="accordion-collapse collapse" data-bs-parent="#EntWebAppAcco">
                                        <div className="accordion-body">
                                            <p>
                                                Enterprise web app development refers to the creation of web-based applications that are specifically designed to meet the complex requirements of large-scale businesses. These applications are built using the latest web technologies and are scalable, secure, and robust.

                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#EntWebAppAcco2" aria-expanded="false" aria-controls="EntWebAppAcco2">
                                            What are the benefits of enterprise web app development?

                                        </button>
                                    </h2>
                                    <div id="EntWebAppAcco2" className="accordion-collapse collapse" data-bs-parent="#EntWebAppAcco">
                                        <div className="accordion-body">
                                            <p>
                                                Enterprise web app development can help businesses to reduce operational costs, enhance operational efficiency, improve customer engagement, streamline workflows, and increase productivity. These applications also provide valuable insights into customer behavior and business performance.

                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#EntWebAppAcco3" aria-expanded="false" aria-controls="EntWebAppAcco3">
                                            What kind of enterprise web apps can Webmaa develop?

                                        </button>
                                    </h2>
                                    <div id="EntWebAppAcco3" className="accordion-collapse collapse" data-bs-parent="#EntWebAppAcco">
                                        <div className="accordion-body">
                                            <p>
                                                Webmaa can develop a wide range of enterprise web apps including enterprise web portals, eCommerce solutions, B2B and B2C applications, ERP and CRM software apps, reporting, billing & secure financial transaction processing systems, etc. Our web solutions are customized to meet the specific requirements of each business.

                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#EntWebAppAcco4" aria-expanded="false" aria-controls="EntWebAppAcco4">
                                            What includes in the PWA upgrades and support service?

                                        </button>
                                    </h2>
                                    <div id="EntWebAppAcco4" className="accordion-collapse collapse" data-bs-parent="#EntWebAppAcco">
                                        <div className="accordion-body">
                                            <p>
                                                Our PWA upgrades and support service includes maintenance and upgrades to ensure the continued smooth operation of your PWA. We provide timely bug fixes, feature upgrades, security patches, and compatibility updates to make sure your PWA keeps up with the latest technologies and standards.

                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#EntWebAppAcco5" aria-expanded="false" aria-controls="EntWebAppAcco5">
                                            How long does it take to develop a hybrid mobile app?

                                        </button>
                                    </h2>
                                    <div id="EntWebAppAcco5" className="accordion-collapse collapse" data-bs-parent="#EntWebAppAcco">
                                        <div className="accordion-body">
                                            <p>
                                                The development timeline for a hybrid mobile app varies based on the complexity of the app, the number of features, and the platform requirements. Our expert developers can provide a project timeline after analyzing your specific expectations.

                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#EntWebAppAcco6" aria-expanded="false" aria-controls="EntWebAppAcco6">
                                            How can you make sure my mobile app is secure?

                                        </button>
                                    </h2>
                                    <div id="EntWebAppAcco6" className="accordion-collapse collapse" data-bs-parent="#EntWebAppAcco">
                                        <div className="accordion-body">
                                            <p>
                                                At Webmaa, security is a top priority. To guarantee the security of your app and user data, we implement industry-standard security methods. To protect your app from any potential security flaws, we use strong encryption, secure authentication, and secure data storage. We also conduct routine security audits to find and address any issues.

                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#EntWebAppAcco7" aria-expanded="false" aria-controls="EntWebAppAcco7">
                                            What is a Discovery Workshop?

                                        </button>
                                    </h2>
                                    <div id="EntWebAppAcco7" className="accordion-collapse collapse" data-bs-parent="#EntWebAppAcco">
                                        <div className="accordion-body">
                                            <p>
                                                A Discovery Workshop is a collaborative process where we work with you to identify and prioritize critical system features for your website or mobile app. It helps to define a clear strategy, identify the target audience, and minimize risk while optimizing cost and time.

                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#EntWebAppAcco8" aria-expanded="false" aria-controls="EntWebAppAcco8">
                                            How do you ensure the quality of your web and mobile app design services?

                                        </button>
                                    </h2>
                                    <div id="EntWebAppAcco8" className="accordion-collapse collapse" data-bs-parent="#EntWebAppAcco">
                                        <div className="accordion-body">
                                            <p>
                                                We have a dedicated team of analysts, UI/UX designers, and technical experts who work together to create a functional and visually appealing design that meets the needs of your target audience. We also conduct user testing and feedback to ensure the quality and effectiveness of our design solutions.

                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#EntWebAppAcco9" aria-expanded="false" aria-controls="EntWebAppAcco9">
                                            What is your approach to website design?

                                        </button>
                                    </h2>
                                    <div id="EntWebAppAcco9" className="accordion-collapse collapse" data-bs-parent="#EntWebAppAcco">
                                        <div className="accordion-body">
                                            <p>
                                                Our approach is focused on challenges and solutions, with the audience at the center of the design process. We prioritize the discovery and organization of ideas, quantification of scope, and choosing the right technology approach to development. We also follow an agile methodology for speed and adaptability to changes.

                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#EntWebAppAcco10" aria-expanded="false" aria-controls="EntWebAppAcco10">
                                            What types of software testing services does Webmaa offer?

                                        </button>
                                    </h2>
                                    <div id="EntWebAppAcco10" className="accordion-collapse collapse" data-bs-parent="#EntWebAppAcco">
                                        <div className="accordion-body">
                                            <p>
                                                Webmaa offers a range of software testing services, including manual QA testing, web testing automation, mobile app testing, performance testing, API testing automation, and expert QA consultation.

                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#EntWebAppAcco11" aria-expanded="false" aria-controls="EntWebAppAcco11">
                                            How does Webmaa approach strategic test planning?

                                        </button>
                                    </h2>
                                    <div id="EntWebAppAcco11" className="accordion-collapse collapse" data-bs-parent="#EntWebAppAcco">
                                        <div className="accordion-body">
                                            <p>
                                                Webmaa begins with a comprehensive analysis of requirements to develop a software testing strategy and execution plan. The QA team then designs test cases, creates test checklists and data, and maintains test reports and documentation.

                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#EntWebAppAcco12" aria-expanded="false" aria-controls="EntWebAppAcco12">
                                            How does Webmaa ensure high-quality documentation throughout the software development life cycle?

                                        </button>
                                    </h2>
                                    <div id="EntWebAppAcco12" className="accordion-collapse collapse" data-bs-parent="#EntWebAppAcco">
                                        <div className="accordion-body">
                                            <p>
                                                Webmaa prioritizes high-quality documentation that is accessible to all team members. The QA team uses tools like TestRail and Zephyr to write and plan test cases and maintains software quality metrics to track progress. Any changes to requirements or documentation are immediately visible to the entire team.

                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#EntWebAppAcco13" aria-expanded="false" aria-controls="EntWebAppAcco13">
                                            What are the different types of digital marketing services offered by Webmaa?

                                        </button>
                                    </h2>
                                    <div id="EntWebAppAcco13" className="accordion-collapse collapse" data-bs-parent="#EntWebAppAcco">
                                        <div className="accordion-body">
                                            <p>
                                                Webmaa offers a range of digital marketing services, including search engine optimization (SEO), pay-per-click (PPC) advertising, social media marketing, email marketing, content marketing, and online reputation management. These services are designed to help businesses improve their online presence, attract more customers, and increase their revenue through effective digital marketing strategies.

                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#EntWebAppAcco14" aria-expanded="false" aria-controls="EntWebAppAcco14">
                                            Can Webmaa provide custom digital marketing solutions based on my business needs?

                                        </button>
                                    </h2>
                                    <div id="EntWebAppAcco14" className="accordion-collapse collapse" data-bs-parent="#EntWebAppAcco">
                                        <div className="accordion-body">
                                            <p>
                                                Webmaa offers a range of services, including custom digital marketing solutions tailored to their clients' specific business needs. They work with clients to understand their goals and target audience, and then develop and implement a digital marketing strategy that is customized to meet those objectives. So, it is likely that Webmaa can provide custom digital marketing solutions based on your business needs.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#EntWebAppAcco15" aria-expanded="false" aria-controls="EntWebAppAcco15">
                                            Why should I hire dedicated developers from Webmaa?

                                        </button>
                                    </h2>
                                    <div id="EntWebAppAcco15" className="accordion-collapse collapse" data-bs-parent="#EntWebAppAcco">
                                        <div className="accordion-body">
                                            <p>
                                                We’ve been in outsourcing service for more than two decades now. We were there when nobody was around, and we’re still going strong since 1997. We have had situations with clients and have continuously refined our internal operations, service quality and communication with the help of learnings of all these years. We have a very strong team bond, where we share a lot of things – good and bad. That’s what keeps us on our toes to ensure to excel at what we do – while we continuously learn from our mistakes.</p>
                                            <p>We are a perfect blend of young minds and experienced veterans in the industry. With 50+ people working with Webmaa for more than 8 years, our average age of the team is still below 28. This is absolutely great sign for driving great ideas backed by even greater minds. For more about us – please visit www.Webmaa.com/about.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#EntWebAppAcco16" aria-expanded="false" aria-controls="EntWebAppAcco16">
                                            During the execution, does your team completely rely on client to provide all the information?

                                        </button>
                                    </h2>
                                    <div id="EntWebAppAcco16" className="accordion-collapse collapse" data-bs-parent="#EntWebAppAcco">
                                        <div className="accordion-body">
                                            <p>
                                                No. They don’t rely 100% on you for generic cases. Most of the time and more often than not – they’ll find out the solution by seeking advice, consulting and technical help from their peers, supervisors and experienced members within the team. However, the more the team knows about long term goals and you treat them as your own team, the better they can perform. All and every expectation shared during the early stage of engagement ensures meeting them over a period easier. Nobody likes surprises and it’s the communication at an appropriate time that can cover it up.

                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#EntWebAppAcco17" aria-expanded="false" aria-controls="EntWebAppAcco17">
                                            Just in case, our hosting services or any other related services are down, do you own the problem or you prefer to say “that’s not what we’re supposed to do”?

                                        </button>
                                    </h2>
                                    <div id="EntWebAppAcco17" className="accordion-collapse collapse" data-bs-parent="#EntWebAppAcco">
                                        <div className="accordion-body">
                                            <p>
                                                We believe it is our responsibility. However, something that’s within our reach or access – we can certainly chime in and help you to fix the issue even before it arises. However, in certain cases – our clients prefer to leave a few parts of the project to their own teams. In such cases, we can’t do much – except to guide you or show you the solution. To be able to “FIX” the problem itself – we need to have your permission along with the access.

                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#EntWebAppAcco18" aria-expanded="false" aria-controls="EntWebAppAcco18">
                                            Can you give quote over quick phone call?

                                        </button>
                                    </h2>
                                    <div id="EntWebAppAcco18" className="accordion-collapse collapse" data-bs-parent="#EntWebAppAcco">
                                        <div className="accordion-body">
                                            <p>
                                                Certainly. The pricing in terms of number is not a difficult. However – as you’d expect, it is not JUST about the price and quote only. The core benchmark of pricing depends on lot of factors such as composition of team, experience level required and type of engagement as well as the length of minimum commitment for the contract.</p>
                                            <p>Most critically – the actual solution that needs to be developed plays a vital role here. It depends a lot on whether the solution has to be built form ground up or work is required on a system that’s already built and is live. In both cases, there are many discussions that need to happen in order to craft a perfect and transparent cost model.

                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#EntWebAppAcco19" aria-expanded="false" aria-controls="EntWebAppAcco19">
                                            Do you have public holiday calendar for days you’ll not be working?

                                        </button>
                                    </h2>
                                    <div id="EntWebAppAcco19" className="accordion-collapse collapse" data-bs-parent="#EntWebAppAcco">
                                        <div className="accordion-body">
                                            <p>
                                                Certainly. We will share our holiday calendar upon initiation process – and will also keep you informed a week in advance for any public holiday.

                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#EntWebAppAcco20" aria-expanded="false" aria-controls="EntWebAppAcco20">
                                            Do you allow developers to come to our offices and work with our local team?

                                        </button>
                                    </h2>
                                    <div id="EntWebAppAcco20" className="accordion-collapse collapse" data-bs-parent="#EntWebAppAcco">
                                        <div className="accordion-body">
                                            <p>
                                                Absolutely. This is totally possible. We can arrange for the developer(s) to visit your country and stay there to work with your team for a period. In such cases, you have to bear the costs of Visa, travel, accommodation and per diem expenses on top of their monthly fee. This can be discussed and may vary on a case-to-case basis.

                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <h4>Web Development FAQ's</h4>
                        </div>
                    </div>
                    <div className="row mb-5">
                        <div className="col-lg-12">
                            <div className="accordion" id="WebDevAcco">
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#WebDevAcco1" aria-expanded="false" aria-controls="WebDevAcco1">
                                            What is CMS? Do I require it?

                                        </button>
                                    </h2>
                                    <div id="WebDevAcco1" className="accordion-collapse collapse" data-bs-parent="#WebDevAcco">
                                        <div className="accordion-body">
                                            <p>
                                                Content Management System is a software package, which makes maintenance of websites in a more systematic way. The owner can manage their websites without any help. In case you want multiple users with multiple roles for your website, CMS is recommended.

                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#WebDevAcco2" aria-expanded="false" aria-controls="WebDevAcco2">
                                            What is portal? What are the benefits?

                                        </button>
                                    </h2>
                                    <div id="WebDevAcco2" className="accordion-collapse collapse" data-bs-parent="#WebDevAcco">
                                        <div className="accordion-body">
                                            <p>
                                                A web portal is basically a website that uniquely fetches data from various sources. Generally, every information source has its own section on the web page to display info. These days, revolutionary changes in technologies have made portals rendering greater benefits. Portal promises to deliver a more consistent and logical info management platform. As a result of this, it is a more user oriented approach for better accessibility.

                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#WebDevAcco3" aria-expanded="false" aria-controls="WebDevAcco3">
                                            How do you test the project?

                                        </button>
                                    </h2>
                                    <div id="WebDevAcco3" className="accordion-collapse collapse" data-bs-parent="#WebDevAcco">
                                        <div className="accordion-body">
                                            <p>
                                                We have a special department (QA & QC) to test projects. We follow the series of below mentioned robust testing for the projects we develop. 1. GUI testing 2. Cross browser testing 3. Black box testing 4. Navigation testing 5. Network testing 6. System testing 7. Unit and integration testing 8. Functional testing 9. Testing on various devices and platforms. Before the project is ready for the above mentioned testing, we parse the code using special software and also manually. Our special programmers leave no stone unturned in screening the codes before executing them for the final version.

                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#WebDevAcco4" aria-expanded="false" aria-controls="WebDevAcco4">
                                            What is the average time you take to develop a website?

                                        </button>
                                    </h2>
                                    <div id="WebDevAcco4" className="accordion-collapse collapse" data-bs-parent="#WebDevAcco">
                                        <div className="accordion-body">
                                            <p>
                                                The overall time to develop a website depends on several factors. The first one is the complexity of the project. A static website usually takes less time than eCommerce websites or websites that require flash development. Some minor or major changes may increase the time limit. An average time taken by our web developer for a static website will be lesser than a website with flash and other dynamic graphics. Nevertheless, to develop complex websites such as eCommerce website takes a bit more time.

                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#WebDevAcco5" aria-expanded="false" aria-controls="WebDevAcco5">
                                            What’s your USP as a web development company?

                                        </button>
                                    </h2>
                                    <div id="WebDevAcco5" className="accordion-collapse collapse" data-bs-parent="#WebDevAcco">
                                        <div className="accordion-body">
                                            <p>
                                                Our quality service at an affordable cost is our USP. We know the value of your money and hence, we work with full dedication and devotion to turn your venture into a successful one. We have a pool of experts in various technologies including php, Microsoft (.net), Java etc. Whether it is a simple, static website or a complex, eCommerce project, we never let our clients’ compromise with the quality. We put the same efforts and dedication into a static website as we do in a complex project. We provide efficient web development services in various top frameworks including the various open source PHP frameworks.

                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#WebDevAcco6" aria-expanded="false" aria-controls="WebDevAcco6">
                                            Do you offer mobile device compatible website?

                                        </button>
                                    </h2>
                                    <div id="WebDevAcco6" className="accordion-collapse collapse" data-bs-parent="#WebDevAcco">
                                        <div className="accordion-body">
                                            <p>
                                                Of course, we do! It is known as Responsive Web Designing and Development. Hire web developers from Webmaa, who have hands-on experience on the latest technologies based on HTML5 and CSS3. We develop eye-catching and graphically convincing web designs that fit even on mobile devices. Nevertheless, you may have to specify this in your objectives before the website or web app development project begins.

                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#WebDevAcco7" aria-expanded="false" aria-controls="WebDevAcco7">
                                            What design options you provide? Do you provide templates as options?

                                        </button>
                                    </h2>
                                    <div id="WebDevAcco7" className="accordion-collapse collapse" data-bs-parent="#WebDevAcco">
                                        <div className="accordion-body">
                                            <p>
                                                Yes, we do provide a few options. However, we encourage our clients to be precise in their requirements and elements on the website depending upon what, we develop a few options. This saves time and money and fastens the process of web development.

                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#WebDevAcco8" aria-expanded="false" aria-controls="WebDevAcco8">
                                            How many changes I can make during the website development project?

                                        </button>
                                    </h2>
                                    <div id="WebDevAcco8" className="accordion-collapse collapse" data-bs-parent="#WebDevAcco">
                                        <div className="accordion-body">
                                            <p>
                                                Generally, no software development company provides you with this. But Webmaa offers a couple of changes during website development other than the objectives specified. Nevertheless, we firmly suggest discussing and defining the scope and elements before the project begins. This saves time, resources and money on iterations as much as possible.

                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#WebDevAcco9" aria-expanded="false" aria-controls="WebDevAcco9">
                                            Do you provide web hosting as well?

                                        </button>
                                    </h2>
                                    <div id="WebDevAcco9" className="accordion-collapse collapse" data-bs-parent="#WebDevAcco">
                                        <div className="accordion-body">
                                            <p>
                                                We do it only if clients ask.

                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#WebDevAcco10" aria-expanded="false" aria-controls="WebDevAcco10">
                                            Do you have similar websites as mine designed earlier?

                                        </button>
                                    </h2>
                                    <div id="WebDevAcco10" className="accordion-collapse collapse" data-bs-parent="#WebDevAcco">
                                        <div className="accordion-body">
                                            <p>
                                                Since 1998, we have developed thousands of websites for companies belonging to different industry verticals. Be it blogs, portals, eCommerce sites or enterprise-level sites, we have expertise and experience in every aspect of website development. We’d have to analyze your specific requirements to say with certainty that we have developed websites similar to yours. But more likely than not, you’ll find that we have worked on projects similar to yours.

                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#WebDevAcco11" aria-expanded="false" aria-controls="WebDevAcco11">
                                            What is your architecture to design or develop the websites? Do you have different frameworks?

                                        </button>
                                    </h2>
                                    <div id="WebDevAcco11" className="accordion-collapse collapse" data-bs-parent="#WebDevAcco">
                                        <div className="accordion-body">
                                            <p>
                                                We use standard architecture –Mobile View Controller (MVC), a type of UI separating the representation of information from the user interaction. We do have different frameworks and use as per the project type and demand.</p>
                                            <p>What is open source? What is difference between open source and MS tech (.net)?</p>
                                            <p>Open source denotes to apps development wherein the coders can access and alter the source code. MS Technology (.net) is a commercial source with limitations on code use, change, and distribution. Open sources are free to download (and so low in cost) and have good community support.

                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#WebDevAcco12" aria-expanded="false" aria-controls="WebDevAcco12">
                                            What is static/dynamic website? Which one suites me?

                                        </button>
                                    </h2>
                                    <div id="WebDevAcco12" className="accordion-collapse collapse" data-bs-parent="#WebDevAcco">
                                        <div className="accordion-body">
                                            <p>
                                                A static website is coded entirely with HTML where every webpage is a separate document without any database or external file to depend. Said this, the only way to edit such a page is by editing each page separately. A dynamic website has complex code like PHP or ASP with a greater degree of functionality such as integrated with the content management system. This has a database and other source support. In this, you can update web pages without having knowledge of HTML or software. It solely depends upon your project type and the purpose. While fixed websites (that don’t require much data to be drawn from external sources) are better developed in static, others are good in dynamic.

                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#WebDevAcco13" aria-expanded="false" aria-controls="WebDevAcco13">
                                            What are cloud services?

                                        </button>
                                    </h2>
                                    <div id="WebDevAcco13" className="accordion-collapse collapse" data-bs-parent="#WebDevAcco">
                                        <div className="accordion-body">
                                            <p>
                                                Cloud computing is having specific data you require ready for use anytime from anywhere. For this, data should be transportable and mobile. The purpose of cloud services is to synchronize data among several devices and fast access. As of now, we don’t provide cloud services but we certainly develop mobile and web applications that are cloud compatible. You may mention this while developing an app and we take care of it.

                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#WebDevAcco14" aria-expanded="false" aria-controls="WebDevAcco14">
                                            If I have an eCommerce store to develop, what’s your recommendation and why?

                                        </button>
                                    </h2>
                                    <div id="WebDevAcco14" className="accordion-collapse collapse" data-bs-parent="#WebDevAcco">
                                        <div className="accordion-body">
                                            <p>
                                                eCommerce requires special coding and huge flexibility. We recommend closed or paid sources (such as ASP.net) over open source technology for several reasons out of which, security and flexibility are the major concerns.

                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <h4>Mobile Development FAQ's</h4>
                        </div>
                    </div>
                    <div className="row mb-5">
                        <div className="col-lg-12">
                            <div className="accordion" id="MobDevAcco">
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#MobDevAcco1" aria-expanded="false" aria-controls="MobDevAcco1">
                                            What is the difference between Native App and Web Based App?

                                        </button>
                                    </h2>
                                    <div id="MobDevAcco1" className="accordion-collapse collapse" data-bs-parent="#MobDevAcco">
                                        <div className="accordion-body">
                                            <p>
                                                Native apps are built for specific platforms (e.g. iOS, Android, Windows Phone, etc.). They are built using platforms’ native SDK and development platforms and are able to use some of the specific features of the device – e.g. Camera, Volume controller, speaker, etc. The web-based apps (also web-apps) run within the mobile browser and are virtually web pages that imitate the look of a native mobile UI. Such apps are advantageous over native apps as they give the same user-experience on different smartphones. However, they can’t use some features of devices like native apps.

                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#MobDevAcco2" aria-expanded="false" aria-controls="MobDevAcco2">
                                            Do you develop mobile app for all platforms?

                                        </button>
                                    </h2>
                                    <div id="MobDevAcco2" className="accordion-collapse collapse" data-bs-parent="#MobDevAcco">
                                        <div className="accordion-body">
                                            <p>
                                                Yes, we build mobile applications for all major mobile development platforms. You can hire our developers for developing an app for iOS (iPhone and iPad), Android, BlackBerry, Symbian, and Windows Mobile. Whether you want to develop apps for just one or two platforms or want to develop an app that works on all the mobile platforms, we are at your service.

                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#MobDevAcco3" aria-expanded="false" aria-controls="MobDevAcco3">
                                            Suppose you develop app for me, what about the revenue this may generate?

                                        </button>
                                    </h2>
                                    <div id="MobDevAcco3" className="accordion-collapse collapse" data-bs-parent="#MobDevAcco">
                                        <div className="accordion-body">
                                            <p>
                                                We don’t have control over this. Nevertheless, most of the applications developed by Webmaa do well on the market. If you are keen to earn out of the application, we recommend using our Business Analysis provided by the experts. Webmaa offers consultancies in such cases.

                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#MobDevAcco4" aria-expanded="false" aria-controls="MobDevAcco4">
                                            Are you open to go for App development on partnership basis?

                                        </button>
                                    </h2>
                                    <div id="MobDevAcco4" className="accordion-collapse collapse" data-bs-parent="#MobDevAcco">
                                        <div className="accordion-body">
                                            <p>
                                                Yes, we are. We have a professional partnership model for clients who want to grow in business with us. Since 1998, we have been in collaboration with partners from multiple global locations with whom we have established a long term vision and business relationship.</p>
                                            <p>If you want the changes to be made in the app, it will cost you. We recommend having the project scope defined before beginning the project to save time and money.

                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#MobDevAcco5" aria-expanded="false" aria-controls="MobDevAcco5">
                                            Do you also upload the application to app store?

                                        </button>
                                    </h2>
                                    <div id="MobDevAcco5" className="accordion-collapse collapse" data-bs-parent="#MobDevAcco">
                                        <div className="accordion-body">
                                            <p>
                                                Yes, we do it for free. Nevertheless, you’ll have to pay the minimum amount of charge (fees) by the main company (for example Apple App Store Yearly charges US$99.00).

                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#MobDevAcco6" aria-expanded="false" aria-controls="MobDevAcco6">
                                            Which framework or programming language would you use to develop a mobile application?

                                        </button>
                                    </h2>
                                    <div id="MobDevAcco6" className="accordion-collapse collapse" data-bs-parent="#MobDevAcco">
                                        <div className="accordion-body">
                                            <p>
                                                The framework or programming language that we use to develop applications depends upon the chosen platform by the client. For example, for iPhone applications, we use iOS SDK, Objective C, Swift, Xcode, and other tools and technologies provided by Apple. Similarly, for Android applications, we use Android SDK. We have expertise in frameworks of Open Source and Microsoft Technologies for Web Service and API based applications.

                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#MobDevAcco7" aria-expanded="false" aria-controls="MobDevAcco7">
                                            Do you also do marketing of Mobile apps?

                                        </button>
                                    </h2>
                                    <div id="MobDevAcco7" className="accordion-collapse collapse" data-bs-parent="#MobDevAcco">
                                        <div className="accordion-body">
                                            <p>
                                                Yes, we do.<br />

                                                Can I make one app which would work in all platforms (like iPhone, iPad, Android, BB)?
                                                Yes, you can get an application that would work in all platforms. For such development, you need to go for cross-platform mobile development in which our developers use frameworks that are used to develop the main application and then deploy it across your desired platforms. For more information on our cross-platform development services, you can check out our services for cross-platform mobile development.

                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#MobDevAcco8" aria-expanded="false" aria-controls="MobDevAcco8">
                                            Can you use the graphics / UI which I have created?

                                        </button>
                                    </h2>
                                    <div id="MobDevAcco8" className="accordion-collapse collapse" data-bs-parent="#MobDevAcco">
                                        <div className="accordion-body">
                                            <p>
                                                Yes, we can. You can hire us for just writing codes for programming.

                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#MobDevAcco9" aria-expanded="false" aria-controls="MobDevAcco9">
                                            Does your company guarantee that Apple won’t reject the app?
                                        </button>
                                    </h2>
                                    <div id="MobDevAcco9" className="accordion-collapse collapse" data-bs-parent="#MobDevAcco">
                                        <div className="accordion-body">
                                            <p>
                                                Most of the mobile apps we develop don’t get rejected. There are no special criteria for this but just making a neat and clean app works. Our apps are full-proof and bug-free as they pass from various robust testing. Though we don’t guarantee approval on iTunes as it may be rejected other than a technical fault.

                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#MobDevAcco10" aria-expanded="false" aria-controls="MobDevAcco10">
                                            Do I require Hosting or Website to get a mobile application developed?

                                        </button>
                                    </h2>
                                    <div id="MobDevAcco10" className="accordion-collapse collapse" data-bs-parent="#MobDevAcco">
                                        <div className="accordion-body">
                                            <p>
                                                Need of hosting or website depends on what architecture your application is using. If your application requires database and web services that connect database to the app, you need hosting in order to manage database and those web services. If you already have hosting infrastructure and server – we can help you utilize the same. As far as website goes, if you need your end users to do any activity or you need website for marketing your app, we can help you develop that as well.

                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#MobDevAcco11" aria-expanded="false" aria-controls="MobDevAcco11">
                                            Can we update the content of application after it’s built?

                                        </button>
                                    </h2>
                                    <div id="MobDevAcco11" className="accordion-collapse collapse" data-bs-parent="#MobDevAcco">
                                        <div className="accordion-body">
                                            <p>
                                                You may! Nevertheless, this may be chargeable.

                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#MobDevAcco12" aria-expanded="false" aria-controls="MobDevAcco12">
                                            What are web services / API?

                                        </button>
                                    </h2>
                                    <div id="MobDevAcco12" className="accordion-collapse collapse" data-bs-parent="#MobDevAcco">
                                        <div className="accordion-body">
                                            <p>
                                                Unlike web apps, mobile apps do not have access to remote databases. In order to fetch the data from the servers, an alternative service is needed. Web services or APIs are that alternative. By creating XML/JSON files from the database, we can use data from these files for user application.

                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#MobDevAcco13" aria-expanded="false" aria-controls="MobDevAcco13">
                                            Can we use Flash?

                                        </button>
                                    </h2>
                                    <div id="MobDevAcco13" className="accordion-collapse collapse" data-bs-parent="#MobDevAcco">
                                        <div className="accordion-body">
                                            <p>
                                                No. Flash is not supported on any iOS device. The support for flash is going to be discontinued from Adobe (who owns flash now) for mobile devices. Also, the flash is very rapidly being replaced by core SDK capabilities and HTML5.

                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#MobDevAcco14" aria-expanded="false" aria-controls="MobDevAcco14">
                                            Do you get my app listed under “New and Noteworthy”?

                                        </button>
                                    </h2>
                                    <div id="MobDevAcco14" className="accordion-collapse collapse" data-bs-parent="#MobDevAcco">
                                        <div className="accordion-body">
                                            <p>
                                                Well, who doesn’t want to be there in that spot? 🙂 Saying that, it totally depends on how cool is your app idea, as well as the app as one solid product. Apparently, it is all up to Apple and Google staff when it comes to selecting an app to feature there. But having a great concept put together well increases your changes. So, it is not just us, however our role could be significant in having you take your app to that place.

                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <h4>Digital Marketing FAQ's</h4>
                        </div>
                    </div>
                    <div className="row mb-5">
                        <div className="col-lg-12">
                            <div className="accordion" id="DigitalMktingAcco">
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#DigitalMktingAcco1" aria-expanded="false" aria-controls="DigitalMktingAcco1">
                                            What social media platforms are best for my business?

                                        </button>
                                    </h2>
                                    <div id="DigitalMktingAcco1" className="accordion-collapse collapse" data-bs-parent="#DigitalMktingAcco">
                                        <div className="accordion-body">
                                            <p>
                                                While social media presence is crucial for all companies, each company will have different social media needs and strategies. For some, Facebook will be the best platform for business, while Instagram may be better for others. To determine which platform is best for your business, it is important to first determine your target audience and social media goals.

                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#DigitalMktingAcco2" aria-expanded="false" aria-controls="DigitalMktingAcco2">
                                            Do I need to pay for Facebook?

                                        </button>
                                    </h2>
                                    <div id="DigitalMktingAcco2" className="accordion-collapse collapse" data-bs-parent="#DigitalMktingAcco">
                                        <div className="accordion-body">
                                            <p>
                                                Facebook is a free social networking site. Users do not have to pay to create profiles or business pages unless they wish to market their page through ad campaigns. Simple and easy to use, Facebook Ads can help businesses boost their visibility, market products, or promote events.

                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#DigitalMktingAcco3" aria-expanded="false" aria-controls="DigitalMktingAcco3">
                                            What does responsive mean?

                                        </button>
                                    </h2>
                                    <div id="DigitalMktingAcco3" className="accordion-collapse collapse" data-bs-parent="#DigitalMktingAcco">
                                        <div className="accordion-body">
                                            <p>
                                                Nowadays, users are using a variety of different devices of all different sizes and orientations. As a result, companies should be establishing a responsive web design when building web pages. Responsive design has the ability to detect the user’s screen size and orientation in order to adjust the content and layout accordingly. For example, the layout on a mobile device will be much narrower than the layout on a desktop.

                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#DigitalMktingAcco4" aria-expanded="false" aria-controls="DigitalMktingAcco4">
                                            What pages should I include in my website?

                                        </button>
                                    </h2>
                                    <div id="DigitalMktingAcco4" className="accordion-collapse collapse" data-bs-parent="#DigitalMktingAcco">
                                        <div className="accordion-body">
                                            <p>
                                                Every small business is different, which every website will be different. Creating a new website can be a daunting task, but webmaa is here to make it easy. Every website should have the basics: Homepage, About, Contact page, Products/ Services, FAQs, and Testimonials. Besides the basics, every business should include additional web pages that are unique and relevant. Every small business is different, which means every website is different.

                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#DigitalMktingAcco5" aria-expanded="false" aria-controls="DigitalMktingAcco5">
                                            What makes for a good web design?

                                        </button>
                                    </h2>
                                    <div id="DigitalMktingAcco5" className="accordion-collapse collapse" data-bs-parent="#DigitalMktingAcco">
                                        <div className="accordion-body">
                                            <p>
                                                Websites should be designed to invite and entertain users, not push them away. The only way to attract and maintain users is with a good web design. The homepage should be visually appealing, easy to understand, and clear of any annoying pop-ups.

                                            </p>
                                            <p>If the homepage is bad, the user will exit the website rather than continue exploring. The web design should also allow for easy navigation and fast load speed without blurry images or extensive copy.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#DigitalMktingAcco6" aria-expanded="false" aria-controls="DigitalMktingAcco6">
                                            How does a website increase sales?

                                        </button>
                                    </h2>
                                    <div id="DigitalMktingAcco6" className="accordion-collapse collapse" data-bs-parent="#DigitalMktingAcco">
                                        <div className="accordion-body">
                                            <p>
                                                The primary way a website increases sales is by offering users a convenient way to shop for products and services. Through the use of pop-ups offers and special online discounts, small businesses can encourage consumers to make the switch from window-shopping to buying. With an enhanced incentive to buy, along with clear and concise product descriptions, users are quickly turning to company websites for all their consumer needs.

                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#DigitalMktingAcco7" aria-expanded="false" aria-controls="DigitalMktingAcco7">
                                            What is the main purpose of social media marketing?

                                        </button>
                                    </h2>
                                    <div id="DigitalMktingAcco7" className="accordion-collapse collapse" data-bs-parent="#DigitalMktingAcco">
                                        <div className="accordion-body">
                                            <p>
                                                Social media falls in the brand awareness and consideration stage of the sales funnel. Social media platforms allow businesses to connect with their target audience and slowly but surely nudge them down the funnel with content and links to learn more. By providing relevant information about products and services, engaging with their audience, and providing free trials or specials, businesses are able to nurture leads into buyers.

                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#DigitalMktingAcco8" aria-expanded="false" aria-controls="DigitalMktingAcco8">
                                            Is it okay to re-purpose content?

                                        </button>
                                    </h2>
                                    <div id="DigitalMktingAcco8" className="accordion-collapse collapse" data-bs-parent="#DigitalMktingAcco">
                                        <div className="accordion-body">
                                            <p>
                                                With reports, statistics, research, quotes, and testimonials being created on a daily basis, there is bound to be content worth re-purposing for digital marketing purposes. There are countless ways to turn formal business documents into creative online content, including infographics, podcasts, videos, and animated graphics.

                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#DigitalMktingAcco9" aria-expanded="false" aria-controls="DigitalMktingAcco9">
                                            What is the difference between digital marketing and digital advertising?

                                        </button>
                                    </h2>
                                    <div id="DigitalMktingAcco9" className="accordion-collapse collapse" data-bs-parent="#DigitalMktingAcco">
                                        <div className="accordion-body">
                                            <p>
                                                Digital marketing allows businesses to raise brand awareness, share information about products, engage with the audience, and monitor online reputation. Digital advertising allows businesses to advertise and promote products, offer discounts or specials, and attract buying customers. Simply put, digital marketing establishes a target consumer audience while digital advertising drives this audience to sales.

                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#DigitalMktingAcco10" aria-expanded="false" aria-controls="DigitalMktingAcco10">
                                            How often should I update my web content?

                                        </button>
                                    </h2>
                                    <div id="DigitalMktingAcco10" className="accordion-collapse collapse" data-bs-parent="#DigitalMktingAcco">
                                        <div className="accordion-body">
                                            <p>
                                                As a general rule of thumb, companies should be publishing new blogs at least twice a month – blogs are key to keeping your website updated with the relevant and fresh information that search engines prefer. If any significant news or information has been released, that content should be posted as soon as possible. Pillar pages and landing pages content should be regularly revised as the company grows and changes.

                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#DigitalMktingAcco11" aria-expanded="false" aria-controls="DigitalMktingAcco11">
                                            What is local SEO?

                                        </button>
                                    </h2>
                                    <div id="DigitalMktingAcco11" className="accordion-collapse collapse" data-bs-parent="#DigitalMktingAcco">
                                        <div className="accordion-body">
                                            <p>
                                                As compared to standard SEO, local SEO refers to Local Search Engine Optimization. Local SEO comes into play when users are searching for products, services, or resources near them in real-time. For example, a search for “shoes” will yield greatly different results than a search for “shoes near me.” Local search often includes locations, which is why companies should keep their Google My Business profile up to date.

                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#DigitalMktingAcco12" aria-expanded="false" aria-controls="DigitalMktingAcco12">
                                            Why is there such a range of SEO prices?

                                        </button>
                                    </h2>
                                    <div id="DigitalMktingAcco12" className="accordion-collapse collapse" data-bs-parent="#DigitalMktingAcco">
                                        <div className="accordion-body">
                                            <p>
                                                When it comes to search engine optimization (SEO), there’s an extremely wide range of strategies to use. Some are simple, yet impactful, and don’t take very long. Others are incredibly intensive and may take days or even weeks to fully implement. Just like many other services, SEO costs all depend on how experienced your SEO agency is and how intensive you want your optimization to be.

                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#DigitalMktingAcco13" aria-expanded="false" aria-controls="DigitalMktingAcco13">
                                            What is site taxonomy and how does it relate to SEO?

                                        </button>
                                    </h2>
                                    <div id="DigitalMktingAcco13" className="accordion-collapse collapse" data-bs-parent="#DigitalMktingAcco">
                                        <div className="accordion-body">
                                            <p>
                                                Site taxonomy is the website structure and ease of navigation through the site. The easier it is to get through your website, the higher Google is to rank it. They like to see clear navigation.

                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#DigitalMktingAcco14" aria-expanded="false" aria-controls="DigitalMktingAcco14">
                                            What is robots.txt?

                                        </button>
                                    </h2>
                                    <div id="DigitalMktingAcco14" className="accordion-collapse collapse" data-bs-parent="#DigitalMktingAcco">
                                        <div className="accordion-body">
                                            <p>
                                                Robots.txt is a file that tells Google which URLs it can and cannot look at on your website.

                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#DigitalMktingAcco15" aria-expanded="false" aria-controls="DigitalMktingAcco15">
                                            What are the benefits of SMS marketing?

                                        </button>
                                    </h2>
                                    <div id="DigitalMktingAcco15" className="accordion-collapse collapse" data-bs-parent="#DigitalMktingAcco">
                                        <div className="accordion-body">
                                            <p>
                                                SMS marketing is a great way to reach your target audience right where they are. In fact, SMS marketing has one of the highest open rate averages of all mediums at 98%. While you should never spam your audience with text messages, it’s a great way to get in front of them with urgent messaging.

                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#DigitalMktingAcco16" aria-expanded="false" aria-controls="DigitalMktingAcco16">
                                            Do webmaa outsource their work?

                                        </button>
                                    </h2>
                                    <div id="DigitalMktingAcco16" className="accordion-collapse collapse" data-bs-parent="#DigitalMktingAcco">
                                        <div className="accordion-body">
                                            <p>
                                                No, Webmaa does not outsource its projects. We have a dedicated in-house team of expert designers, developers, Digital Marketing Professionals, and Content Writers that shoulder the whole responsibility to handle all the projects that we work on. The team is capable of taking care of all the necessary things so that we can carry out all necessary activities needed for the digital promotion of the projects.

                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#DigitalMktingAcco17" aria-expanded="false" aria-controls="DigitalMktingAcco17">
                                            Do you keep yourself up to date with industry changes? If yes, how?

                                        </button>
                                    </h2>
                                    <div id="DigitalMktingAcco17" className="accordion-collapse collapse" data-bs-parent="#DigitalMktingAcco">
                                        <div className="accordion-body">
                                            <p>
                                                Certainly, we do! We believe that keeping updated is a basic responsibility of all the leading service providers that carry out more responsibilities to meet the challenges involved in digital marketing procedures. We accept that the whole team of professionals does the needful to enrich themselves and help other departments. We work as a team and we are sensible in carrying out the responsibilities very well.

                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#DigitalMktingAcco18" aria-expanded="false" aria-controls="DigitalMktingAcco18">
                                            Which tool do you use to prepare the report?

                                        </button>
                                    </h2>
                                    <div id="DigitalMktingAcco18" className="accordion-collapse collapse" data-bs-parent="#DigitalMktingAcco">
                                        <div className="accordion-body">
                                            <p>
                                                Like every leading digital marketing company, webmaa takes very good care of the projects we work on. We use a few powerful tools to carry out all the necessary technicalities to ease the task. The following are a few tools that we use for various purposes:

                                            </p>
                                            <ul>
                                                <li><strong>SE Ranking:</strong>  We use this tool to get the competitor’s activities and competitive keywords. </li>
                                                <li><strong>Google Search Console:</strong>  This tool tells us what is trending in the market at the moment. It is a very powerful tool that helps us to determine the future course of DM activities.</li>
                                                <li><strong>Keyword Planner:</strong>  This is yet another powerful SEO tool that we use to find the best keywords that we can focus on for promoting the business digitally. </li>
                                                <li><strong>SEM Rush:</strong>  It is very effective in finding the website traffic and current ranking of the keywords we are currently using for our promotional work. </li>
                                            </ul>
                                            <p>Remember, all these four tools are hot favorites as they help every SEO company or individual to come up with better and more meaningful SEO plans and strategies.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#DigitalMktingAcco19" aria-expanded="false" aria-controls="DigitalMktingAcco19">
                                            Do you provide any standard DM packages?

                                        </button>
                                    </h2>
                                    <div id="DigitalMktingAcco19" className="accordion-collapse collapse" data-bs-parent="#DigitalMktingAcco">
                                        <div className="accordion-body">
                                            <p>
                                                Yes, we do. We have individual packages for every service we offer and we allow all our customers to pick up the right package as per their needs. Additionally, we also have tailormade-customized  packages that we create keeping in mind the individual needs that every business has.

                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#DigitalMktingAcco20" aria-expanded="false" aria-controls="DigitalMktingAcco20">
                                            What is the minimum contract period that I have to sign up for?

                                        </button>
                                    </h2>
                                    <div id="DigitalMktingAcco20" className="accordion-collapse collapse" data-bs-parent="#DigitalMktingAcco">
                                        <div className="accordion-body">
                                            <p>
                                                Yes, we do have specifications for all our digital marketing services. This period for digital marketing services, SEO, SMO, and Content Writing services is 6 months. However, we do not impose any such minimum contract period for website design and development services as both are one-time activities.

                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>



                </div>
            </section>

            <section className="pb-5">
                <div className="container-xl">
                    <div className="start_project p-4 p-sm-5">
                        <div className="row gx-3">
                            <div className="col-lg-8 text-center text-lg-start mb-4 mb-lg-0 align-items-center">
                                <h2 className="mb-3 mb-lg-1 text-primary fw-500">Ready to start a project ?</h2>
                                <p className="lead fw-300 m-0">To start, use form to tell us about you and the project</p>
                            </div>
                            <div className="col-lg-4 text-center align-self-center text-lg-end">
                                <a href="../contact/" className="btn btn-primary">Let’s connect</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    )
}