// define app header

import { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import HeaderLogo from "../assets/logos/logo.webp";

console.log(window.location.pathname);

const Header = () => {
    const [navbarMenu, setNavbarMenu] = useState([
        {
            menuName: "About",
            menuRoutePath: "/about",
            active: false,
            className: "nav-item"
        },
        {
            menuName: "Services",
            menuRoutePath: "/services",
            active: false,
            className: "nav-item"
        },
        {
            menuName: "Solutions",
            menuRoutePath: "/solutions",
            active: false,
            className: "nav-item"
        },
        {
            menuName: "Works",
            menuRoutePath: "/works",
            active: false,
            className: "nav-item"
        },
        {
            menuName: "Hire Team",
            menuRoutePath: "/hire-team",
            active: false,
            className: "nav-item"
        },
        {
            menuName: "Blog",
            menuRoutePath: "/blog",
            active: false,
            className: "nav-item"
        },
        {
            menuName: "Contact",
            menuRoutePath: "/contact",
            active: false,
            className: "nav-item me-0"
        }
    ]);
    const _onclickMenuItem = (clickedIndex: number) => {
        const modifiedMenuItem = navbarMenu.map((item, index) => {
            if (index === clickedIndex) {
                return {
                    ...item,
                    active: true
                }
            } else {
                return {
                    ...item,
                    active: false
                }
            }
        });
        setNavbarMenu(modifiedMenuItem);
    }
    useEffect(() => {
        const modifiedMenuItem = navbarMenu.map((item) => {
            if (item.menuRoutePath === window.location.pathname) {
                return {
                    ...item,
                    active: true
                }
            } else {
                return {
                    ...item,
                    active: false
                }
            }
        });
        setNavbarMenu(modifiedMenuItem);
    }, []);
    return (
        <Fragment>
            <header style={{ backgroundColor: window.location.pathname === "/services" ? "rgba(255,255,255,0.8)" : "" }}>
                <div className="container-xl">
                    <div className="row gx-3">
                        <div className="col-8 col-lg-3 align-self-center">
                            <Link className="header-logo d-inline-block" to="/"><img alt="" src={HeaderLogo} className="img-fluid" /></Link>
                        </div>
                        <div className="col-4 col-lg-9 align-self-center text-end">
                            <button className="btn-contact-slideup-991 d-inline-block d-lg-none me-0 m-sm-auto" type="button">
                                <i className="lar la-paper-plane"></i>
                            </button>
                            <button className="btn-mobile-menu d-inline-block d-lg-none me-0 m-sm-auto" type="button">
                                <i className="la la-bars"></i>
                            </button>
                            <nav className="navbar navbar-expand-lg justify-content-end d-none d-lg-flex">
                                <ul className="navbar-nav">
                                    {navbarMenu.map((item, index) =>
                                        <li className={item.className} key={index} onClick={() => _onclickMenuItem(index)}>
                                            <Link to={item.menuRoutePath} className={item.menuName === 'Contact' ? `nav-link btn` : `nav-link ${item.active ? 'active' : ''}`}>{item.menuName}</Link>
                                        </li>
                                    )}
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
                <div className="mobile-menu">
                    <div className="menu-content">
                        <div className="d-flex mobile-logo align-items-center justify-content-between">
                            <img alt="" src="assets/images/logo.webp" className="img-fluid" width="140" />
                            <button className="btn-mobile-menu d-block d-lg-none" type="button">
                                <i className="la la-times"></i>
                            </button>
                        </div>
                        <ul className="navbar-nav">
                            <li className="nav-item"><Link className="nav-link" to="/about">About</Link></li>
                            <li className="nav-item"><Link className="nav-link" to="/services">Services</Link></li>
                            <li className="nav-item"><Link className="nav-link" to="/solutions">Solutions</Link></li>
                            <li className="nav-item"><Link className="nav-link" to="/works">Works</Link></li>
                            <li className="nav-item"><Link className="nav-link" to="/hire-team">Hire Team</Link></li>
                            <li className="nav-item"><Link className="nav-link" to="/blog">Blog</Link></li>
                            <li className="nav-item"><Link className="nav-link btn" to="/contact">Contact</Link></li>
                        </ul>
                    </div>
                </div>
                <div className="mobile-menu">
                    <div className="menu-content">
                        <div className="d-flex mobile-logo align-items-center justify-content-between">
                            <img alt="" src="assets/images/logo.webp" className="img-fluid" width="140" />
                            <button className="btn-mobile-menu d-block d-lg-none" type="button">
                                <i className="la la-times"></i>
                            </button>
                        </div>
                        <ul className="navbar-nav">
                            <li className="nav-item"><Link className="nav-link" to="/about">About</Link></li>
                            <li className="nav-item"><Link className="nav-link" to="/services">Services</Link></li>
                            <li className="nav-item"><Link className="nav-link" to="/solutions">Solutions</Link></li>
                            <li className="nav-item"><Link className="nav-link" to="/works">Works</Link></li>
                            <li className="nav-item"><Link className="nav-link" to="/hire-team">Hire Team</Link></li>
                            <li className="nav-item"><Link className="nav-link" to="/blog">Blog</Link></li>
                            <li className="nav-item"><Link className="nav-link btn" to="/contact">Contact</Link></li>
                        </ul>
                    </div>
                </div>
            </header>
            <div className="contact-slideup-991">
                <Link to="/contact" className="slide-item">
                    <div className="slide-icon"><i className="la la-edit"></i></div>
                    <div className="slide-content">
                        <p>Have a project overview?</p>
                        <h6>Submit a form</h6>
                    </div>
                    <div className="slide-link text-end">
                        <i className="la la-angle-right"></i>
                    </div>
                </Link>
                <a href="mailto:hello@webmaa.com" className="slide-item">
                    <div className="slide-icon"><i className="la la-envelope"></i></div>
                    <div className="slide-content">
                        <p>Share detailed specification</p>
                        <h6>hello@webmaa.com</h6>
                    </div>
                    <div className="slide-link text-end">
                        <i className="la la-angle-right"></i>
                    </div>
                </a>
                <a target="_blank" href="https://web.whatsapp.com/send?phone=919836987431&amp;text=Hello! I need some help" className="slide-item">
                    <div className="slide-icon"><i className="la la-whatsapp"></i></div>
                    <div className="slide-content">
                        <p>Connect on the go via Whatsapp?</p>
                        <h6>+91 9836987431</h6>
                    </div>
                    <div className="slide-link text-end">
                        <i className="la la-angle-right"></i>
                    </div>
                </a>
            </div>
        </Fragment>
    );
}

export default Header;