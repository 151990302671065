import BlogGrid from "../../components/blog-grid";

const HomeNewsBlogs = () => {
    return (
        <section className="p-0">
            <div className="container-xl my-3 my-lg-4 mb-xl-5">
                <div className="row gx-3">
                    <div className="col-lg-9 col-md-12">
                        <h6 className="title">NEWS & BLOG</h6>
                        <h3 className="title fw-500 mb-5">Stay ahead in the tech game with the latest industry trends curated by a leading app development agency. <a className="underlined" href="blog/">Explore more on our blog.</a></h3>
                    </div>
                    <div className="col-lg-12">
                        <BlogGrid />
                    </div>
                </div>
            </div>
        </section>
    );
}

export default HomeNewsBlogs;