import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { Fragment } from "react/jsx-runtime";

const SouluionScreen = () => {
    return (
        <Fragment>
            <Helmet>
                <title>Our Solutions</title>
                <meta charSet="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
            </Helmet>
            <section className="inner-banner solutions">
                <div className="container-xl">
                    <div className="row gx-3">
                        <div className="col-lg-10 offset-lg-1 text-center">
                            <h1 className="mb-3"><span className="text-green">Ready-made solutions.</span> <br />
                                Customisable for your business.</h1>
                            <p className="lead-lg">We transform businesses across multiple industries using expertise built over years of operation. Let us shake hands and gain immediate access to our top tech talent, like your own team, for building great software solutions.</p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="py-5">
                <div className="container-xl my-0 my-lg-4">
                    <div className="row gx-3">
                        <div className="col-lg-12 text-center">
                            <h3 className="title mb-4 pb-2">Developing great software solutions for</h3>
                        </div>
                    </div>
                    <div className="row gx-3">
                        <div className="col-sm-6 col-lg-3 mb-4 mb-lg-0">
                            <div className="mybox sol-box">
                                <img src="../assets/images/solicon1.webp" alt="" className="mb-3" />
                                <h6 className="fw-600">Start-up</h6>
                                <p>Fostering enterprise mobility and automation through collaborative software development that results organizational excellence.</p>
                            </div>
                        </div>
                        <div className="col-sm-6 col-lg-3 mb-4 mb-lg-0">
                            <div className="mybox sol-box">
                                <img src="../assets/images/solicon2.webp" alt="" className="mb-3" />
                                <h6>Enterprises</h6>
                                <p>Fostering enterprise mobility and automation through collaborative software development that results organizational excellence.</p>
                            </div>
                        </div>
                        <div className="col-sm-6 col-lg-3 mb-4 mb-lg-0">
                            <div className="mybox sol-box">
                                <img src="../assets/images/solicon3.webp" alt="" className="mb-3" />
                                <h6>B2B Companies</h6>
                                <p>Fostering enterprise mobility and automation through collaborative software development that results organizational excellence.</p>
                            </div>
                        </div>
                        <div className="col-sm-6 col-lg-3">
                            <div className="mybox sol-box">
                                <img src="../assets/images/solicon4.webp" alt="" className="mb-3" />
                                <h6>B2C Companies</h6>
                                <p>Fostering enterprise mobility and automation through collaborative software development that results organizational excellence.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="pb-5">
                <div className="container-xl my-0 my-lg-2">
                    <div className="sol-demo-box p-4 p-sm-5 bg-red rad-10">
                        <div className="row gx-3">
                            <div className="col-lg-5 mb-5 mb-lg-0 align-self-center">
                                <img src="../assets/images/logo-identic.webp" alt="" className="img-fluid mb-4 mb-sm-5" />
                                <h3 className="title fw-600 mb-4 mb-sm-5">Launch your OTT streaming service in just weeks</h3>
                                <h6 className="mb-4 mb-sm-5 fw-300 text-white">End-to-end video platform to power your Live, Video On Demand and 24x7 Linear streaming business.</h6>
                                <Link to="/solutions/demo-ott" className="btn btn-light">Request Demo</Link>
                            </div>
                            <div className="col-lg-6 offset-lg-1 align-self-center">
                                <img src="../assets/images/demo-identic.webp" alt="" className="img-fluid d-block me-lg-0 ms-lg-auto mx-auto" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="pb-5">
                <div className="container-xl my-0 my-lg-2">
                    <div className="sol-demo-box cartface p-4 p-sm-5 rad-10">
                        <div className="row gx-3">
                            <div className="col-lg-5 mb-5 mb-lg-0 align-self-center">
                                <img src="../assets/images/logo-cartface.webp" alt="" className="img-fluid mb-4 mb-sm-5" />
                                <h3 className="title fw-600 mb-4 mb-sm-5">Launch your commerce service in just few clicks</h3>
                                <h6 className="mb-4 mb-sm-5 fw-300 text-secondary">End-to-end commerce solutions to power your business with our phygital powered platform.</h6>
                                <Link to="/solutions/demo-cartface" className="btn btn-blue">Request Demo</Link>
                            </div>
                            <div className="col-lg-6 offset-lg-1 align-self-center">
                                <img src="../assets/images/demo-cartface.webp" alt="" className="img-fluid d-block mx-auto me-lg-0 ms-lg-auto" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="pb-5">
                <div className="container-xl my-0 my-lg-2">
                    <div className="sol-demo-box mate-hcm p-4 p-sm-5 rad-10">
                        <div className="row gx-3">
                            <div className="col-lg-5 mb-5 mb-lg-0 align-self-center">
                                <img src="../assets/images/logo-mate-hcm.webp" alt="" className="img-fluid mb-4 mb-sm-5" />
                                <h3 className="title fw-600 mb-4 mb-sm-5">Your one stop HCM platform to manage Hire to Retire</h3>
                                <h6 className="mb-4 mb-sm-5 fw-300 text-blue">We offer an array of features designed to assist HR professionals in enhancing employee experiences, streamlining operations, and accelerating company growth</h6>
                                <Link to="/solutions/demo-mate-hcm" className="btn btn-green">Request Demo</Link>
                            </div>
                            <div className="col-lg-6 offset-lg-1 align-self-center">
                                <img src="../assets/images/demo-mate-hcm2.webp" alt="" className="img-fluid d-block mx-auto me-lg-0 ms-lg-auto" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="p-0 mb-5">
                <div className="container-xl">
                    <div className="sol-demo-box make-sure rad-10">
                        <div className="row gx-3">
                            <div className="col-lg-5 align-self-center">
                                <div className="p-4 p-sm-5">
                                    <img src="../assets/images/logo-make-sure.webp" alt="" className="img-fluid mb-4 mb-sm-5" />
                                    <h3 className="title fw-600 mb-4 mb-sm-5">Device Financing Risk Management Platforms</h3>
                                    <h6 className="mb-4 mb-sm-5 fw-300 text-red">Encourage customers to make payments on time. Reduce smartphone loan default risk</h6>
                                    <Link to="/solutions/demo-matrix" className="btn btn-primary">Request Demo</Link>
                                </div>
                            </div>
                            <div className="col-lg-6 offset-lg-1 align-self-center">
                                <img src="../assets/images/demo-make-sure.webp" alt="" className="img-fluid d-block mx-auto me-lg-0 ms-lg-auto demo-box-banner" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="pb-5">
                <div className="container-xl">
                    <div className="start_project p-4 p-sm-5">
                        <div className="row gx-3">
                            <div className="col-lg-8 text-center text-lg-start mb-4 mb-lg-0 align-items-center">
                                <h2 className="mb-3 mb-lg-1 text-primary fw-500">Ready to start a project ?</h2>
                                <p className="lead fw-300 m-0">To start, use form to tell us about you and the project</p>
                            </div>
                            <div className="col-lg-4 text-center align-self-center text-lg-end">
                                <Link to="/contact" className="btn btn-primary">Let’s connect</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    );
}

export default SouluionScreen;