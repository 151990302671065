import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { Fragment } from "react/jsx-runtime";

const WorkScreen = () => {
    return (
        <Fragment>
            <Helmet>
                <title>Our Works</title>
                <meta charSet="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
            </Helmet>
            <section className="inner-banner works">
                <div className="container-xl portfolio-details">
                    <div className="row gx-3">
                        <div className="col-lg-8 offset-lg-2 text-center">
                            <h1 className="mb-3"><span className="text-green">Innovation,</span> unleashed<br />
                                5% showcase reflects 100% power</h1>
                            <p className="lead-lg">Examples of the work we’ve done with established brands, growing businesses, and venture-backed startups.</p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="py-5">
                <div className="container-xl portfolio-details mt-0 mt-lg-4">
                    <div className="row">
                        <div className="col-sm-6 mb-5">
                            <Link to="/portfolio/reason-clothing" className="item-work-blog">
                                <div className="image-wrap mb-3 mb-sm-4">
                                    <img src="../assets/images/portfolio/reason-clothing/hero.webp" alt="" className="img-fluid w-100" />
                                </div>
                                <div className="content-wrap">
                                    <h6>For Reason Clothing, we developed a comprehensive e-commerce store and mobile app, enhancing user experience and streamlining online shopping.</h6>
                                    <p>The platform features an intuitive interface, secure payment options, and seamless navigation, showcasing their stylish apparel collection. The mobile app, compatible with both iOS and Android, provides personalized notifications, easy browsing, and a smooth checkout process. Our solution ensures a robust, scalable, and engaging shopping experience, driving customer satisfaction and sales growth for Reason Clothing.</p>
                                </div>
                            </Link>
                        </div>
                        <div className="col-sm-6 mb-5">
                            <Link to="/portfolio/maap" className="item-work-blog">
                                <div className="image-wrap mb-3 mb-sm-4">
                                    <img src="../assets/images/portfolio/maap/hero.webp" alt="" className="img-fluid w-100" />
                                </div>
                                <div className="content-wrap">
                                    <h6>For MAAP, we developed a comprehensive e-commerce store and mobile app, enhancing user experience and streamlining online shopping for their premium cycling apparel.</h6>
                                    <p>The platform features an intuitive interface, secure payment options, and seamless navigation, showcasing their high-performance gear. The mobile app, compatible with both iOS and Android, provides personalized notifications, easy browsing, and a smooth checkout process. Our solution ensures a robust, scalable, and engaging shopping experience, driving customer satisfaction and sales growth for MAAP.</p>
                                </div>
                            </Link>
                        </div>
                        <div className="col-sm-6 mb-5">
                            <Link to="/portfolio/moijey-fine-jewelry" className="item-work-blog">
                                <div className="image-wrap mb-3 mb-sm-4">
                                    <img src="../assets/images/portfolio/moijey-fine-jewelry/hero.webp" alt="" className="img-fluid w-100" />
                                </div>
                                <div className="content-wrap">
                                    <h6>For Moijey Fine Jewelry & Diamonds, we developed a comprehensive e-commerce store and mobile app, enhancing user experience and streamlining online shopping for their exquisite jewelry collections.</h6>
                                    <p>The platform features an intuitive interface, secure payment options, and seamless navigation, showcasing their luxurious diamonds and fine jewelry. The mobile app, compatible with both iOS and Android, provides personalized notifications, easy browsing, and a smooth checkout process. Our solution ensures a robust, scalable, and engaging shopping experience, driving customer satisfaction and sales growth for Moijey.</p>
                                </div>
                            </Link>
                        </div>
                        <div className="col-sm-6 mb-5">
                            <Link to="/portfolio/dukes-seafood" className="item-work-blog">
                                <div className="image-wrap mb-3 mb-sm-4">
                                    <img src="../assets/images/portfolio/dukes-seafood/hero.webp" alt="" className="img-fluid w-100" />
                                </div>
                                <div className="content-wrap">
                                    <h6>For Duke's Seafood, we developed a comprehensive WordPress-based e-commerce site, enhancing user experience and streamlining online food ordering and recipe management. </h6>
                                    <p>The platform features an intuitive interface, secure payment options, and seamless navigation, showcasing their delicious seafood offerings and curated recipes. The integrated system ensures a robust, scalable, and engaging online experience, driving customer satisfaction and sales growth for Duke's Seafood.</p>
                                </div>
                            </Link>
                        </div>
                        <div className="col-sm-6 mb-5">
                            <Link to="/portfolio/liquor" className="item-work-blog">
                                <div className="image-wrap mb-3 mb-sm-4">
                                    <img src="../assets/images/portfolio/liquor/hero.webp" alt="" className="img-fluid w-100" />
                                </div>
                                <div className="content-wrap">
                                    <h6>For Liquor.com, we developed a comprehensive custom PHP-based e-commerce site, enhancing user experience and streamlining online beverage shopping and recipe management. </h6>
                                    <p>The platform features an intuitive interface, secure payment options, and seamless navigation, showcasing their extensive selection of spirits and curated cocktail recipes. Our solution ensures a robust, scalable, and engaging online experience, driving customer satisfaction and sales growth for Liquor.com.</p>
                                </div>
                            </Link>
                        </div>
                        <div className="col-sm-6 mb-5">
                            <Link to="/portfolio/melgio-vanni" className="item-work-blog">
                                <div className="image-wrap mb-3 mb-sm-4">
                                    <img src="../assets/images/portfolio/melgio-vanni/hero.webp" alt="" className="img-fluid w-100" />
                                </div>
                                <div className="content-wrap">
                                    <h6>For MelgioVanni, we developed a comprehensive WordPress-based portfolio management site, enhancing user experience and streamlining image gallery management. </h6>
                                    <p>The platform features an intuitive interface, secure access controls, and seamless navigation, showcasing their diverse portfolio of images. Our solution ensures a robust, scalable, and engaging online experience, driving client satisfaction and showcasing MelgioVanni’s artistic excellence.</p>
                                </div>
                            </Link>
                        </div>
                        <div className="col-sm-6 mb-5">
                            <Link to="/portfolio/crafto-leather" className="item-work-blog">
                                <div className="image-wrap mb-3 mb-sm-4">
                                    <img src="../assets/images/portfolio/crafto-leather/hero.webp" alt="" className="img-fluid w-100" />
                                </div>
                                <div className="content-wrap">
                                    <h6>For CraftoLeather, we developed a sophisticated e-commerce store on our Laravel-based platform, enhancing user experience and streamlining online leather goods sales. </h6>
                                    <p>The platform features an intuitive interface, secure payment options, and seamless navigation, showcasing their premium leather products. Our solution ensures a robust, scalable, and engaging shopping experience, driving customer satisfaction and sales growth for CraftoLeather.</p>
                                </div>
                            </Link>
                        </div>
                        <div className="col-sm-6 mb-5">
                            <Link to="/portfolio/bengal-beverages" className="item-work-blog">
                                <div className="image-wrap mb-3 mb-sm-4">
                                    <img src="../assets/images/portfolio/bengal-beverages/hero.webp" alt="" className="img-fluid w-100" />
                                </div>
                                <div className="content-wrap">
                                    <h6>For Bengal Beverages, we developed a cutting-edge website on our Laravel-React-based CMS system, enhancing user experience and streamlining the online management of their beverage offerings. </h6>
                                    <p>The platform features a modern interface, secure payment options, and seamless navigation, showcasing their diverse range of beverages. Our solution ensures a robust, scalable, and engaging online experience, driving customer satisfaction and business growth for Bengal Beverages.</p>
                                </div>
                            </Link>
                        </div>
                        <div className="col-sm-6 mb-5">
                            <Link to="/portfolio/fectiv" className="item-work-blog">
                                <div className="image-wrap mb-3 mb-sm-4">
                                    <img src="../assets/images/portfolio/fectiv/hero.webp" alt="" className="img-fluid w-100" />
                                </div>
                                <div className="content-wrap">
                                    <h6>For Fectiv, we developed a comprehensive WordPress-based website builder tailored specifically for small businesses, enhancing ease of use and functionality. </h6>
                                    <p>The platform features an intuitive interface, customizable templates, and powerful design tools, enabling users to create professional websites effortlessly. Our solution ensures a robust, scalable, and engaging web-building experience, driving client satisfaction and growth for Fectiv.</p>
                                </div>
                            </Link>
                        </div>
                        <div className="col-sm-6 mb-5">
                            <Link to="/portfolio/mohendra-dutt" className="item-work-blog">
                                <div className="image-wrap mb-3 mb-sm-4">
                                    <img src="../assets/images/portfolio/mohendra-dutt/hero.webp" alt="" className="img-fluid w-100" />
                                </div>
                                <div className="content-wrap">
                                    <h6>For Mohendra Dutt, we overhauled their existing WooCommerce-based webstore to address numerous issues and enhance overall performance. </h6>
                                    <p>The revamped platform features a modern interface, optimized navigation, and secure payment options, delivering a seamless shopping experience. Our solution ensures a robust, scalable, and engaging online presence, driving customer satisfaction and boosting sales for Mohendra Dutt.</p>
                                </div>
                            </Link>
                        </div>
                        <div className="col-sm-6 mb-5">
                            <Link to="/portfolio/around-me" className="item-work-blog">
                                <div className="image-wrap mb-3 mb-sm-4">
                                    <img src="../assets/images/portfolio/around-me/hero.webp" alt="" className="img-fluid w-100" />
                                </div>
                                <div className="content-wrap">
                                    <h6>For AroundMe, we developed a comprehensive iOS app designed to enhance users' ability to discover and explore their surroundings. </h6>
                                    <p>The app features an intuitive interface, real-time location-based search, and detailed information about nearby businesses and services. Our solution ensures a robust, user-friendly experience, driving engagement and satisfaction for AroundMe users.</p>
                                </div>
                            </Link>
                        </div>
                        <div className="col-sm-6 mb-5">
                            <Link to="/portfolio/wiseasy" className="item-work-blog">
                                <div className="image-wrap mb-3 mb-sm-4">
                                    <img src="../assets/images/portfolio/wiseasy/hero.webp" alt="" className="img-fluid w-100" />
                                </div>
                                <div className="content-wrap">
                                    <h6>For Wiseasy, a leading POS manufacturer in China, we developed a cutting-edge website using custom PHP and Lit HTML. </h6>
                                    <p>The new platform showcases their advanced POS solutions with an intuitive interface, comprehensive product information, and seamless navigation. Our solution ensures a robust, scalable, and engaging online presence, driving customer satisfaction and business growth for Wiseasy.</p>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </section>
            <section className="pb-5">
                <div className="container-xl">
                    <div className="start_project p-4 p-sm-5">
                        <div className="row gx-3">
                            <div className="col-lg-8 text-center text-lg-start mb-4 mb-lg-0 align-items-center">
                                <h2 className="mb-3 mb-lg-1 text-primary fw-500">Ready to start a project ?</h2>
                                <p className="lead fw-300 m-0">To start, use form to tell us about you and the project</p>
                            </div>
                            <div className="col-lg-4 text-center align-self-center text-lg-end">
                                <a href="../contact/" className="btn btn-primary">Let’s connect</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    );
}

export default WorkScreen;