import { Link } from "react-router-dom";

export default function BengalBeverages() {
    return (
        <section className="page-portfolio pt-5">
            <div className="container-xl portfolio-details mt-5">
                <div className="row pt-1 pt-sm-5">
                    <div className="col-lg-10 offset-lg-1 text-center">
                        <h2 className="title mb-5">Bengal Beverages</h2>
                    </div>
                </div>
            </div>
            <div className="container-xl portfolio-details mb-5">
                <img src="../../assets/images/portfolio/bengal-beverages/hero.webp" alt="" className="img-fluid w-100" />
            </div>
            <div className="container-xl portfolio-details mb-5">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="about-project">
                            <div className="row">
                                <div className="col-lg-6 mb-5 mb-lg-0">
                                    <h3>About this project </h3>
                                    <p>For Bengal Beverages, we developed a cutting-edge website on our Laravel-React-based CMS system, enhancing user experience and streamlining the online management of their beverage offerings. The platform features a modern interface, secure payment options, and seamless navigation, showcasing their diverse range of beverages. Our solution ensures a robust, scalable, and engaging online experience, driving customer satisfaction and business growth for Bengal Beverages.</p>
                                </div>
                                <div className="col-lg-6">
                                    <div className="about-project-availability">
                                        <div className="row">
                                            <div className="col-sm-6 mb-4">
                                                <h6>Services</h6>
                                                <ul>
                                                    <li>UI/UX Designing</li>
                                                    <li>Project Management</li>
                                                    <li>Application development</li>
                                                    <li>Mobile app development</li>
                                                    <li>Quality Assurance</li>
                                                </ul>
                                            </div>
                                            <div className="col-sm-6 mb-4">
                                                <h6>Technology </h6>
                                                <ul>
                                                    <li>Figma</li>
                                                    <li>CodeIgniter PHP</li>
                                                    <li>Android</li>
                                                    <li>iOS</li>
                                                    <li>MySQL</li>
                                                </ul>
                                            </div>
                                        </div>

                                        <div className="availability">
                                            <a href="https://bengalbeverages.net/" target="_blank"><img src="../../assets/images/link-web.webp" alt="Website" /></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-xl portfolio-details mb-5">
                <div className="row">
                    <div className="col-lg-10 offset-lg-1">
                        <h3>Meet Bengal Beverages</h3>
                        <p>Bengal Beverages has launched an innovative website built on our advanced Laravel-React CMS system to bring its extensive range of beverages directly to consumers. Founded with a passion for quality and variety, Bengal Beverages offers a curated selection of drinks that cater to a wide array of tastes and preferences.</p>
                        <p>The new website is designed to provide a seamless browsing and purchasing experience, featuring intuitive navigation, secure payment options, and detailed product information. The platform is optimized for both desktop and mobile devices, allowing users to explore and order Bengal Beverages' products with ease. Committed to quality and customer satisfaction, Bengal Beverages continues to set the standard in the beverage market.</p>
                    </div>
                </div>
            </div>
            <div className="container-xl portfolio-details mb-5">
                <img src="../../assets/images/portfolio/bengal-beverages/image1.png" alt="" className="img-fluid w-100" />
            </div>


            <div className="container-xl portfolio-details mb-5">
                <div className="row">
                    <div className="col-lg-10 offset-lg-1">
                        <h3>Bengal Beverages’  Growth Story</h3>
                        <p>Bengal Beverages began as a local distributor with a vision to offer a diverse selection of high-quality beverages to a broader audience. Through dedication to quality and a deep understanding of their customers' preferences, they quickly established a strong market presence. Launching their new Laravel-React-based website expanded their reach, offering a seamless and engaging online shopping experience. Strategic marketing and customer engagement efforts further enhanced their brand visibility and sales. Today, Bengal Beverages stands as a leading online retailer of beverages, continuously growing and setting new standards in the industry, with a loyal and expanding customer base.</p>
                    </div>
                </div>
            </div>
            <div className="container-xl portfolio-details mb-5">
                <img src="../../assets/images/portfolio/bengal-beverages/image2.png" alt="" className="img-fluid w-100" />
            </div>
            <div className="container-xl portfolio-details mb-5">
                <div className="row">
                    <div className="col-lg-10 offset-lg-1">
                        <h3>What is unique about Bengal Beverages</h3>
                        <p>Bengal Beverages stands out for its unique blend of high-quality beverage offerings and innovative website technology, delivering an exceptional shopping experience. Each product is selected with meticulous attention to quality, ensuring a diverse and premium selection of drinks. What truly sets Bengal Beverages apart is their commitment to excellence in both product quality and online user experience. Their Laravel-React-based CMS system provides a seamless, user-friendly interface, featuring secure payment options, detailed product descriptions, and an optimized mobile experience. Additionally, Bengal Beverages maintains a strong connection with its audience through strategic marketing and customer engagement initiatives, fostering a loyal customer base. By continuously evolving their product offerings and incorporating customer feedback, Bengal Beverages ensures they stay ahead of industry trends while meeting the diverse needs of their clientele. This dedication to quality, innovation, and customer satisfaction makes Bengal Beverages a standout in the beverage industry.</p>
                    </div>
                </div>
            </div>
            <div className="container-xl portfolio-details mb-5">
                <img src="../../assets/images/portfolio/bengal-beverages/image3.png" alt="" className="img-fluid w-100" />
            </div>


            <div className="container-xl portfolio-details mb-5">
                <div className="row">
                    <div className="col-lg-10 offset-lg-1">
                        <h3>Faced Challenges</h3>
                        <p>The existing platform struggled with limited functionality and slow performance, impacting the client’s ability to manage and showcase their extensive range of products effectively.</p>
                        <p>Addressing these challenges required implementing a robust CMS system, optimizing site performance, and enhancing product management features. Successfully navigating these tasks demanded a combination of advanced technology integration, performance optimization, and strategic planning. The ability to efficiently manage a diverse product catalog, provide a smooth user experience, and ensure fast loading times played a crucial role in achieving business success.</p>
                    </div>
                </div>
            </div>


            <div className="container-xl portfolio-details mb-5">
                <img src="../../assets/images/portfolio/bengal-beverages/image4.png" alt="" className="img-fluid w-100" />
            </div>


            <div className="container-xl portfolio-details mb-5">
                <div className="row">
                    <div className="col-lg-10 offset-lg-1">
                        <h3>Project Approach</h3>
                        <p>The project began with discovery, analyzing client needs. Planning set milestones, timelines, and communication. Development optimized performance. Testing ensured quality. Successful implementation replaced old versions.</p>
                        <p>The overall approach to the project involved a systematic and comprehensive process. Starting with the discovery phase, the team thoroughly analyzed the client’s requirements, identified pain points, and determined the scope of the project. During the planning stage, they defined the project milestones, created a detailed timeline, and established communication channels for seamless collaboration. In the development phase, the team focused on front-end and back-end development using Laravel and React, ensuring optimal performance and user experience. Extensive testing and quality assurance measures were implemented to identify and rectify any issues. The project was then implemented, with the new website seamlessly replacing the previous version.</p>
                        <p className="mb-2">Key Benefits</p>
                        <ul>
                            <li>Enhanced functionality and performance with a Laravel-React-based CMS system.</li>
                            <li>Improved user experience through a visually appealing design and intuitive navigation. </li>
                            <li>Robust and scalable platform optimized for managing a diverse product catalog.</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="container-xl portfolio-details mb-5">
                <img src="../../assets/images/portfolio/bengal-beverages/image5.png" alt="" className="img-fluid w-100" />
            </div>

            <div className="container-xl portfolio-details mb-5">
                <div className="row">
                    <div className="col-lg-10 offset-lg-1">
                        <h3>Results</h3>
                        <p>Following the implementation of the project, Bengal Beverages experienced numerous positive outcomes. The new Laravel-React-based website resolved the functionality and performance issues that previously plagued the client, resulting in a reliable and efficient platform for managing and showcasing their beverage offerings. The enhanced features allowed for better product management, improved site performance, and increased customer engagement. The optimized website catered to a growing customer base, boosting Bengal Beverages’ online presence and sales. Overall, the project brought significant improvements to the client’s online operations, ensuring a smooth and engaging experience for their users.</p>
                    </div>
                </div>
            </div>
            <div className="container-xl portfolio-details mb-5">
                <div className="row">
                    <div className="col-lg-8 offset-lg-2">
                        <div className="portfolio-quote">
                            <div className="content">
                                Webmaa's adaptability to our evolving needs and the steady stream of insightful feedback and ideas prove they were not just a partner – they were an indispensable asset in helping us reach our vision to democratize the fintech industry.
                            </div>
                            <div className="author">
                                <div className="auth-image">
                                    <img src="../../assets/images/portfolio/bengal-beverages/author.png" alt="author" />
                                </div>
                                <div className="auth-desig">
                                    <h6>User Name</h6>
                                    <p>Designation, Company Name</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-xl portfolio-nav mb-5">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="row">
                            <div className="col-6">
                                <Link to="/portfolio/crafto-leather" className="d-block pprev">
                                    <span className="fs-14 fw-500 text-black">Previous Project</span> <br />
                                    <span className="fs-13 text-gray d-none d-sm-block">Fast and Simple Website Builder<br /></span>
                                    <span className="fs-15 fw-600 text-secondary">Crafto Leather</span>
                                </Link>
                            </div>
                            <div className="col-6">
                                <Link to="/portfolio/fectiv" className="d-block pnext text-end">
                                    <span className="fs-14 fw-500 text-black">Next Project</span> <br />
                                    <span className="fs-13 text-gray d-none d-sm-block">Fast and Simple Website Builder<br /></span>
                                    <span className="fs-15 fw-600 text-secondary">Fectiv</span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section >
    )
}