import { Helmet } from "react-helmet";
import { Fragment } from "react/jsx-runtime"

const AboutScreen = () => {
    return (
        <Fragment>
            <Helmet>
                <title>About Webmaa</title>
                <meta charSet="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
            </Helmet>
            <section className="inner-banner about">
                <div className="container-xl">
                    <div className="row gx-3">
                        <div className="col-lg-10 offset-lg-1 text-center">
                            <p className="lead-lg">Innovation-driven software agency delivering apps on demand since 2008.</p>
                            <h1 className="mb-4 mb-sm-5">We craft <span className="text-purple">apps</span> and <span className="text-red">digital experiences</span> for enterprises, mid-size companies, and startups.</h1>
                            <a href="../contact/" className="btn btn-success">Let&rsquo;s work together!</a>
                        </div>
                    </div>
                </div>
            </section>
            <section className="py-5">
                <div className="container-xl my-0 my-lg-4">
                    <div className="row gx-3">
                        <div className="col-lg-8 col-md-12">
                            <h6 className="title">What we believe in</h6>
                            <h3 className="title">We believe in people and technology, the never ending self-improvement that pushes us toward excellence.</h3>
                            <p className="lead mb-4">Through years of experience, we’ve learned that life is too short to build unused software. Before starting a new partnership, we don’t just ask if a project is feasible; we ask if it’s desirable and impactful.</p>
                        </div>
                    </div>
                    <div className="row gx-0 mb-2">
                        <div className="col-md-4 mb-3">
                            <div className="mybox believe-box">
                                <h6>Curiosity</h6>
                                <p>We&#39;re a bunch of curious minds, always on a hunt for information and novelty, which allows us to innovate and exceed the expectations of our clients.</p>
                            </div>
                        </div>
                        <div className="col-md-4 mb-3">
                            <div className="mybox believe-box border-lr-0">
                                <h6>Leadership</h6>
                                <p>We create an inspiring environment where our clients and employees can reach their potential. We challenge the status quo and help others grow.</p>
                            </div>
                        </div>
                        <div className="col-md-4 mb-3">
                            <div className="mybox believe-box">
                                <h6>Relation - orientation</h6>
                                <p>Our team is like a close family, our clients are like relatives. We make sure that our relationships result in supportive partnerships.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row gx-3 mb-4">
                        <div className="col-lg-9 col-md-12">
                            <p className="lead-lg fw-300">We understand that delivering outstanding products requires very precise software development cycles and skills. We&#39;re meticulous and proud of it!</p>
                        </div>
                    </div>
                    <div className="row gx-3">
                        <div className="col-lg-12">
                            <div className="owl-carousel owl-theme owl_about nav_left_right">
                                <div className="item"><img src="../assets/images/about1.webp" /></div>
                                <div className="item"><img src="../assets/images/about1.webp" /></div>
                                <div className="item"><img src="../assets/images/about2.webp" /></div>
                                <div className="item"><img src="../assets/images/about3.webp" /></div>
                                <div className="item"><img src="../assets/images/about4.webp" /></div>
                                <div className="item"><img src="../assets/images/about5.webp" /></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="pb-5">
                <div className="container-xl my-3 my-lg-4">
                    <div className="row gx-3">
                        <div className="col-lg-8 col-md-12">
                            <h6 className="title">QUICK FACTS</h6>
                            <h3 className="title mb-4">Over the last decade, we’ve helped our clients ship products for multiple platforms</h3>
                        </div>
                    </div>
                    <div className="row gx-3">
                        <div className="col-12">
                            <div className="fact_grid">
                                <div className="fact_grid_item">
                                    <p>2008<br />
                                        <span>Journey</span><span>
                                            Started</span></p>
                                </div>

                                <div className="fact_grid_item">
                                    <p>1000+<br />
                                        <span>Project</span><span>
                                            Delivered</span></p>
                                </div>

                                <div className="fact_grid_item">
                                    <p>250+<br />
                                        <span>Clients</span><span>
                                            Worldwide</span></p>
                                </div>

                                <div className="fact_grid_item">
                                    <p>40+<br />
                                        <span>Technologies</span><span>
                                            supported</span></p>
                                </div>

                                <div className="fact_grid_item">
                                    <p>10 Mil.<br />
                                        <span>Code</span><span>
                                            developed</span></p>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="py-5" style={{ backgroundColor: "#F8FBFC" }}>
                <div className="container-xl my-3 my-lg-4">
                    <div className="row gx-3">
                        <div className="col-lg-8 col-md-12">
                            <h6 className="title">Our core values</h6>
                            <h3 className="title mb-4 mb-md-5">Our core values evolved with us as the company grew and we <span className="text-green fw-500">learned from our experiences.</span></h3>
                        </div>
                    </div>
                    <div className="row gx-0 mb-2">
                        <div className="col-md-4 mb-3">
                            <div className="mybox corevalue-box">
                                <img src="../assets/images/icon-core1.webp" alt="" />
                                <p>Never settle for &ldquo;good enough&rdquo; when you know you can and should do better.</p>
                            </div>
                        </div>
                        <div className="col-md-4 mb-3">
                            <div className="mybox corevalue-box border-lr-0">
                                <img src="../assets/images/icon-core2.webp" alt="" />
                                <p>Take ownership and question the status quo in a constructive manner</p>
                            </div>
                        </div>
                        <div className="col-md-4 mb-3">
                            <div className="mybox corevalue-box">
                                <img src="../assets/images/icon-core3.webp" alt="" />
                                <p>Build an inclusive, transparent and socially responsible culture and we work on it every day.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="py-5">
                <div className="container-xl my-3 my-lg-4">
                    <div className="row gx-3">
                        <div className="col-lg-8 col-md-12">
                            <h6 className="title">how we work</h6>
                            <h3 className="title">Flexible at the core. Organized to stay in sync. with <span className="text-red fw-500">Transparent Workflow.</span></h3>
                            <p className="lead mb-4 mb-md-5">To deliver outstanding software, we work in Agile and Lean. We believe in the power of the iterative process — the step-by-step design, development, testing, and release of functionalities.</p>
                        </div>
                    </div>
                    <div className="row gx-3">
                        <div className="col-lg-12 exelecnce-wrap">
                            <div className="exelecnce-box">
                                <h4>01</h4>
                                <h5>Discover</h5>
                                <ul className="list-unstyled">
                                    <li>Brainstorming</li>
                                    <li>Hypothesis</li>
                                    <li>Conceptualization</li>
                                    <li>Assumptions</li>
                                    <li>Constraints</li>
                                </ul>
                            </div>

                            <div className="exelecnce-box">
                                <h4>02</h4>
                                <h5>Define</h5>
                                <ul className="list-unstyled">
                                    <li>Req. Definition</li>
                                    <li>SRS Document</li>
                                    <li>Project Planning</li>
                                    <li>Dev. Planning</li>
                                    <li>Milestones</li>
                                </ul>
                            </div>

                            <div className="exelecnce-box">
                                <h4>03</h4>
                                <h5>Design</h5>
                                <ul className="list-unstyled">
                                    <li>System Design</li>
                                    <li>Wireframing</li>
                                    <li>Prototyping</li>
                                    <li>UI / UX Design</li>
                                    <li>Architecture</li>
                                </ul>
                            </div>

                            <div className="exelecnce-box">
                                <h4>04</h4>
                                <h5>Develop</h5>
                                <ul className="list-unstyled">
                                    <li>Coding</li>
                                    <li>APIs</li>
                                    <li>Testing / QA</li>
                                    <li>Debug</li>
                                    <li>Change Mgmt</li>
                                </ul>
                            </div>

                            <div className="exelecnce-box">
                                <h4>05</h4>
                                <h5>Deliver</h5>
                                <ul className="list-unstyled">
                                    <li>Environment</li>
                                    <li>Deployment</li>
                                    <li>Migration</li>
                                    <li>Support</li>
                                    <li>Review</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="pb-4">
                <div className="container-xl my-3 my-lg-4">
                    <div className="row gx-3">
                        <div className="col-lg-8 col-md-12">
                            <h6 className="title">the team</h6>
                            <h3 className="title mb-4 mb-md-5">Our People are the <span className="text-green fw-500">key to success.</span> We feel proud every time when someone new is joining</h3>
                        </div>
                    </div>
                    <div className="row gx-2 about-ceo-sec">
                        <div className="col-lg-5 mb-4 mb-lg-0">
                            <div className="row gx-2">
                                <div className="col-6">
                                    <img src="../assets/images/soumya-banerjee.webp" alt="Soumya Banerjee" className="img-fluid mb-3 w-100" />
                                    <h6 className="mb-1 fw-600">Soumya Banerjee</h6>
                                    <p className="m-0 fw-300">Head Business Development</p>
                                    <a className="display-inline-block fs-20 text-dark" href="https://www.linkedin.com/in/soumyabanerjee2/" target="_blank"><i className="la la-linkedin"></i></a>
                                </div>
                                <div className="col-6">
                                    <img src="../assets/images/souvik-banerjee.webp" alt="Souvik Banerjee" className="img-fluid mb-3 w-100" />
                                    <h6 className="mb-1 fw-600">Souvik Banerjee</h6>
                                    <p className="m-0 fw-300">Head Business Development</p>
                                    <a className="display-inline-block fs-20 text-dark" href="https://www.linkedin.com/in/souvik-banerjee-49802611/" target="_blank"><i className="la la-linkedin"></i></a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-7">
                            <div className="bg-primary p-3 p-sm-4">
                                <div className="d-flex gap-2 about-ceo-desk align-items-start">
                                    <img src="../assets/images/quote-about.webp" />
                                    <div className="mb-2 mb-sm-0  ">
                                        <h4 className="mb-4">Since the beginning our main goal was to help entrepreneurs build new software solution that matters. Building and shipping products that are helpful for people, making world a better place, for everyone, including ourselves.</h4>
                                        <div className="d-flex gap-2 pt-1 align-items-start">
                                            <img src="../assets/images/icon-ceo1.webp" />
                                            <div>
                                                <h6 className="m-0 text-black">Soumya Banerjee</h6>
                                                <p className="m-0 text-black">Founder & Head Business Development</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section className="py-5">
                <div className="container-xl">
                    <div className="row gx-3">
                        <div className="col-lg-6 mb-4 mb-lg-0">
                            <h6 className="title mb-2">words from our clients</h6>
                            <h3 className="title mb-2">We believe in building lasting relationships.</h3>
                            <p className="fs-14 m-0">We have connected with amazing clients to enable them with all that the digital revolution has to offer. Seeing our clients passionate about their ideas makes us want to deliver exceedingly successful mobile applications and websites. And that’s what we strive to do everyday.</p>
                        </div>
                        <div className="col-lg-6">
                            <div className="row gx-3">
                                <div className="col-6">
                                    <div className="youtube_video_thumb">
                                        <img src="../assets/images/video-thumb1.webp" alt="Video 1" className="img-fluid w-100" />
                                        <a href="javascript:;" data-bs-toggle="modal" data-bs-target="#modalYtVideo1"><img src="../assets/images/YouTube-play.webp" /></a>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="youtube_video_thumb">
                                        <img src="../assets/images/video-thumb2.webp" alt="Video 2" className="img-fluid w-100" />
                                        <a href="javascript:;" data-bs-toggle="modal" data-bs-target="#modalYtVideo2"><img src="../assets/images/YouTube-play.webp" /></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="pb-4">
                <div className="container-xl my-3 my-lg-4">
                    <div className="row gx-3">
                        <div className="col-12 col-md-8 offset-md-2">
                            <h2 className="text-center m-0">More than 10 years in the game and we&#39;re just getting started.</h2>
                        </div>
                    </div>
                </div>
            </section>
        </Fragment >
    );
}

export default AboutScreen;