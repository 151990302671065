import { CSSProperties, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Fragment } from "react/jsx-runtime";
import ScaleLoader from "react-spinners/ScaleLoader";
import { Blog } from "../../types/blog-types";
import { Helmet } from "react-helmet";

const override: CSSProperties = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
};

const BlogScreen = () => {
    const [allBlogs, setAllBlogs] = useState<Array<Blog>>([]);
    const [loaderVisibility, setLoaderVisibility] = useState<boolean>(true);

    const getAllBlogs = () => {
        fetch(`${process.env.REACT_APP_API_URL}blogs`, {
            method: 'GET'
        })
            .then(res => res.json())
            .then(response => {
                // console.log(response);
                setAllBlogs(response.data);
                setTimeout(() => {
                    setLoaderVisibility(false);
                }, 1000);
            })
            .catch(error => {
                setLoaderVisibility(false);
                toast.error('Something went wrong !', {
                    position: "bottom-center",
                    theme: "dark"
                });
            });
    }

    useEffect(() => {
        getAllBlogs();
    }, []);
    return (
        <Fragment>
            <Helmet>
                <title>Our Blog</title>
                <meta charSet="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
            </Helmet>
            <section className="inner-banner banner-slick blog">
                <div className="container-xl">
                    <div className="row">
                        <div className="col-lg-10 offset-lg-1 text-center">
                            <h6 className="title">Our Blog</h6>
                            <h3 className="title fw-500 mb-4">News and Updates at <span className="text-red">Webmaa</span></h3>
                        </div>
                    </div>
                </div>
            </section>
            {loaderVisibility ? (
                <section className="py-5">
                    <div className="container-xl mt-0 mt-lg-4 d-flex">
                        <ScaleLoader
                            color={"red"}
                            loading={loaderVisibility}
                            cssOverride={override}
                            // size={150}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                        />
                    </div>
                </section>
            ) : (
                <section className="py-5">
                    <div className="container-xl mt-0 mt-lg-4">
                        <div className="row mb-5">
                            <div className="col-lg-8">
                                <Link to={`/blog-details/${allBlogs[0].id}`} className="item-work-blog item-blog featured">
                                    <div className="image-wrap mb-3 mb-sm-4">
                                        <img src={allBlogs[0]['banner_image']} alt="" className="img-fluid w-100" />
                                    </div>
                                    <div className="content-wrap">
                                        <h6>{allBlogs[0]['title']}</h6>
                                        <p dangerouslySetInnerHTML={{ __html: allBlogs[0]['content'] }} />
                                    </div>
                                </Link>
                            </div>
                            <div className="col-lg-4 d-none d-lg-block">
                                <h5 className="m-0">Featured Blogs</h5>
                                <br />
                                {allBlogs?.length === 0 && <p>No blogs found</p>}
                                {allBlogs?.map((item, index) => {
                                    if (item.is_featured) {
                                        return <Link key={index} to={`/blog-details/${item.id}`} className="blog-recent-post-item">
                                            <img src={item?.thumbnail_image} alt="" className="img-fluid" />
                                            <h6>{item.title}</h6>
                                        </Link>
                                    }
                                })}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <h3 className="m-0">Related Blog</h3>
                                <hr />
                            </div>
                            {allBlogs?.map((item, index) =>
                                <div className="col-lg-4 col-sm-6 mb-5" key={index}>
                                    <Link to={`/blog-details/${item.id}`} className="item-work-blog item-blog">
                                        <div className="image-wrap mb-3 mb-sm-4">
                                            <img src={item.banner_image} alt="" className="img-fluid w-100" />
                                        </div>
                                        <div className="content-wrap">
                                            <h6>{item.title}</h6>
                                            <p dangerouslySetInnerHTML={{ __html: item.content }} />
                                        </div>
                                    </Link>
                                </div>
                            )}
                            {/* <div className="col-lg-4 col-sm-6 mb-5">
                                <a href="../blog-details/" className="item-work-blog item-blog">
                                    <div className="image-wrap mb-3 mb-sm-4">
                                        <img src="../assets/images/blog2.webp" alt="" className="img-fluid w-100" />
                                    </div>
                                    <div className="content-wrap">
                                        <h6>Redefining a telecom customer journey with a powerful all-in-one app</h6>
                                        <p>We made omnichannel a reality for A1 with over 60% customers using the mobile app.</p>
                                    </div>
                                </a>
                            </div>
                            <div className="col-lg-4 col-sm-6 mb-5">
                                <a href="../blog-details/" className="item-work-blog item-blog">
                                    <div className="image-wrap mb-3 mb-sm-4">
                                        <img src="../assets/images/blog3.webp" alt="" className="img-fluid w-100" />
                                    </div>
                                    <div className="content-wrap">
                                        <h6>Redefining a telecom customer journey with a powerful all-in-one app</h6>
                                        <p>We made omnichannel a reality for A1 with over 60% customers using the mobile app.</p>
                                    </div>
                                </a>
                            </div>
                            <div className="col-lg-4 col-sm-6 mb-5">
                                <a href="../blog-details/" className="item-work-blog item-blog">
                                    <div className="image-wrap mb-3 mb-sm-4">
                                        <img src="../assets/images/blog2.webp" alt="" className="img-fluid w-100" />
                                    </div>
                                    <div className="content-wrap">
                                        <h6>Redefining a telecom customer journey with a powerful all-in-one app</h6>
                                        <p>We made omnichannel a reality for A1 with over 60% customers using the mobile app.</p>
                                    </div>
                                </a>
                            </div>
                            <div className="col-lg-4 col-sm-6 mb-5">
                                <a href="../blog-details/" className="item-work-blog item-blog">
                                    <div className="image-wrap mb-3 mb-sm-4">
                                        <img src="../assets/images/blog1.webp" alt="" className="img-fluid w-100" />
                                    </div>
                                    <div className="content-wrap">
                                        <h6>Redefining a telecom customer journey with a powerful all-in-one app</h6>
                                        <p>We made omnichannel a reality for A1 with over 60% customers using the mobile app.</p>
                                    </div>
                                </a>
                            </div>
                            <div className="col-lg-4 col-sm-6 mb-5">
                                <a href="../blog-details/" className="item-work-blog item-blog">
                                    <div className="image-wrap mb-3 mb-sm-4">
                                        <img src="../assets/images/blog2.webp" alt="" className="img-fluid w-100" />
                                    </div>
                                    <div className="content-wrap">
                                        <h6>Redefining a telecom customer journey with a powerful all-in-one app</h6>
                                        <p>We made omnichannel a reality for A1 with over 60% customers using the mobile app.</p>
                                    </div>
                                </a>
                            </div>
                            <div className="col-lg-4 col-sm-6 mb-5">
                                <a href="../blog-details/" className="item-work-blog item-blog">
                                    <div className="image-wrap mb-3 mb-sm-4">
                                        <img src="../assets/images/blog3.webp" alt="" className="img-fluid w-100" />
                                    </div>
                                    <div className="content-wrap">
                                        <h6>Redefining a telecom customer journey with a powerful all-in-one app</h6>
                                        <p>We made omnichannel a reality for A1 with over 60% customers using the mobile app.</p>
                                    </div>
                                </a>
                            </div>
                            <div className="col-lg-4 col-sm-6 mb-5">
                                <a href="../blog-details/" className="item-work-blog item-blog">
                                    <div className="image-wrap mb-3 mb-sm-4">
                                        <img src="../assets/images/blog2.webp" alt="" className="img-fluid w-100" />
                                    </div>
                                    <div className="content-wrap">
                                        <h6>Redefining a telecom customer journey with a powerful all-in-one app</h6>
                                        <p>We made omnichannel a reality for A1 with over 60% customers using the mobile app.</p>
                                    </div>
                                </a>
                            </div> */}
                        </div>
                    </div>
                </section>
            )}
        </Fragment>
    );
}

export default BlogScreen;