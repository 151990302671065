const HomeIndustryWiseSolutions = () => {
    return (
        <section className="pb-5">
            <div className="container-xl my-3 my-lg-4">
                <div className="row gx-3">
                    <div className="col-lg-12">
                        <h6 className="title">industry wise solutions</h6>
                        <h3 className="title mb-5 fw-600"><span>Since 2008</span>, Webmaa <span>has aspired</span> global businesses <span>to use the</span> technologies of today <span>to</span> build a better tomorrow. <span>Let us shake hands and gain immediate access to our</span> top tech talent, <span>like your own</span> team, <span>for</span> building great software solutions.</h3>
                    </div>
                    <div className="col-lg-6 mb-4 mb-lg-0">
                        <ul className="list-industry">
                            <li>E-Commerce</li>
                            <li>Social Networking</li>
                            <li>Healthcare</li>
                            <li>Government</li>
                            <li>Business</li>
                            <li>Fitness</li>
                            <li>Food Delivery</li>
                            <li>Delivery Network</li>
                            <li>Real Estate</li>
                            <li>Retail</li>
                            <li>Entertainment</li>
                            <li>Travel</li>
                            <li>Education</li>
                            <li>Sports</li>
                        </ul>
                    </div>
                    <div className="col-lg-6">
                        <img alt="" className="img-fluid w-100" src="assets/images/sollution.webp" title="" />
                    </div>
                </div>
            </div>
        </section>
    );
}

export default HomeIndustryWiseSolutions;