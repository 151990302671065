// define app routes with createBrowserRouter
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
import MainContainer from "../screens/main/main-container";
import HomeScreen from "../screens/home";
import BlogScreen from "../screens/blog/blog-screen";
import AboutScreen from "../screens/about/about-screen";
import ServiceScreen from "../screens/service/service-screen";
import SouluionScreen from "../screens/solution/solution-screen";
import WorkScreen from "../screens/work/work-screen";
import HireTeamScreen from "../screens/hire/hire-team-screen";
import Contact from "../screens/contact";
import BlogDetails from "../screens/blog/blog-details";
import Portpolio from "../screens/work/portpolio";
import SolutionDetails from "../screens/solution/solution-details";
import FAQ from "../screens/cms/faq";
import Career from "../screens/cms/career";
import Policies from "../screens/cms/policies";
import Sitemap from "../screens/cms/sitemap";

const router = createBrowserRouter([
    {
        path: "/",
        element: <MainContainer />,
        children: [
            {
                path: "/",
                element: <HomeScreen />,
            },
            {
                path: "/blog",
                element: <BlogScreen />,
            },
            {
                path: "/about",
                element: <AboutScreen />,
            },
            {
                path: "/services",
                element: <ServiceScreen />,
            },
            {
                path: "/solutions",
                element: <SouluionScreen />,
            },
            {
                path: "/works",
                element: <WorkScreen />,
            },
            {
                path: "/hire-team",
                element: <HireTeamScreen />,
            },
            {
                path: "/contact",
                element: <Contact />
            },
            {
                path: "/blog-details/:id",
                element: <BlogDetails />
            },
            {
                path: "/portfolio/:type",
                element: <Portpolio />
            },
            {
                path: "/solutions/:type",
                element: <SolutionDetails />
            },
            {
                path: "/faq",
                element: <FAQ />
            },
            {
                path: "/career",
                element: <Career />
            },
            {
                path: "/policies",
                element: <Policies />
            },
            {
                path: "/sitemap",
                element: <Sitemap />
            }
        ],
    }
]);

export function Router() {
    return <RouterProvider router={router} />;
}