export default function Career() {
    return (
        <section className="inner-banner works">
            <div className="container-xl">
                <div className="row gx-3">
                    <div className="col-lg-8 offset-lg-2 text-center">
                        <h1 className="mb-5">Career <span className="text-green">Opportunities</span></h1>
                        <p className="lead-lg mb-2">We'll be back very soon.</p>
                        <p className="lead-lg m-0">Stay tuned for the opening right for you...</p>
                    </div>
                </div>
            </div>
        </section>
    )
}