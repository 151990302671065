import { Link } from "react-router-dom";

export default function CraftoLeather() {
    return (
        <section className="page-portfolio pt-5">
            <div className="container-xl portfolio-details mt-5">
                <div className="row pt-1 pt-sm-5">
                    <div className="col-lg-10 offset-lg-1 text-center">
                        <h2 className="title mb-5">Crafto Leather</h2>
                    </div>
                </div>
            </div>
            <div className="container-xl portfolio-details mb-5">
                <img src="../../assets/images/portfolio/crafto-leather/hero.webp" alt="" className="img-fluid w-100" />
            </div>
            <div className="container-xl portfolio-details mb-5">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="about-project">
                            <div className="row">
                                <div className="col-lg-6 mb-5 mb-lg-0">
                                    <h3>About this project </h3>
                                    <p>For CraftoLeather, we developed a sophisticated e-commerce store on our Laravel-based platform, enhancing user experience and streamlining online leather goods sales. The platform features an intuitive interface, secure payment options, and seamless navigation, showcasing their premium leather products. Our solution ensures a robust, scalable, and engaging shopping experience, driving customer satisfaction and sales growth for CraftoLeather.</p>
                                </div>
                                <div className="col-lg-6">
                                    <div className="about-project-availability">
                                        <div className="row">
                                            <div className="col-sm-6 mb-4">
                                                <h6>Services</h6>
                                                <ul>
                                                    <li>UI/UX Designing</li>
                                                    <li>Project Management</li>
                                                    <li>Application development</li>
                                                    <li>Mobile app development</li>
                                                    <li>Quality Assurance</li>
                                                </ul>
                                            </div>
                                            <div className="col-sm-6 mb-4">
                                                <h6>Technology </h6>
                                                <ul>
                                                    <li>Figma</li>
                                                    <li>CodeIgniter PHP</li>
                                                    <li>Android</li>
                                                    <li>iOS</li>
                                                    <li>MySQL</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="availability">
                                            <a href="https://craftoleather.com/" target="_blank"><img src="../../assets/images/link-web.webp" alt="Website" /></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-xl portfolio-details mb-5">
                <div className="row">
                    <div className="col-lg-10 offset-lg-1">
                        <h3>Meet CraftoLeather</h3>
                        <p>CraftoLeather has launched an innovative e-commerce platform built on our advanced Laravel-based system to bring its high-quality leather goods directly to customers. Founded by leather artisans with a passion for craftsmanship and design, CraftoLeather offers a curated selection of premium leather products that blend traditional techniques with modern style.</p>
                        <p>The online store is designed to provide a seamless shopping experience, featuring intuitive navigation, secure payment options, and detailed product information. The platform is optimized for both desktop and mobile devices, allowing users to browse, purchase, and explore CraftoLeather’s exquisite offerings with ease. Committed to quality and customer satisfaction, CraftoLeather continues to set the standard in the online leather goods market.</p>
                    </div>
                </div>
            </div>
            <div className="container-xl portfolio-details mb-5">
                <img src="../../assets/images/portfolio/crafto-leather/image1.webp" alt="" className="img-fluid w-100" />
            </div>
            <div className="container-xl portfolio-details mb-5">
                <div className="row">
                    <div className="col-lg-10 offset-lg-1">
                        <h3>CraftoLeather’s Growth Story</h3>
                        <p>CraftoLeather started as a boutique leather workshop with a vision to offer premium leather products to a global audience. Through dedication to quality and a keen understanding of their customers' needs, they quickly gained a loyal following. Launching their innovative Laravel-based e-commerce store expanded their reach, providing a seamless and engaging online shopping experience. Strategic marketing and customer engagement initiatives further enhanced their brand visibility and sales. Today, CraftoLeather stands as a leading online retailer of luxury leather goods, continuously growing and setting new standards in the industry, with a strong and dedicated customer base.</p>
                    </div>
                </div>
            </div>
            <div className="container-xl portfolio-details mb-5">
                <img src="../../assets/images/portfolio/crafto-leather/image2.webp" alt="" className="img-fluid w-100" />
            </div>
            <div className="container-xl portfolio-details mb-5">
                <div className="row">
                    <div className="col-lg-10 offset-lg-1">
                        <h3>What is unique about CraftoLeather</h3>
                        <p>CraftoLeather stands out for its unique combination of premium leather craftsmanship and innovative e-commerce solutions, offering a distinctive shopping experience that appeals to leather enthusiasts. Each product is crafted with meticulous attention to detail, ensuring high-quality leather goods that exude sophistication and durability. What truly sets CraftoLeather apart is their commitment to excellence in both product quality and online shopping experience. Their Laravel-based e-commerce site provides a seamless, user-friendly interface, featuring secure payment options, detailed product descriptions, and an optimized mobile experience. Additionally, CraftoLeather maintains a strong connection with its audience through strategic marketing and customer engagement, fostering a loyal customer base. By continuously evolving their product offerings and incorporating customer feedback, CraftoLeather ensures they stay ahead of industry trends while meeting the diverse needs of their clientele. This dedication to quality, innovation, and customer satisfaction makes CraftoLeather a standout in the leather goods market.</p>
                    </div>
                </div>
            </div>
            <div className="container-xl portfolio-details mb-5">
                <img src="../../assets/images/portfolio/crafto-leather/image3.webp" alt="" className="img-fluid w-100" />
            </div>
            <div className="container-xl portfolio-details mb-5">
                <div className="row">
                    <div className="col-lg-10 offset-lg-1">
                        <h3>Faced Challenges</h3>
                        <p>The existing platform faced issues with scalability and user experience, impacting the client’s ability to handle high traffic and manage inventory effectively.</p>
                        <p>Addressing these challenges required implementing robust backend systems, optimizing user interface design, and enhancing product management capabilities. Successfully navigating these tasks demanded a combination of technology integration, performance tuning, and strategic planning. The ability to efficiently manage inventory, provide a smooth shopping experience, and handle increasing traffic played a crucial role in achieving business success.</p>
                    </div>
                </div>
            </div>
            <div className="container-xl portfolio-details mb-5">
                <img src="../../assets/images/portfolio/crafto-leather/image4.webp" alt="" className="img-fluid w-100" />
            </div>
            <div className="container-xl portfolio-details mb-5">
                <div className="row">
                    <div className="col-lg-10 offset-lg-1">
                        <h3>Project Approach</h3>
                        <p>The project began with discovery, analyzing client needs. Planning set milestones, timelines, and communication. Development optimized performance. Testing ensured quality. Successful implementation replaced old versions.</p>
                        <p>The overall approach to the project involved a systematic and comprehensive process. Starting with the discovery phase, the team thoroughly analyzed the client’s requirements, identified pain points, and determined the scope of the project. During the planning stage, they defined the project milestones, created a detailed timeline, and established communication channels for seamless collaboration. In the development phase, the team focused on front-end and back-end development, ensuring optimal performance and user experience. Extensive testing and quality assurance measures were implemented to identify and rectify any issues. The project was then implemented, with the new website seamlessly replacing the previous version.</p>
                        <p className="mb-2">Key Benefits</p>
                        <ul>
                            <li>- Enhanced scalability to manage high traffic and inventory effectively.</li>
                            <li>- Improved user experience through a visually appealing design and intuitive navigation.</li>
                            <li>- Robust and secure e-commerce platform optimized for performance.</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="container-xl portfolio-details mb-5">
                <img src="../../assets/images/portfolio/crafto-leather/image5.webp" alt="" className="img-fluid w-100" />
            </div>
            <div className="container-xl portfolio-details mb-5">
                <div className="row">
                    <div className="col-lg-10 offset-lg-1">
                        <h3>Results</h3>
                        <p>Following the implementation of the project, CraftoLeather experienced numerous positive outcomes. The new Laravel-based e-commerce site resolved scalability and performance issues, resulting in a reliable platform for handling high traffic and managing inventory. The enhanced features allowed for better user experience, streamlined product management, and increased customer engagement. The optimized website catered to a growing customer base, boosting CraftoLeather’s online presence and sales. Overall, the project brought significant improvements to the client’s e-commerce operations, ensuring a smooth and engaging experience for their users.</p>
                    </div>
                </div>
            </div>
            <div className="container-xl portfolio-details mb-5">
                <div className="row">
                    <div className="col-lg-8 offset-lg-2">
                        <div className="portfolio-quote">
                            <div className="content">
                                Webmaa's adaptability to our evolving needs and the steady stream of insightful feedback and ideas prove they were not just a partner – they were an indispensable asset in helping us reach our vision to democratize the fintech industry.
                            </div>
                            <div className="author">
                                <div className="auth-image">
                                    <img src="../../assets/images/portfolio/crafto-leather/author.png" alt="author" />
                                </div>
                                <div className="auth-desig">
                                    <h6>User Name</h6>
                                    <p>Designation, Company Name</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-xl portfolio-nav mb-5">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="row">
                            <div className="col-6">
                                <Link to="/portfolio/melgio-vanni" className="d-block pprev">
                                    <span className="fs-14 fw-500 text-black">Previous Project</span> <br />
                                    <span className="fs-13 text-gray d-none d-sm-block">Fast and Simple Website Builder<br /></span>
                                    <span className="fs-15 fw-600 text-secondary">Melgio Vanni</span>
                                </Link>
                            </div>
                            <div className="col-6">
                                <Link to="/portfolio/bengal-beverages" className="d-block pnext text-end">
                                    <span className="fs-14 fw-500 text-black">Next Project</span> <br />
                                    <span className="fs-13 text-gray d-none d-sm-block">Fast and Simple Website Builder<br /></span>
                                    <span className="fs-15 fw-600 text-secondary">Bengal Beverages</span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}