import { Link } from "react-router-dom";
import { Fragment } from "react/jsx-runtime";

export default function ReasonClothing() {
    return (
        <Fragment>
            <section className="page-portfolio pt-5">
                <div className="container-xl portfolio-details mt-5">
                    <div className="row pt-1 pt-sm-5">
                        <div className="col-lg-10 offset-lg-1 text-center">
                            <h2 className="title mb-5">Reason Clothing</h2>
                        </div>
                    </div>
                </div>
                <div className="container-xl portfolio-details mb-5">
                    <img src="../../assets/images/portfolio/reason-clothing/hero.webp" alt="" className="img-fluid w-100" />
                </div>
                <div className="container-xl portfolio-details mb-5">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="about-project">
                                <div className="row">
                                    <div className="col-lg-6 mb-5 mb-lg-0">
                                        <h3>About this project </h3>
                                        <p>For Reason Clothing, we developed a comprehensive e-commerce store and mobile app, enhancing user experience and streamlining online shopping. The platform features an intuitive interface, secure payment options, and seamless navigation, showcasing their stylish apparel collection. The mobile app, compatible with both iOS and Android, provides personalized notifications, easy browsing, and a smooth checkout process. Our solution ensures a robust, scalable, and engaging shopping experience, driving customer satisfaction and sales growth for Reason Clothing.</p>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="about-project-availability">
                                            <div className="row">
                                                <div className="col-sm-6 mb-4">
                                                    <h6>Services</h6>
                                                    <ul>
                                                        <li>UI/UX Designing</li>
                                                        <li>Project Management</li>
                                                        <li>Application development</li>
                                                        <li>Mobile app development</li>
                                                        <li>Quality Assurance</li>
                                                    </ul>
                                                </div>
                                                <div className="col-sm-6 mb-4">
                                                    <h6>Technology </h6>
                                                    <ul>
                                                        <li>Figma</li>
                                                        <li>CodeIgniter PHP</li>
                                                        <li>Android</li>
                                                        <li>iOS</li>
                                                        <li>MySQL</li>
                                                    </ul>
                                                </div>
                                            </div>

                                            <div className="availability">
                                                <a href="https://apps.apple.com/us/app/reason-clothing/id1539090815" target="_blank"><img src="../../assets/images/link-appstore.webp" alt="App Store" /></a>
                                                <a href="https://play.google.com/store/apps/details?id=co.tapcart.app.id_7U6pfs4HPZ" target="_blank"><img src="../../assets/images/link-playstore.webp" alt="Google Play" /></a>
                                                <a href="https://reasonclothing.com/" target="_blank"><img src="../../assets/images/link-web.webp" alt="Website" /></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-xl portfolio-details mb-5">
                    <div className="row">
                        <div className="col-lg-10 offset-lg-1">
                            <h3>Meet Reason Clothing</h3>
                            <p>Reason Clothing has developed an innovative e-commerce platform to bring its unique fashion collections directly to consumers. Founded by industry veterans with a passion for style and quality, Reason Clothing offers a curated selection of apparel that blends contemporary trends with timeless designs. </p>
                            <p>The online store is designed to provide a seamless shopping experience, featuring intuitive navigation, secure payment options, and personalized recommendations. The Reason Clothing mobile app enhances this experience, offering easy browsing, exclusive notifications, and a streamlined checkout process. Committed to quality and customer satisfaction, Reason Clothing continues to set the standard in online fashion retail.</p>
                        </div>
                    </div>
                </div>
                <div className="container-xl portfolio-details mb-5">
                    <img src="../../assets/images/portfolio/reason-clothing/image1.webp" alt="" className="img-fluid w-100" />
                </div>
                <div className="container-xl portfolio-details mb-5">
                    <div className="row">
                        <div className="col-lg-10 offset-lg-1">
                            <h3>Reason Clothing’s Growth Story</h3>
                            <p>Reason Clothing started as a small fashion brand with a vision for combining contemporary trends and timeless designs. Through dedication to quality and a keen understanding of their audience, they quickly gained popularity. Launching their innovative e-commerce store and mobile app expanded their reach, offering seamless shopping experiences and personalized service. Strategic marketing and customer engagement initiatives further boosted brand loyalty and sales. Today, Reason Clothing stands as a leading online fashion retailer, continuously growing and setting new standards in the industry, with a passionate customer base and a strong presence in the fashion market.</p>
                        </div>
                    </div>
                </div>
                <div className="container-xl portfolio-details mb-5">
                    <img src="../../assets/images/portfolio/reason-clothing/image2.webp" alt="" className="img-fluid w-100" />
                </div>
                <div className="container-xl portfolio-details mb-5">
                    <div className="row">
                        <div className="col-lg-10 offset-lg-1">
                            <h3>What is unique about Reason Clothing</h3>
                            <p>Reason Clothing stands out for its unique blend of contemporary trends and timeless designs, offering a distinctive fashion experience that appeals to a wide range of styles. Each piece is crafted with meticulous attention to detail, ensuring high-quality apparel that not only looks good but feels great. What truly sets Reason Clothing apart is their commitment to innovation in the fashion retail space. Their e-commerce store and mobile app provide a seamless, user-friendly shopping experience, featuring secure payment options, personalized recommendations, and exclusive notifications. Additionally, Reason Clothing maintains a strong connection with its audience through strategic marketing and customer engagement initiatives, fostering a loyal customer base. By continuously evolving their product offerings and incorporating customer feedback, Reason Clothing ensures they stay ahead of fashion trends while meeting the diverse needs of their clientele. This dedication to quality, innovation, and customer satisfaction makes Reason Clothing a standout in the fashion industry.</p>

                        </div>
                    </div>
                </div>
                <div className="container-xl portfolio-details mb-5">
                    <img src="../../assets/images/portfolio/reason-clothing/image3.webp" alt="" className="img-fluid w-100" />
                </div>
                <div className="container-xl portfolio-details mb-5">
                    <div className="row">
                        <div className="col-lg-10 offset-lg-1">
                            <h3>Faced Challenges</h3>
                            <p>The existing backend system frequently experienced speed issues, causing headaches for the client.</p>
                            <p>Addressing these challenges required implementing robust data management systems, improving customer relationship management strategies, optimizing promotional campaigns, and enhancing order processing workflows. Successfully navigating these tasks demanded a combination of technology integration, staff training, and strategic planning. The ability to efficiently handle data, interact with customers, run promotions seamlessly, and manage orders played a pivotal role in achieving business success.</p>
                        </div>
                    </div>
                </div>
                <div className="container-xl portfolio-details mb-5">
                    <img src="../../assets/images/portfolio/reason-clothing/image4.webp" alt="" className="img-fluid w-100" />
                </div>
                <div className="container-xl portfolio-details mb-5">
                    <div className="row">
                        <div className="col-lg-10 offset-lg-1">
                            <h3>Project Approach</h3>
                            <p>The project began with discovery, analyzing client needs. Planning set milestones, timelines, and communication. Development optimized performance. Testing ensured quality. Successful implementation replaced old versions.</p>
                            <p>The overall approach to the project involved a systematic and comprehensive process. Starting with the discovery phase, the team thoroughly analyzed the client’s requirements, identified pain points, and determined the scope of the project. During the planning stage, they defined the project milestones, created a detailed timeline, and established communication channels for seamless collaboration. In the development phase, the team focused on front-end and back-end development, ensuring optimal performance and user experience. Extensive testing and quality assurance measures were implemented to identify and rectify any issues. The project was then implemented, with the new website and mobile app seamlessly replacing the previous versions.</p>
                            <p className="mb-2">Key Benefits</p>
                            <ul>
                                <li>Streamlined data management, customer interactions, promotions, and order processes. </li>
                                <li>Improved user experience through a visually appealing design and intuitive navigation.  </li>
                                <li>Enhanced website and mobile app performance.</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="container-xl portfolio-details mb-5">
                    <img src="../../assets/images/portfolio/reason-clothing/image5.webp" alt="" className="img-fluid w-100" />
                </div>
                <div className="container-xl portfolio-details mb-5">
                    <div className="row">
                        <div className="col-lg-10 offset-lg-1">
                            <h3>Results</h3>
                            <p>Following the implementation of the project, Reason Clothing experienced numerous positive outcomes. The new backend system resolved the speed issues that previously plagued the client, resulting in a seamless and reliable platform for their business operations. The enhanced features allowed for better management of data, customers, promotions, and orders, leading to improved efficiency and customer satisfaction. The flawless website and cross-platform mobile app catered to both iOS and Android users, expanding the client’s reach and increasing their customer base. Overall, the project brought significant improvements to the client’s operations, ensuring a smooth and engaging experience for their users.</p>

                        </div>
                    </div>
                </div>
                <div className="container-xl portfolio-details mb-5">
                    <div className="row">
                        <div className="col-lg-8 offset-lg-2">
                            <div className="portfolio-quote">
                                <div className="content">
                                    Webmaa's adaptability to our evolving needs and the steady stream of insightful feedback and ideas prove they were not just a partner – they were an indispensable asset in helping us reach our vision to democratize the fintech industry.
                                </div>
                                <div className="author">
                                    <div className="auth-image">
                                        <img src="../../assets/images/portfolio/reason-clothing/author.png" alt="author" />
                                    </div>
                                    <div className="auth-desig">
                                        <h6>User Name</h6>
                                        <p>Designation, Company Name</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-xl portfolio-nav mb-5">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="row">
                                <div className="col-6">
                                    <Link to="/portfolio/wiseasy" className="d-block pprev">
                                        <span className="fs-14 fw-500 text-black">Previous Project</span> <br />
                                        <span className="fs-13 text-gray d-none d-sm-block">Fast and Simple Website Builder<br /></span>
                                        <span className="fs-15 fw-600 text-secondary">Wiseasy</span>
                                    </Link>
                                </div>
                                <div className="col-6">
                                    <Link to="/portfolio/maap" className="d-block pnext text-end">
                                        <span className="fs-14 fw-500 text-black">Next Project</span> <br />
                                        <span className="fs-13 text-gray d-none d-sm-block">Fast and Simple Website Builder<br /></span>
                                        <span className="fs-15 fw-600 text-secondary">Maap</span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    )
}