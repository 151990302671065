// define a component for the home screen

import { Fragment } from 'react';
import { Helmet } from "react-helmet";
import HomeBanner from './home-banner';
import HomeWhoWeAre from './home-whoweare';
import HomeRecentWork from './home-recent-work';
import HomeIndustryWiseSolutions from './home-industrywise-solutions';
import HomeHiringModel from './home-hiring-model';
import HomeTestimonials from './home-testimonials';
import HomeQuery from './home-query';
import HomeNewsBlogs from './home-news-blogs';
import HomeContact from './home-contact';

const HomeScreen = () => {
    return (
        <Fragment>
            <Helmet>
                <title>Top Web, Mobile App and Custom Software Development Company</title>
                <meta charSet="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="description" content="We are a top web,mobile app and Software development company in India &amp; USA providing IT technology services &amp; solutions to startups, enterprises." />
                <link rel="canonical" href="https://www.webmaa.com/" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Top Web, Mobile App and Custom Software Development Company" />
                <meta property="og:description" content="We are a top web,mobile app and Software development company in India &amp; USA providing IT technology services &amp; solutions to startups, enterprises." />
                <meta property="og:url" content="https://www.webmaa.com/" />
                <meta property="og:site_name" content="Webmaa" />
            </Helmet>
            <HomeBanner />
            <HomeWhoWeAre />
            <HomeIndustryWiseSolutions />
            <HomeRecentWork />
            <HomeHiringModel />
            <HomeTestimonials />
            <HomeQuery />
            <HomeNewsBlogs />
            <HomeContact />
        </Fragment>
    );
}

export default HomeScreen;