import { useParams } from "react-router";
import ReasonClothing from "./portpolio-1";
import Maap from "./portpolio-2";
import MoijeyFineJewelryDiamonds from "./portpolio-3";
import DukesSeafood from "./portpolio-4";
import LiquorDotCom from "./portpolio-5";
import MelgioVanni from "./portpolio-6";
import CraftoLeather from "./portpolio-7";
import BengalBeverages from "./portpolio-8";
import Fectiv from "./portpolio-9";
import MohendraDutt from "./portpolio-10";
import AroundMe from "./portpolio-11";
import Wiseasy from "./portpolio-12";

export default function Portpolio() {
    const { type } = useParams();
    switch (type) {
        case 'reason-clothing':
            return <ReasonClothing />;
        case 'maap':
            return <Maap />;
        case 'moijey-fine-jewelry':
            return <MoijeyFineJewelryDiamonds />;
        case 'dukes-seafood':
            return <DukesSeafood />;
        case 'liquor':
            return <LiquorDotCom />;
        case 'melgio-vanni':
            return <MelgioVanni />;
        case 'crafto-leather':
            return <CraftoLeather />;
        case 'bengal-beverages':
            return <BengalBeverages />;
        case 'fectiv':
            return <Fectiv />;
        case 'mohendra-dutt':
            return <MohendraDutt />;
        case 'around-me':
            return <AroundMe />;
        case 'wiseasy':
            return <Wiseasy />;
        default:
            return <p>No portpolio type found</p>;
    }
}