const HomeWhoWeAre = () => {
    return (
        <section className="py-5">
            <div className="container-xl my-3 my-lg-4">
                <div className="row gx-3 mb-5">
                    <div className="col-lg-6 mb-5 mb-lg-0 align-self-center">
                        <h6 className="title">Who we are</h6>
                        <h3 className="title">Your partner for innovative web, mobile, and cloud solutions. We boost engineering and design, accelerate product and MVP development, automate processes, and reduce costs.</h3>
                        <p className="lead">The top-notch and preeminent Web and Mobile App Development Company of India, Webmaa is first-rate choice of the clients worldwide. With sheer customer satisfaction in mind, we are profoundly dedicated to developing highly intriguing apps that strictly meet the business requirements and catering a wide spectrum of projects.</p>
                    </div>
                    <div className="col-lg-6 align-self-center">
                        <img alt="" className="img-fluid w-100" src="assets/images/who.webp" title="" />
                    </div>
                </div>
                <div className="row gx-3 pt-4">
                    <div className="col-lg-10 offset-lg-1 text-center">
                        <h6 className="title">services we offer</h6>
                        <h3 className="title fw-500 mb-5">Offshore software development company since 2008, providing a full range of web and mobile app development and IT services and solutions globally.</h3>
                    </div>
                    <div className="col-lg-12">
                        <div className="owl-carousel owl-theme owl_services">
                            <div className="item">
                                <div className="service-box">
                                    <div>
                                        <h6>Ideation &amp; <span className="text-red">Evaluation</span></h6>
                                        <p>No matter if you need to craft the idea for a completely new product or evaluate the quality of an already existing one - we're here to help you.</p>
                                    </div>
                                    <ul className="list-services">
                                        <li>Design Sprint</li>
                                        <li>UX review</li>
                                        <li>Rapid prototyping</li>
                                        <li>Product strategy</li>
                                        <li>Scoping session</li>
                                        <li>Research & development</li>
                                    </ul>
                                </div>
                            </div>

                            <div className="item">
                                <div className="service-box">
                                    <div>
                                        <h6>Product <span className="text-red">Design services</span></h6>
                                        <p>Product design strives to deliver beautiful and usable products that solve user problems and help you achieve your business goals</p>
                                    </div>
                                    <ul className="list-services">
                                        <li>Product design</li>
                                        <li>UI design</li>
                                        <li>UX design</li>
                                        <li>UX review</li>
                                        <li>User journeys</li>
                                        <li>Wireframe</li>
                                        <li>Illustrations</li>
                                        <li>Design system</li>
                                    </ul>
                                </div>
                            </div>

                            <div className="item">
                                <div className="service-box">
                                    <div>
                                        <h6>Web App <span className="text-purple">Development</span></h6>
                                        <p>Leverage reliable methodologies and best practices in development.</p>
                                    </div>
                                    <ul className="list-services">
                                        <li>WordPress</li>
                                        <li>Laravel</li>
                                        <li>CodeIgniter</li>
                                        <li>Node.js</li>
                                        <li>Python</li>
                                        <li>React Js</li>
                                        <li>Vue Js</li>
                                        <li>Flutter</li>
                                        <li>MySQL</li>
                                        <li>SQLite</li>
                                        <li>GraphQL</li>
                                    </ul>
                                </div>
                            </div>


                            <div className="item">
                                <div className="service-box">
                                    <div>
                                        <h6>Mobile App <span className="text-purple">Development</span></h6>
                                        <p>Get professional support from the mobile team at every stage of development.</p>
                                    </div>
                                    <ul className="list-services">
                                        <li>PWA</li>
                                        <li>React native</li>
                                        <li>Flutter</li>
                                        <li>Android native</li>
                                        <li>iOS native</li>
                                    </ul>
                                </div>
                            </div>

                            <div className="item">
                                <div className="service-box">
                                    <div>
                                        <h6>Application <span className="text-purple">Development</span></h6>
                                        <p>Leverage reliable methodologies and best practices in development.</p>
                                    </div>
                                    <ul className="list-services">
                                        <li>Commerce</li>
                                        <li>Logistics</li>
                                        <li>FinTech</li>
                                        <li>Digital Media</li>
                                        <li>EdTech</li>
                                        <li>Health care</li>
                                        <li>OTT platform</li>
                                        <li>Business listing</li>
                                        <li>API’s</li>
                                    </ul>
                                </div>
                            </div>

                            <div className="item">
                                <div className="service-box">
                                    <div>
                                        <h6>Resource as a <span className="text-green">Services</span></h6>
                                        <p>Leverage reliable methodologies and best practices in development.</p>
                                    </div>
                                    <ul className="list-services">
                                        <li>Product development</li>
                                        <li>Quality testing</li>
                                        <li>System management</li>
                                        <li>Maintenance</li>
                                        <li>Performance & scalability monitoring</li>
                                    </ul>
                                </div>
                            </div>

                            <div className="item">
                                <div className="service-box">
                                    <div>
                                        <h6>Digital <span className="text-green">Marketing</span></h6>
                                        <p>Leverage reliable methodologies and best practices in development.</p>
                                    </div>
                                    <ul className="list-services">
                                        <li>Brand positioning</li>
                                        <li>Market research</li>
                                        <li>SEO/SMO/SMM</li>
                                        <li>Content marketing</li>
                                        <li>Email marketing</li>
                                        <li>PPC campaign</li>
                                        <li>SMS marketing</li>
                                        <li>WhatsApp marketing</li>
                                        <li>Video marketing</li>
                                        <li>Lead funneling</li>
                                    </ul>
                                </div>
                            </div>


                            <div className="item">
                                <div className="service-box">
                                    <div>
                                        <h6>Support & Management <span className="text-green">Services</span></h6>
                                        <p>To develop a successful project is to to meet the ever changing market needs. We are here to support your growth</p>
                                    </div>
                                    <ul className="list-services">
                                        <li>Project management</li>
                                        <li>Quality assurance</li>
                                        <li>Product management</li>
                                        <li>Cloud technology</li>
                                        <li>Cybersecurity</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default HomeWhoWeAre;