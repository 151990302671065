import { Link } from "react-router-dom";
import { Fragment } from "react/jsx-runtime";

export default function DemoCartface() {
    return (
        <Fragment>
            <section className="inner-banner cartface">
                <div className="container-xl">
                    <div className="row gx-3">
                        <div className="col-lg-6 text-center text-lg-start  align-self-center mb-5 mb-lg-0">
                            <a className="d-inline-block mb-2" href="https://cartface.com/" target="_blank"><img src="../assets/images/cf-beta.webp" alt="" className="img-fluid" /></a>
                            <h1 className="mb-3">E-Commerce as a service for your <span className="text-blue">Furniture</span> Business</h1>
                            <p className="lead-lg mb-3 fw-500">Your vision. Your commerce OS. We just build it.</p>
                            <p className="lead-lg mb-3 mb-sm-5 fw-300">Our no-code design platform helps eCommerce stores boost sales by creating engaging, personalized site experiences with ease.</p>
                            <div className="banner-content-bottom">
                                <Link to="/contact" className="btn btn-blue btn-rounded">Book a Demo</Link>
                            </div>
                        </div>
                        <div className="col-lg-6 align-self-center">
                            <img src="../assets/images/cf-hero.webp" alt="" className="img-fluid d-block mx-auto" />
                        </div>
                    </div>
                </div>
            </section>
            <section className="py-5">
                <div className="container-xl my-0 my-lg-4">
                    <div className="row gx-3 mb-5">
                        <div className="col-lg-7 text-center text-lg-start">
                            <h3 className="mb-4 text-warning">Everything you need for growth</h3>
                            <p className="lead fw-500">One platform with all the ecommerce and point of sale features you need to start, run, and grow your business.</p>
                        </div>
                    </div>
                    <div className="row gx-3 justify-content-center">
                        <div className="col-md-4 col-sm-6 mb-20">
                            <div className="mybox cf-box1">
                                <h5 className="mb-4">Make omnichannel a mantra for your success</h5>
                                <p>Bring in new channels and connect them seamlessly into unforgettable customer journeys.</p>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6 mb-20">
                            <div className="mybox cf-box1">
                                <h5 className="mb-4">From local hero to global champion, with ease</h5>
                                <p>Manage multiple brands, languages and currencies with a platform that’s fully flexible and customizable.</p>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6 mb-20">
                            <div className="mybox cf-box1">
                                <h5 className="mb-4">Your commerce on cloud nine of performance</h5>
                                <p>Take your performance to the next level with a scalable solution that meets your growth and speed requirements.</p>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6 mb-20">
                            <div className="mybox cf-box1">
                                <h5 className="mb-4">Go the extra mile with extensibility</h5>
                                <p>Extend your commerce beyond out-of-the-box features and create truly custom experiences to increase conversions faster.</p>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6 mb-20">
                            <div className="mybox cf-box1">
                                <h5 className="mb-4">One platform to rule all things commerce</h5>
                                <p>Manage your commerce from one place, from multiple business models such as B2B, B2C and D2C to digital and physical touch points.</p>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6 mb-20">
                            <div className="mybox cf-box1">
                                <h5 className="mb-4">A dream team of integrations and partners</h5>
                                <p>Plan, implement and optimize your commerce with our pre-built connectors, implementation partners and accelerators.</p>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
            <section className="pb-5">
                <div className="container-xl">
                    <div className="row gx-3 mb-5">
                        <div className="col-lg-10 offset-lg-1 text-center">
                            <h3 className="mb-4">The leading API driven headless composable commerce platform for speed, simplicity and scale</h3>
                            <p className="lead">cartface empowers you to build and run outstanding shopping experiences with remarkable simplicity, limitless scale and lightning-fast speed. Begin your composable journey and experience your first results in a matter of weeks with a lower total cost of ownership.</p>
                            <Link to="/contact" className="btn btn-lg btn-blue rad-30">Book your demo now <i className="la la-arrow-right"></i></Link>
                        </div>
                    </div>
                    <div className="row gx-3 mb-4">
                        <div className="col-md-12">
                            <img src="https://s3-alpha-sig.figma.com/img/15bb/2897/4459c13fe6d34ba883c7e04cfe6d9633?Expires=1722211200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=GTbSPxXBalFmHyf5NbFNmbWhJ8qLXk208SrfcPlBVN0q2lYCwu1o7YKVSSvcjNGSPfu45vDkqGPchXx22L0jKpqXT-mzOAjGEjO4rIwMzvWRCPi0FVuVzo~meFba19K93WXVe0pBnt9QnDHYT0-sndrAmGVjyJKwUa6~ptiTilpGwtg5RWZbnNA8gGcRRnMmuoGjcVSCxW-JckJ2FafHth85KAFUtmt2xg71H06u~sS8JCU5cmFFXNYcPC11Cwd1ifdXy1U~PaDJKVcadpsJMLrMeQyaCGRjUbyaIuo6sJsS4TgWqXrqzy3ziQZuVFhg48TQb420R-UJ67psYn24mQ__" alt="" className="img-fluid w-100" />
                        </div>
                    </div>
                    <div className="row gx-3">
                        <div className="col-lg-10 offset-lg-1 text-center">
                            <h3 className="mb-3">All-in-one platform to scale your online store</h3>
                            <p className="lead">Scale your business easily in just 3 step</p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="pb-5">
                <div className="container-xl my-0 my-lg-4">
                    <div className="row gx-3">
                        <div className="col-lg-5  mb-4 mb-lg-0 align-self-center">
                            <h3 className="fw-400">Set up your online store</h3>
                            <p className="lead fw-300">Pick up the best theme for your business and start customizing your website. Get free training for website setup from our Ecommerce experts.</p>
                            <ul className="list-dot">
                                <li>Delivery and Logistics Support</li>
                                <li>Payment Gateway Integration</li>
                                <li>Free training for website setup</li>
                                <li>Customize your website</li>
                                <li>Premium Themes</li>
                            </ul>
                        </div>
                        <div className="col-lg-6 offset-lg-1 align-self-center">
                            <img src="../assets/images/cf-demo1.webp" alt="" className="img-fluid d-block mx-auto" />
                        </div>
                    </div>
                </div>
            </section>
            <section className="pb-5">
                <div className="container-xl my-0 my-lg-4">
                    <div className="row gx-3">
                        <div className="col-lg-5 offset-lg-1 order-1 order-lg-2  mb-4 mb-lg-0 align-self-center">
                            <h3 className="fw-400">Manage your business</h3>
                            <p className="lead fw-300">Learn the smartest way to manage and grow your business online. Manage your inventories and staff with just a click</p>
                            <ul className="list-dot">
                                <li>Data Insights and Analytics Tools</li>
                                <li>Inventory Management System</li>
                                <li>Staff Management System</li>
                                <li>Sell on marketplaces</li>
                            </ul>
                        </div>
                        <div className="col-lg-6 order-2 order-lg-1  align-self-center">
                            <img src="../assets/images/cf-demo2.webp" alt="" className="img-fluid d-block mx-auto" />
                        </div>
                    </div>
                </div>
            </section>
            <section className="pb-5">
                <div className="container-xl my-0 my-lg-4">
                    <div className="row gx-3">
                        <div className="col-lg-5  mb-4 mb-lg-0 align-self-center">
                            <h3 className="fw-400">Promote your business</h3>
                            <p className="lead fw-300">Drive traffic to the website and grow your audience by creating powerful marketing campaigns, collections, blogs, and emails</p>
                            <ul className="list-dot">
                                <li>Launch campaigns</li>
                                <li>Create blogs</li>
                                <li>Free SMS and Emails</li>
                                <li>Marketing collections</li>
                                <li>Discounts and coupons</li>
                                <li>Rating and reviews</li>
                                <li>Advanced SEO tools</li>
                            </ul>
                        </div>
                        <div className="col-lg-6 offset-lg-1 align-self-center">
                            <img src="../assets/images/cf-demo3.webp" alt="" className="img-fluid d-block mx-auto" />
                        </div>
                    </div>
                </div>
            </section>
            <section className="pb-5">
                <div className="container-xl">
                    <div className="row gx-3 mb-5">
                        <div className="col-lg-10 offset-lg-1 text-center">
                            <h3 className="mb-3">Solution for every commerce business model</h3>
                            <p className="lead">A modern and powerful cloud-based e-commerce platform built for unique e-commerce business needs</p>
                        </div>
                    </div>
                    <div className="row gx-3 justify-content-center">
                        <div className="col-md-4 col-sm-6 mb-20">
                            <div className="mybox cf-box2">
                                <h5 className="mb-50">Multi Currency store</h5>
                                <p>"Expand your customer base and increase conversions with Cartface's multi-currency store, enabling global sales with real-time currency conversions."</p>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6 mb-20">
                            <div className="mybox cf-box2">
                                <h5 className="mb-50">Multi lingual store</h5>
                                <p>Cartface Commerce's multi-lingual store allows you to reach a global audience, boosting engagement and sales with language customization.</p>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6 mb-20">
                            <div className="mybox cf-box2">
                                <h5 className="mb-50">Multi brand store</h5>
                                <p>Cartface Commerce's multi-brand store lets you manage and showcase various brands seamlessly, enhancing your reach and customer choices.</p>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6 mb-20">
                            <div className="mybox cf-box2">
                                <h5 className="mb-50">D2C Business</h5>
                                <p>Empowers D2C businesses with robust tools for direct customer engagement, streamlined operations, and enhanced brand loyalty with Cartface Commerce.</p>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6 mb-20">
                            <div className="mybox cf-box2">
                                <h5 className="mb-50">B2C Business</h5>
                                <p>Cartface enhances B2C businesses with advanced e-commerce solutions, driving customer engagement, sales growth, and efficient operations.</p>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6 mb-20">
                            <div className="mybox cf-box2">
                                <h5 className="mb-50">B2B Business</h5>
                                <p>Optimizes B2B businesses with comprehensive tools for streamlined operations, enhanced client relationships, and efficient bulk transactions in Cartface.</p>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6 mb-20">
                            <div className="mybox cf-box2">
                                <h5 className="mb-50">Mobile ready commerce</h5>
                                <p>Cartface offers mobile-ready commerce solutions, ensuring seamless shopping experiences on any device, driving engagement and sales on the go.</p>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6 mb-20">
                            <div className="mybox cf-box2">
                                <h5 className="mb-50">Omni channel commerce</h5>
                                <p>Cartface enables omnichannel commerce, integrating online and offline sales channels for a unified customer experience and increased sales opportunities.</p>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6 mb-20">
                            <div className="mybox cf-box2">
                                <h5 className="mb-50">Phygital commerce</h5>
                                <p>Cartface blends physical and digital commerce seamlessly, offering a unified shopping experience that enhances customer engagement and sales growth.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="pb-5">
                <div className="container-xl">
                    <div className="row gx-3 mb-5">
                        <div className="col-lg-10 offset-lg-1 text-center">
                            <h3 className="mb-3">Explore our awesome features for your online commerce</h3>
                            <p className="lead">We are more than an e-commerce website builder</p>
                        </div>
                    </div>
                    <div className="row gx-3 justify-content-center">
                        <div className="col-md-4 col-sm-6 mb-20">
                            <div className="mybox cf-box3">
                                <h5 className="mb-4">Progressive Web Apps</h5>
                                <p>Deliver native app-like experiences to customers on website.</p>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6 mb-20">
                            <div className="mybox cf-box3">
                                <h5 className="mb-4">Publish Mobile Apps</h5>
                                <p>Future ready omnichannel ecommerce to sell everywhere along native mobile apps</p>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6 mb-20">
                            <div className="mybox cf-box3">
                                <h5 className="mb-4">Responsive Web Store</h5>
                                <p>Deliver web store adapts seamlessly across devices for optimal usability.</p>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6 mb-20">
                            <div className="mybox cf-box3">
                                <h5 className="mb-4">Multi vendor commerce</h5>
                                <p>Simplifies vendor management for hassle-free operations.</p>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6 mb-20">
                            <div className="mybox cf-box3">
                                <h5 className="mb-4">Sell on Facebook</h5>
                                <p>Expand your reach by listing products on Facebook for more visitors.</p>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6 mb-20">
                            <div className="mybox cf-box3">
                                <h5 className="mb-4">Sell on Instagram</h5>
                                <p>Showcase your products on Instagram for wider audience engagement.</p>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6 mb-20">
                            <div className="mybox cf-box3">
                                <h5 className="mb-4">Sell on Google Shopping</h5>
                                <p>List your products on Google Shopping for increased visibility and sales.</p>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6 mb-20">
                            <div className="mybox cf-box3">
                                <h5 className="mb-4">Sell on Store in POS</h5>
                                <p>Sell directly from Cartface POS for streamlined in-store transactions.</p>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6 mb-20">
                            <div className="mybox cf-box3">
                                <h5 className="mb-4">Shipping Integration</h5>
                                <p>Process orders to shipment from one single platform and notify buyers seamlessly.</p>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6 mb-20">
                            <div className="mybox cf-box3">
                                <h5 className="mb-4">Payment Integration</h5>
                                <p>Start accepting payments through various PG available in your location.</p>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6 mb-20">
                            <div className="mybox cf-box3">
                                <h5 className="mb-4">Advanced SEO Enabled</h5>
                                <p>Offers advanced SEO capabilities for enhanced online visibility and rankings.</p>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6 mb-20">
                            <div className="mybox cf-box3">
                                <h5 className="mb-4">WhatsApp Integration</h5>
                                <p>Integrates WhatsApp for seamless customer communication and support services.</p>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6 mb-20">
                            <div className="mybox cf-box3">
                                <h5 className="mb-4">Inventory Management</h5>
                                <p>Simplifies inventory management for efficient business operations and growth.</p>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6 mb-20">
                            <div className="mybox cf-box3">
                                <h5 className="mb-4">Catalogue Management</h5>
                                <p>Manage multiple price catalogues for flexible pricing strategies.</p>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6 mb-20">
                            <div className="mybox cf-box3">
                                <h5 className="mb-4">Customer management</h5>
                                <p>streamlines customers for personalized service and enhanced satisfaction.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="py-5 bg-light-blue">
                <div className="container-xl">
                    <div className="row gx-3">
                        <div className="col-lg-10 offset-lg-1 text-center">
                            <h3 className="mb-4">We are more than just an online store builder</h3>
                        </div>
                    </div>
                    <div className="row gx-3 justify-content-center">
                        <div className="col-md-4 col-sm-6 mb-20">
                            <div className="mybox cf-box4">
                                <h5 className="mb-4">Performance</h5>
                                <ul className="list-dot-sm">
                                    <li>Advance SEO</li>
                                    <li>Uncomplicated, customizable templates to start</li>
                                    <li>Real-time inventory sync</li>
                                    <li>HTTP/2 support</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6 mb-20">
                            <div className="mybox cf-box4">
                                <h5 className="mb-4">Architecture and Security</h5>
                                <ul className="list-dot-sm">
                                    <li>Integrates with any third party apps</li>
                                    <li>Affordability of SaaS</li>
                                    <li>Headless Commerce</li>
                                    <li>Stable APIs with long term support</li>
                                    <li>Industry-standard strong password security</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6 mb-20">
                            <div className="mybox cf-box4">
                                <h5 className="mb-4">Support</h5>
                                <ul className="list-dot-sm">
                                    <li>24x7 support direct to engineers</li>
                                    <li>Dedicated accounts manager to take care of all your requirements</li>
                                    <li>Trouble-free order/customer management</li>
                                </ul>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
            <section className="py-5 cf-how-we-work">
                <div className="container-xl">
                    <div className="row gx-3">
                        <div className="col-lg-10 offset-lg-1 text-start text-md-center">
                            <h3 className="mb-4">How we work</h3>
                        </div>
                    </div>
                    <div className="row gx-3">
                        <div className="col-md-12">
                            <div className="how-we-work">
                                <div className="content">
                                    <div className="cont">
                                        <img src="../assets/images/icon-talk.webp" />
                                        <div>
                                            <h5 className="mb-3">Talk</h5>
                                            <p>to one of our experts or solution partners</p>
                                        </div>
                                    </div>
                                    <div className="cont">
                                        <img src="../assets/images/icon-confirm.webp" />
                                        <div><h5 className="mb-3">Confirm</h5>
                                            <p>the design and layout of your ecommerce store</p></div>
                                    </div>
                                    <div className="cont">
                                        <img src="../assets/images/icon-config.webp" />
                                        <div><h5 className="mb-3">Configure</h5>
                                            <p>your store with complete checklist and business goals.</p></div>
                                    </div>
                                    <div className="cont">
                                        <img src="../assets/images/icon-launch.webp" />
                                        <div><h5 className="mb-3">Launch</h5>
                                            <p>your business or Migrate from existing ones with ease. All stores are well tested.</p></div>
                                    </div>
                                    <div className="cont">
                                        <img src="../assets/images/icon-complete.webp" />
                                        <div><h5 className="mb-3">Complete</h5>
                                            <p>training of running and managing business from platform to your team.</p></div>
                                    </div>
                                    <div className="cont">
                                        <img src="../assets/images/icon-live.webp" />
                                        <div> <h5 className="mb-3">Post Live</h5>
                                            <p>dedicated support by commerceup and solution partners team.</p></div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
            <section className="pb-5">
                <div className="container-xl">
                    <div className="box-book-demo">
                        <div className="circle-top"></div>
                        <div className="circle-bottom"></div>
                        <div className="row gx-3">
                            <div className="col-lg-8 text-center text-lg-start mb-4 mb-lg-0 align-items-center">
                                <div id="content-book-demo">
                                    <h2 className="mb-3 fw-600">Direct Support. Fast Experience. Client Specific Solutions.</h2><p className="lead-lg fw-500 m-0">How Cartface differentiates itself from other SaaS based ecommerce service providers.</p>
                                </div>
                            </div>
                            <div className="col-lg-4 text-center align-content-end text-lg-end">
                                <Link to="/contact" className="btn btn-light btn-lg btn-rounded">Book a Demo</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    )
}