import { Helmet } from "react-helmet";
import { Fragment } from "react/jsx-runtime"

const HireTeamScreen = () => {
    return (
        <Fragment>
            <Helmet>
                <title>Hire Team</title>
                <meta charSet="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
            </Helmet>
            <section className="inner-banner works">
                <div className="container-xl">
                    <div className="row gx-3">
                        <div className="col-lg-10 offset-lg-1 text-center">
                            <h1 className="mb-3"><span className="text-green">Dedicated engagement</span> model<br />
                                for evolving scope</h1>
                            <p className="lead-lg">With multiple engagement models in place, we noted a factor considered by our 3000+ clients while choosing the RIGHT MODEL. That is, how much control and understanding they have with the present scope of work. Choosing the right model via expert consultation really makes a difference, believe it or not!!</p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="py-5">
                <div className="container-xl my-0 my-lg-4">
                    <div className="row gx-3">
                        <div className="col-lg-10 offset-lg-1 text-center">
                            <h3 className="mb-4">Why should you hire a dedicated team?</h3>
                            <h6 className="mb-4">Dedicated teams or HIRE Model approach in simple terms is about choosing and creating your own team to work on your software development project so that you remain focused on core business areas. You can hire them on a full time, part-time or hourly basis, from a single developer to a team of developers, that shall be working independently or in conjunction with another team(s).</h6>
                            <h6 className="fw-300 m-0">With effective collaboration, a dedicated software development team can have clear insights about the project objective. The business vision and goals about what your END users are expected to get are explained in advance along with a roadmap on project management – from the kickoff to delivery. And from our 20+ years of experience, we can guarantee that this approach increases efficiency, quality and timely delivery of the project by a great deal.</h6>
                        </div>
                    </div>
                </div>
            </section>


            <section className="pb-5">
                <div className="container-xl my-0 my-lg-4">
                    <div className="row gx-3 mb-5">
                        <div className="col-lg-10 offset-lg-1 text-center">
                            <h3 className="mb-4 fw-600">Numbers Speak for Themselves</h3>
                            <h6 className="fw-300 m-0">IBM Study found that fuzzy business objectives, out-of-sync stakeholders, and excessive rework mean that 75% of project participants lack confidence that their projects will succeed. To add more, the report says:</h6>
                        </div>
                    </div>
                    <div className="row gx-3">
                        <div className="col-lg-5 mb-4 mb-lg-0 align-self-center">
                            <ol className="team">
                                <li>Only 2.5% companies complete 100% of their projects</li>
                                <li>57% projects fail due to “breakdown in communication”</li>
                                <li>Only 40% projects meet milestones, budget and quality goals</li>
                            </ol>
                        </div>
                        <div className="col-lg-6 offset-lg-1">
                            <img src="../assets/images/img-team.webp" alt="team" className="img-fluid w-100" />
                        </div>
                    </div>
                </div>
            </section>

            <section className="pb-5">
                <div className="container-xl my-0 my-lg-4">
                    <div className="row gx-3 mb-5">
                        <div className="col-lg-10 offset-lg-1 text-center">
                            <h2 className="mb-4 fw-600">What we provide? Our Hiring Models</h2>
                            <h6 className="fw-300 m-0">To meet various project needs for any size of business, IndiaNIC offers full-time, part time and hourly <br />hiring for dedicated development projects. Have a look at the engagement models that results <br />in meeting various business demands.</h6>
                        </div>
                        <div className="row gx-3 justify-content-center">
                            <div className="col-sm-6 col-lg-3 mb-4">
                                <div className="mybox hire-box">
                                    <h6 className="fw-600">Full Time</h6>
                                    <p>8 hours/day <b>|</b> 5days/week</p>
                                </div>
                            </div>
                            <div className="col-sm-6 col-lg-3 mb-4">
                                <div className="mybox hire-box">
                                    <h6 className="fw-600">Part Time</h6>
                                    <p>4 hours/day <b>|</b> 5days/week</p>
                                </div>
                            </div>
                            <div className="col-sm-6 col-lg-3 mb-4">
                                <div className="mybox hire-box">
                                    <h6 className="fw-600">Hourly Basis</h6>
                                    <p>Starts with 40 hours <b>|</b> Pay as you go</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="pb-5">
                <div className="container-xl my-0 my-lg-4">
                    <div className="row gx-3">
                        <div className="col-lg-10 offset-lg-1 text-center">
                            <h2 className="mb-4 fw-600">Onboard Process</h2>
                        </div>
                    </div>
                    <div className="row-servicebox row-onboard">
                        <div className="col-servicebox onboard">
                            <div className="item-servicebox">
                                <div className="no">01</div>
                                <h6>Send us a Request</h6>
                                <p>Contact us and we will get back to you instantly or within 24 hours to know about your project needs, target areas, users, tech stack and other possible nuances to come up with precise suggestions.</p>
                            </div>
                        </div>

                        <div className="col-servicebox onboard">
                            <div className="item-servicebox">
                                <div className="no">02</div>
                                <h6>Custom Engagement Plan</h6>
                                <p>Based on the information collected, we design the tech stack along with a hiring plan that includes details about required technical expertise and experience of your development team.</p>
                            </div>
                        </div>

                        <div className="col-servicebox onboard">
                            <div className="item-servicebox">
                                <div className="no">03</div>
                                <h6>Planned Screening Tests</h6>
                                <p>We look into our vast pool of talented developers to handpick the best ones for screening tests. This is to measure communication skills, problem solving ability and relevant skill set to your business idea.</p>
                            </div>
                        </div>

                        <div className="col-servicebox onboard">
                            <div className="item-servicebox">
                                <div className="no">04</div>
                                <h6>Interviews/ Talent Sourcing</h6>
                                <p>We forward the resumes to your notice to schedule interviews and select the best candidates. Through talent sourcing, we forward best resources via stringent coding tests after measuring their skills.</p>
                            </div>
                        </div>

                        <div className="col-servicebox onboard">
                            <div className="item-servicebox">
                                <div className="no">05</div>
                                <h6>Selection & Onboarding</h6>
                                <p>We sign an NDA to secure your project IP and confidential infrastructure. Once you choose the team, the kick-off immediately takes place and the project starts usually within 24-72 hours.</p>
                            </div>
                        </div>

                        <div className="col-servicebox onboard">
                            <div className="item-servicebox">
                                <div className="no">06</div>
                                <h6>Quality-driven Reporting</h6>
                                <p>We share daily updates via tools like Basecamp, JIRA, etc. Weekly review meetings take place to track progress & tackle bottlenecks. Monthly sessions help to introspect the work done, plan and optimize the delivery.</p>
                            </div>
                        </div>

                        <div className="col-servicebox onboard">
                            <div className="item-servicebox">
                                <div className="no">07</div>
                                <h6>Escalation Process</h6>
                                <p>Our escalation process is static. Project Manager is the first point of contact and if this does not work, clients can approach the Delivery Head, or may reach out to the Director of Operations for super serious matters.</p>
                            </div>
                        </div>

                        <div className="col-servicebox onboard">
                            <div className="item-servicebox">
                                Things you will always love to have in your journey
                            </div>
                        </div>

                    </div>
                </div>
            </section>


            <section className="p-0">
                <div className="container-xl my-0 my-lg-4">
                    <div className="row gx-3">
                        <div className="col-lg-10 offset-lg-1 text-center">
                            <h2 className="fw-600 mb-5">Advantages! to Hire Dedicated Team from us</h2>
                        </div>
                    </div>
                    <div className="row gx-3">
                        <div className="col-sm-6 col-lg-4 mb-5">
                            <div className="dedicated-box">
                                <img src="../assets/images/dedicated1.webp" alt="" className="mb-3" />
                                <h6 className="fw-600">Direct Communication</h6>
                                <p>You will get complete access to talk with the chosen developers at all times through Skype, email and phone with scheduled audio/video conferencing to overcome the communication barrier.</p>
                            </div>
                        </div>
                        <div className="col-sm-6 col-lg-4 mb-5">
                            <div className="dedicated-box">
                                <img src="../assets/images/dedicated2.webp" alt="" className="mb-3" />
                                <h6 className="fw-600">Your Team, Your Selection</h6>
                                <p>Choose your own team of developers through vetted process of screening and interviews. We will present you the resumes of the most appropriate candidates to select from.</p>
                            </div>
                        </div>
                        <div className="col-sm-6 col-lg-4 mb-5">
                            <div className="dedicated-box">
                                <img src="../assets/images/dedicated3.webp" alt="" className="mb-3" />
                                <h6 className="fw-600">ZERO Upfront Investment</h6>
                                <p>We will help you onboard your own dev team with ZERO cost upfront considering infrastructure, hiring and staffing. You will only need to pay for the engagement period with us.</p>
                            </div>
                        </div>
                        <div className="col-sm-6 col-lg-4 mb-5">
                            <div className="dedicated-box">
                                <img src="../assets/images/dedicated4.webp" alt="" className="mb-3" />
                                <h6 className="fw-600">100% Project Control</h6>
                                <p>You will get complete access to talk with the chosen developers at all times through Skype, email and phone with scheduled audio/video conferencing to overcome the communication barrier.</p>
                            </div>
                        </div>
                        <div className="col-sm-6 col-lg-4 mb-5">
                            <div className="dedicated-box">
                                <img src="../assets/images/dedicated5.webp" alt="" className="mb-3" />
                                <h6 className="fw-600">Plug 'N Play</h6>
                                <p>Choose your own team of developers through vetted process of screening and interviews. We will present you the resumes of the most appropriate candidates to select from.</p>
                            </div>
                        </div>
                        <div className="col-sm-6 col-lg-4 mb-5">
                            <div className="dedicated-box">
                                <img src="../assets/images/dedicated6.webp" alt="" className="mb-3" />
                                <h6 className="fw-600">Transparent Ecosystem</h6>
                                <p>We will help you onboard your own dev team with ZERO cost upfront considering infrastructure, hiring and staffing. You will only need to pay for the engagement period with us.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="pb-5">
                <div className="container-xl mb-0 mb-lg-4">
                    <div className="row gx-3">
                        <div className="col-lg-10 offset-lg-1 text-center">
                            <h2 className="fw-600 mb-5">Frequently asked questions</h2>
                        </div>
                    </div>
                    <div className="row gx-3 mb-4">
                        <div className="col-lg-10 offset-lg-1">
                            <div className="accordion" id="accordionFAQ">
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#acco1" aria-expanded="true" aria-controls="acco1">
                                            Can I select my preferred developer? If yes, how do I do so?
                                        </button>
                                    </h2>
                                    <div id="acco1" className="accordion-collapse collapse show" data-bs-parent="#accordionFAQ">
                                        <div className="accordion-body">
                                            <p>You may choose your own software development team if you’re going for an Evolving project scope model. You have to share with us the profile of the ideal candidate and we’ll schedule interviews with you and our team members.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#acco2" aria-expanded="false" aria-controls="acco2">
                                            Is there a minimum limit for hiring developers under the Progressive Scope model?
                                        </button>
                                    </h2>
                                    <div id="acco2" className="accordion-collapse collapse" data-bs-parent="#accordionFAQ">
                                        <div className="accordion-body">
                                            <p>Well, there’s no limit as such. You can hire as little or as much as you think your project will require. However, we will certainly help you in composing the best team that helps you take your idea to the market with unmatched quality and customer service.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#acco3" aria-expanded="false" aria-controls="acco3">
                                            Is there any minimum period of hiring programmers for my project?
                                        </button>
                                    </h2>
                                    <div id="acco3" className="accordion-collapse collapse" data-bs-parent="#accordionFAQ">
                                        <div className="accordion-body">
                                            <p>This is applicable to the Evolving Scope model. We require that you hire a team for at least 3 months minimum – so that they get to know your objectives, align closely with your team (if you have) and ensure all critical aspects of the projects are taken care of. During this period – they’ll not work on any of the projects except for yours. If you see you need shorter engagement, we’d recommend you go for a fixed price model.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#acco4" aria-expanded="false" aria-controls="acco4">
                                            Can I alter the scope during development?
                                        </button>
                                    </h2>
                                    <div id="acco4" className="accordion-collapse collapse" data-bs-parent="#accordionFAQ">
                                        <div className="accordion-body">
                                            <p>Well, we always prefer that project scope is outlined and concluded upfront in case of Fixed Scope model (as the name suggests). Obviously, it is purely due to the planning and expectations set in terms of timeline and cost. While in the case of Progressive Scope model, you’re free to make alterations because you’ll be directly driving the team here – giving you enough room to set new goals, scope, timelines and everything else. Although – even in the later case, we recommend you plan small, execute it and then set up new goals.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#acco5" aria-expanded="false" aria-controls="acco5">
                                            I am not a techie. Will I still be able to work?
                                        </button>
                                    </h2>
                                    <div id="acco5" className="accordion-collapse collapse" data-bs-parent="#accordionFAQ">
                                        <div className="accordion-body">
                                            <p>Certainly. While we absolutely love our clients to be technical, we also carry years of experience with us to fill in the gap for technology on your behalf.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#acco6" aria-expanded="false" aria-controls="acco6">
                                            My documentation and project scope are ready, and my requirements are clearly defined. What next?
                                        </button>
                                    </h2>
                                    <div id="acco6" className="accordion-collapse collapse" data-bs-parent="#accordionFAQ">
                                        <div className="accordion-body">
                                            <p>It couldn’t have been better. If you know what you’d expect from the development team and know what to execute, it will require much less time to get started. We just need to understand everything you’ve got for us on the table and we can figure out the right engagement model for the project. We can provide you the estimates and the Fixed Scope model would just be right for you.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#acco7" aria-expanded="false" aria-controls="acco7">
                                            May I interview the developers I hire for my project?
                                        </button>
                                    </h2>
                                    <div id="acco7" className="accordion-collapse collapse" data-bs-parent="#accordionFAQ">
                                        <div className="accordion-body">
                                            <p>Yes, you can! In the model where you build your own web or mobile app development team, it is possible to interview and evaluate the skills of programmers at Webmaa. Nevertheless, you are provided with each developer’s resume that contains information about their expertise and experience in the domain.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#acco8" aria-expanded="false" aria-controls="acco8">
                                            What technologies does Webmaa work with?
                                        </button>
                                    </h2>
                                    <div id="acco8" className="accordion-collapse collapse" data-bs-parent="#accordionFAQ">
                                        <div className="accordion-body">
                                            <p>Oh we are absolutely in love with modern technologies. We carry tremendous enthusiasm to learn, adapt and implement new technologies. There is an array of technologies we have excelled at when it comes to Web including PHP and .NET – of course with a bunch of open source and SaaS products. For Mobile – we work with Native SDKs for iOS and Android. We also work at times with cross-platform tools such as Titanium, PhoneGap and Configure.IT. For creating stunning UI and Graphics, we use a combination of software such as Adobe Photoshop, Sketch, Affinity, and Corel Suites.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#acco9" aria-expanded="false" aria-controls="acco9">
                                            I have a technical team at my end. Can you work in conjunction with them?
                                        </button>
                                    </h2>
                                    <div id="acco9" className="accordion-collapse collapse" data-bs-parent="#accordionFAQ">
                                        <div className="accordion-body">
                                            <p>Of course. We’d love to collaborate with fellow team members at your end who can make a great team along with our team. We recommend hiring a team of people from us – who can fill in the gap of your team to establish a perfect balance for your project.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row gx-3 pt-2">
                        <div className="col-lg-12 text-center">
                            Still have query?  Visit our <a href="../faq/"><u>FAQ’s page</u></a>
                        </div>
                    </div>
                </div>
            </section>


            <section className="pb-5">
                <div className="container-xl">
                    <div className="start_project p-4 p-sm-5">
                        <div className="row gx-3">
                            <div className="col-lg-8 text-center text-lg-start mb-4 mb-lg-0 align-items-center">
                                <h2 className="mb-3 mb-lg-1 text-primary fw-500">Ready to start a project ?</h2>
                                <p className="lead fw-300 m-0">To start, use form to tell us about you and the project</p>
                            </div>
                            <div className="col-lg-4 text-center align-self-center text-lg-end">
                                <a href="../contact/" className="btn btn-primary">Let’s connect</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    );
}

export default HireTeamScreen;