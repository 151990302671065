const HomeTestimonials = () => {
    return (
        <section className="pb-5">
            <div className="container-xl my-3 my-lg-4">
                <div className="row gx-3">
                    <div className="col-lg-8 col-md-12">
                        <h6 className="title">testimonials</h6>
                        <h3 className="title mb-5">Leading companies and renowned brands chose us to build and scale their digital products.</h3>
                    </div>
                </div>
                <div className="row gx-3">
                    <div className="col-lg-12 mb-4 mb-lg-5">
                        <div className="owl-carousel owl-theme owl_testimonial_logo">
                            <div className="item"><img className="img-fluid" src="assets/images/testi-logo1.webp" title="Pailan" /></div>
                            <div className="item"><img className="img-fluid" src="assets/images/testi-logo2.webp" title="Coca-Cola" /></div>
                            <div className="item"><img className="img-fluid" src="assets/images/testi-logo3.webp" title="Biswa Bangla" /></div>
                            <div className="item"><img className="img-fluid" src="assets/images/testi-logo4.webp" title="Wiseasy" /></div>
                            <div className="item"><img className="img-fluid" src="assets/images/testi-logo5.webp" title="DSGN ST." /></div>
                            <div className="item"><img className="img-fluid" src="assets/images/testi-logo6.webp" title="Govt of India" /></div>
                        </div>
                    </div>
                    <div className="col-lg-6 mb-4 mb-lg-0">
                        <div className="owl-carousel owl-theme owl_testimonial">
                            <div className="item">
                                <p>Webmaa (Sketch) has been a fantastic company to deal with. When I first decided I needed a website for my business I didn’t have much of an idea of what I wanted, team is completely provide guidance with their experience. </p>
                                <h6><b>Emad Ayyad</b> | Director of Shiftling</h6>
                            </div>
                            <div className="item">
                                <p>Thank you so much for developing a mobile application for us. Excellent performance of your professionals makes you exceptional! All the team members are very co operative & helpful.</p>
                                <h6><b>Akbar</b> | Founder of Grape International</h6>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="row gx-3">
                            <div className="col-6">
                                <div className="youtube_video_thumb">
                                    <img src="assets/images/video-thumb1.webp" alt="Video 1" className="img-fluid w-100" />
                                    <a href="javascript:;" data-bs-toggle="modal" data-bs-target="#modalYtVideo1"><img src="assets/images/YouTube-play.webp" /></a>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="youtube_video_thumb">
                                    <img src="assets/images/video-thumb2.webp" alt="Video 2" className="img-fluid w-100" />
                                    <a href="javascript:;" data-bs-toggle="modal" data-bs-target="#modalYtVideo2"><img src="assets/images/YouTube-play.webp" /></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default HomeTestimonials;