const HomeQuery = () => {
    return (
        <section className="pb-5">
            <div className="container-xl my-3 my-lg-4">
                <div className="row gx-3">
                    <div className="col-lg-10 offset-lg-1 text-center">
                        <h6 className="title">Any Query?</h6>
                        <h3 className="title">Frequently asked questions</h3>
                    </div>
                </div>
                <div className="row gx-3">
                    <div className="col-md-12">
                        <div className="accordion" id="accordionFAQ">
                            <div className="accordion-item">
                                <h2 className="accordion-header">
                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#acco1" aria-expanded="true" aria-controls="acco1">
                                        Where is Webmaa based? What is the workforce?
                                    </button>
                                </h2>
                                <div id="acco1" className="accordion-collapse collapse show" data-bs-parent="#accordionFAQ">
                                    <div className="accordion-body">
                                        <p>Established in 2008, we are a premier offshore software development IT company based in Kolkata, India. Kolkata, located in the state of West Bengal, is one of the oldest cities in the country and is affectionately known as the 'City of Joy.' Our highly skilled team consists of over 50 talented professionals, with the majority working from our headquarters in Kolkata.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#acco2" aria-expanded="false" aria-controls="acco2">
                                        What is your core expertise and industry experience?
                                    </button>
                                </h2>
                                <div id="acco2" className="accordion-collapse collapse" data-bs-parent="#accordionFAQ">
                                    <div className="accordion-body">
                                        <p>Our team of exceptionally talented web and mobile app developers excels at creating outstanding mobile apps, websites, and games. When we launched in 2008, our focus was on graphic design and web design services. With the advent of smartphones, we expanded our expertise significantly. Over the past two decades, we have distinguished ourselves in web development, mobile app development, and game app development services.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#acco3" aria-expanded="false" aria-controls="acco3">
                                        Does your company provide a paid support contract?
                                    </button>
                                </h2>
                                <div id="acco3" className="accordion-collapse collapse" data-bs-parent="#accordionFAQ">
                                    <div className="accordion-body">
                                        <p>Yes, our company offers paid support contracts. You can request an extension of our support services after the project is completed. The charges for support vary depending on the project's size and scope. Please inquire about this once these details are defined.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#acco4" aria-expanded="false" aria-controls="acco4">
                                        What about the confidentiality of my idea?
                                    </button>
                                </h2>
                                <div id="acco4" className="accordion-collapse collapse" data-bs-parent="#accordionFAQ">
                                    <div className="accordion-body">
                                        <p>We understand the value of your idea and take its confidentiality very seriously. From the outset, we ensure your idea remains protected by signing a Non-Disclosure Agreement (NDA) before any discussions begin. Throughout the development process, we implement strict security and confidentiality measures to prevent unauthorized information sharing. Additionally, all our employees, including the software development teams, are bound by legal agreements to maintain security and trust..</p>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#acco5" aria-expanded="false" aria-controls="acco5">
                                        Do you reuse code?
                                    </button>
                                </h2>
                                <div id="acco5" className="accordion-collapse collapse" data-bs-parent="#accordionFAQ">
                                    <div className="accordion-body">
                                        <p>This is a nuanced question. We never reuse your proprietary algorithms, license keys, trade secrets, trademarks, or patented processes. However, to streamline development and enhance quality, we utilize open-source libraries and our own libraries. This approach helps save time and ensures you don't pay for something that can be efficiently produced using existing resources.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#acco6" aria-expanded="false" aria-controls="acco6">
                                        What is your project development methodology?
                                    </button>
                                </h2>
                                <div id="acco6" className="accordion-collapse collapse" data-bs-parent="#accordionFAQ">
                                    <div className="accordion-body">
                                        <p>Each web or mobile app development project is unique and demands a tailored approach. However, most projects follow these common development steps: requirement gathering, analysis, solution consulting, wireframing/prototyping, UI design, development, and testing. Depending on the project's scope and nature, the sequence and selection of these steps may vary.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#acco7" aria-expanded="false" aria-controls="acco7">
                                        Do you work according to the client's time zone?
                                    </button>
                                </h2>
                                <div id="acco7" className="accordion-collapse collapse" data-bs-parent="#accordionFAQ">
                                    <div className="accordion-body">
                                        <p>Our development team is based at our headquarters in Kolkata, India. However, we are flexible and can accommodate your time zone for special meetings and specific situations. We prefer scheduling these in advance to ensure we can plan our other commitments accordingly.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#acco8" aria-expanded="false" aria-controls="acco8">
                                        Can you send over the resources onsite for initial startup?
                                    </button>
                                </h2>
                                <div id="acco8" className="accordion-collapse collapse" data-bs-parent="#accordionFAQ">
                                    <div className="accordion-body">
                                        <p>Yes, if the project requires and where our representatives are available, we can send a resource onsite for the initial startup. Webmaa offers cost-effective solutions for global clients to hire dedicated developers at their locations. If we do not have an office in your city or country and the project is substantial, we will travel to your location from our corporate office.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#acco9" aria-expanded="false" aria-controls="acco9">
                                        What is the Quality Control Methodology which you have in place?
                                    </button>
                                </h2>
                                <div id="acco9" className="accordion-collapse collapse" data-bs-parent="#accordionFAQ">
                                    <div className="accordion-body">
                                        <p>We follow robust processes to ensure the highest quality. Our dedicated QA and QC division rigorously tests every project using the black-box method. They perform comprehensive testing to cover all aspects, providing the final approval before handover and deployment.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#acco10" aria-expanded="false" aria-controls="acco10">
                                        Do you have a Service Level Agreement in place?
                                    </button>
                                </h2>
                                <div id="acco10" className="accordion-collapse collapse" data-bs-parent="#accordionFAQ">
                                    <div className="accordion-body">
                                        <p>Yes, we do have. We have been a trusted offshore web and mobile app development company since 2008, adhering to protocols of the IT industry. We have a service level agreement in place as a symbol of trust and quality IT services delivery.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#acco11" aria-expanded="false" aria-controls="acco11">
                                        What’s your pricing model?
                                    </button>
                                </h2>
                                <div id="acco11" className="accordion-collapse collapse" data-bs-parent="#accordionFAQ">
                                    <div className="accordion-body">
                                        <p>We offer two primary pricing models to cater to different project needs. First is the 'Fixed Scope' model, where we provide a set timeframe and fixed price for the project. The second option is the 'Progressive Scope' model, ideal for projects whose size and details may evolve over time. For this, we assemble a team of diverse technology experts who work collaboratively with you as the project progresses. Given the flexibility required, charges are based on a monthly fee per team member. In both models, we outline payment milestones that correspond closely with project delivery stages.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#acco12" aria-expanded="false" aria-controls="acco12">
                                        How do we communicate with the project or software development team?
                                    </button>
                                </h2>
                                <div id="acco12" className="accordion-collapse collapse" data-bs-parent="#accordionFAQ">
                                    <div className="accordion-body">
                                        <p>We utilize Asana for project communications. Once you're signed up, we integrate you into our Asana platform alongside the Webmaa team assigned to your project. In addition to Asana, we also leverage Email and Skype for communication. Typically, you'll interact with a Project Manager and Tech Lead, but you're welcome to communicate directly with any team member involved in your project.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default HomeQuery;