import { Link } from "react-router-dom";

export default function DukesSeafood() {
    return (
        <section className="page-portfolio pt-5">
            <div className="container-xl portfolio-details mt-5">
                <div className="row pt-1 pt-sm-5">
                    <div className="col-lg-10 offset-lg-1 text-center">
                        <h2 className="title mb-5">Duke's Seafood</h2>
                    </div>
                </div>
            </div>
            <div className="container-xl portfolio-details mb-5">
                <img src="../../assets/images/portfolio/dukes-seafood/hero.webp" alt="" className="img-fluid w-100" />
            </div>
            <div className="container-xl portfolio-details mb-5">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="about-project">
                            <div className="row">
                                <div className="col-lg-6 mb-5 mb-lg-0">
                                    <h3>About this project </h3>
                                    <p>For Duke's Seafood, we developed a comprehensive WordPress-based e-commerce site, enhancing user experience and streamlining online food ordering and recipe management. The platform features an intuitive interface, secure payment options, and seamless navigation, showcasing their delicious seafood offerings and curated recipes. The integrated system ensures a robust, scalable, and engaging online experience, driving customer satisfaction and sales growth for Duke's Seafood.</p>
                                </div>
                                <div className="col-lg-6">
                                    <div className="about-project-availability">
                                        <div className="row">
                                            <div className="col-sm-6 mb-4">
                                                <h6>Services</h6>
                                                <ul>
                                                    <li>UI/UX Designing</li>
                                                    <li>Project Management</li>
                                                    <li>Application development</li>
                                                    <li>Mobile app development</li>
                                                    <li>Quality Assurance</li>
                                                </ul>
                                            </div>
                                            <div className="col-sm-6 mb-4">
                                                <h6>Technology </h6>
                                                <ul>
                                                    <li>Figma</li>
                                                    <li>CodeIgniter PHP</li>
                                                    <li>Android</li>
                                                    <li>iOS</li>
                                                    <li>MySQL</li>
                                                </ul>
                                            </div>
                                        </div>

                                        <div className="availability">
                                            <a href="https://www.dukesseafood.com/" target="_blank"><img src="../../assets/images/link-web.webp" alt="Website" /></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-xl portfolio-details mb-5">
                <div className="row">
                    <div className="col-lg-10 offset-lg-1">
                        <h3>Meet Duke's Seafood</h3>
                        <p>Duke's Seafood has created an innovative WordPress-based platform to bring its fresh seafood and curated recipes directly to consumers. Founded by culinary experts with a passion for quality and flavor, Duke's Seafood offers a diverse menu that combines traditional recipes with contemporary twists.</p>
                        <p>The online store is designed to provide a seamless ordering experience, featuring intuitive navigation, secure payment options, and personalized recommendations. The platform also includes a comprehensive recipe management system, allowing customers to explore and try out new seafood dishes. Committed to quality and customer satisfaction, Duke's Seafood continues to set the standard in online seafood retail.</p>
                    </div>
                </div>
            </div>
            <div className="container-xl portfolio-details mb-5">
                <img src="../../assets/images/portfolio/dukes-seafood/image1.webp" alt="" className="img-fluid w-100" />
            </div>
            <div className="container-xl portfolio-details mb-5">
                <div className="row">
                    <div className="col-lg-10 offset-lg-1">
                        <h3>Duke's Seafood’s Growth Story</h3>
                        <p>Duke's Seafood started as a small seafood restaurant with a vision for providing high-quality seafood and exceptional dining experiences. Through dedication to quality and a keen understanding of their audience, they quickly gained popularity. Launching their innovative WordPress-based e-commerce site expanded their reach, offering seamless ordering experiences and personalized service. Strategic marketing and customer engagement initiatives further boosted brand loyalty and sales. Today, Duke's Seafood stands as a leading online seafood retailer, continuously growing and setting new standards in the industry, with a passionate customer base and a strong presence in the seafood market.</p>
                    </div>
                </div>
            </div>
            <div className="container-xl portfolio-details mb-5">
                <img src="../../assets/images/portfolio/dukes-seafood/image2.webp" alt="" className="img-fluid w-100" />
            </div>
            <div className="container-xl portfolio-details mb-5">
                <div className="row">
                    <div className="col-lg-10 offset-lg-1">
                        <h3>What is unique about Duke's Seafood</h3>
                        <p>Duke's Seafood stands out for its unique blend of high-quality seafood and curated recipes, offering a distinctive culinary experience that appeals to seafood lovers. Each dish is crafted with meticulous attention to detail, ensuring fresh and flavorful seafood that delights the palate. What truly sets Duke's Seafood apart is their commitment to innovation in the seafood retail space. Their WordPress-based e-commerce site provides a seamless, user-friendly ordering experience, featuring secure payment options, personalized recommendations, and a comprehensive recipe management system. Additionally, Duke's Seafood maintains a strong connection with its audience through strategic marketing and customer engagement initiatives, fostering a loyal customer base. By continuously evolving their product offerings and incorporating customer feedback, Duke's Seafood ensures they stay ahead of culinary trends while meeting the diverse needs of their clientele. This dedication to quality, innovation, and customer satisfaction makes Duke's Seafood a standout in the seafood industry.</p>
                    </div>
                </div>
            </div>
            <div className="container-xl portfolio-details mb-5">
                <img src="../../assets/images/portfolio/dukes-seafood/image3.webp" alt="" className="img-fluid w-100" />
            </div>
            <div className="container-xl portfolio-details mb-5">
                <div className="row">
                    <div className="col-lg-10 offset-lg-1">
                        <h3>Faced Challenges</h3>
                        <p>The existing backend system frequently experienced speed issues, causing headaches for the client.</p>
                        <p>Addressing these challenges required implementing robust data management systems, improving customer relationship management strategies, optimizing promotional campaigns, and enhancing order processing workflows. Successfully navigating these tasks demanded a combination of technology integration, staff training, and strategic planning. The ability to efficiently handle data, interact with customers, run promotions seamlessly, and manage orders played a pivotal role in achieving business success.</p>
                    </div>
                </div>
            </div>
            <div className="container-xl portfolio-details mb-5">
                <img src="../../assets/images/portfolio/dukes-seafood/image4.webp" alt="" className="img-fluid w-100" />
            </div>
            <div className="container-xl portfolio-details mb-5">
                <div className="row">
                    <div className="col-lg-10 offset-lg-1">
                        <h3>Project Approach</h3>
                        <p>The project began with discovery, analyzing client needs. Planning set milestones, timelines, and communication. Development optimized performance. Testing ensured quality. Successful implementation replaced old versions.</p>
                        <p>The overall approach to the project involved a systematic and comprehensive process. Starting with the discovery phase, the team thoroughly analyzed the client’s requirements, identified pain points, and determined the scope of the project. During the planning stage, they defined the project milestones, created a detailed timeline, and established communication channels for seamless collaboration. In the development phase, the team focused on front-end and back-end development, ensuring optimal performance and user experience. Extensive testing and quality assurance measures were implemented to identify and rectify any issues. The project was then implemented, with the new website seamlessly replacing the previous version.</p>

                        <p className="mb-2">Key Benefits</p>
                        <ul>
                            <li>Streamlined data management, customer interactions, promotions, and order processes.</li>
                            <li>Improved user experience through a visually appealing design and intuitive navigation.</li>
                            <li>Enhanced website performance.</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="container-xl portfolio-details mb-5">
                <img src="../../assets/images/portfolio/dukes-seafood/image5.webp" alt="" className="img-fluid w-100" />
            </div>
            <div className="container-xl portfolio-details mb-5">
                <div className="row">
                    <div className="col-lg-10 offset-lg-1">
                        <h3>Results</h3>
                        <p>Following the implementation of the project, Duke's Seafood experienced numerous positive outcomes. The new backend system resolved the speed issues that previously plagued the client, resulting in a seamless and reliable platform for their business operations. The enhanced features allowed for better management of data, customers, promotions, and orders, leading to improved efficiency and customer satisfaction. The flawless website catered to an expanding customer base, increasing their reach and driving sales. Overall, the project brought significant improvements to the client’s operations, ensuring a smooth and engaging experience for their users.</p>
                    </div>
                </div>
            </div>
            <div className="container-xl portfolio-details mb-5">
                <div className="row">
                    <div className="col-lg-8 offset-lg-2">
                        <div className="portfolio-quote">
                            <div className="content">
                                Webmaa's adaptability to our evolving needs and the steady stream of insightful feedback and ideas prove they were not just a partner – they were an indispensable asset in helping us reach our vision to democratize the fintech industry.
                            </div>
                            <div className="author">
                                <div className="auth-image">
                                    <img src="../../assets/images/portfolio/dukes-seafood/author.png" alt="author" />
                                </div>
                                <div className="auth-desig">
                                    <h6>User Name</h6>
                                    <p>Designation, Company Name</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-xl portfolio-nav mb-5">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="row">
                            <div className="col-6">
                                <Link to="/portfolio/moijey-fine-jewelry" className="d-block pprev">
                                    <span className="fs-14 fw-500 text-black">Previous Project</span> <br />
                                    <span className="fs-13 text-gray d-none d-sm-block">Fast and Simple Website Builder<br /></span>
                                    <span className="fs-15 fw-600 text-secondary">Moijey Fine Jewelry & Diamonds</span>
                                </Link>
                            </div>
                            <div className="col-6">
                                <Link to="/portfolio/liquor" className="d-block pnext text-end">
                                    <span className="fs-14 fw-500 text-black">Next Project</span> <br />
                                    <span className="fs-13 text-gray d-none d-sm-block">Fast and Simple Website Builder<br /></span>
                                    <span className="fs-15 fw-600 text-secondary">Liquor.com</span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}