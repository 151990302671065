import { Link } from "react-router-dom";
import { Fragment } from "react/jsx-runtime";

export default function DemoMatrix() {
    return (
        <Fragment>
            <section className="inner-banner matrix">
                <div className="container-xl">
                    <div className="row gx-3">
                        <div className="col-lg-8 text-center text-lg-start">
                            <h1 className="mb-3 mb-lg-5">Accelerate Android <br />Smartphone Financing</h1>
                            <p className="lead-lg mb-4 mb-sm-5 fw-300">Encourage customers to make payments on time. Reduce smartphone loan default risk</p>
                            <div className="matrix-banner-row">
                                <Link to="/contact" className="btn btn-primary rad-30 px-4">Let’s work together!</Link>
                                <ul>
                                    <li>Safe</li>
                                    <li>Secure</li>
                                    <li>Quick</li>
                                    <li>Easy</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-4 position-relative">
                            <div className="matrix-hero">
                                <img src="../assets/images/matrix-hero.webp" alt="" className="img-fluid d-block mx-auto" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="py-5">
                <div className="container-xl my-0 my-lg-4">
                    <div className="row gx-3">
                        <div className="col-lg-10 offset-lg-1 text-center">
                            <h3 className="mb-4">Protecting your investment is always a smart move</h3>
                            <p className="lead m-0">With the rise in smartphone financing, commercial risks also increase. Secure all your financed Android devices with Make Secure’s DFRM (Device Financing Risk Management) platform. Broaden your Android product lineup to boost revenue while mitigating payment defaults and handset fraud</p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="pb-5 matrix-safeguard">
                <div className="container-xl my-0 my-lg-4">
                    <div className="row gx-3">
                        <div className="col-lg-6 mb-4 mb-lg-0">
                            <img src="../assets/images/matrix-banner.webp" alt="" className="img-fluid w-100" />
                        </div>
                        <div className="col-lg-5 offset-lg-1">
                            <h3 className="text-primary">How To Secure Your EMI Payments</h3>
                            <p className="lead mb-4">Our automated device locking platform facilitates prompt bill payment by allowing you to remotely lock smartphones of non-paying customers.</p>
                            <p className="lead mb-4">By ensuring increased revenue assurance and streamlining operations, our solution reduces costs, saves your team time, and boosts margins. This enables you to extend handset financing, allowing you to say 'yes' to more customers.</p>
                            <p className="lead mb-4">Make Secure helps to secure EMI Payment for devices to Finance, Resellers & Telecom Carriers companies.</p>
                            <h6 className="mb-3 text-primary">Track live location</h6>
                            <h6 className="mb-3 text-primary">One touch Lock / Unlock</h6>
                            <h6 className="mb-3 text-primary">Auto lock on SIM swap</h6>
                            <h6 className="mb-3 text-primary">SMS lock while offline</h6>
                            <h6 className="mb-3 text-primary">Send reminders before due date</h6>
                        </div>
                    </div>
                </div>
                <img src="../assets/images/matrix-safeguard.webp" alt="" className="img-matrix-safeguard" />
            </section>
            <section className="pb-5">
                <div className="container-xl my-0 my-lg-4">
                    <div className="row gx-3">
                        <div className="col-lg-12 text-center">
                            <h3 className="title mb-4 pb-2">Why Make Secure Prime</h3>
                        </div>
                    </div>
                    <div className="row gx-3">
                        <div className="col-sm-6 col-lg-3 mb-5 mb-lg-0">
                            <div className="mybox matrix-box">
                                <h6 className="fw-600 text-primary">Easy to Use</h6>
                                <p>After customers make their last device payment, remove all restrictions and offboard smartphones from the DFRM platform at the click of a button.</p>
                            </div>
                        </div>
                        <div className="col-sm-6 col-lg-3 mb-5 mb-lg-0">
                            <div className="mybox matrix-box">
                                <h6 className="fw-600 text-primary">Prevent tamper</h6>
                                <p>Stop customers from factory resetting a financed Android smartphone</p>
                            </div>
                        </div>
                        <div className="col-sm-6 col-lg-3 mb-5 mb-lg-0">
                            <div className="mybox matrix-box">
                                <h6 className="fw-600 text-primary">OEM Agnostic</h6>
                                <p>Onboard Android 7.0 and over smartphones or tablets by the OEM of your choice on Make Secure’s DFRM platform</p>
                            </div>
                        </div>
                        <div className="col-sm-6 col-lg-3">
                            <div className="mybox matrix-box">
                                <h6 className="fw-600 text-primary">Support 24x7</h6>
                                <p>Offer 24x7 support for retails and financer for any sorts of quires and communication issues with the consumers.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="py-5 matrix-banner-ad mb-5">
                <div className="container-xl my-0 my-lg-4">
                    <div className="row gx-3">
                        <div className="col-md-7 mb-5 mb-md-0 align-self-center text-center text-md-start">
                            <h2 className="fw-400 text-white mb-5">Offer Android smartphone financing to a broad customer base to boost revenue and enhance monthly payment collections.</h2>
                            <Link to="/contact" className="btn btn-light">Get Started</Link>
                        </div>
                        <div className="col-md-5 align-self-center">
                            <img src="../assets/images/matrix-mobile.webp" alt="" className="img-fluid d-block mx-auto mt-3" />
                        </div>
                    </div>
                </div>
            </section>
            <section className="py-5">
                <div className="container-xl">
                    <div className="row gx-3 mb-4">
                        <div className="col-lg-10 offset-lg-1 text-center">
                            <h3 className="mb-4">Designed for Financiers</h3>
                            <p className="lead-lg">Our cloud-based platform provides financial service providers with tailored management of devices from the leading Android OEMs, all through a single portal. Scalability, resilience and security are integrated into the platform design. Secure cloud infrastructure supports 99.9% service level agreement (SLA) and global deployment capabilities. Gives you the peace of mind to focus on your bottom line.</p>
                        </div>
                    </div>
                    <div className="row gx-3 justify-content-center">
                        <div className="col-md-4 mb-4">
                            <div className="mybox matrix-box2">
                                <h6 className="fw-600">Single Interface</h6>
                                <ul className="m-0"><li>Single pan of glass to manage your entire Android portfolio</li></ul>
                            </div>
                        </div>
                        <div className="col-md-4 mb-4">
                            <div className="mybox matrix-box2">
                                <h6 className="fw-600">Management Reporting</h6>
                                <ul className="m-0"><li>Harness the power of data for more informed business decisions </li></ul>
                            </div>
                        </div>
                        <div className="col-md-4 mb-4">
                            <div className="mybox matrix-box2">
                                <h6 className="fw-600">Managed Services</h6>
                                <ul className="m-0"><li>Low upfront cost and low ongoing investment</li></ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="pb-5">
                <div className="container-xl my-0 my-lg-4">
                    <div className="row gx-3">
                        <div className="col-lg-12">
                            <div className="owl-carousel owl-theme owl_matrix_partners">
                                <div className="item"><img src="../assets/images/logo-vivo.webp" alt="" /></div>
                                <div className="item"><img src="../assets/images/logo-realme.webp" alt="" /></div>
                                <div className="item"><img src="../assets/images/logo-vivo.webp" alt="" /></div>
                                <div className="item"><img src="../assets/images/logo-one-plus.webp" alt="" /></div>
                                <div className="item"><img src="../assets/images/logo-nokia.webp" alt="" /></div>
                                <div className="item"><img src="../assets/images/logo-mi.webp" alt="" /></div>
                                <div className="item"><img src="../assets/images/logo-htc.webp" alt="" /></div>
                                <div className="item"><img src="../assets/images/logo-ifinix.webp" alt="" /></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="pb-5">
                <div className="container-xl">
                    <div className="box-book-demo">
                        <div className="circle-top"></div>
                        <div className="circle-bottom"></div>
                        <div className="row gx-3">
                            <div className="col-lg-8 text-center text-lg-start mb-4 mb-lg-0 align-items-center">
                                <div id="content-book-demo">
                                    <h2 className="mb-3 fw-600">Connect us to find out more</h2><p className="lead-lg fw-500 m-0">Get your store listed and drive more profits for your device financing business</p>
                                </div>
                            </div>
                            <div className="col-lg-4 text-center align-content-end text-lg-end">
                                <Link to="/contact" className="btn btn-light btn-lg btn-rounded">Book a Demo</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    )
}