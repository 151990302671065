import React, { Fragment } from "react";
import Header from "../../components/header";
import { Outlet } from "react-router";
import Footer from "../../components/footer";

export default function MainContainer() {
    return (
        <Fragment>
            <Header />
            <Outlet />
            <Footer />
        </Fragment>
    );
}