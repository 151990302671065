import { Fragment } from "react/jsx-runtime";

export default function Policies() {
    return (
        <Fragment>
            <section className="inner-banner policy">
                <div className="container-xl">
                    <div className="row">
                        <div className="col-lg-10 offset-lg-1 text-center">
                            <h6 className="title">Policies</h6>
                            <h3 className="title fw-500 mb-4">Webma's Policies</h3>
                        </div>
                    </div>
                </div>
            </section>
            <section className="py-5 cmspages">
                <div className="container-xl">
                    <div className="row">
                        <div className="col-lg-12">
                            <p><strong>Summary of our Privacy Policy</strong></p>
                            <p>This is a summary of our new privacy policy which takes effect on January 1st, 2024. It covers every Webmaa website that links here, and all of the products and services contained on those websites. The detailed policy follows the same structure as this summary and constitutes the actual legal document.</p>

                            <p><strong>Privacy Policy</strong></p>
                            <p>This Privacy Policy statement is made by the Sketch Enterprises consisting of all the entities listed here (collectively, "Webmaa", "we", "us" or "our") and is effective as of 1st January 2024.</p>

                            <p><strong>Webmaa's Privacy Commitment</strong></p>
                            <p>For nearly 15 years, Webmaa has prioritized customer and user privacy, long before it was popular or legally required. We only ask for and collect the minimum necessary information needed for business transactions. We inform customers about the data we have and allow them to opt out of specific engagements. Importantly, we have never and will never profit from advertising revenue, even if someone just submits an inquiry and doesn't become a customer. This ensures we avoid conflicts of interest that could compromise customer privacy.</p>

                            <p>Our policy aims to clearly outline what information we collect from our customers and website users, how we use it, and what we do not do with it. While the policy may be longer than we prefer, it's necessary to address all relevant situations. We strive to keep the language of the policy straightforward and easy to understand.</p>

                            <p>Webmaa ( a brand of Sketch Enterprises)&nbsp; is dedicated towards ensuring that privacy of the information or details, you provide as a user of the website stays protected. In case if we ask you to provide us specific information pertaining to which you can be identified as a user of the website, we will assure that such information will only be utilized by staying compliant to our privacy statement.</p>

                            <p>Webmaa may make changes to this policy as and when required by updating the page. You must check the privacy policy page from time to time in order to ensure that you have accepted the changes.</p>

                            <h4>Part I - What we are liable to collect</h4>
                            <p>We collect only the information necessary to provide our services effectively. This includes data you voluntarily provide when contacting us via email, contact forms, or lead generation forms to inquire about our services or make purchases. We retain your name, contact details such as phone numbers and social profiles, address, and project-related information.</p>

                            <p>When you visit our websites or use our services, we automatically log basic information such as how you accessed the site, your navigation paths, location, and which features you use. This data helps us enhance our websites, improve our services, and better serve you.</p>

                            <p>At times, we may receive information indirectly. For example, if you inquire about our services through a referral program or reselling partner, or sign into one of our products via a service provider like LinkedIn, Google, or Facebook, they may share your contact details with us. We use this information to fulfill your requests. Additionally, if you interact with our brand on social media (e.g., by liking, commenting, retweeting, mentioning, or following us), we may access and retain your interaction history and profile information, even if you later delete it from the social media platform.</p>

                            <p><strong><u>Information that you provide us</u></strong></p>
                            <ol>
                                <li>Enquiry Form / Email / Third Party Partners to generate new enquiries : When you contact us using online web form, email to obtain or enquire regarding one or more of our services, we ask for information like your name, contact number, email address, company name and country to complete the enquiry process. You may also provide us with more information such as your photo, time zone and language, but we don't require that information for initial contact. Once you submit the enquiry in any above mentioned form, our legitimate employees will have direct access to your information to further process the enquiry and contact you in regard with obtaining pre-sales information regarding your business and project related information.</li>
                                <li>Surveys, Feedback and other form submissions: We record information that you submit when you (i) register for any event, including webinars or seminars, (ii) subscribe to our newsletter or any other mailing list, (iii) submit a form in order to download portfolio, company profile, whitepaper, or other materials, (iv) participate in contests or respond to surveys, or (v) submit a form to request customer support or to contact Webmaa for any other purpose.</li>
                                <li>Project Creation : When you acquire or sign a contract to buy any of our services, we ask you to provide your name, contact information, personal details, project related information, social media profile links and timezone. We store all of this information to our secured project management software (ERP).</li>
                                <li>Payment processing : When you acquire or buy any of our services from us, we ask you to provide your name, contact information, and credit card information or paypal. We NEVER STORE any of your Payment related information including credit card, bank details, paypal details or any other means through which you choose to make a payment. All payments are getting processed with secured servers of our Payment Gateway Service Providers.</li>
                                <li>Testimonials : When you authorize us to post testimonials about our products and services on websites, we may include your name and other personal information in the testimonial. You will be given an opportunity to review and approve the testimonial before we post it. If you wish to update or delete your testimonial, you can contact us through this form.</li>
                                <li>Interactions with Webmaa : We may record, analyze and use your interactions with us, including email, telephone, and chat conversations with our sales, project management, account management and customer support professionals, for improving our interactions with you and other customers.</li>
                            </ol>

                            <p><strong><u>Information that we collect automatically</u></strong></p>
                            <ol>
                                <li>Information from browsers, devices and servers : When you visit our websites, we collect information that web browsers, mobile devices and servers make available, such as the internet protocol address, browser type, language preference, time zone, referring URL, date and time of access, operating system, mobile device manufacturer, mobile network information and your geographic information. We include these in our log files to understand more about visitors to our websites.</li>
                                <li>Information from first party cookies and tracking technologies : We use temporary and permanent cookies to identify users of our services and to enhance user experience. We also use cookies, tags, scripts, and other similar technologies to identify visitors, track website navigation, gather demographic information about visitors and users, understand email campaign effectiveness and for targeted visitor and user engagement by tracking your activities on our websites. We use third-party tracking services like google analytics and google tag manager to understand the behavior of our website visitors and serve them better.</li>
                                <li>Information from application logs and mobile analytics : We collect information about your use of our website and mobile applications from application logs and third party analytics tools, and use it to understand how your business use and needs can improve our services. This information includes clicks, scrolls, features accessed, access time and frequency, errors generated, performance data, and configurations, and devices used to access and their locations.</li>
                            </ol>

                            <p><strong><u>Information that we collect from third parties</u></strong></p>
                            <ol>
                                <li>Referrals : If someone has referred any of our products or services to you through any of our referral programs, that person may have provided us your name, email address and other personal information. You may contact us through this form to request that we remove your information from our database. If you provide us information about another person, or if another person gives us your information, we will only use that information for the specific reason for which it was provided to us.</li>
                                <li>Information from social media sites and other publicly available sources : When you interact or engage with us on social media sites such as Facebook, Twitter, Google+ and Instagram through posts, comments, questions and other interactions, we may collect such publicly available information, including profile information, to allow us to connect with you, improve our services, or better understand user reactions and issues. We must tell you that once collected, this information may remain with us even if you delete it from the social media sites.</li>
                            </ol>

                            <h4>Part II - What we do with your information</h4>
                            <p>We use your information to fulfill the services you've requested, manage and maintain your accounts. Additionally, we use it to communicate with you regarding your current services, customer support inquiries, new service offerings, opportunities for feedback, and policy updates. We analyze collected data to better understand user needs and enhance our websites and services.</p>

                            <p>We adhere to legal requirements for collecting and processing your information. In most cases, we obtain your consent or collect information necessary to provide requested services. Alternatively, we may rely on legitimate business interests when applicable.</p>

                            <p>You have the option to decline certain uses of your information by choosing not to provide it initially or opting out later. Disabling cookies in your browser can prevent us from collecting certain information, although it may impact website functionality. We do not use third-party cookies on any Webmaa websites.</p>

                            <p>Access to your personal information is restricted to our employees and vendors with a legitimate need to access it.</p>

                            <p>We keep your personal information for as long as it is required for the purposes stated in the Privacy Policy. When we no longer have a legitimate need to process your information, we will delete, anonymize, or isolate your information whichever is appropriate.</p>

                            <p><strong>Purposes for using information</strong></p>
                            <p>In addition to the purposes mentioned above, we may use your information for the following purposes:</p>

                            <ul>
                                <li>To communicate with you (such as through email) about projects that you have and services that you have enquired for, changes to this Privacy Policy, changes to the Terms of Service, or important notices;</li>
                                <li>To keep you posted on new products and services, upcoming events, offers, promotions and other information that we think will be of interest to you;</li>
                                <li>To ask you to participate in surveys, or to solicit feedback on our products and services;</li>
                                <li>To set up and maintain your account, and to do all other things required for providing our services, such as enabling collaboration, requirement gathering, project development, and deployment of project;</li>
                                <li>To understand how users use our products and services, to monitor and prevent problems, and to improve our products and services;</li>
                                <li>To provide customer support, and to analyze and improve our interactions with customers;</li>
                                <li>To detect and prevent fraudulent transactions and other illegal activities, to report spam, and to protect the rights and interests of Webmaa, Webmaa's users, third parties and the public;</li>
                                <li>To update, expand and analyze our records, identify new customers, and provide products and services that may be of interest to you;</li>
                                <li>To analyze trends, administer our websites, and track visitor navigations on our websites to understand what visitors are looking for and to better help them;</li>
                                <li>To monitor and improve marketing campaigns and make suggestions relevant to the user.</li>
                            </ul>

                            <p><strong>Legal bases for collecting and using information</strong></p>
                            <p>Legal processing bases applicable to Webmaa : If you are an individual from the European Economic Area (EEA), our legal basis for information collection and use depends on the personal information concerned and the context in which we collect it. Most of our information collection and processing activities are typically based on (i) contractual necessity, (ii) one or more legitimate interests of Webmaa or a third party that are not overridden by your data protection interests, or (iii) your consent. Sometimes, we may be legally required to collect your information, or may need your personal information to protect your vital interests or those of another person.</p>

                            <p>Withdrawal of consent : Where we rely on your consent as the legal basis, you have the right to withdraw your consent at any time, but this will not affect any processing that has already taken place.</p>

                            <p>Legitimate interests notice : Where we rely on legitimate interests as the legal basis and those legitimate interests are not specified above, we will clearly explain to you what those legitimate interests are at the time that we collect your information.</p>

                            <p><strong>Your rights with respect to information we hold about you as a controller</strong></p>
                            <p>If you are in the European Economic Area (EEA), you have the following rights with respect to information that Webmaa holds about you. Webmaa undertakes to provide you the same rights no matter where you choose to live.</p>

                            <p>Right to access : You have the right to access (and obtain a copy of, if required) the categories of personal information that we hold about you, including the information source, purpose and period of processing, and the persons to whom the information is shared.</p>

                            <p>Right to rectification : You have the right to update the information we hold about you or to rectify any inaccuracies. Based on the purpose for which we use your information, you can instruct us to add supplemental information about you.</p>

                            <p>Right to erasure : You have the right to request that we delete your personal information in certain circumstances, such as when it is no longer necessary for the purpose for which it was originally collected.</p>

                            <p>Right to restriction of processing : You may also have the right to request to restrict the use of your information in certain circumstances, such as when you have objected to our use of your data but we need to verify whether we have overriding legitimate grounds to use it.</p>

                            <p>Right to object : You have the right to object to the use of your information in certain circumstances, such as the use of your personal information for direct marketing.</p>

                            <p>Right to complain : You have the right to complain to the appropriate supervisory authority if you have any grievance against the way we collect, use or share your information. This right may not be available to you if there is no supervisory authority dealing with data protection in your country.</p>

                            <p><strong>Retention of information</strong></p>
                            <p>We retain your personal information for as long as it is required for the purpose stated in this privacy policy. We may retain your information for longer periods as permitted or required by law, such as to maintain suppression lists, prevent abuse, if required in connection with a legal claim or proceeding, to enforce our agreements, for tax, accounting, or comply with other legal obligations. We may store your information as we do required information related to our customers for legal means and financing and future contact purposes.</p>


                            <h4>Part III &ndash; Information that Webmaa processes on your behalf</h4>
                            <p>If you entrust us with handling data through Webmaa services, such as information about your customers or employees, you retain ownership of that service data. We safeguard it, restrict access to it, and process it solely according to your instructions. You have the right to access this data, share it via third-party integrations, and request its export or deletion.</p>

                            <p>We maintain your data for as long as you use Webmaa services. Upon termination of your contract, with your consent, we may retain data backups for a specified period before securely deleting your data from our development and backup servers.</p>

                            <p>If you are in the European Economic Area and believe that someone has entrusted your information to us for processing (e.g., your employer or a company using our services), you can exercise your data rights by contacting the individual or entity that entrusted us with your data. We will collaborate with them to address your request.</p>

                            <p><strong><u>Information entrusted to Webmaa and purpose</u></strong></p>
                            <p>Information provided in connection with services : You may entrust information that you or your organization ("you") control, to Webmaa in connection with use of our services or for requesting technical support for our services. This includes information regarding your customers and your employees (if you are a controller) or data that you hold and use on behalf of another person for a specific purpose, such as a customer to whom you provide services (if you are a processor). The data may either be stored on our servers when you use our services, or transferred or shared to us as part of a request for technical support or other services.</p>

                            <p>(All the information entrusted to Webmaa is collectively termed "service data")</p>

                            <p><strong><u>Ownership and control of your service data</u></strong></p>
                            <p>We recognize that you own your service data. We provide you complete control of your service data by providing you the ability to (i) access your service data, (ii) share your service data through supported third-party integrations, and (iii) request export or deletion of your service data.</p>

                            <p><strong><u>How we use service data</u></strong></p>
                            <p>We process your service data when you provide us instructions through the various phases of our services or project development.</p>

                            <p><strong><u>Who we share service data with</u></strong></p>
                            <p>Webmaa group and third party sub-processors : In order to provide services and technical support during the development, the contracting entity for which you want to acquire third-party services.</p>

                            <p>Employees and independent vendors : We may provide access to your service data to our employees and individuals who are independent contractors of the Webmaa group entities involved in providing the services (collectively our "employees") so that they can (i) identify, analyze and resolve errors, (ii) manually verify emails reported as spam to improve spam detection, We ensure that access by our employees to your service data is restricted to specific individuals, and is logged and audited. Our employees will also have access to data that you knowingly share with us for project development, technical support or to import data for testing purpose for project development needs. We communicate our privacy and security guidelines to our employees and strictly enforce privacy safeguards within the Webmaa group.</p>

                            <p>Third-party integrations you have enabled : If you choose to enable any third-party integrations with your project, you may be allowing the third party to access your service information and personal information about you. We encourage you to review the privacy practices of the third-party services and products before you enable integrations with them.</p>

                            <p>Other cases : Other scenarios in which we may share information that are common to information covered under Parts I and II are described in Part III.</p>

                            <p><strong><u>Retention of information</u></strong></p>
                            <p>We hold the data in your account as long as you choose to use Webmaa Services. Once your contract with Webmaa or Project is completed and delivered, your data, code, database will be stored for backup and repository purposes only in a secured environment. Your personal and service data information being a client will be stored within our ERP for future reference and accounting purposes.</p>

                            <p><strong><u>Data subject requests</u></strong></p>
                            <p>If you are from the European Economic Area and you believe that we store, use or process your information on behalf of one of our customers, please contact the customer support if you would like to access, rectify, erase, restrict or object to processing, or export your personal data. We will extend our support to our customer in responding to your request within a reasonable timeframe.</p>


                            <h4>Part IV &ndash; General</h4>
                            <p>Our commitment to your privacy has certain limitations. We may disclose personal information when required by law, to prevent fraud, enforce agreements, or protect the safety of our users. Currently, we do not honor Do Not Track signals from internet browsers; once a universal standard is established, we will comply with it.</p>

                            <p>Third-party websites and social media widgets operate under their own privacy policies. Always review these policies before providing personal information to third parties.</p>

                            <p>You can reach out to us to: inquire about our privacy practices, request a GDPR-compliant Data Processing Addendum, report concerns about personal data collected from minors, or request removal of your personal information from our blogs or forums. You can also review our Security Policy and Privacy Policy.</p>

                            <p>We will notify you of any significant changes to our privacy policy, or in the unlikely event of a decision to sell our services.</p>

                            <p><strong><u>How secure is your information</u></strong></p>
                            <p>We have taken steps to implement appropriate administrative, technical &amp; physical safeguards to prevent unauthorized access, use, modification, disclosure or destruction of the information you entrust to us. If you have any concerns regarding the security of your data, we encourage you to check our Security Policy or write to us through this form with any questions.</p>

                            <p><strong><u>Locations and international transfers</u></strong></p>
                            <p>We share your personal information and service data within the Webmaa Group. By accessing or using our products and services or otherwise providing personal information or service data to us, you consent to the processing, transfer, and storage of your personal information or Service Data within the United States of America, the European Economic Area (EEA) and other countries where Webmaa operates. Such transfer is subject to a group company agreement that is based on EU Commission's Model Contractual Clauses.</p>

                            <p><strong><u>Do Not Track (DNT) requests</u></strong></p>
                            <p>Some internet browsers have enabled &lsquo;Do Not Track' (DNT) features, which send out a signal (called the DNT signal) to the websites that you visit indicating that you don't wish to be tracked. Currently, there is no standard that governs what websites can or should do when they receive these signals. For now, we do not take action in response to these signals.</p>

                            <p><strong><u>External links on our websites</u></strong></p>
                            <p>Some pages of our websites may contain links to websites that are not linked to this Privacy Policy. If you submit your personal information to any of these third-party sites, your personal information is governed by their privacy policies. As a safety measure, we recommend that you not share any personal information with these third parties unless you've checked their privacy policies and assured yourself of their privacy practices.</p>

                            <p><strong><u>Blogs and forums</u></strong></p>
                            <p>We offer publicly accessible blogs on our websites. Please be aware that any information you provide on these blogs may be used to contact you with unsolicited messages. We urge you to be cautious in disclosing personal information in our blogs and forums. Webmaa is not responsible for the personal information you elect to disclose publicly. Your posts and certain profile information may remain even after you terminate your account with Webmaa. To request the removal of your information from our blogs, you can contact us through this form.</p>

                            <p><strong><u>Social media widgets</u></strong></p>
                            <p>Our websites and blog include social media widgets such as Facebook "like" buttons and Twitter "tweet" buttons that let you share articles and other information. These widgets may collect information such as your IP address and the pages you navigate in the website, and may set a cookie to enable the widgets to function properly. Your interactions with these widgets are governed by the privacy policies of the companies providing them.</p>


                            <h4>Disclosures in compliance with legal obligations</h4>
                            <p>We may be required by law to preserve or disclose your personal information and service data to comply with any applicable law, regulation, legal process or governmental request, including to meet national security requirements.</p>

                            <p><strong><u>Enforcement of our rights</u></strong></p>
                            <p>We may disclose personal information and service data to a third party if we believe that such disclosure is necessary for preventing fraud, investigating any suspected illegal activity, enforcing our agreements or policies, or protecting the safety of our users.</p>

                            <p><strong><u>Business Transfers</u></strong></p>
                            <p>We do not intend to sell our business. However, in the unlikely event that we sell our business or get acquired or merged, we will ensure that the acquiring entity is legally bound to honor our commitments to you. We will notify you via email or through a prominent notice on our website of any change in ownership or in the uses of your personal information and service data. We will also notify you about any choices you may have regarding your personal information and service data.</p>

                            <p><strong><u>Compliance with this Privacy Policy</u></strong></p>
                            <p>We make every effort, including periodic reviews, to ensure that the personal information you provide is used in conformity with this Privacy Policy. If you have any concerns about our adherence to this Privacy Policy or the manner in which your personal information is used, kindly write to us through this form. We'll contact you, and if required, coordinate with the appropriate regulatory authorities to effectively address your concerns.</p>

                            <p><strong><u>Notification of changes</u></strong></p>
                            <p>We may modify the Privacy Policy at any time, upon notifying you through a service announcement or by sending an email to your primary email address. If we make significant changes to the Privacy Policy that affect your rights, you will be provided with at least 30 days' advance notice of the changes by email to your primary email address. If you think that the updated Privacy Policy affects your rights with respect to your use of our products or services, you may terminate your use by sending us an email within 30 days. Your continued use after the effective date of changes to the Privacy Policy will be deemed to be your agreement to the modified Privacy Policy. You will not receive email notification of minor changes to the Privacy Policy. If you are concerned about how your personal information is used, you should check back at Our Policies periodically.</p>

                            <p><strong><u>Your acceptance of these terms</u></strong></p>
                            <p>By using this Site, you signify your acceptance of this policy. If you do not agree to this policy, please do not use our Site. Your continued use of the Site following the posting of changes to this policy will be deemed your acceptance of those changes.</p>

                            <h4>Governing Law</h4>
                            <p>The terms and conditions and transactions contemplated hereby shall be governed by and construed and interpreted in accordance with, the state of West Bengal, India, without regard to the choice-of-law principles thereof. The United Nations Convention on the Contracts for the International Sale of Goods shall not apply to any agreement done with Webmaa or its subsidiary or parent company/companies. Any action seeking legal or equitable relief arising out of or relating to these Terms or the Manual will be brought only in the courts of Kolkata, West Bengal, India.</p>


                            <h4>Delivery Policy</h4>
                            <p>We are in the service business and don't have tangible items to deliver. So there is no chance of missing or misplacement of any item. Our deliverables are in the form of designs file, software code &amp; compiled application. These deliverables are delivered to you in the form of electronic files using our collaboration tool or email. Thereafter you have to save a copy of delivered electronic files on your server or on any storage media. Up-to 30 days from delivery date Webmaa can re-deliver the electronic files to you, thereafter Webmaa doesn't owe the responsibility.</p>

                            <h4>Cancellation Policy</h4>
                            <p>All cancellation requests must be submitted to the Project Management Office (PMO) of Webmaa. You may submit your cancellation requests by email. Your request will not be considered valid unless and until you receive confirmation from our Project Management Office.</p>
                            <ul>
                                <li>We take a minimum 2 weeks to process the cancellation request.</li>
                                <li>Cancellation of project is based on service level agreement, as agreed with you while starting the project.</li>
                                <li>Once a project is released for beta delivery, it cannot be canceled.</li>
                                <li>In the event of cancellation of a project mutually decided between you &amp; Webmaa, you have to pay for efforts invested in the project up to date of cancellation.</li>
                                <li>Source Code, Design or any other material related to the project will not be delivered if payment is not settled by you.</li>
                            </ul>
                            <h4>Refund Policy</h4>
                            <p>Webmaa believes in helping its customers as much as possible, and has therefore a considerate cancellation policy. We, as a merchant, provide a service that comes in the form of services. As such, our offering is not a tangible product that can be returned for a refund. It can be used immediately upon receipt; there is no product to return.</p>
                            <ul>
                                <li>Webmaa undertakes all projects under the purview of Milestones that dictate the direction / road-map followed by the project.</li>
                                <li>All Web Design/ Development/ Applications are carried out and accepted upon a complete analysis and creation of a complete scope document, in order to make sure that teams have complete understanding of the work needed to be done, eliminating possibilities of a project cancellation / reversal / dispute.</li>
                                <li>Since Webmaa and the Clientele realize that there is work involved in achieving every milestone of the project and completion of every module, a refund would not be possible for the work already completed.</li>
                                <li>Webmaa makes sure that in case of a project termination on a mutual basis, the client has control of all the work done till that date and the money, if paid for further development, is returned.</li>
                                <li>Webmaa also respects a decision made by a client to work with a different provider within 48 hours of the project initiation. In such a scenario Webmaa would refund the amount for the project that has been paid till date.</li>
                                <li>No cancellations are entertained for those Services that the Webmaa marketing team has offered on special occasions. These are limited occasion offers and therefore cancellations are not possible.</li>
                                <li>Development Services and Consulting &ndash; if the services opted by you are T&amp;M (Time &amp; Material) based services or Hire Dedicated Developer service, these are non-refundable.</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    )
}