import { Link } from "react-router-dom";

export default function Fectiv() {
    return (
        <section className="page-portfolio pt-5">
            <div className="container-xl portfolio-details mt-5">
                <div className="row pt-1 pt-sm-5">
                    <div className="col-lg-10 offset-lg-1 text-center">
                        <h2 className="title mb-5">Fectiv</h2>
                    </div>
                </div>
            </div>
            <div className="container-xl portfolio-details mb-5">
                <img src="../../assets/images/portfolio/fectiv/hero.webp" alt="" className="img-fluid w-100" />
            </div>
            <div className="container-xl portfolio-details mb-5">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="about-project">
                            <div className="row">
                                <div className="col-lg-6 mb-5 mb-lg-0">
                                    <h3>About this project </h3>
                                    <p>For Fectiv, we developed a comprehensive WordPress-based website builder tailored specifically for small businesses, enhancing ease of use and functionality. The platform features an intuitive interface, customizable templates, and powerful design tools, enabling users to create professional websites effortlessly. Our solution ensures a robust, scalable, and engaging web-building experience, driving client satisfaction and growth for Fectiv.</p>
                                </div>
                                <div className="col-lg-6">
                                    <div className="about-project-availability">
                                        <div className="row">
                                            <div className="col-sm-6 mb-4">
                                                <h6>Services</h6>
                                                <ul>
                                                    <li>UI/UX Designing</li>
                                                    <li>Project Management</li>
                                                    <li>Application development</li>
                                                    <li>Mobile app development</li>
                                                    <li>Quality Assurance</li>
                                                </ul>
                                            </div>
                                            <div className="col-sm-6 mb-4">
                                                <h6>Technology </h6>
                                                <ul>
                                                    <li>Figma</li>
                                                    <li>CodeIgniter PHP</li>
                                                    <li>Android</li>
                                                    <li>iOS</li>
                                                    <li>MySQL</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="availability">
                                            <a href="https://fectiv.com/" target="_blank"><img src="../../assets/images/link-web.webp" alt="Website" /></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-xl portfolio-details mb-5">
                <div className="row">
                    <div className="col-lg-10 offset-lg-1">
                        <h3>Meet Fectiv</h3>
                        <p>Fectiv has launched an innovative WordPress-based website builder designed to empower small businesses with the tools needed to create stunning online presences. Founded with a commitment to simplicity and effectiveness, Fectiv offers a range of customizable templates and design options that cater to various business needs and styles.</p>
                        <p>The platform is crafted to provide a user-friendly experience, featuring drag-and-drop functionality, secure hosting, and responsive designs. It’s optimized for both desktop and mobile devices, allowing users to build and manage their websites with ease. Committed to supporting small businesses, Fectiv delivers a solution that simplifies web development while ensuring professional and visually appealing results.</p>
                    </div>
                </div>
            </div>
            <div className="container-xl portfolio-details mb-5">
                <img src="../../assets/images/portfolio/fectiv/image1.webp" alt="" className="img-fluid w-100" />
            </div>
            <div className="container-xl portfolio-details mb-5">
                <div className="row">
                    <div className="col-lg-10 offset-lg-1">
                        <h3>Fectiv’s Growth Story</h3>
                        <p>Fectiv began as a vision to democratize website creation for small businesses, providing them with the tools to establish a strong online presence. By focusing on user-friendly design and robust functionality, Fectiv quickly gained traction among small business owners. The launch of their WordPress-based website builder further expanded their reach, offering an accessible and efficient solution for creating professional websites. Strategic marketing and customer support initiatives have enhanced their brand visibility and user satisfaction. Today, Fectiv stands as a leading website builder for small businesses, continuously growing and setting new standards in the industry, with a dedicated and expanding user base.</p>
                    </div>
                </div>
            </div>
            <div className="container-xl portfolio-details mb-5">
                <img src="../../assets/images/portfolio/fectiv/image2.webp" alt="" className="img-fluid w-100" />
            </div>
            <div className="container-xl portfolio-details mb-5">
                <div className="row">
                    <div className="col-lg-10 offset-lg-1">
                        <h3>What is unique about Fectiv</h3>
                        <p>Fectiv stands out for its unique combination of simplicity and functionality in website creation, offering small businesses a powerful yet easy-to-use platform. Each feature is designed with user experience in mind, providing customizable templates, drag-and-drop design tools, and responsive layouts that cater to various business needs. What truly sets Fectiv apart is its commitment to making professional web development accessible to everyone. The WordPress-based platform ensures a seamless and engaging web-building experience, featuring secure hosting, reliable performance, and continuous support. Additionally, Fectiv maintains a strong connection with its users through dedicated customer support and regular updates, fostering a loyal client base. By continuously enhancing their platform and incorporating user feedback, Fectiv ensures they stay ahead of industry trends while meeting the diverse needs of small businesses. This dedication to innovation and customer satisfaction makes Fectiv a standout choice for website building.</p>
                    </div>
                </div>
            </div>
            <div className="container-xl portfolio-details mb-5">
                <img src="../../assets/images/portfolio/fectiv/image3.webp" alt="" className="img-fluid w-100" />
            </div>
            <div className="container-xl portfolio-details mb-5">
                <div className="row">
                    <div className="col-lg-10 offset-lg-1">
                        <h3>Faced Challenges</h3>
                        <p>The existing web-building tools faced challenges with complexity and lack of customization options, impacting the client’s ability to serve small business needs effectively.</p>
                        <p>Addressing these challenges required simplifying the user interface, enhancing customization features, and ensuring a robust backend for reliable performance. Successfully navigating these tasks demanded a combination of intuitive design, advanced technology integration, and strategic planning. The ability to offer a user-friendly experience, extensive customization, and reliable support played a crucial role in achieving business success.</p>
                    </div>
                </div>
            </div>
            <div className="container-xl portfolio-details mb-5">
                <img src="../../assets/images/portfolio/fectiv/image4.webp" alt="" className="img-fluid w-100" />
            </div>
            <div className="container-xl portfolio-details mb-5">
                <div className="row">
                    <div className="col-lg-10 offset-lg-1">
                        <h3>Project Approach</h3>
                        <p>The project began with discovery, analyzing client needs. Planning set milestones, timelines, and communication. Development optimized features. Testing ensured quality. Successful implementation replaced old versions.</p>
                        <p>The overall approach to the project involved a systematic and comprehensive process. Starting with the discovery phase, the team thoroughly analyzed the client’s requirements, identified pain points, and determined the scope of the project. During the planning stage, they defined the project milestones, created a detailed timeline, and established communication channels for seamless collaboration. In the development phase, the team focused on creating a user-friendly and feature-rich WordPress-based website builder, ensuring optimal performance and usability. Extensive testing and quality assurance measures were implemented to identify and rectify any issues. The project was then implemented, with the new platform seamlessly replacing the previous version.</p>
                        <p className="mb-2">Key Benefits</p>
                        <ul>
                            <li>Simplified website creation with an intuitive, drag-and-drop interface.</li>
                            <li>Enhanced customization options with customizable templates and design tools.</li>
                            <li>Reliable and scalable platform optimized for small business needs.</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="container-xl portfolio-details mb-5">
                <img src="../../assets/images/portfolio/fectiv/image5.webp" alt="" className="img-fluid w-100" />
            </div>
            <div className="container-xl portfolio-details mb-5">
                <div className="row">
                    <div className="col-lg-10 offset-lg-1">
                        <h3>Results</h3>
                        <p>Following the implementation of the project, Fectiv experienced numerous positive outcomes. The new WordPress-based website builder resolved the complexity and customization issues that previously impacted the client, resulting in a powerful and user-friendly platform for small businesses. The enhanced features allowed users to create professional websites easily, improving client satisfaction and engagement. The optimized platform catered to a growing user base, boosting Fectiv’s online presence and market reach. Overall, the project brought significant improvements to the client’s web-building capabilities, ensuring a seamless and effective experience for their users.</p>
                    </div>
                </div>
            </div>
            <div className="container-xl portfolio-details mb-5">
                <div className="row">
                    <div className="col-lg-8 offset-lg-2">
                        <div className="portfolio-quote">
                            <div className="content">
                                Webmaa's adaptability to our evolving needs and the steady stream of insightful feedback and ideas prove they were not just a partner – they were an indispensable asset in helping us reach our vision to democratize the fintech industry.
                            </div>
                            <div className="author">
                                <div className="auth-image">
                                    <img src="../../assets/images/portfolio/fectiv/author.png" alt="author" />
                                </div>
                                <div className="auth-desig">
                                    <h6>User Name</h6>
                                    <p>Designation, Company Name</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-xl portfolio-nav mb-5">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="row">
                            <div className="col-6">
                                <Link to="/portfolio/bengal-beverages" className="d-block pprev">
                                    <span className="fs-14 fw-500 text-black">Previous Project</span> <br />
                                    <span className="fs-13 text-gray d-none d-sm-block">Fast and Simple Website Builder<br /></span>
                                    <span className="fs-15 fw-600 text-secondary">Bengal Beverages</span>
                                </Link>
                            </div>
                            <div className="col-6">
                                <Link to="/portfolio/mohendra-dutt" className="d-block pnext text-end">
                                    <span className="fs-14 fw-500 text-black">Next Project</span> <br />
                                    <span className="fs-13 text-gray d-none d-sm-block">Fast and Simple Website Builder<br /></span>
                                    <span className="fs-15 fw-600 text-secondary">Mohendra Dutt</span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}