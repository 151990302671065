import { Link } from "react-router-dom";
import { Fragment } from "react/jsx-runtime";

export default function DemoMateHcm() {
    return (
        <Fragment>
            <section className="inner-banner matehcm">
                <div className="container-xl zindex6">
                    <div className="row gx-3">
                        <div className="col-lg-8 text-center text-lg-start  align-self-center mb-5 mb-lg-0">
                            <h1 className="mb-3 mb-lg-5 mt-0 mt-sm-5 mt-lg-0">Simplify <span className="text-green">hiring</span>, <span className="text-red">paying</span>, and <span className="text-warning">managing</span> your team in a single solution.</h1>
                            <p className="lead-lg mb-4 mb-sm-5">Mate empowers your team. From automating HR tasks to fostering a vibrant and motivated culture, Mate is your key to transforming a good company into a great one.</p>
                            <div className="banner-content-bottom">
                                <Link to="/contact" className="btn btn-success btn-rounded">Book a Demo</Link>
                            </div>
                        </div>
                        <div className="col-lg-4 align-self-center d-lg-none d-block">
                            <img src="../assets/images/hcm-hero.webp" alt="" className="img-fluid d-block mx-auto matehcm-hero-mob" />
                        </div>
                    </div>
                </div>
                <div className="matehcm-hero-pc d-none d-lg-block">
                    <div className="container-xl">
                        <img src="../assets/images/hcm-hero.webp" alt="" className="img-fluid d-block ms-auto me-0" />
                    </div>
                </div>
            </section>
            <section className="py-5">
                <div className="container-xl my-0 my-lg-4">
                    <div className="row gx-3 mb-5">
                        <div className="col-lg-10 offset-lg-1 text-center">
                            <h3 className="mb-4">What makes Mate HCM better choice ?</h3>
                            <p className="lead">"We provide features that help HR professionals improve employee experiences, simplify operations, and boost company growth."</p>
                        </div>
                    </div>
                    <div className="row gx-3">
                        <div className="col-lg-6 col-md-4 mb-4 mb-md-0 text-center">
                            <img src="../assets/images/demo-mate-hcm-page.webp" alt="" className="img-fluid d-block mx-auto mb-5" />
                            <h5 className="mb-5">Transform your HR with Mate</h5>
                            <Link to="/contact" className="btn btn-lg btn-success">Request demo</Link>
                        </div>
                        <div className="col-lg-6 col-md-8">
                            <div className="mybox hcm-box mb-20">
                                <h5>Simplified Core HR </h5>
                                <p>Forget about boring spreadsheets and inflexible systems for HR tasks. Switch to smarter, more efficient software with features that eliminate administrative work.</p>
                            </div>
                            <div className="mybox hcm-box mb-20">
                                <h5>Mobile first for your go-getter employees</h5>
                                <p>Includes a feature-rich mobile app, giving employees full access to their own data and daily activities including leave & attendance, travel & expenses, culture & recognition, application & approvals etc.</p>
                            </div>
                            <div className="mybox hcm-box mb-20">
                                <h5>Top-notch Security and Privacy</h5>
                                <p>Ensures watertight security through encryption at rest and SOC Type 2 certified cloud data centres.</p>
                            </div>
                            <div className="mybox hcm-box mb-20">
                                <h5>All-In-One Solution</h5>
                                <p>Delivers a unified HR platform that consolidates all your HR data and processes into one seamless system.</p>
                            </div>
                            <div className="mybox hcm-box mb-20">
                                <h5>Fully Scalable</h5>
                                <p>Scalable and adaptable, we cater to the unique needs of customers ranging from small startups to large enterprises.</p>
                            </div>
                            <div className="mybox hcm-box mb-20">
                                <h5>Smooth User Experience</h5>
                                <p>Empower employees, managers, and HR executives to easily handle their HR tasks, even remotely, with a clean, interactive interface that enhances user experience.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="pb-5">
                <div className="container-xl my-0 my-lg-4">
                    <div className="row gx-3">
                        <div className="col-lg-10 offset-lg-1 text-center">
                            <h3 className="mb-4">"All-in-One HCM Platform for Expanding Teams"</h3>
                        </div>
                    </div>
                    <div className="row gx-3">
                        <div className="col-sm-6 mb-20">
                            <div className="mybox hcm-box2">
                                <h5 className="mb-5">Source & attract</h5>
                                <p>In the digital era, Mate enhances your online presence and helps you stand out, reaching candidates wherever they are.</p>
                            </div>
                        </div>
                        <div className="col-sm-6 mb-20">
                            <div className="mybox hcm-box2">
                                <h5 className="mb-5">Evaluate & collaborate</h5>
                                <p>Mate simplifies hiring for any team size. Collaborate seamlessly, assess applicants, avoid bias, and choose the best fit, all in one system.</p>
                            </div>
                        </div>
                        <div className="col-sm-6 mb-20">
                            <div className="mybox hcm-box2">
                                <h5 className="mb-5">Automate & hire</h5>
                                <p>Speed up with Mate's automated platform for admin tasks, from requisition to offer letter, including approvals, reports, compliance, and more.</p>
                            </div>
                        </div>
                        <div className="col-sm-6 mb-20">
                            <div className="mybox hcm-box2">
                                <h5 className="mb-5">Onboard & manage</h5>
                                <p>Efficiently onboard and manage employees with streamlined processes and integrated tools for a seamless experience.</p>
                            </div>
                        </div>
                        <div className="col-sm-6 mb-20">
                            <div className="mybox hcm-box2">
                                <h5 className="mb-5">Time & attendance</h5>
                                <p>Streamline time and attendance management with automated tracking and reporting for efficient workforce oversight and compliance.</p>
                            </div>
                        </div>
                        <div className="col-sm-6 mb-20">
                            <div className="mybox hcm-box2">
                                <h5 className="mb-5">Payroll</h5>
                                <p>A wizard-driven approach and integrated data inputs ensure accurate, automated, and compliant payroll processing.</p>
                            </div>
                        </div>
                        <div className="col-sm-6 mb-20">
                            <div className="mybox hcm-box2">
                                <h5 className="mb-5">Track performance</h5>
                                <p>OKR-based system wherein one can create multiple goals, evaluate them with flexible review cycles, and improve them with insightful MIS.
                                </p>
                            </div>
                        </div>
                        <div className="col-sm-6 mb-20">
                            <div className="mybox hcm-box2">
                                <h5 className="mb-5">Travel & expenses</h5>
                                <p>Simplify travel plans, approval and expenses management with integrated tools for efficient tracking, reporting, and reimbursement across your organization.</p>
                            </div>
                        </div>
                        <div className="col-sm-6 mb-20">
                            <div className="mybox hcm-box2">
                                <h5 className="mb-5">Employees self-service</h5>
                                <p>Open up HR bandwidth by giving easy access to relevant information and data to your employees online through employee self service portal.
                                </p>
                            </div>
                        </div>
                        <div className="col-sm-6 mb-20">
                            <div className="mybox hcm-box2">
                                <h5 className="mb-5">Mobile app</h5>
                                <p>Empower employees with a modern mobile app for effortless HR tasks anywhere, reducing HR workload and enhancing efficiency.</p>
                            </div>
                        </div>
                        <div className="col-sm-6 mb-20">
                            <div className="mybox hcm-box2">
                                <h5 className="mb-5">Rules & policies</h5>
                                <p>Define and enforce company rules and policies seamlessly, ensuring clarity and compliance across all organizational operations and interactions.</p>
                            </div>
                        </div>
                        <div className="col-sm-6 mb-20">
                            <div className="mybox hcm-box2">
                                <h5 className="mb-5">Insights & reporting </h5>
                                <p>Gain valuable insights and generate comprehensive reports to inform strategic decisions and optimize organizational performance effectively.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="pb-5">
                <div className="container-xl">
                    <div className="row gx-3 mb-5">
                        <div className="col-lg-8 offset-lg-2 text-center">
                            <h3 className="mb-3">Transform your HR with personalized HCM software, tailored to your <span className="text-green">business size</span> and <span className="text-warning">industry</span>.</h3>
                            <p className="lead">"With over a decade of experience, we've served businesses of all sizes. Discover how Mate HCM can enhance your workforce."</p>
                        </div>
                    </div>
                    <div className="row gx-3">
                        <div className="col-lg-8">
                            <div className="row gx-3">
                                <div className="col-sm-6 mb-20">
                                    <div className="mybox hcm-box3">
                                        <div className="heading-icon mb-5">
                                            <h5 className="mt-3">Manufacturing</h5>
                                            <img src="../assets/images/icon-hcm1.webp" />
                                        </div>
                                        <p>Facilitates workforce efficiency and organization, prioritizing data privacy and security for critical information.</p>
                                    </div>
                                </div>
                                <div className="col-sm-6 mb-20">
                                    <div className="mybox hcm-box3">
                                        <div className="heading-icon mb-5">
                                            <h5 className="mt-3">Information technology</h5>
                                            <img src="../assets/images/icon-hcm2.webp" />
                                        </div>
                                        <p>Leverage insights and reports for strategic decisions, optimizing organizational performance efficiently</p>
                                    </div>
                                </div>
                                <div className="col-sm-6 mb-20">
                                    <div className="mybox hcm-box3">
                                        <div className="heading-icon mb-5">
                                            <h5 className="mt-3">Healthcare</h5>
                                            <img src="../assets/images/icon-hcm3.webp" />
                                        </div>
                                        <p>Leverage insights and reports for strategic decisions, optimizing organizational performance efficiently</p>
                                    </div>
                                </div>
                                <div className="col-sm-6 mb-20">
                                    <div className="mybox hcm-box3">
                                        <div className="heading-icon mb-5">
                                            <h5 className="mt-3">Retail</h5>
                                            <img src="../assets/images/icon-hcm4.webp" />
                                        </div>
                                        <p>Supports an efficient, organized workforce, ensuring the privacy and security of critical data at all times.</p>
                                    </div>
                                </div>
                                <div className="col-sm-6 mb-20">
                                    <div className="mybox hcm-box3">
                                        <div className="heading-icon mb-5">
                                            <h5 className="mt-3">Finance</h5>
                                            <img src="../assets/images/icon-hcm5.webp" />
                                        </div>
                                        <p>Empowers BFSIs to streamline workforce capabilities and securely organize crucial data.</p>
                                    </div>
                                </div>
                                <div className="col-sm-6 mb-20">
                                    <div className="mybox hcm-box3">
                                        <div className="heading-icon mb-5">
                                            <h5 className="mt-3">Automotive</h5>
                                            <img src="../assets/images/icon-hcm6.webp" />
                                        </div>
                                        <p>Simplifies and manages HR operations for the automotive industry to streamline its processes and improve overall efficiency.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="row gx-3">
                                <div className="col-md-6 order-2 order-lg-1 col-lg-12 mb-20">
                                    <div className="mybox hcm-ad">
                                        <div className="content">
                                            <h5>Your One-Stop HCM Platform from Hire to Retire</h5>
                                            <Link to="/contact" className="btn btn-rounded btn-dark">Book a demo <i className="la la-arrow-right"></i></Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 order-1 order-lg-2 col-lg-12 mb-20">
                                    <div className="mybox hcm-box3 h-auto">
                                        <div className="heading-icon mb-5">
                                            <h5 className="mt-3">Education</h5>
                                            <img src="../assets/images/icon-hcm7.webp" />
                                        </div>
                                        <p>Streamlines everyday operations like payroll and attendance relating to workforce management.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="pb-5">
                <div className="container-xl">
                    <div className="box-book-demo">
                        <div className="circle-top"></div>
                        <div className="circle-bottom"></div>
                        <div className="row gx-3">
                            <div className="col-lg-8 text-center text-lg-start mb-4 mb-lg-0 align-items-center">
                                <div id="content-book-demo">
                                    <h3 className="mb-2 w-400">Stop wasting time</h3><h2 className="mb-3 mb-sm-2 fw-600">Youre just steps away from easy and automated payroll.</h2><p className="lead-lg fw-500 m-0 ">"Reduce your HR teams workload and boost employee productivity with Mate, which automates all essential operations."</p>
                                </div>
                            </div>
                            <div className="col-lg-4 text-center align-content-end text-lg-end">
                                <Link to="/contact" className="btn btn-light btn-lg btn-rounded">Book a Demo</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    )
}