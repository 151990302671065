import { Fragment } from "react/jsx-runtime";

export default function Sitemap() {
    return (
        <Fragment>
            <section className="inner-banner banner-slick sitemap">
                <div className="container-xl">
                    <div className="row">
                        <div className="col-lg-8 offset-lg-2 text-center">
                            <h6 className="title">Sitemap</h6>
                            <h4 className="mb-4 mb-sm-5"><span className="text-red">Explore</span> the structure of our <span className="text-green">website </span> through the <span className="text-blue">links</span> below.</h4>
                        </div>
                    </div>
                </div>
            </section>
            <section className="py-5 d-none">
                <div className="container-xl">
                    <div className="mb-4">
                        <h1 className="text-center">Sitemap</h1>
                        <p className="text-center"> the    </p>
                    </div>
                    <div className="row sitemap-section ">
                        <div className="col-md-4">
                            <h2>Main Pages</h2>
                            <ul className="list-unstyled">
                                <li><a href="#" className="text-decoration-none">Home</a></li>
                                <li><a href="#" className="text-decoration-none">About Us</a></li>
                                <li><a href="#" className="text-decoration-none">Services</a></li>
                                <li><a href="#" className="text-decoration-none">Contact</a></li>
                            </ul>
                        </div>
                        <div className="col-md-4">
                            <h2>Products</h2>
                            <ul className="list-unstyled">
                                <li><a href="#" className="text-decoration-none">Product 1</a></li>
                                <li><a href="#" className="text-decoration-none">Product 2</a></li>
                                <li><a href="#" className="text-decoration-none">Product 3</a></li>
                                <li><a href="#" className="text-decoration-none">Product 4</a></li>
                            </ul>
                        </div>
                        <div className="col-md-4">
                            <h2>Blog</h2>
                            <ul className="list-unstyled">
                                <li><a href="#" className="text-decoration-none">Latest Posts</a></li>
                                <li><a href="#" className="text-decoration-none">Categories</a></li>
                                <li><a href="#" className="text-decoration-none">Archives</a></li>
                                <li><a href="#" className="text-decoration-none">Tags</a></li>
                            </ul>
                        </div>
                    </div>

                    <div className="row sitemap-section">
                        <div className="col-md-4">
                            <h2>Support</h2>
                            <ul className="list-unstyled">
                                <li><a href="#" className="text-decoration-none">FAQ</a></li>
                                <li><a href="#" className="text-decoration-none">Help Center</a></li>
                                <li><a href="#" className="text-decoration-none">Privacy Policy</a></li>
                                <li><a href="#" className="text-decoration-none">Terms of Service</a></li>
                            </ul>
                        </div>
                        <div className="col-md-4">
                            <h2>Account</h2>
                            <ul className="list-unstyled">
                                <li><a href="#" className="text-decoration-none">Login</a></li>
                                <li><a href="#" className="text-decoration-none">Register</a></li>
                                <li><a href="#" className="text-decoration-none">Profile</a></li>
                                <li><a href="#" className="text-decoration-none">Settings</a></li>
                            </ul>
                        </div>
                        <div className="col-md-4">
                            <h2>Social Media</h2>
                            <ul className="list-unstyled">
                                <li><a href="#" className="text-decoration-none">Facebook</a></li>
                                <li><a href="#" className="text-decoration-none">Twitter</a></li>
                                <li><a href="#" className="text-decoration-none">Instagram</a></li>
                                <li><a href="#" className="text-decoration-none">LinkedIn</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </Fragment >
    )
}