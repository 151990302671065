import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Fragment } from "react/jsx-runtime";

const initialContactState = {
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    message: "",
    services: "",
    budget: "",
    start_in: "",
    requirement: ""
};

const ContactForm = () => {
    const [contactFormInputs, setContactFormInputs] = useState(initialContactState);
    const [contactFile, setContactFile] = useState<File | string>("");
    const [disableSubmitButton, setDisableSubmitButton] = useState<boolean>(false);

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            const file = event.target.files[0];
            // console.log(file);
            setContactFile(file);
        }
    };
    const submitContactUs = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (event.currentTarget.checkValidity()) {
            let formData = new FormData();
            (Object.keys(contactFormInputs) as Array<keyof typeof contactFormInputs>).forEach(key => {
                let value = contactFormInputs[key];
                formData.append(key, value);
            });
            formData.append("file", contactFile);
            // console.log(formData);
            setDisableSubmitButton(true);
            fetch(`${process.env.REACT_APP_API_URL}contact`, {
                method: 'POST',
                body: formData
            })
                .then(res => res.json())
                .then(response => {
                    // console.log(response);
                    toast.success(response.message, {
                        position: "bottom-center",
                        theme: "dark"
                    });
                    setContactFormInputs(initialContactState);
                    setDisableSubmitButton(false);
                })
                .catch(error => {
                    toast.error(error.message, {
                        position: "bottom-center",
                        theme: "dark"
                    });
                    setDisableSubmitButton(false);
                });
        } else {
            event.currentTarget.reportValidity();
        }
    }
    useEffect(() => {
        ($('.owl_testimonial_logo_sm') as any).owlCarousel();
        ($('.owl_testimonial') as any).owlCarousel({
            items: 1
        });
    }, []);
    return (
        <Fragment>
            <section className="py-5">
                <div className="container-xl my-3 my-lg-4">
                    <div className="row gx-3">
                        <div className="col-lg-6 mb-4 mb-lg-0">
                            <div className="p-0 w-100 pe-lg-5">
                                <h6 className="title">say hello</h6>
                                <h3 className="title fw-500">We’d love to hear from you. Get custom solutions, resumes, recommendations, or estimates with confidentiality and guaranteed same-day response!</h3>
                                <p className="fs-18">Before we start, we would like to better understand your needs. We’ll review your application and schedule a free estimation call.</p>
                                <p className="fs-18 mb-5">Please fill in the form or mail us your requirements to <a href="mailto:info@webmaa.com">info@webmaa.com</a></p>

                                <div className="owl-carousel owl-theme owl_testimonial mb-4">
                                    <div className="item">
                                        <p>Webmaa (Sketch) has been a fantastic company to deal with. When I first decided I needed a website for my business I didn’t have much of an idea of what I wanted, team is completely provide guidance with their experience. </p>
                                        <h6><b>Emad Ayyad</b> | Director of Shiftling</h6>
                                    </div>
                                    <div className="item">
                                        <p>Thank you so much for developing a mobile application for us. Excellent performance of your professionals makes you exceptional! All the team members are very co operative & helpful.</p>
                                        <h6><b>Akbar</b> | Founder of Grape International</h6>
                                    </div>
                                </div>
                                <div className="owl-carousel owl-theme owl_testimonial_logo_sm">
                                    <div className="item"><img className="img-fluid" src="../assets/images/testi-logo1.webp" alt="" title="Pailan" /></div>
                                    <div className="item"><img className="img-fluid" src="../assets/images/testi-logo2.webp" alt="" title="Coca-Cola" /></div>
                                    <div className="item"><img className="img-fluid" src="../assets/images/testi-logo3.webp" alt="" title="Biswa Bangla" /></div>
                                    <div className="item"><img className="img-fluid" src="../assets/images/testi-logo4.webp" alt="" title="Wiseasy" /></div>
                                    <div className="item"><img className="img-fluid" src="../assets/images/testi-logo5.webp" alt="" title="DSGN ST." /></div>
                                    <div className="item"><img className="img-fluid" src="../assets/images/testi-logo6.webp" alt="" title="Govt of India" /></div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <form className="contact-form" onSubmit={submitContactUs} noValidate>
                                <label>Name</label>
                                <div className="row gx-3">
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <input
                                                className="form-control"
                                                placeholder="First Name"
                                                type="text"
                                                value={contactFormInputs.first_name}
                                                onChange={event => setContactFormInputs(prevState => {
                                                    return {
                                                        ...prevState,
                                                        first_name: event.target.value
                                                    }
                                                })}
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <input
                                                className="form-control"
                                                placeholder="Last Name"
                                                type="text"
                                                value={contactFormInputs.last_name}
                                                onChange={event => setContactFormInputs(prevState => {
                                                    return {
                                                        ...prevState,
                                                        last_name: event.target.value
                                                    }
                                                })}
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="form-group">
                                    <label>Email Id</label>
                                    <input
                                        className="form-control"
                                        placeholder="Email Id"
                                        type="email"
                                        value={contactFormInputs.email}
                                        onChange={event => setContactFormInputs(prevState => {
                                            return {
                                                ...prevState,
                                                email: event.target.value
                                            }
                                        })}
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Mobile phone</label>
                                    <input
                                        className="form-control"
                                        placeholder="Mobile Number"
                                        type="number"
                                        value={contactFormInputs.phone}
                                        onChange={event => setContactFormInputs(prevState => {
                                            return {
                                                ...prevState,
                                                phone: event.target.value
                                            }
                                        })}
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Select Services</label>
                                    <select
                                        className="form-select"
                                        value={contactFormInputs.services}
                                        onChange={event => setContactFormInputs(prevState => {
                                            return {
                                                ...prevState,
                                                services: event.target.value
                                            }
                                        })}
                                        required
                                    >
                                        <option>Select</option>
                                        <option value={"Brand Design"}>Brand Design</option>
                                        <option value={"Website Design"}>Website Design</option>
                                        <option value={"UI/UX Design"}>UI/UX Design</option>
                                        <option value={"Product Photography"}>Product Photography</option>
                                        <option value={"Illustration Design"}>Illustration Design</option>
                                        <option value={"Web application development"}>Web application development</option>
                                        <option value={"Full Stack Development"}>Full Stack Development</option>
                                        <option value={"Backend Development"}>Backend Development</option>
                                        <option value={"Wordpress Development"}>Wordpress Development</option>
                                        <option value={"Mobile App Development"}>Mobile App Development</option>
                                        <option value={"Software Development"}>Software Development</option>
                                        <option value={"E-Commerce Website"}>E-Commerce Website</option>
                                        <option value={"Website Speed Optimization"}>Website Speed Optimization</option>
                                        <option value={"Dedicated Team Hiring"}>Dedicated Team Hiring</option>
                                        <option value={"Data Mining/Processing"}>Data Mining/Processing</option>
                                        <option value={"Gen AI Development"}>Gen AI Development</option>
                                        <option value={"API Development"}>API Development</option>
                                        <option value={"Portal Development"}>Portal Development</option>
                                        <option value={"MVP Development"}>MVP Development</option>
                                        <option value={"Market Research"}>Market Research</option>
                                        <option value={"Social Media Promotion"}>Social Media Promotion</option>
                                        <option value={"Search Engine Optimization"}>Search Engine Optimization</option>
                                        <option value={"Digital Marketing"}>Digital Marketing</option>
                                        <option value={"Project Management"}>Project Management</option>
                                        <option value={"Quality Management"}>Quality Management</option>
                                        <option value={"DevOps"}>DevOps</option>
                                        <option value={"Cybersecurity"}>Cybersecurity</option>
                                        <option value={"Others"}>Others</option>
                                    </select>
                                </div>
                                <div className="row gx-3">
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label>Budget</label>
                                            <select
                                                className="form-select"
                                                value={contactFormInputs.budget}
                                                onChange={event => setContactFormInputs(prevState => {
                                                    return {
                                                        ...prevState,
                                                        budget: event.target.value
                                                    }
                                                })}
                                                required
                                            >
                                                <option>Select</option>
                                                <option value={"Below $1000"}>Below $1000</option>
                                                <option value={"$1000 - $5000"}>$1000 - $5000</option>
                                                <option value={"$5000 - $10000"}>$5000 - $10000</option>
                                                <option value={"$10000 - $25000"}>$10000 - $25000</option>
                                                <option value={"$25000 - $40000"}>$25000 - $40000</option>
                                                <option value={"More then $40000"}>More then $40000</option>
                                                <option value={"Not know"}>Not know</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label>How soon you want to start</label>
                                            <select
                                                className="form-select"
                                                value={contactFormInputs.start_in}
                                                onChange={event => setContactFormInputs(prevState => {
                                                    return {
                                                        ...prevState,
                                                        start_in: event.target.value
                                                    }
                                                })}
                                            >
                                                <option>Select</option>
                                                <option value={"Immediately"}>Immediately</option>
                                                <option value={"Within a month"}>Within a month</option>
                                                <option value={"Within next 3 months"}>Within next 3 months</option>
                                                <option value={"Not decided yet"}>Not decided yet</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="form-group">
                                    <label>Requirement</label>
                                    <select className="form-select">
                                        <option>Select</option>
                                    </select>
                                </div> */}
                                <div className="form-group">
                                    <label>Brief about the project</label>
                                    <textarea
                                        className="form-control"
                                        rows={4}
                                        onChange={event => setContactFormInputs(prevState => {
                                            return {
                                                ...prevState,
                                                message: event.target.value
                                            }
                                        })}
                                        required
                                    >{contactFormInputs.message}</textarea>
                                </div>
                                <div className="form-group">
                                    <label>Have a file to share</label>
                                    <input className="d-none" id="file" type="file" onChange={handleFileChange} multiple={false} />
                                    <label className="input form-control mb-0 mt-1 py-2" htmlFor="file">Select or drop your file here</label>
                                    <p className="m-0 fs-11 mt-1">Only Document, Image or ZIP file. <small>Max size 8MB</small></p>
                                </div>
                                <div className="form-group m-0 d-md-flex align-items-center">
                                    <button type="submit" className="btn btn-primary nowrap me-3 mb-3 mb-md-0" disabled={disableSubmitButton}>Let&rsquo;s connect</button>
                                    <p className="m-0 fs-11">This site is protected by reCAPTCHA and the Google Privacy Policy and Terms of Service apply.</p>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    )
}

export default ContactForm;