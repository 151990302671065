import { Fragment } from 'react';
import './App.css';
import { Router } from './react-router/router';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <Fragment>
      <Router />
      <ToastContainer />
    </Fragment>
  );
}

export default App;
