import { Link } from "react-router-dom";

export default function Wiseasy() {
    return (
        <section className="page-portfolio pt-5">
            <div className="container-xl portfolio-details mt-5">
                <div className="row pt-1 pt-sm-5">
                    <div className="col-lg-10 offset-lg-1 text-center">
                        <h2 className="title mb-5">Wiseasy</h2>
                    </div>
                </div>
            </div>
            <div className="container-xl portfolio-details mb-5">
                <img src="../../assets/images/portfolio/wiseasy/hero.webp" alt="" className="img-fluid w-100" />
            </div>
            <div className="container-xl portfolio-details mb-5">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="about-project">
                            <div className="row">
                                <div className="col-lg-6 mb-5 mb-lg-0">
                                    <h3>About this project </h3>
                                    <p>For Wiseasy, a leading POS manufacturer in China, we developed a cutting-edge website using custom PHP and Lit HTML. The new platform showcases their advanced POS solutions with an intuitive interface, comprehensive product information, and seamless navigation. Our solution ensures a robust, scalable, and engaging online presence, driving customer satisfaction and business growth for Wiseasy.</p>
                                </div>
                                <div className="col-lg-6">
                                    <div className="about-project-availability">
                                        <div className="row">
                                            <div className="col-sm-6 mb-4">
                                                <h6>Services</h6>
                                                <ul>
                                                    <li>UI/UX Designing</li>
                                                    <li>Project Management</li>
                                                    <li>Application development</li>
                                                    <li>Mobile app development</li>
                                                    <li>Quality Assurance</li>
                                                </ul>
                                            </div>
                                            <div className="col-sm-6 mb-4">
                                                <h6>Technology </h6>
                                                <ul>
                                                    <li>Figma</li>
                                                    <li>CodeIgniter PHP</li>
                                                    <li>Android</li>
                                                    <li>iOS</li>
                                                    <li>MySQL</li>
                                                </ul>
                                            </div>
                                        </div>

                                        <div className="availability">
                                            <a href="https://www.wiseasy.com/" target="_blank"><img src="../../assets/images/link-web.webp" alt="Website" /></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-xl portfolio-details mb-5">
                <div className="row">
                    <div className="col-lg-10 offset-lg-1">
                        <h3>Meet Wiseasy</h3>
                        <p>Wiseasy has launched a state-of-the-art website to highlight its role as a premier POS manufacturer in China. With a commitment to innovation and quality, Wiseasy offers a range of advanced Point of Sale (POS) solutions designed to meet the diverse needs of businesses.</p>
                        <p>The website, built on custom PHP and Lit HTML, features a modern design, detailed product catalogs, and easy-to-use navigation. It provides comprehensive information about Wiseasy’s POS systems, ensuring potential clients have access to the latest technological advancements and solutions. By focusing on user experience and functionality, Wiseasy aims to enhance its online presence and support its global customer base.</p>
                    </div>
                </div>
            </div>
            <div className="container-xl portfolio-details mb-5">
                <img src="../../assets/images/portfolio/wiseasy/image1.webp" alt="" className="img-fluid w-100" />
            </div>
            <div className="container-xl portfolio-details mb-5">
                <div className="row">
                    <div className="col-lg-10 offset-lg-1">
                        <h3>Wiseasy’s  Growth Story</h3>
                        <p>Wiseasy started with a vision to revolutionize POS technology with cutting-edge solutions. As their business grew, the need for a robust online platform became evident. The development of their custom PHP and Lit HTML website marked a significant milestone, enabling Wiseasy to present its products effectively and reach a wider audience. Strategic marketing and a focus on customer engagement have further boosted their visibility and client base. Today, Wiseasy stands out as a leading POS manufacturer, continually innovating and expanding its market presence with a strong digital footprint.</p>
                    </div>
                </div>
            </div>
            <div className="container-xl portfolio-details mb-5">
                <img src="../../assets/images/portfolio/wiseasy/image2.webp" alt="" className="img-fluid w-100" />
            </div>
            <div className="container-xl portfolio-details mb-5">
                <div className="row">
                    <div className="col-lg-10 offset-lg-1">
                        <h3>What is unique about Wiseasy</h3>
                        <p>Wiseasy stands out for its advanced POS technology and commitment to innovation. The website’s use of custom PHP and Lit HTML delivers a unique combination of performance and aesthetics, offering a seamless browsing experience. Each feature is designed to showcase Wiseasy’s high-quality POS solutions effectively, with detailed product information and intuitive navigation. What truly sets Wiseasy apart is its dedication to technological advancement and customer satisfaction. The platform’s modern design and robust functionality reflect Wiseasy’s position as a leader in the POS industry, providing clients with a reliable and engaging online experience. This focus on quality and innovation makes Wiseasy a standout choice for POS solutions.</p>
                    </div>
                </div>
            </div>
            <div className="container-xl portfolio-details mb-5">
                <img src="../../assets/images/portfolio/wiseasy/image3.webp" alt="" className="img-fluid w-100" />
            </div>
            <div className="container-xl portfolio-details mb-5">
                <div className="row">
                    <div className="col-lg-10 offset-lg-1">
                        <h3>Faced Challenges</h3>
                        <p>The previous website faced challenges with outdated technology and limited functionality, impacting the client’s ability to effectively showcase their POS products and engage with potential customers.</p>
                        <p>Addressing these challenges required a complete redesign using custom PHP and Lit HTML to enhance performance, user interface design, and product presentation. Successfully navigating these tasks involved integrating modern web technologies, optimizing site performance, and ensuring a user-friendly experience. The ability to deliver accurate product information, a visually appealing design, and seamless navigation was crucial for achieving business success.</p>
                    </div>
                </div>
            </div>
            <div className="container-xl portfolio-details mb-5">
                <img src="../../assets/images/portfolio/wiseasy/image4.webp" alt="" className="img-fluid w-100" />
            </div>
            <div className="container-xl portfolio-details mb-5">
                <div className="row">
                    <div className="col-lg-10 offset-lg-1">
                        <h3>Project Approach</h3>
                        <p>The project began with discovery, analyzing client needs and existing website limitations. Planning set milestones, timelines, and communication. Development focused on integrating custom PHP and Lit HTML. Testing ensured quality. Successful implementation launched the updated website.</p>
                        <p>The overall approach to the project involved a comprehensive process. Starting with the discovery phase, the team assessed the client’s requirements, identified existing issues, and defined project goals. During the planning stage, milestones and timelines were established, and communication channels were set up for effective collaboration. In the development phase, the focus was on building a modern, high-performance website using custom PHP and Lit HTML, addressing all identified challenges. Extensive testing and quality assurance measures were implemented to ensure a flawless user experience. The project was then implemented, with the new website successfully launching to users.</p>
                        <p className="mb-2">Key Benefits</p>
                        <ul>
                            <li>Enhanced performance and design using custom PHP and Lit HTML.</li>
                            <li>Improved user experience with modern interface and navigation.</li>
                            <li>Effective showcase of POS products and solutions.</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="container-xl portfolio-details mb-5">
                <img src="../../assets/images/portfolio/wiseasy/image5.webp" alt="" className="img-fluid w-100" />
            </div>
            <div className="container-xl portfolio-details mb-5">
                <div className="row">
                    <div className="col-lg-10 offset-lg-1">
                        <h3>Results</h3>
                        <p>Following the implementation of the project, Wiseasy experienced significant improvements. The new website, built with custom PHP and Lit HTML, resolved previous issues with outdated technology, providing a robust and engaging platform for showcasing their POS solutions. The enhanced features and modern design resulted in a more effective presentation of products, increased user satisfaction, and a broader reach. The optimized site successfully attracted more clients and bolstered Wiseasy’s position as a leading POS manufacturer. Overall, the project delivered substantial advancements in website functionality and user experience, supporting Wiseasy’s business growth and market presence.</p>
                    </div>
                </div>
            </div>
            <div className="container-xl portfolio-details mb-5">
                <div className="row">
                    <div className="col-lg-8 offset-lg-2">
                        <div className="portfolio-quote">
                            <div className="content">
                                Webmaa's adaptability to our evolving needs and the steady stream of insightful feedback and ideas prove they were not just a partner – they were an indispensable asset in helping us reach our vision to democratize the fintech industry.
                            </div>
                            <div className="author">
                                <div className="auth-image">
                                    <img src="../../assets/images/portfolio/wiseasy/author.png" alt="author" />
                                </div>
                                <div className="auth-desig">
                                    <h6>User Name</h6>
                                    <p>Designation, Company Name</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-xl portfolio-nav mb-5">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="row">
                            <div className="col-6">
                                <Link to="/portfolio/around-me" className="d-block pprev">
                                    <span className="fs-14 fw-500 text-black">Previous Project</span> <br />
                                    <span className="fs-13 text-gray d-none d-sm-block">Fast and Simple Website Builder<br /></span>
                                    <span className="fs-15 fw-600 text-secondary">AroundMe</span>
                                </Link>
                            </div>
                            <div className="col-6">
                                <Link to="/portfolio/reason-clothing" className="d-block pnext text-end">
                                    <span className="fs-14 fw-500 text-black">Next Project</span> <br />
                                    <span className="fs-13 text-gray d-none d-sm-block">Fast and Simple Website Builder<br /></span>
                                    <span className="fs-15 fw-600 text-secondary">Reason Clothing</span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}