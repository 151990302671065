import { useParams } from "react-router";
import DemoCartface from "./demo-cartface";
import DemoMateHcm from "./demo-mate-hcm";
import DemoMatrix from "./demo-matrix";
import DemoOtt from "./demo-ott";

export default function SolutionDetails() {
    const { type } = useParams();
    if (type === 'demo-cartface')
        return <DemoCartface />
    else if (type === "demo-mate-hcm")
        return <DemoMateHcm />
    else if (type === "demo-matrix")
        return <DemoMatrix />
    else if (type === "demo-ott")
        return <DemoOtt />
    else
        return <p>No data found</p>
}