import { Link } from "react-router-dom";

export default function MelgioVanni() {
    return (
        <section className="page-portfolio pt-5">
            <div className="container-xl portfolio-details mt-5">
                <div className="row pt-1 pt-sm-5">
                    <div className="col-lg-10 offset-lg-1 text-center">
                        <h2 className="title mb-5">Melgio Vanni</h2>
                    </div>
                </div>
            </div>
            <div className="container-xl portfolio-details mb-5">
                <img src="../../assets/images/portfolio/melgio-vanni/hero.webp" alt="" className="img-fluid w-100" />
            </div>
            <div className="container-xl portfolio-details mb-5">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="about-project">
                            <div className="row">
                                <div className="col-lg-6 mb-5 mb-lg-0">
                                    <h3>About this project </h3>
                                    <p>For MelgioVanni, we developed a comprehensive WordPress-based portfolio management site, enhancing user experience and streamlining image gallery management. The platform features an intuitive interface, secure access controls, and seamless navigation, showcasing their diverse portfolio of images. Our solution ensures a robust, scalable, and engaging online experience, driving client satisfaction and showcasing MelgioVanni’s artistic excellence.</p>
                                </div>
                                <div className="col-lg-6">
                                    <div className="about-project-availability">
                                        <div className="row">
                                            <div className="col-sm-6 mb-4">
                                                <h6>Services</h6>
                                                <ul>
                                                    <li>UI/UX Designing</li>
                                                    <li>Project Management</li>
                                                    <li>Application development</li>
                                                    <li>Mobile app development</li>
                                                    <li>Quality Assurance</li>
                                                </ul>
                                            </div>
                                            <div className="col-sm-6 mb-4">
                                                <h6>Technology </h6>
                                                <ul>
                                                    <li>Figma</li>
                                                    <li>CodeIgniter PHP</li>
                                                    <li>Android</li>
                                                    <li>iOS</li>
                                                    <li>MySQL</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="availability">
                                            <a href="http://www.melgiovanni.com//" target="_blank"><img src="../../assets/images/link-web.webp" alt="Website" /></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-xl portfolio-details mb-5">
                <div className="row">
                    <div className="col-lg-10 offset-lg-1">
                        <h3>Meet MelgioVanni</h3>
                        <p>MelgioVanni has created an innovative WordPress-based platform to showcase its impressive portfolio of images directly to clients and art enthusiasts. Founded by a visionary team with a passion for art and design, MelgioVanni offers a curated collection of works that highlight their creative prowess and unique style.</p>
                        <p>The online gallery is designed to provide a seamless viewing experience, featuring intuitive navigation, secure access controls, and organized image galleries. The platform is optimized for both desktop and mobile devices, allowing users to explore and enjoy MelgioVanni’s artistic creations with ease. Committed to quality and client satisfaction, MelgioVanni continues to set the standard in online portfolio management.</p>
                    </div>
                </div>
            </div>
            <div className="container-xl portfolio-details mb-5">
                <img src="../../assets/images/portfolio/melgio-vanni/image1.webp" alt="" className="img-fluid w-100" />
            </div>
            <div className="container-xl portfolio-details mb-5">
                <div className="row">
                    <div className="col-lg-10 offset-lg-1">
                        <h3>MelgioVanni’s  Growth Story</h3>
                        <p>MelgioVanni began as a creative project with a vision to present their artistic work to a broader audience. Through dedication to artistry and a keen understanding of their audience’s needs, they quickly established a strong online presence. Launching their innovative WordPress-based portfolio site expanded their reach, offering a seamless and engaging way to showcase their work. Strategic marketing and client engagement initiatives further enhanced their visibility and reputation. Today, MelgioVanni stands as a leading platform for showcasing artistic portfolios, continuously growing and setting new standards in the art and design industry.</p>
                    </div>
                </div>
            </div>
            <div className="container-xl portfolio-details mb-5">
                <img src="../../assets/images/portfolio/melgio-vanni/image2.webp" alt="" className="img-fluid w-100" />
            </div>
            <div className="container-xl portfolio-details mb-5">
                <div className="row">
                    <div className="col-lg-10 offset-lg-1">
                        <h3>What is unique about MelgioVanni</h3>
                        <p>MelgioVanni stands out for its unique blend of artistic vision and portfolio presentation, offering a distinctive experience that highlights the beauty and creativity of their work. Each image is presented with meticulous attention to detail, ensuring high-quality display and an immersive experience. What truly sets MelgioVanni apart is their commitment to innovation in portfolio management. Their WordPress-based site provides a seamless, user-friendly gallery experience, featuring secure access controls, organized image galleries, and a responsive design. Additionally, MelgioVanni maintains a strong connection with its audience through strategic marketing and client engagement initiatives, fostering a loyal following. By continuously evolving their portfolio and incorporating feedback, MelgioVanni ensures they stay ahead of trends while meeting the diverse needs of their audience. This dedication to quality, innovation, and presentation makes MelgioVanni a standout in the art and design industry.</p>
                    </div>
                </div>
            </div>
            <div className="container-xl portfolio-details mb-5">
                <img src="../../assets/images/portfolio/melgio-vanni/image3.webp" alt="" className="img-fluid w-100" />
            </div>
            <div className="container-xl portfolio-details mb-5">
                <div className="row">
                    <div className="col-lg-10 offset-lg-1">
                        <h3>Faced Challenges</h3>
                        <p>The existing portfolio management system struggled with slow performance and limited scalability, causing issues for the client.</p>
                        <p>Addressing these challenges required implementing robust data management systems, optimizing gallery presentation, and enhancing user interface design. Successfully navigating these tasks demanded a combination of technology integration, design enhancements, and strategic planning. The ability to efficiently handle image management, provide a smooth user experience, and ensure scalability played a crucial role in achieving project success.</p>
                    </div>
                </div>
            </div>
            <div className="container-xl portfolio-details mb-5">
                <img src="../../assets/images/portfolio/melgio-vanni/image4.webp" alt="" className="img-fluid w-100" />
            </div>
            <div className="container-xl portfolio-details mb-5">
                <div className="row">
                    <div className="col-lg-10 offset-lg-1">
                        <h3>Project Approach</h3>
                        <p>The project began with discovery, analyzing client needs. Planning set milestones, timelines, and communication. Development optimized performance. Testing ensured quality. Successful implementation replaced old versions.</p>
                        <p>The overall approach to the project involved a systematic and comprehensive process. Starting with the discovery phase, the team thoroughly analyzed the client’s requirements, identified pain points, and determined the scope of the project. During the planning stage, they defined the project milestones, created a detailed timeline, and established communication channels for seamless collaboration. In the development phase, the team focused on front-end and back-end development, ensuring optimal performance and user experience. Extensive testing and quality assurance measures were implemented to identify and rectify any issues. The project was then implemented, with the new website seamlessly replacing the previous version.</p>
                        <p className="mb-2">Key Benefits</p>
                        <ul>
                            <li>Streamlined image management and gallery presentation.</li>
                            <li>Improved user experience through a visually appealing design and intuitive navigation.</li>
                            <li>Enhanced website performance and scalability.</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="container-xl portfolio-details mb-5">
                <img src="../../assets/images/portfolio/melgio-vanni/image5.webp" alt="" className="img-fluid w-100" />
            </div>
            <div className="container-xl portfolio-details mb-5">
                <div className="row">
                    <div className="col-lg-10 offset-lg-1">
                        <h3>Results</h3>
                        <p>Following the implementation of the project, MelgioVanni experienced numerous positive outcomes. The new WordPress-based site resolved the performance issues that previously plagued the client, resulting in a seamless and reliable platform for showcasing their portfolio. The enhanced features allowed for better management of images, improved gallery presentation, and increased user engagement. The optimized website catered to a growing audience, enhancing MelgioVanni’s online presence and client satisfaction. Overall, the project brought significant improvements to the client’s portfolio management, ensuring a smooth and engaging experience for their users.</p>
                    </div>
                </div>
            </div>
            <div className="container-xl portfolio-details mb-5">
                <div className="row">
                    <div className="col-lg-8 offset-lg-2">
                        <div className="portfolio-quote">
                            <div className="content">
                                Webmaa's adaptability to our evolving needs and the steady stream of insightful feedback and ideas prove they were not just a partner – they were an indispensable asset in helping us reach our vision to democratize the fintech industry.
                            </div>
                            <div className="author">
                                <div className="auth-image">
                                    <img src="../../assets/images/portfolio/melgio-vanni/author.png" alt="author" />
                                </div>
                                <div className="auth-desig">
                                    <h6>User Name</h6>
                                    <p>Designation, Company Name</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-xl portfolio-nav mb-5">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="row">
                            <div className="col-6">
                                <Link to="/portfolio/liquor" className="d-block pprev">
                                    <span className="fs-14 fw-500 text-black">Previous Project</span> <br />
                                    <span className="fs-13 text-gray d-none d-sm-block">Fast and Simple Website Builder<br /></span>
                                    <span className="fs-15 fw-600 text-secondary">Liquor.com</span>
                                </Link>
                            </div>
                            <div className="col-6">
                                <Link to="/portfolio/crafto-leather" className="d-block pnext text-end">
                                    <span className="fs-14 fw-500 text-black">Next Project</span> <br />
                                    <span className="fs-13 text-gray d-none d-sm-block">Fast and Simple Website Builder<br /></span>
                                    <span className="fs-15 fw-600 text-secondary">Crafto Leather</span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}