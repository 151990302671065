import { Link } from "react-router-dom";
import { Fragment } from "react/jsx-runtime";

export default function DemoOtt() {
    return (
        <Fragment>
            <section className="inner-banner ott">
                <div className="container-xl">
                    <div className="row gx-3">
                        <div className="col-lg-7 text-center text-lg-start  align-self-center mb-5 mb-lg-0">
                            <h1 className="mb-3 mb-lg-5 fw-600"><img src="../assets/images/logo-identic.webp" width="140" /> - Launch your <span className="text-red">OTT streaming service</span> in just weeks</h1>
                            <p className="lead-lg mb-4 mb-sm-5 fw-300">End-to-end video platform to power your Live, Video On Demand and 24x7 Linear streaming business.</p>
                            <div className="banner-content-bottom">
                                <Link to="/contact" className="btn btn-primary btn-rounded">Book a Demo</Link>
                            </div>
                        </div>
                        <div className="col-lg-5 align-self-center">
                            <img src="../assets/images/ott-hero.webp" alt="" className="img-fluid d-block mx-auto" />
                        </div>
                    </div>
                </div>
            </section>
            <section className="py-5">
                <div className="container-xl my-0 my-lg-4">
                    <div className="row gx-3 mb-5">
                        <div className="col-lg-10 offset-lg-1 text-center">
                            <h3 className="mb-4">Get ahead with our one-stop-shop solution for all your video streaming software needs</h3>
                            <p className="lead">Focus on your core business and create more content—we'll handle the technology! Benefit from our easy-to-deploy components to deliver a stunning and compelling video and audio experience across all devices. Step into the future of video streaming with our best-in-class solutions.</p>
                        </div>
                    </div>
                    <div className="row gx-3">
                        <div className="col-lg-3 col-md-4 col-sm-6 mb-4">
                            <div className="mybox ott-box1">
                                <h5 className="m-0 fw-300">Video CMS</h5>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-6 mb-4">
                            <div className="mybox ott-box1">
                                <h5 className="m-0 fw-300">LIVE/VOD/Catch-up</h5>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-6 mb-4">
                            <div className="mybox ott-box1">
                                <h5 className="m-0 fw-300">Monetization</h5>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-6 mb-4">
                            <div className="mybox ott-box1">
                                <h5 className="m-0 fw-300">Multi-platform Apps</h5>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-6 mb-4">
                            <div className="mybox ott-box1">
                                <h5 className="m-0 fw-300">User Management</h5>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-6 mb-4">
                            <div className="mybox ott-box1">
                                <h5 className="m-0 fw-300">Analytics & Reporting</h5>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-6 mb-4">
                            <div className="mybox ott-box1">
                                <h5 className="m-0 fw-300">50+ Integrated Partners</h5>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-6 mb-4">
                            <div className="mybox ott-box1">
                                <h5 className="m-0 fw-300">Easy customization</h5>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
            <section className="pb-5">
                <div className="container-xl">
                    <div className="row gx-3">
                        <div className="col-lg-12 text-center">
                            <h3 className="mb-4">Available across 12+ platforms</h3>
                        </div>
                    </div>
                    <div className="row gx-3">
                        <div className="col-6 col-sm-4 col-lg-3 col-xl-2 mb-4"><img src="../assets/images/logo-ott1.webp" alt="" className="img-fluid w-100" /></div>
                        <div className="col-6 col-sm-4 col-lg-3 col-xl-2 mb-4"><img src="../assets/images/logo-ott2.webp" alt="" className="img-fluid w-100" /></div>
                        <div className="col-6 col-sm-4 col-lg-3 col-xl-2 mb-4"><img src="../assets/images/logo-ott3.webp" alt="" className="img-fluid w-100" /></div>
                        <div className="col-6 col-sm-4 col-lg-3 col-xl-2 mb-4"><img src="../assets/images/logo-ott4.webp" alt="" className="img-fluid w-100" /></div>
                        <div className="col-6 col-sm-4 col-lg-3 col-xl-2 mb-4"><img src="../assets/images/logo-ott5.webp" alt="" className="img-fluid w-100" /></div>
                        <div className="col-6 col-sm-4 col-lg-3 col-xl-2 mb-4"><img src="../assets/images/logo-ott6.webp" alt="" className="img-fluid w-100" /></div>
                        <div className="col-6 col-sm-4 col-lg-3 col-xl-2 mb-4"><img src="../assets/images/logo-ott7.webp" alt="" className="img-fluid w-100" /></div>
                        <div className="col-6 col-sm-4 col-lg-3 col-xl-2 mb-4"><img src="../assets/images/logo-ott8.webp" alt="" className="img-fluid w-100" /></div>
                        <div className="col-6 col-sm-4 col-lg-3 col-xl-2 mb-4"><img src="../assets/images/logo-ott9.webp" alt="" className="img-fluid w-100" /></div>
                        <div className="col-6 col-sm-4 col-lg-3 col-xl-2 mb-4"><img src="../assets/images/logo-ott10.webp" alt="" className="img-fluid w-100" /></div>
                        <div className="col-6 col-sm-4 col-lg-3 col-xl-2 mb-4"><img src="../assets/images/logo-ott11.webp" alt="" className="img-fluid w-100" /></div>
                        <div className="col-6 col-sm-4 col-lg-3 col-xl-2 mb-4"><img src="../assets/images/logo-ott12.webp" alt="" className="img-fluid w-100" /></div>
                    </div>
                </div>
            </section>
            <section className="pb-5">
                <div className="container-xl my-0 my-lg-4">
                    <div className="row gx-3">
                        <div className="col-lg-6 text-center text-lg-start mb-4 mb-lg-0 align-self-center">
                            <h3>Manage all your content from one central location</h3>
                            <p className="lead fw-300">Easily upload & manage your content with our API-first, robust video hosting & streaming platforms via Enveu’s video content management system (CMS) or integrate your existing CMS via API. Manage all your content from a central hub, add metadata to each piece of content, effortlessly handle and preview all content variants by device and platforms, control User levels & permissions for different teams, and much more!</p>
                        </div>
                        <div className="col-lg-6 align-self-center">
                            <img src="../assets/images/ott1.webp" alt="" className="img-fluid d-block mx-auto" />
                        </div>
                    </div>
                </div>
            </section>
            <section className="pb-5">
                <div className="container-xl my-0 my-lg-4">
                    <div className="row gx-3">
                        <div className="col-lg-6 order-1 order-lg-2 text-center text-lg-start mb-4 mb-lg-0 align-self-center">
                            <h3>Create & manage intuitive apps across platforms</h3>
                            <p className="lead fw-300">Use the powerful yet simple apps manager to create new UI widgets and change layouts on one or all your app platforms simultaneously & instantly. Add/modify journeys, make unlimited design & layout edits with less / no code across all your ott app or ott tv simultaneously & instantly.</p>
                            <p className="lead fw-300">Leverage easy discovery and binge-watching options to keep your user base engaged. Easily craft a unique experience that your subscribers can relate to with your brand and measure data insights to drive ROI.</p>
                        </div>
                        <div className="col-lg-6 order-2 order-lg-1  align-self-center">
                            <img src="../assets/images/ott2.webp" alt="" className="img-fluid d-block mx-auto" />
                        </div>
                    </div>
                </div>
            </section>
            <section className="pb-5">
                <div className="container-xl my-0 my-lg-4">
                    <div className="row gx-3">
                        <div className="col-lg-6 text-center text-lg-start mb-4 mb-lg-0 align-self-center">
                            <h3>Drive user engagement with live streaming</h3>
                            <p className="lead fw-300">Broadcast your content across 12+ platforms in real-time and engage with your audience smoothly. Give users the power to be in the driver’s seat and see the action as it happens, LIVE!</p>
                            <p className="lead fw-300">Offer live streaming for appointment-based shows & programs and allow users that missed the live stream to access your content from your overall streaming library in a seamless fashion.</p>
                        </div>
                        <div className="col-lg-6 align-self-center">
                            <img src="../assets/images/ott3.webp" alt="" className="img-fluid d-block mx-auto" />
                        </div>
                    </div>
                </div>
            </section>
            <section className="pb-5">
                <div className="container-xl my-0 my-lg-4">
                    <div className="row gx-3">
                        <div className="col-lg-6 order-1 order-lg-2 text-center text-lg-start mb-4 mb-lg-0 align-self-center">
                            <h3>Easily manage subscriptions for your entire userbase</h3>
                            <p className="lead fw-300">Use Identic’s Subscription and Video Broadcasting Platforms to offer PPV, Event Passes, SVOD, and TVOD. Integrate with partners for diverse ad monetization options. </p>
                            <p className="lead fw-300">Provide a seamless payment process, switch revenue models easily, and reduce churn with discounts and coupons. Enhance user engagement with push notifications, personalized emails, and pre-integrated payment gateways to drive long-term adoption.</p>
                        </div>
                        <div className="col-lg-6 order-2 order-lg-1  align-self-center">
                            <img src="../assets/images/ott4.webp" alt="" className="img-fluid d-block mx-auto" />
                        </div>
                    </div>
                </div>
            </section>
            <section className="pt-5 pb-4">
                <div className="container-xl my-0 my-lg-4">
                    <div className="row gx-3 mb-4">
                        <div className="col-md-12 ott-fixed-banner">
                            <img src="../assets/images/ott-banner2.webp" alt="" className="img-fluid w-100" />
                        </div>
                    </div>
                    <div className="row gx-3">
                        <div className="col-md-4 col-sm-6 mb-4">
                            <div className="mybox ott-box2">
                                <h5 className="mb-5">Kickstart your OTT</h5>
                                <p>invest & grow your ott business the right way</p>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6 mb-4">
                            <div className="mybox ott-box2">
                                <h5 className="mb-5">Grow your audience</h5>
                                <p>attract more consumers by launching across multiple devices & platforms</p>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6 mb-4">
                            <div className="mybox ott-box2">
                                <h5 className="mb-5">Choose from multiple monetization models</h5>
                                <p>multiple subscription methods, free trials, advertising (AVOD, display ads)</p>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6 mb-4">
                            <div className="mybox ott-box2">
                                <h5 className="mb-5">Go-to-market faster</h5>
                                <p>multiple subscription methods, free trials, advertising (AVOD, display ads)</p>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6 mb-4">
                            <div className="mybox ott-box2">
                                <h5 className="mb-5">Pay as you grow</h5>
                                <p>we are invested in your growth and succeed only if you do</p>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6 mb-4">
                            <div className="mybox ott-box2">
                                <h5 className="mb-5">Low code, save huge ongoing</h5>
                                <p>easily make changes & tweaks without spending time or money</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="pb-5">
                <div className="container-xl">
                    <div className="box-book-demo">
                        <div className="circle-top"></div>
                        <div className="circle-bottom"></div>
                        <div className="row gx-3">
                            <div className="col-lg-8 text-center text-lg-start mb-4 mb-lg-0 align-items-center">
                                <div id="content-book-demo">
                                    <h2 className="mb-4 fw-600">Your Streaming Success Begins Here</h2><p className="lead-lg fw-500 m-0">Take control of your digital media strategy. Contact us for a no-obligation demo of the Experience Cloud, tailor-made for you!</p>
                                </div>
                            </div>
                            <div className="col-lg-4 text-center align-content-end text-lg-end">
                                <Link to="/contact" className="btn btn-light btn-lg btn-rounded">Book a Demo</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    )
}