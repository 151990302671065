import { useEffect, useState } from "react";
import { Fragment } from "react/jsx-runtime";
import { Blog } from "../types/blog-types";

export default function BlogGrid() {
    const [allBlogs, setAllBlogs] = useState<Array<Blog>>([]);

    const getAllBlogs = () => {
        fetch(`${process.env.REACT_APP_API_URL}blogs`, {
            method: 'GET'
        })
            .then(res => res.json())
            .then(response => {
                setAllBlogs(response.data);
            })
            .catch(error => {
                console.log(error);
            });
    }

    useEffect(() => {
        getAllBlogs();
    }, []);

    useEffect(() => {
        // Ensure the Owl Carousel is initialized after the component is rendered and DOM is ready
        if (allBlogs.length > 0) {
            ($(`.owl_blog`) as any).owlCarousel({
                loop: true,
                autoplay: true,
                autoplayHoverPause: true
            });
        }
    }, [allBlogs]);

    return (
        <Fragment>
            {allBlogs.length > 0 && (
                <div className="owl-theme owl-carousel owl_blog" id="blog_grid_panel">
                    {allBlogs.map((item, index) =>
                        <div className="item" key={index}>
                            <div className="blog-image-wrap mb-3">
                                <img className="img-fluid" src={item.banner_image} alt={item.title} />
                            </div>
                            <h6>{item.title}</h6>
                            <p className="d-flex justify-content-between m-0">
                                <span><b>2023 . <span className="text-green">Automation</span></b></span>
                                <span><img className="img-fluid" src="assets/images/Vision.webp" alt="Vision Icon" />5,000</span>
                            </p>
                        </div>
                    )}
                </div>
            )}
        </Fragment>
    )
}
