import { Link } from "react-router-dom";

export default function AroundMe() {
    return (
        <section className="page-portfolio pt-5">
            <div className="container-xl portfolio-details mt-5">
                <div className="row pt-1 pt-sm-5">
                    <div className="col-lg-10 offset-lg-1 text-center">
                        <h2 className="title mb-5">AroundMe</h2>
                    </div>
                </div>
            </div>
            <div className="container-xl portfolio-details mb-5">
                <img src="../../assets/images/portfolio/around-me/hero.webp" alt="" className="img-fluid w-100" />
            </div>
            <div className="container-xl portfolio-details mb-5">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="about-project">
                            <div className="row">
                                <div className="col-lg-6 mb-5 mb-lg-0">
                                    <h3>About this project </h3>
                                    <p>For AroundMe, we developed a comprehensive iOS app designed to enhance users' ability to discover and explore their surroundings. The app features an intuitive interface, real-time location-based search, and detailed information about nearby businesses and services. Our solution ensures a robust, user-friendly experience, driving engagement and satisfaction for AroundMe users.</p>
                                </div>
                                <div className="col-lg-6">
                                    <div className="about-project-availability">
                                        <div className="row">
                                            <div className="col-sm-6 mb-4">
                                                <h6>Services</h6>
                                                <ul>
                                                    <li>UI/UX Designing</li>
                                                    <li>Project Management</li>
                                                    <li>Application development</li>
                                                    <li>Mobile app development</li>
                                                    <li>Quality Assurance</li>
                                                </ul>
                                            </div>
                                            <div className="col-sm-6 mb-4">
                                                <h6>Technology </h6>
                                                <ul>
                                                    <li>Figma</li>
                                                    <li>CodeIgniter PHP</li>
                                                    <li>Android</li>
                                                    <li>iOS</li>
                                                    <li>MySQL</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="availability">
                                            <a href="https://apps.apple.com/in/app/aroundme/id290051590?platform=iphone" target="_blank"><img src="../../assets/images/link-appstore.webp" alt="App Store" /></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-xl portfolio-details mb-5">
                <div className="row">
                    <div className="col-lg-10 offset-lg-1">
                        <h3>Meet AroundMe</h3>
                        <p>AroundMe has launched an innovative iOS app to provide users with an effortless way to discover and interact with their local environment. Designed with a focus on simplicity and functionality, the app allows users to find nearby businesses, services, and points of interest based on their current location.</p>
                        <p>The app features a user-friendly interface, real-time location updates, and comprehensive details about various local establishments. With its seamless navigation and quick search capabilities, AroundMe empowers users to make informed decisions and enhance their daily experiences. Committed to delivering value and convenience, AroundMe continues to set a high standard in location-based discovery apps.</p>
                    </div>
                </div>
            </div>
            <div className="container-xl portfolio-details mb-5">
                <img src="../../assets/images/portfolio/around-me/image1.png" alt="" className="img-fluid w-100" />
            </div>
            <div className="container-xl portfolio-details mb-5">
                <div className="row">
                    <div className="col-lg-10 offset-lg-1">
                        <h3>AroundMe’s  Growth Story</h3>
                        <p>AroundMe began with a vision to make local exploration easier and more intuitive. The initial success of their concept led to the development of their iOS app, which aimed to provide users with instant access to valuable information about their surroundings. The launch of the app marked a significant step forward, offering enhanced functionality and a refined user experience. Strategic updates and user feedback have further refined the app, boosting its popularity and user engagement. Today, AroundMe stands out as a leading app for local discovery, with a growing user base and a strong presence in the app market.</p>
                    </div>
                </div>
            </div>
            <div className="container-xl portfolio-details mb-5">
                <img src="../../assets/images/portfolio/around-me/image2.png" alt="" className="img-fluid w-100" />
            </div>
            <div className="container-xl portfolio-details mb-5">
                <div className="row">
                    <div className="col-lg-10 offset-lg-1">
                        <h3>What is unique about AroundMe</h3>
                        <p>AroundMe stands out for its seamless integration of real-time location data and user-centric design. The app offers a distinctive experience by providing users with instant, accurate information about nearby businesses and services. Each feature is crafted to ensure ease of use, with an intuitive interface that simplifies the process of finding and exploring local options. What truly sets AroundMe apart is its commitment to delivering a valuable tool for daily life, combining functionality with convenience. The app’s focus on real-time updates and comprehensive local information ensures users have access to the best options available in their vicinity. This dedication to innovation and user satisfaction makes AroundMe a standout choice for discovering and navigating local environments.</p>
                    </div>
                </div>
            </div>
            <div className="container-xl portfolio-details mb-5">
                <img src="../../assets/images/portfolio/around-me/image3.png" alt="" className="img-fluid w-100" />
            </div>
            <div className="container-xl portfolio-details mb-5">
                <div className="row">
                    <div className="col-lg-10 offset-lg-1">
                        <h3>Faced Challenges</h3>
                        <p>The existing app faced challenges with location accuracy and user interface design, impacting the overall effectiveness of discovering local businesses and services.</p>
                        <p>Addressing these challenges required optimizing location-based services, enhancing the app’s interface for better usability, and ensuring accurate and reliable data. Successfully navigating these tasks demanded a combination of advanced technology integration, design improvements, and rigorous testing. The ability to provide precise location information, a user-friendly interface, and a seamless experience was crucial for achieving app success.</p>
                    </div>
                </div>
            </div>
            <div className="container-xl portfolio-details mb-5">
                <img src="../../assets/images/portfolio/around-me/image4.png" alt="" className="img-fluid w-100" />
            </div>
            <div className="container-xl portfolio-details mb-5">
                <div className="row">
                    <div className="col-lg-10 offset-lg-1">
                        <h3>Project Approach</h3>
                        <p>The project began with discovery, analyzing user needs and current app challenges. Planning set milestones, timelines, and communication. Development optimized features and performance. Testing ensured quality. Successful implementation launched the updated app.</p>
                        <p>The overall approach to the project involved a detailed and structured process. Starting with the discovery phase, the team examined user feedback, identified pain points, and defined areas for improvement. During the planning stage, milestones and timelines were established, and communication channels were set up for effective collaboration. In the development phase, the focus was on enhancing location accuracy, refining the user interface, and improving app performance. Extensive testing and quality assurance measures were implemented to ensure a flawless user experience. The project was then implemented, with the updated app successfully launching to users.</p>
                        <p className="mb-2">Key Benefits</p>
                        <ul>
                            <li>Improved location accuracy and real-time updates.</li>
                            <li>Enhanced user interface for a more intuitive experience.</li>
                            <li>Reliable and comprehensive information about nearby businesses and services.</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="container-xl portfolio-details mb-5">
                <img src="../../assets/images/portfolio/around-me/image5.png" alt="" className="img-fluid w-100" />
            </div>
            <div className="container-xl portfolio-details mb-5">
                <div className="row">
                    <div className="col-lg-10 offset-lg-1">
                        <h3>Results</h3>
                        <p>Following the implementation of the project, AroundMe experienced numerous positive outcomes. The updated iOS app resolved previous issues with location accuracy and user interface design, resulting in a more reliable and engaging tool for local discovery. The enhanced features provided users with accurate, real-time information, boosting satisfaction and increasing app usage. The optimized app successfully attracted a growing user base, enhancing AroundMe’s presence in the market. Overall, the project delivered significant improvements to the app’s functionality and user experience, ensuring a valuable and effective tool for exploring local environments.</p>
                    </div>
                </div>
            </div>
            <div className="container-xl portfolio-details mb-5">
                <div className="row">
                    <div className="col-lg-8 offset-lg-2">
                        <div className="portfolio-quote">
                            <div className="content">
                                Webmaa's adaptability to our evolving needs and the steady stream of insightful feedback and ideas prove they were not just a partner – they were an indispensable asset in helping us reach our vision to democratize the fintech industry.
                            </div>
                            <div className="author">
                                <div className="auth-image">
                                    <img src="../../assets/images/portfolio/around-me/author.png" alt="author" />
                                </div>
                                <div className="auth-desig">
                                    <h6>User Name</h6>
                                    <p>Designation, Company Name</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-xl portfolio-nav mb-5">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="row">
                            <div className="col-6">
                                <Link to="/portfolio/mohendra-dutt" className="d-block pprev">
                                    <span className="fs-14 fw-500 text-black">Previous Project</span> <br />
                                    <span className="fs-13 text-gray d-none d-sm-block">Fast and Simple Website Builder<br /></span>
                                    <span className="fs-15 fw-600 text-secondary">Mohendra Dutt</span>
                                </Link>
                            </div>
                            <div className="col-6">
                                <Link to="/portfolio/wiseasy" className="d-block pnext text-end">
                                    <span className="fs-14 fw-500 text-black">Next Project</span> <br />
                                    <span className="fs-13 text-gray d-none d-sm-block">Fast and Simple Website Builder<br /></span>
                                    <span className="fs-15 fw-600 text-secondary">Wiseasy</span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}